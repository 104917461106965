import {normalize} from "normalizr";
import * as schema from "../schema";

import {toastr} from "react-redux-toastr";

function shouldFetchData(state) {
    return (Object.keys(state.preRegistrations.dictionary).length == 0 && !state.preRegistrations.isFetching)
}

function fetchData() {
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch({
                type: 'PREREGISTRATIONLIST_FETCH',

                payload: fetch(Routing.generate('portfolio_domains_json'), {
                    credentials: 'same-origin'
                }).then(response => {
                        return response.json()
                    }
                ).then(domains => {
                    let d = domains.map(domain => {
                        return {
                            ...domain,
                            expirationdate: new Date(domain.expirationdate),
                        }
                    })

                    if (d.length == 0) {
                        return {
                            entities: {
                                domain: {}
                            },
                            result: []
                        }
                    } else {
                        return normalize(d, schema.arrayOfDomains);
                    }
                })
            })
        }
    }
}

function sortBy(sortKey) {
    return {
        type: 'PREREGISTRATIONLIST_SORT',
        orderBy: sortKey
    }
}

function gotoPage(page) {
    return {
        type: "PREREGISTRATIONLIST_GOTO_PAGE",
        page: page
    }
}

function setItemsPerPage(itemsPerPage) {
    return {
        type: "PREREGISTRATIONLIST_SET_ITEMS_PER_PAGE",
        itemsPerPage: itemsPerPage
    }
}

function filterBy(filter) {
    return {
        type: "PREREGISTRATIONLIST_FILTER",
        filter: filter
    }
}

function confirmClaim(preRegistration, claimConfirmation) {

    return (dispatch, getState) => {

        var state = getState();

        let csrfToken = state.csrf.token;

        if (claimConfirmation == undefined) {
            // dispatch TrademarkClaim
            dispatch({
                type: 'CLAIM_CONFIRMATION_MISSING',
                payload: {
                    domain: preRegistration.domain,
                    toplevelDomain: preRegistration.tld,
                    lookupKey: trademarkClaim,
                }
            });

            return;
        } else {
            const query = new URLSearchParams();

            let csrfToken = getState().csrf.token;

            query.append('_token', csrfToken);

            return dispatch({
                type: 'PREREGISTRATION_CONFIRM_TRADEMARK',
                meta: {
                    domain: preRegistration.domain,
                    toplevelDomain: preRegistration.tld,
                },
                payload: fetch(Routing.generate('portfolio_prereg_confirm_claim', {'domain': preRegistration.domain}), {
                    credentials: 'same-origin',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then(response => {
                    if (response.headers.get('Content-Type').indexOf("json") > 0) {
                        return response.json();
                    } else {
                        toastr.error(response.statusText);
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                    }
                }).then(ret => {
                    if (ret.success == false) {
                        toastr.error(ret.message);
                        throw Error(ret.message);
                    }

                    return ret.preOrder;
                })
            });
        }
    }
}

function deletePreRegistration(preRegistration) {

    return (dispatch, getState) => {

        var state = getState();

        const query = new URLSearchParams();
        let csrfToken = state.csrf.token;
        query.append('_token', csrfToken);

        return dispatch({
            type: 'PREREGISTRATION_DELETE',
            meta: {
                domain: preRegistration.domain,
                toplevelDomain: preRegistration.tld,
            },
            payload: fetch(Routing.generate('portfolio_prereg_delete', {'id': preRegistration.id}), {
                credentials: 'same-origin',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: query
            }).then(response => {
                if (response.headers.get('Content-Type').indexOf("json") > 0) {
                    return response.json();
                } else {
                    toastr.error(response.statusText);
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                }
            }).then(ret => {
                if (ret.success == false) {
                    toastr.error(ret.message);
                    throw Error(ret.message);
                } else {
                    toastr.success('The pre-registration for ' + preRegistration.domain + ' was successfully deleted')
                }

                return;
            })
        });
    }
}

export default {
    fetchData,
    filterBy,
    sortBy,
    gotoPage,
    setItemsPerPage,
    confirmClaim,
    deletePreRegistration,
}
