import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {presets, spring, TransitionMotion} from "react-motion";
import {bindActionCreators} from "redux";
import {cartActions} from "../actions";
import MaterialIcon from "../components/MaterialIcon";
import CartMenuElement from "../components/CartMenuElement";

class CartMenu extends React.Component {

    // actual animation-related logic
    getDefaultStyles = () => {
        return this.props.cart.dictionary.map((domain) => {
            return {
                data: domain,
                key: domain,
                style: {
                    height: 0,
                    opacity: 1,
                    fontSize: 12,
                    paddingTop: 0,
                    paddingBottom: 0
                }
            };
        });
    };

    getStyles = () => {
        return this.props.cart.dictionary.map((domain) => {
            return {
                data: this.props.cart.entities[domain],
                key: domain,
                style: {
                    height: spring(15, presets.gentle),
                    opacity: spring(1, presets.gentle),
                    fontSize: spring(12, presets.gentle),
                    paddingTop: spring(8, presets.gentle),
                    paddingBottom: spring(8, presets.gentle),
                }
            };
        });
    };

    willEnter() {
        return {
            height: 0,
            opacity: 1,
            fontSize: 12,
            paddingTop: 0,
            paddingBottom: 0
        };
    };

    willLeave() {
        return {
            height: spring(.5),
            opacity: spring(0),
            fontSize: spring(.5),
            paddingTop: spring(0),
            paddingBottom: spring(0)
        };
    };

    render() {
        return (
            <React.Fragment>
                <li>
                    <h3>Your Cart</h3>
                </li>
                {(() => {
                    if (this.props.cart.dictionary.length == 0) {
                        return (<li>
                            No domains in your cart.
                        </li>);
                    } else {
                        return <React.Fragment><TransitionMotion
                            defaultStyles={this.getDefaultStyles()}
                            styles={this.getStyles()}
                            willLeave={this.willLeave}
                            willEnter={this.willEnter}>
                            {styles => <React.Fragment>
                                {styles.map((style) => (
                                    <CartMenuElement key={style.key}
                                                     item={style.data}
                                                     removeDomainFromCart={this.props.removeDomainFromCart}
                                                     style={style.style}
                                    />
                                ))}
                            </React.Fragment>
                            }
                        </TransitionMotion>
                            {(() => {
                                if(window.taxRate && window.taxRate > 0) {
                                    let formattedTaxRate = window.taxRate;
                                    return <div id="taxHint">Prices include {formattedTaxRate}% VAT</div>
                                } else {
                                    return '';
                                }
                            })()}
                        </React.Fragment>
                    }
                })()}
                <li>
                    {(() => {
                        if (this.props.cart.dictionary.length == 0) {
                            <button className="btn btn-success disabled">
                                Checkout <MaterialIcon icon="trending_flat"/>
                            </button>
                        } else {
                            return <a href={Routing.generate('cart_index')} className="btn btn-success">
                                Checkout <MaterialIcon icon="trending_flat"/>
                            </a>
                        }
                    })()}
                </li>
            </React.Fragment>
        );
    }

    static propTypes = {
        children: PropTypes.node
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CartMenu)
