function getMailForwardData(state, resourceRecords, key) {

    // there should only be one x-smtp entry for name == '@'
    let smtpRecordIndex = resourceRecords.findIndex(rr => rr.type == 'x-smtp' && rr.name == '@');

    let forwards = {
        dictionary: [],
        entities: {},
        edit: null,
        saving: null
    };

    if (smtpRecordIndex == undefined) {
        return forwards;
    }

    let smtpRecord = resourceRecords[smtpRecordIndex];

    /**
     * group by forwarded mail address
     *
     * smtpRecord = {
     *  key: "..."
     *  name: "..."
     *  rdata: [
     *      "test@ MAILFORWARD test2@test.com",
     *      "test2@ MAILFORWARD test@test.com",
     *      "test2@ MAILFORWARD info@test.com",
     *  ]
     *
     *  mailForwards: {
     *      dictionary: [
     *          'test',
     *          'test2'
     *      ]
     *      entries: {
     *          'test': {
     *              'local': 'test',
     *              'targets': [
     *                  'test2@test.com',
     *              ]
     *          },
     *          'test2': {
     *              'local: 'test2',
     *              'targets': [
     *                  'test@test.com',
     *                  'info@test.com'
     *              ],
     *          }
     *      }
     *      edit: null
     *  }
     */

    if(smtpRecord != undefined && smtpRecord.hasOwnProperty("rdata")) {
        for (let rd of smtpRecord.rdata) {
            let parts = rd.split(' ');
            parts[0] = parts[0].slice(0, -1);

            if (forwards.entities.hasOwnProperty(parts[0])) {
                forwards.entities[parts[0]].targets.push(parts[2]);
            } else {
                let deleting = false;
                if(
                    state.entities.hasOwnProperty(parts[0]) &&
                    state.entities[parts[0]].deleting == true
                ) {
                    deleting = true;
                }

                forwards.dictionary.push(parts[0]);
                forwards.entities[parts[0]] = {
                    'local': parts[0],
                    'targets': [parts[2]],
                    'deleting': deleting,
                    'testing': false
                };
            }
        }

        forwards.dictionary.sort();
    }


    return forwards;
}

const mailForwardReducer = (state = {
    entities: {},
    dictionary: []

    /*entities: {
        'fraud-filter-test.com.': {
            "dictionary": ["test", "test2", "test5", "test7"],
            "entities": {
                "test": {"local": "test", "targets": ["test@test.com", "test2@test.com"]},
                "test2": {"local": "test2", "targets": ["test3@test.com", "test4@test.com"]},
                "test5": {"local": "test5", "targets": ["test6@test.com"]},
                "test7": {"local": "test7", "targets": ["test8@test.com"]}
            },
            "edit": null
        }
    },
    dictionary: [
        'fraud-filter-test.com.'
    ]*/
}, action) => {

    let zone;

    // implement sorting resource records
    switch (action.type) {
        case "DNSZONE_FETCH_FULFILLED": {
            let mailForwards = getMailForwardData(state, action.payload.resourceRecords);

            let dictionary = state.dictionary;

            if(dictionary.indexOf(action.meta.zone) == -1) {
                dictionary.push(action.meta.zone);
            }

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: mailForwards
                },
                dictionary: [
                    ,
                    action.meta.zone
                ],
            }
        }
        case "DNSZONE_DELETE_RR_PENDING":
        case "DNSZONE_SAVE_RR_SET_PENDING": {
            if (action.meta.type == 'x-smtp' && action.meta.name == '@') {
                if (action.meta.hasOwnProperty('mailForwardOp')) {
                    switch (action.meta.mailForwardOp.type) {
                        case "delete":
                            return {
                                ...state,
                                entities: {
                                    ...state.entities,
                                    [action.meta.zone]: {
                                        ...state.entities[action.meta.zone],
                                        entities: {
                                            ...state.entities[action.meta.zone].entities,
                                            [action.meta.mailForwardOp.local]: {
                                                ...state.entities[action.meta.zone].entities[action.meta.mailForwardOp.local],
                                                deleting: true
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case "save":
                            return {
                                ...state,
                                entities: {
                                    ...state.entities,
                                    [action.meta.zone]: {
                                        ...state.entities[action.meta.zone],
                                        saving: action.meta.mailForwardOp.initialLocal
                                    }
                                }
                            }
                            break;
                        default:
                            // this should never happen
                            return state;
                    }
                }
            }
            return state;
        }
        case "DNSZONE_DELETE_RR_FULFILLED":
        case "DNSZONE_SAVE_RR_SET_FULFILLED": {
            if(!action.payload.data.hasOwnProperty('resourceRecordSet')) {
                return state;
            }
            let rrSet = action.payload.data.resourceRecordSet;

            if (!rrSet.hasOwnProperty('name')) {
                return state;
            }
            if(rrSet.name != '@' || rrSet.type != 'x-smtp') {
                return state;
            }

            let mailForwards = getMailForwardData(state, [rrSet]);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: mailForwards
                },
                dictionary: [
                    ...state.dictionary,
                    action.meta.zone
                ],
            }
        }
        case "MAILFORWARD_EDIT":

            return {
                ...state,
                entities: {
                    [action.payload.zone]: {
                        ...state.entities[action.payload.zone],
                        edit: action.payload.local
                    }
                }
            };
        case "MAILFORWARD_CANCEL_EDIT":
            return {
                ...state,
                entities: {
                    [action.payload.zone]: {
                        ...state.entities[action.payload.zone],
                        edit: null
                    }
                }
            };
        case "MAILFORWARD_TEST_FORWARD_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain + '.']: {
                        ...state.entities[action.meta.domain + '.'],
                        entities: {
                            ...state.entities[action.meta.domain + '.'].entities,
                            [action.meta.local]: {
                                ...state.entities[action.meta.domain + '.'].entities[action.meta.local],
                                testing: true
                            }
                        }
                    }
                }
            }
        case "MAILFORWARD_TEST_FORWARD_REJECTED":
        case "MAILFORWARD_TEST_FORWARD_FULFILLED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain + '.']: {
                        ...state.entities[action.meta.domain + '.'],
                        entities: {
                            ...state.entities[action.meta.domain + '.'].entities,
                            [action.meta.local]: {
                                ...state.entities[action.meta.domain + '.'].entities[action.meta.local],
                                testing: false
                            }
                        }
                    }
                }
            }
        default: {
            return state;
        }
    }
};

export default mailForwardReducer;
