import React from "react";
import PropTypes from "prop-types";
import {DropdownButton, FormControl, MenuItem, OverlayTrigger, Pagination, Popover, Table} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import saveAs from "save-as";
import dateFormat from "dateformat";
import LoadingIndicator from "../LoadingIndicator";
import Switch from "react-bootstrap-switch";
import ConfirmDomainExpirationModal from "./ConfirmDomainExpirationModal";
import ConfirmDisableWPSModal from "./ConfirmDisableWPSModal";
import MaterialIcon from "../MaterialIcon";
import Link from "../../containers/Link";

class ManageDomainsTable extends React.Component {

    constructor(props) {
        super(props)
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.exportList = this.exportList.bind(this);
        this.getItemsPerPageOptions = this.getItemsPerPageOptions.bind(this);
    }

    componentDidMount() {
        this.props.fetchData()
    }

    getItemsPerPageOptions() {
        // only show this when we have more than 20 pages, show only the useful items
        return [10, 20, 50, 100, 200, 500].map((ipp) => {
            if (ipp <= 2.5 * this.props.dictionary.length) {
                return (
                    <MenuItem key={ipp}
                              onSelect={() => this.props.setItemsPerPage(ipp)}>
                        {ipp}
                    </MenuItem>);
            }
        });
    }

    handleFilterChange(event) {
        this.props.filterBy(event.target.value.trim());
    }

    handleSort(sortBy) {
        this.props.sortBy(sortBy);
    }

    exportList() {
        let csv;

        csv = this.props.pageList.map((domain) => {
            let domainData = this.props.entities[domain];
            return '"' + domainData.domain + '";"' + domainData.status + '";"' + dateFormat(domainData.expirationdate, "yyyy-mm-dd") + '";"' + domainData.wps + '"' + "\r\n";
        });

        csv.unshift('"Domain";"Status";"Whois Privacy";"Installed WebApps"' + "\n");

        let blob = new Blob(csv, {type: "text/plain;charset=utf-8"});
        saveAs(blob, "domains.csv");
    }

    getFooterText() {
        const {isFetching, length, filteredLength, page, itemsPerPage} = this.props;
        let ret;

        if (length == 0) {
            if (isFetching) {
                ret = "Loading your domains..."
            } else {
                ret = "You have no domains registered at this time."
            }
        } else {
            if (filteredLength == 0) {
                return 'None of the ' + length + ' domains match your filter';
            }

            let first = ((page - 1) * itemsPerPage + 1);
            let last = (page * itemsPerPage);

            if (last > filteredLength) {
                last = filteredLength;
            }
            ret = "Showing " + first + " to " + last + " of " + filteredLength + " Domains"

            if (filteredLength < length) {
                ret += " (filtered from " + length + " total Domains)";
            }
        }

        return ret;
    }

    changeWps(domain, enable) {
        if (enable == false) {
            this.props.changeWPSDomain(domain);
        } else {
            this.props.setWps(domain, true);
        }
    }

    loadAuthcode(domain) {
        this.props.loadAuthCode(domain);
    }

    renewDomain(domain) {
        this.props.addToCart(domain.domain, domain.tld, null, {'type': 13});
    }

    getTableContents() {
        return this.props.pageList.map((domainName) => {
            let domain = this.props.entities[domainName];

            return (
                <tr key={domain.domain}>
                    <td>
                        <a href={"http://" + domain.domain} target="_blank"
                           title={"Open " + domain.domain + " in the browser"}>
                            {domain.domain}&nbsp;
                            <span className="glyphicon glyphicon-link" aria-hidden="true"></span>
                        </a>
                        {(() => {
                            if (domain.lock.hasLocks) {
                                const popover = (
                                    <Popover id={domain.domain + '_locked'} title="This domain is locked">
                                        You cannot edit the configuration of this domain. Please contact our customer
                                        support to resolve this issue.
                                    </Popover>
                                );

                                return (
                                    <OverlayTrigger overlay={popover}>
                                        <a href="#">
                                            <MaterialIcon icon="lock" className="red"/>
                                        </a>
                                    </OverlayTrigger>);
                            }

                            return;
                        })()}
                    </td>
                    <td>
                        {(() => {
                            if (domain.status != 'billingexpire') {
                                // implement changing the domain-status
                                // from expire to renew without modal
                                // from renew to expire with modal

                                return <span>
                                    <Switch value={domain.status == 'autorenew'}
                                            bsSize="mini"
                                            wrapperclassName=""
                                            onText="Autorenew"
                                            offText="Expire"
                                            offColor="danger"
                                            disabled={domain.lock.hasLocks}
                                            onChange={() => domain.status == 'autorenew' ? this.props.changeDomainStatus(domain) : this.props.saveDomainStatus(domain, 'autorenew')}/>
                                    {' '}
                                    <LoadingIndicator active={domain.savingStatus}
                                                      inline={true}
                                                      size="mini"/>
                                </span>
                            } else {

                                return (
                                    <span className="color-red" disabled={true}>
                                        unpaid (<a href={Routing.generate('portfolio_billing_index')}>pay</a>)
                                    </span>)
                            }
                        })()}
                    </td>
                    <td>{(() => {
                        switch (domain.wps) {
                            case 'enabled':
                            case 'disabled':
                                return (
                                    <span>
                                        <Switch value={domain.wps == 'enabled'}
                                                bsSize="mini"
                                                wrapperclassName=""
                                                onText="Enabled"
                                                offText="Disabled"
                                                disabled={domain.savingWPS || domain.lock.hasLocks || domain.status == "billingexpire"}
                                                onChange={() => this.changeWps(domain, domain.wps != 'enabled')}/>{' '}
                                        <LoadingIndicator active={domain.savingWPS}
                                                          inline={true}
                                                          size="mini"
                                        />
                                    </span>)
                                break;
                            default:
                                return;
                        }
                    })()}
                    </td>
                    <td><FormattedDate
                        value={domain.expirationdate}
                        year='numeric'
                        month='long'
                        day='2-digit'/>
                    </td>
                    <td>
                        {(() => {
                            if (domain.status == 'billinexpire') {
                                return <span>unpaid</span>
                            } else if (domain.hasLocks) {
                                return <span>locked</span>
                            } else if (domain.authcode == null) {
                                return <div className="input-group">
                                    <button className="btn btn-xs btn-default"
                                            onClick={() => {
                                                this.loadAuthcode(domain.domain)
                                            }}
                                            disabled={domain.loadingAuthcode}
                                    >
                                        {domain.loadingAuthcode ? 'Loading...' : 'Get Authcode'}
                                    </button>
                                </div>
                            } else {
                                return <React.Fragment>
                                    <input className="authcode form-control input-sm"
                                           value={domain.authcode}
                                           id={'authcode_' + domain.domain}
                                           readOnly={true}
                                    />
                                    {/*<span className="input-group-btn">
                                        <button className="btn btn-sm btn-default authcode-clipboard"
                                                data-clipboard-target={'#authcode_' + domain.domain} type="button">
                                            <img className="clippy" src="/img/clippy.svg" width="15" alt="Copy to clipboard" />
                                        </button>
                                    </span>*/}
                                </React.Fragment>
                            }
                        })()}
                    </td>
                    <td>
                        <Link to={"/portfolio/dns/" + domain.domain}
                              className="btn btn-gray btn-xs"
                              disabled={domain.lock.dns || domain.status == 'billingexpire'}>
                            DNS
                        </Link>{' '}
                        <Link to={"/portfolio/forwards/" + domain.domain}
                              className="btn btn-gray btn-xs"
                              disabled={domain.lock.dns || domain.status == 'billingexpire'}>Forwards</Link>{' '}
                        <Link to={'/portfolio/email/' + domain.domain}
                              className="btn btn-gray btn-xs"
                              disabled={domain.lock.mail || domain.status == 'billingexpire'}>Email</Link>{' '}
                        <Link to={"/portfolio/whois/" + domain.domain}
                              className="btn btn-gray btn-xs"
                              disabled={domain.lock.whois || domain.status == 'billingexpire'}>
                            Whois
                        </Link>
                    </td>
                    <td>
                        {(() => {
                            if (this.props.cart.entities && this.props.cart.entities[domainName]) {
                                // if the domain renews within the next 7 days, we should disallow manual renewal
                                if (this.props.cart.entities[domainName].adding) {
                                    return <button disabled={true}
                                                   className="btn btn-xs btn-primary renewNow"
                                    >
                                        Renew in advance
                                    </button>
                                } else {
                                    return <a href={Routing.generate('cart_index')} title="Goto your cart"
                                              className="btn btn-xs btn-success">
                                        In your cart
                                    </a>
                                }
                            } else {
                                if (domain.status != "billingexpire") {
                                    return <button onClick={() => {
                                        this.renewDomain(domain)
                                    }}
                                                   className="btn btn-xs btn-primary renewNow"
                                    >
                                        Renew in advance
                                    </button>
                                }
                            }
                        })()}
                    </td>
                </tr>
            )
        });
    }

    render() {
        const {totalPages, isFetching, page, itemsPerPage, currentStateDomain, currentWPSDomain, error, orderBy, order, dictionary} = this.props;

        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="row clearfix">
                        <div className="col-xs-12 col-sm-6">
                            <h1>Manage Domains</h1>
                        </div>
                        <div className="col-sm-6 hidden-xs">
                            <div className="pull-right mt15">
                                <form role="form" className="form-inline">
                                    <div className="dropdown  hidden-sm" style={{display: "inline-block"}}>
                                        <button className="btn btn-primary dropdown-toggle" type="button"
                                                id="dropdownMenu1"
                                                data-toggle="dropdown" aria-expanded="true">
                                            Bulk Actions&nbsp;
                                            <span className="caret"></span>
                                        </button>
                                        <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu1">
                                            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                                       onClick={this.exportList}>Export
                                                domains</a></li>
                                            { /*<li role="presentation"><a role="menuitem" tabIndex="-1" href="#">Enable Domain
                                             Privacy</a></li>
                                             <li role="presentation"><a role="menuitem" tabIndex="-1" href="#">Renew</a></li>
                                             <li role="presentation"><a role="menuitem" tabIndex="-1" href="#">Set Status</a></li>*/}
                                        </ul>
                                    </div>
                                    &nbsp;
                                    {(() => {
                                        if (this.props.dictionary.length > 20) {
                                            return (
                                                <div className="form-group">
                                        <span className='dropdown'>
                                            <DropdownButton title={itemsPerPage} id="bg-nested-dropdown">
                                                {this.getItemsPerPageOptions()}
                                            </DropdownButton>
                                        </span>&nbsp;
                                                </div>
                                            )
                                        }
                                    })()}
                                    {(() => {
                                        if (this.props.dictionary.length > 1) {
                                            return (<div className="form-group">
                                                <FormControl
                                                    type="search"
                                                    placeholder="Filter"
                                                    onChange={this.handleFilterChange}
                                                />
                                            </div>)
                                        }
                                    })()}
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Table responsive hover striped>
                                <thead>
                                <tr>
                                    <th onClick={() => this.handleSort('domain')}
                                        className={orderBy == 'domain' ? 'sorting-' + order : 'sorting'}>
                                        Domain&nbsp;</th>
                                    <th onClick={() => this.handleSort('status')}
                                        className={orderBy == 'status' ? 'sorting-' + order : 'sorting'}>
                                        Status&nbsp;</th>
                                    <th onClick={() => this.handleSort('wps')}
                                        className={orderBy == 'wps' ? 'sorting-' + order : 'sorting'}><span
                                        className="hidden-xs hidden-sm">Domain&nbsp;</span>Privacy&nbsp;</th>
                                    <th onClick={() => this.handleSort('expirationdate')}
                                        className={orderBy == 'expirationdate' ? 'sorting-' + order : 'sorting'}>
                                        Expiration
                                        <span className="hidden-xs hidden-sm">&nbsp;Date&nbsp;</span></th>
                                    <th>Auth-Code</th>
                                    <th>Settings</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tfoot>
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        {this.getFooterText()}
                                    </td>
                                </tr>
                                </tfoot>
                                <tbody>
                                {this.getTableContents()}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <ConfirmDomainExpirationModal
                        domain={this.props.entities[currentStateDomain]}
                        onConfirm={() => this.props.saveDomainStatus(this.props.entities[currentStateDomain], 'expire')}
                        onCancel={() => this.props.changeDomainStatus(null)}
                    />

                    <ConfirmDisableWPSModal
                        domain={this.props.entities[currentWPSDomain]}
                        onConfirm={() => this.props.setWps(this.props.entities[currentWPSDomain], false)}
                        onCancel={() => this.props.changeWPSDomain(null)}
                    />

                    {(() => {
                        if (dictionary.length > 20) {
                            return <Pagination
                                prev
                                next
                                first
                                last
                                boundaryLinks
                                items={totalPages}
                                maxButtons={5}
                                activePage={page}
                                onSelect={this.props.gotoPage}
                            />
                        }
                    })()}

                </div>
            </div>
        )
    }

    static propTypes = {
        // actions
        fetchData: PropTypes.func.isRequired,
        gotoPage: PropTypes.func.isRequired,
        setItemsPerPage: PropTypes.func.isRequired,
        sortBy: PropTypes.func.isRequired,
        changeDomainStatus: PropTypes.func.isRequired,
        saveDomainStatus: PropTypes.func.isRequired,
        changeWPSDomain: PropTypes.func.isRequired,
        setWps: PropTypes.func.isRequired,
        loadAuthCode: PropTypes.func.isRequired,

        // state data
        totalPages: PropTypes.number.isRequired,
        length: PropTypes.number.isRequired,
        filteredLength: PropTypes.number.isRequired,
        pageList: PropTypes.array.isRequired,
        entities: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        page: PropTypes.number.isRequired
    }
}

export default ManageDomainsTable
