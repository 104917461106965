import React, {Component} from "react";
import {OverlayTrigger, Popover, Table} from "react-bootstrap";
import {spring, TransitionMotion} from "react-motion";
import MaterialIcon from "../MaterialIcon";
import GlueRecord from "./GlueRecord";
import GlueRecordForm from "./Form/GlueRecordForm";
import {toastr} from "react-redux-toastr";
import {confirm} from "../../util/confirm";

class GlueRecords extends Component {

    constructor(props) {
        super(props);

        this.editGlueRecord = this.editGlueRecord.bind(this);
        this.deleteGlueRecord = this.deleteGlueRecord.bind(this);
        this.cancelEditGlueRecord = this.cancelEditGlueRecord.bind(this);
    }

    // actual animation-related logic
    getDefaultStyles = () => {
        return this.props.zone.glueRecords.map((glueRecord, index) => ({
            data: glueRecord,
            key: glueRecord.key,
            style: {
                height: 40,
                opacity: 1,
                fontSize: 14,
                paddingTop: 8,
                paddingBottom: 8
            }
        }));
    };

    getStyles = () => {
        return this.props.zone.glueRecords.map((glueRecord, index) => {
            return {
                data: glueRecord,
                key: glueRecord.key,
                style: {
                    height: spring(40, {stiffness: 90, damping: 14}),
                    opacity: spring(1, {stiffness: 90, damping: 14}),
                    fontSize: spring(14, {stiffness: 90, damping: 14}),
                    paddingTop: spring(8, {stiffness: 90, damping: 14}),
                    paddingBottom: spring(8, {stiffness: 90, damping: 14}),
                }
            };
        });
    };

    deleteGlueRecord(name, key) {
        confirm('Are you sure to delete the glue record for ' + name + '.' + this.props.domain.domain + '? This action cannot be undone!', {
            'title': 'Delete Glue record?'
        }).then(() => {
            return this.props.deleteGlueRecord(this.props.domain.domain + '.', name, key);
        }).then(({value, action}) => {
            if (value.data && value.data.success && value.data.success == true) {
                toastr.success("Glue Record deleted successfully!");
            }
        }).catch(error => {
        })
    }

    editGlueRecord(key) {
        this.props.editGlueRecord(this.props.domain.domain + ".", key);
    }

    cancelEditGlueRecord() {
        this.props.cancelEditGlueRecord(this.props.domain.domain + ".");
    }

    willLeave() {
        return {
            height: spring(0, {stiffness: 90, damping: 14}),
            opacity: spring(0, {stiffness: 90, damping: 14}),
            fontSize: spring(0, {stiffness: 90, damping: 14}),
            paddingTop: spring(0, {stiffness: 90, damping: 14}),
            paddingBottom: spring(0, {stiffness: 90, damping: 14}),
        };
    };

    willEnter() {
        return {
            height: 0,
            opacity: 0,
            fontSize: 0,
            paddingTop: 0,
            paddingBottom: 0,
        };
    };

    render() {
        const hostnamePopover = (
            <Popover id="glue-hostname-help">
                The public name of the host to register.
            </Popover>
        );

        const ipv4Popover = (
            <Popover id="glue-ipv4-help">
                The IPv4 address or addresses of the host to register.
            </Popover>
        );

        const ipv6Popover = (
            <Popover id="glue-ipv6-help">
                The IPv6 address or addresses of the host to register.
            </Popover>
        );

        return (
            <React.Fragment>
                <Table id="glue-records">
                    <thead>
                    <GlueRecordForm new={true}
                                    _key="_new"
                                    initialValues={{
                                        'name': '',
                                        'ipv4': [''],
                                        'ipv6': [''],
                                        'key': '_new'
                                    }}
                                    cancelEditGlueRecord={this.cancelEditGlueRecord}
                                    zone={this.props.domain.domain + '.'}
                                    allGlueRecords={this.props.zone.glueRecords}
                                    form="GlueRecord_new"
                                    saving={this.props.zone.savingGlue}
                    />
                    <tr>
                        <th>
                            Hostname{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={hostnamePopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th>
                            IPv4 Address{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={ipv4Popover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th>
                            IPv6 Address{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={ipv6Popover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <TransitionMotion
                        defaultStyles={this.getDefaultStyles()}
                        styles={this.getStyles()}
                        willLeave={this.willLeave}
                        willEnter={this.willEnter}
                    >
                        {styles =>
                            <tbody>
                            {(() => {
                                if (styles.length) {
                                    return styles.map((d, index) => {
                                        let className;

                                        if (d.style.height < 40) {
                                            className = "removing";
                                        }

                                        if (
                                            this.props.zone.editGlue != null &&
                                            this.props.zone.editGlue == d.data.key
                                        ) {
                                            return (
                                                <GlueRecordForm key={d.key}
                                                                _key={d.key}
                                                                initialValues={d.data}
                                                                cancelEditGlueRecord={this.cancelEditGlueRecord}
                                                                zone={this.props.domain.domain + '.'}
                                                                allGlueRecords={this.props.zone.glueRecords}
                                                                form={"GlueRecord_" + d.key}
                                                                saving={this.props.zone.savingGlue}
                                                />
                                            )
                                        } else {
                                            let {paddingTop, paddingBottom, ...s} = d.style;

                                            return <GlueRecord key={d.key}
                                                               _key={d.key}
                                                               style={s}
                                                               tdStyle={{
                                                                   paddingBottom: paddingBottom,
                                                                   paddingTop: paddingTop
                                                               }}
                                                               domain={this.props.domain.domain}
                                                               glueRecord={d.data}
                                                               className={className}
                                                               editGlueRecord={this.editGlueRecord}
                                                               deleting={this.props.zone.deletingGlue == d.key}
                                                               deleteGlueRecord={this.deleteGlueRecord}
                                            />
                                        }

                                    })
                                } else {
                                    return <tr>
                                        <td colSpan={5}>
                                            No Glue-Records found
                                        </td>
                                    </tr>
                                }
                            })()}
                            </tbody>
                        }
                    </TransitionMotion>
                </Table>
            </React.Fragment>
        )
    }
}

export default GlueRecords
