import {change} from "redux-form";

function sortBy(sortKey) {
    return {
        type: 'SEARCH_RESULT_SORT',
        orderBy: sortKey
    }
}

function intersection(a, b) {
    return a.filter(function (n) {
        return b.indexOf(n) !== -1;
    });
}

function startSearch(searchTerm) {

    let searchTerms = searchTerm.split('\n');

    return (dispatch, getState) => {
        let state = getState();

        if (!state.searchResult.current.isFetching && !state.searchResult.current.isExpanding) {

            const previousSearchResultIndex = state.searchResult.history.findIndex((previousSearch) => {
                const previousSearchTerms = previousSearch.searchTerms;

                if (searchTerms.length > 1) {
                    return searchTerms.length === intersection(searchTerms, previousSearchTerms)
                } else {
                    if (previousSearchTerms.length == 1) {
                        if (previousSearchTerms[0] == searchTerms[0]) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (previousSearchResultIndex == -1) {
                let es = new EventSource(Routing.generate('search_results', {'domain': searchTerms}));

                es.addEventListener("open", (event) => dispatch({
                    type: 'SEARCH_RESULT_EVENT_OPEN',
                    event: event,
                    searchTerms: searchTerms
                }));
                es.addEventListener("result", (event) => {
                    dispatch({
                        type: 'SEARCH_RESULT_EVENT_RESULT',
                        result: JSON.parse(event.data)
                    });
                });
                es.addEventListener("stats", (event) => {
                    dispatch({
                        type: 'SEARCH_RESULT_EVENT_STATS',
                        payload: event.data
                    });
                });
                es.addEventListener("finish", (event) => dispatch({type: 'SEARCH_RESULT_EVENT_FINISH', event: event}));
                es.addEventListener("message", (event) => dispatch({
                    type: 'SEARCH_RESULT_EVENT_MESSAGE',
                    event: event
                }));
                es.addEventListener("error", (event) => {
                    return dispatch({type: 'SEARCH_RESULT_EVENT_ERROR', event: event})
                });
                es.onerror = (event) => {
                    return dispatch({type: 'SEARCH_RESULT_EVENT_ERROR'});
                };
            } else {
                dispatch(change('SearchForm', 'domain', searchTerm))

                return dispatch({
                    type: 'SEARCH_RESULT_SHOW_PREVIOUS_RESULT',
                    payload: {
                        index: previousSearchResultIndex,
                        searchTerms: state.searchResult.history[previousSearchResultIndex].searchTerms
                    }
                })
            }
        }
    }
}

function expandSearch() {
    return (dispatch, getState) => {
        return startExpand(getState(), dispatch);
    }
}

function startExpand(state, dispatch) {

    let searchTerms = state.searchResult.current.searchTerms;

    let batchedResults = [];

    if (!state.searchResult.current.isExpanding && !state.searchResult.current.isFetching) {

        let es = new EventSource(Routing.generate('search_results', {'domain': searchTerms, 'extended': true}));

        es.addEventListener("open", (event) => dispatch({
            type: 'SEARCH_RESULT_EXPANDING_EVENT_OPEN',
            event: event,
            searchTerms: searchTerms
        }));
        es.addEventListener("result", (event) => {
            batchedResults.push(JSON.parse(event.data));
            if (batchedResults.length >= 25) {
                dispatch({
                    type: 'SEARCH_RESULT_EXPANDING_EVENT_RESULTS',
                    results: batchedResults
                });

                batchedResults = [];
            }

        });
        es.addEventListener("stats", (event) => {
            dispatch({
                type: 'SEARCH_RESULT_EXPANDING_EVENT_STATS',
                payload: event.data
            });
        });
        es.addEventListener("finish", (event) => {
            if (batchedResults.length > 0) {
                dispatch({
                    type: 'SEARCH_RESULT_EXPANDING_EVENT_RESULTS',
                    results: batchedResults
                });

                batchedResults = [];
            }
            dispatch({type: 'SEARCH_RESULT_EXPANDING_EVENT_FINISH', event: event})
        });
        es.addEventListener("message", (event) => dispatch({
            type: 'SEARCH_RESULT_EXPANDING_EVENT_MESSAGE',
            event: event
        }));
        es.addEventListener("error", (event) => dispatch({
            type: 'SEARCH_RESULT_EXPANDING_EVENT_ERROR',
            event: event
        }));
        es.onerror = dispatch({type: 'SEARCH_RESULT_EXPANDING_EVENT_ERROR'});
    }
}

function autoExpand(state, dispatch, forceExpand = false) {
    if ((
            state.searchResult.current.filteredList.length == 0 ||
            forceExpand
        ) &&
        !state.searchResult.current.isFetching &&
        !state.searchResult.current.isExpanded &&
        !state.searchResult.current.isExpanding
    ) {
        return startExpand(state, dispatch);
    }
}

function toggleTakenDomainsFilter() {
    return (dispatch, getState) => {
        var state = getState();

        var x = dispatch({
            type: 'SEARCH_RESULT_SET_FILTERS',
            payload: {
                ...state.searchResult.filters,
                hideTakenDomains: !state.searchResult.filters.hideTakenDomains
            }
        });

        return autoExpand(getState(), dispatch);
    }
}

function togglePromoDomainsFilter() {
    return (dispatch, getState) => {
        var state = getState();

        var x = dispatch({
            type: 'SEARCH_RESULT_SET_FILTERS',
            payload: {
                ...state.searchResult.filters,
                promos: !state.searchResult.filters.promos
            }
        });

        return autoExpand(getState(), dispatch, true);
    }
}

function toggleStatusFilter(status) {
    return (dispatch, getState) => {
        var state = getState();

        var stati = state.searchResult.filters.stati;
        stati[status] = !stati[status];

        dispatch({
            type: 'SEARCH_RESULT_SET_FILTERS',
            payload: {
                ...state.searchResult.filters,
                stati: stati
            }
        });

        return autoExpand(getState(), dispatch);
    }
}

function toggleCategoryFilter(categoryId) {
    return (dispatch, getState) => {
        var state = getState();
        var categories = state.searchResult.filters.categories;

        if (categories[categoryId] != undefined) {
            delete categories[categoryId]
        } else {
            categories[categoryId] = true;
        }

        dispatch({
            type: 'SEARCH_RESULT_SET_FILTERS',
            payload: {
                ...state.searchResult.filters,
                categories: categories
            }
        });

        return autoExpand(getState(), dispatch);
    }
}

export default {
    sortBy,
    startSearch,
    expandSearch,
    toggleTakenDomainsFilter,
    toggleStatusFilter,
    toggleCategoryFilter,
    togglePromoDomainsFilter,
}