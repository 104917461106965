import React from "react";
import PropTypes from "prop-types";
import {DropdownButton, FormControl, MenuItem, Pagination, Table} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import FormattedCurrency from "../FormattedCurrency"
import saveAs from "save-as";
import dateFormat from "dateformat";

class InvoiceTable extends React.Component {

    constructor(props) {
        super(props)
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.exportList = this.exportList.bind(this);
        this.getItemsPerPageOptions = this.getItemsPerPageOptions.bind(this);
    }

    getItemsPerPageOptions() {
        // only show this when we have more than 20 pages, show only the useful items
        return [10, 20, 50, 100, 200, 500].map((ipp) => {
            if (ipp <= 2.5 * this.props.dictionary.length) {
                return (
                    <MenuItem key={ipp}
                              onSelect={ () => this.props.setItemsPerPage(ipp) }>
                        {ipp}
                    </MenuItem>);
            }
        });
    }

    handleFilterChange(event) {
        this.props.filterBy(event.target.value);
    }

    handleSort(sortBy) {
        this.props.sortBy(sortBy);
    }

    exportList() {
        let csv;

        csv = this.props.pageList.map((domain) => {
            let domainData = this.props.entities[domain];
            return '"' + domainData.domain + '";"' + domainData.status + '";"' + dateFormat(domainData.expirationdate, "yyyy-mm-dd") + '";"' + domainData.wps + '"' + "\r\n";
        });

        csv.unshift('"Domain";"Status";"Whois Privacy";"Installed WebApps"' + "\n");

        let blob = new Blob(csv, {type: "text/plain;charset=utf-8"});
        saveAs(blob, "domains.csv");
    }

    getFooterText() {
        const {isFetching, length, filteredLength, page, itemsPerPage} = this.props;
        let ret;

        if(length > 3) {

            if (length == 0) {
                if (isFetching) {
                    ret = "Invoices are loading..."
                } else {
                    ret = "You have no invoices at this time."
                }
            } else {
                if (filteredLength == 0) {
                    return 'None of the ' + length + ' invoices match your filter';
                }

                let first = ((page - 1) * itemsPerPage + 1);
                let last = (page * itemsPerPage);

                if (last > filteredLength) {
                    last = filteredLength;
                }
                ret = "Showing " + first + " to " + last + " of " + filteredLength + " invoices"

                if (filteredLength < length) {
                    ret += " (filtered from " + length + " total invoices)";
                }
            }

            return ret;
        }
    }


    getTableContents() {
        return this.props.pageList.map((invoiceId) => {
            let invoice = this.props.entities[invoiceId];

            return (
                <tr key={invoice.id}>
                    <td>{invoice.invoice_nr}</td>
                    <td>
                        {invoice.type == 'CREDITNOTE' ? 'Credit Note' : 'Invoice'}
                    </td>
                    <td>
                        <FormattedCurrency value={invoice.amount} addtax={false} />
                    </td>
                    <td>
                        {(() => {
                            if (invoice.paid != undefined) {
                                if (invoice.type == "CREDITNOTE") {
                                    return "paid back";
                                } else {
                                    if (invoice.paid == true) {
                                        return <span className="green">Paid</span>;
                                    } else if (invoice.paid == false && invoice.charged == 0) {
                                        return <span className="red">Unpaid</span>;
                                    } else {
                                        if(invoice.amount.currency == window.defaultCurrency) {
                                            if (invoice.hasOwnProperty('charging') && invoice.charging == true) {
                                                return (<button className="btn btn-success btn-xs" disabled={true}>Charging...</button>)
                                            } else {
                                                return (<button
                                                    onClick={(e) => this.props.charge(invoice.invoice_nr, invoice.id)}
                                                    className="btn btn-success btn-xs">Charge
                                                </button>)
                                            }
                                        } else {
                                            return <span className="red">Failed</span>;
                                        }
                                    }
                                }
                            } else {
                                return "unknown";
                            }
                        })()}
                    </td>
                    <td>
                        <FormattedDate value={invoice.issue_date}
                                       year='numeric'
                                       month='long'
                                       day='2-digit'/>
                    </td>
                    <td>
                        <a href={Routing.generate('portfolio_billing_invoicepdf', {'invoiceNr': invoice.invoice_nr})} target="_blank">Download</a>
                    </td>
                </tr>
            )
        });
    }

    render() {
        const {totalPages, isFetching, page, itemsPerPage, orderBy, order, dictionary} = this.props;

        return (
            <div>
                <div className="row clearfix">
                    <div className="col-sm-12 hidden-xs">
                        <div className="pull-right mt15">
                            <form role="form" className="form-inline">
                                &nbsp;
                                {(() => {
                                    if (this.props.dictionary.length > 20) {
                                        return (
                                            <div className="form-group">
                                        <span className='dropdown'>
                                            <DropdownButton title={itemsPerPage} id="bg-nested-dropdown">
                                                {this.getItemsPerPageOptions()}
                                            </DropdownButton>
                                        </span>&nbsp;
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (this.props.dictionary.length > 1) {
                                        return (<div className="form-group">
                                            <FormControl
                                                type="search"
                                                placeholder="Filter"
                                                onChange={this.handleFilterChange}
                                            />
                                        </div>)
                                    }
                                })()}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Table responsive hover striped>
                            <thead>
                            <tr>
                                <th onClick={() => this.handleSort('invoice_no')}
                                    className={orderBy == 'invoice_no' ? 'sorting-' + order : 'sorting'}>Invoice
                                    No.&nbsp;</th>
                                <th onClick={() => this.handleSort('type')}
                                    className={orderBy == 'type' ? 'sorting-' + order : 'sorting'}>Type&nbsp;</th>
                                <th onClick={() => this.handleSort('amount.amount')}
                                    className={orderBy == 'amount.amount' ? 'sorting-' + order : 'sorting'}>Total&nbsp;</th>
                                <th onClick={() => this.handleSort('paid')}
                                    className={orderBy == 'paid' ? 'sorting-' + order : 'sorting'}>Status&nbsp;</th>
                                <th onClick={() => this.handleSort('date')}
                                    className={orderBy == 'date' ? 'sorting-' + order : 'sorting'}>
                                    Date&nbsp;
                                </th>
                                <th>Download</th>
                            </tr>
                            </thead>
                            <tfoot>
                            <tr>
                                <td colSpan="6" className="text-center">
                                    {this.getFooterText()}
                                </td>
                            </tr>
                            </tfoot>
                            <tbody>
                            {this.getTableContents()}
                            </tbody>
                        </Table>
                    </div>
                </div>
                {(() => {
                    if (dictionary.length > 20) {
                        return <Pagination
                            prev
                            next
                            first
                            last
                            boundaryLinks
                            items={totalPages}
                            maxButtons={5}
                            activePage={page}
                            onSelect={this.props.gotoPage}
                        />
                    }
                })()}
            </div>
        )
    }

    static propTypes = {
        // actions
        charge: PropTypes.func.isRequired,
        sortBy: PropTypes.func.isRequired,
        filterBy: PropTypes.func.isRequired,
        gotoPage: PropTypes.func.isRequired
    }
}

export default InvoiceTable
