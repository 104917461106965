import {toastr} from "react-redux-toastr";
import React from "react";

var param = require('jquery-param');

function addDomainToCart(domain, toplevelDomain, trademarkClaim = null, options = {}) {

    return (dispatch, getState) => {

        var state = getState();

        let csrfToken = state.csrf.token;

        if (state.cart.entities[domain]) {
            return;
        }

        if (trademarkClaim != null && options.claimConfirmation == undefined) {
            // dispatch TrademarkClaim
            dispatch({
                type: 'CLAIM_CONFIRMATION_MISSING',
                payload: {
                    domain: domain,
                    toplevelDomain: toplevelDomain,
                    lookupKey: trademarkClaim,
                    options: options
                }
            });

            return;
        }

        let body = {
            cartitem: {
                ...options,
                domain: domain,
                toplevelDomain: toplevelDomain,
                _token: csrfToken
            }
        }

        return dispatch({
            type: 'CART_ADD_DOMAIN',
            meta: {
                domain: domain,
                toplevelDomain: toplevelDomain,
            },
            payload: fetch(Routing.generate('cart_adddomain'), {
                credentials: 'same-origin',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: param(body)
            }).then(response => {
                if (response.headers.get('Content-Type').indexOf("json") > 0) {
                    return response.json();
                } else {
                    toastr.error(response.statusText);
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                }
            }).then(ret => {
                console.log(ret);
                if (ret.status == false) {
                    toastr.error(ret.message);
                    throw Error(ret.message);
                }

                if(ret.hasOwnProperty('message')) {
                    toastr.success(ret.message);
                }

                return {
                    domain: domain,
                    domainName: ret.domainName,
                    tld: ret.tld,
                    price: ret.price,
                    id: ret.id,
                }
            })
        })
    };
};

function removeDomainFromCart(cartItem, options = {}) {
    if (cartItem.adding == false && cartItem.removing == false) {

        return (dispatch, getState) => {

            let csrfToken = getState().csrf.token;

            let body = {
                ...options,
                domain: cartItem.domain,
                _token: csrfToken
            }

            return dispatch({
                type: 'CART_REMOVE_DOMAIN',
                meta: {
                    domain: cartItem.domain
                },
                payload: fetch(Routing.generate('cart_removedomain'), {
                    credentials: 'same-origin',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: param(body)
                }).then(response => {
                    return response.json();
                }).then(response => {
                    return response.success;
                })
            })
        };
    }

    return null;
}

function cancelPreRegs() {
    return {
        type: 'CART_CANCEL_PREREGS',
    }
}

function setPreRegToConfirm(preReg, confirm) {
    return {
        type: 'CART_SET_PREREG_CONFIRM',
        payload: {
            preReg: preReg,
            confirm: confirm
        }
    }
}

function setAllPreRegsToConfirm(confirm) {
    return {
        type: 'CART_SET_ALL_PREREGS_CONFIRM',
        payload: confirm
    }
}

function confirmPreRegs(preRegsToConfirmDictionary, preRegsToConfirmEntities) {
    return (dispatch, getState) => {

        const query = new URLSearchParams();

        let csrfToken = getState().csrf.token;


        preRegsToConfirmDictionary.forEach((preRegDomain) => {
            if (preRegsToConfirmEntities[preRegDomain].checked == true) {
                query.append('preRegs[]', preRegDomain)
            }
        });

        query.append('_token', csrfToken);

        return dispatch({
            type: 'CART_PREREGS_CONFIRM',
            payload: fetch(Routing.generate('cart_addpreregs'), {
                credentials: 'same-origin',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: query
            }).then(response => {
                return response.json();
            }).then(json => {
                if (json.success == true) {
                    if (json.removeMessage != null) {
                        toastr.error(json.removeMessage);
                    }
                    return json;
                } else {
                    toastr.error(json.message);
                    return json;
                }
            }).catch((error) => {
                toastr.error(error.message);
            })
        })
    }
}

export default {
    addDomainToCart,
    removeDomainFromCart,
    cancelPreRegs,
    setPreRegToConfirm,
    setAllPreRegsToConfirm,
    confirmPreRegs
}
