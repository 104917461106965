import React from "react";
import {Route} from  "react-router";
import FrontendApp from "./FrontendApp";
import {default as PortfolioApp} from "./Portfolio/App";

class App extends React.Component {
    render() {
        const {children} = this.props
        return (
            <div>
                <FrontendApp location={this.props.location} />
                <Route path='/portfolio/' component={PortfolioApp}/>
                <Route path='/app_dev.php/portfolio/' component={PortfolioApp}/>
            </div>
        )
    }
}

export default App