const accountReducer = (state = {
    data: {
        address1: null,
        address2: null,
        city: null,
        country: null,
        id: null,
        firstname: null,
        lastname: null,
        organization: null,
        phone: null,
        salutation: null,
        username: null,
        zip: null,
        extra_icm_membership_token: null,
        extraBirthday: null,
        extraPassportId: null,
        extraState: null,
        extraVatId: null,
        extraLegalForm: null
    },
    two_factor_authentication: {
        settingUp: false,
        disabling: false,
        enabling: false,
        method: null,
        google_authenticator_secret: null,
        configured: false,
        generating_secret: false,
        qrcode: null,
        current_code: "",
        password: ""
    },
    allowDelete: false,
    deletingAccount: false
}, action) => {

    switch (action.type) {
        case "ACCOUNT_SETUP_2FA": {
            return {
                ...state,
                two_factor_authentication: {
                    ...state.two_factor_authentication,
                    settingUp: action.payload
                }
            }
        }
        case "ACCOUNT_CANCEL_2FA_SETUP": {
            return {
                ...state,
                two_factor_authentication: {
                    ...state.two_factor_authentication,
                    settingUp: false,
                    current_code: ""
                }
            }
        }
        case "ACCOUNT_GET_2FA_CODE_PENDING": {
            return {
                ...state,
                two_factor_authentication: {
                    ...state.two_factor_authentication,
                    generating_secret: true
                }
            }
        }
        case "ACCOUNT_GET_2FA_CODE_FULFILLED": {
            return {
                ...state,
                two_factor_authentication: {
                    ...state.two_factor_authentication,
                    generating_secret: false,
                    google_authenticator_secret: action.payload.secret,
                    qrcode: action.payload.qrcontent,
                    current_code: ""
                }
            }
        }
        case "ACCOUNT_2FA_SET_CURRENT_CODE": {
            return {
                ...state,
                two_factor_authentication: {
                    ...state.two_factor_authentication,
                    current_code: action.payload
                }
            }
        }
        case "ACCOUNT_2FA_SET_PASSWORD": {
            return {
                ...state,
                two_factor_authentication: {
                    ...state.two_factor_authentication,
                    password: action.payload
                }
            }
        }
        case 'ACCOUNT_SAVE_2FA_PENDING': {
            return {
                ...state,
                two_factor_authentication: {
                    settingUp: false,
                    disabling: false,
                    enabling: true,
                    method: null,
                    google_authenticator_secret: null,
                    configured: true,
                    generating_secret: false,
                    qrcode: null,
                    current_code: "",
                    password: ""
                }
            }
        }
        case 'ACCOUNT_SAVE_2FA_FULFILLED': {
            return {
                ...state,
                two_factor_authentication: {
                    settingUp: false,
                    disabling: false,
                    enabling: false,
                    method: null,
                    google_authenticator_secret: null,
                    configured: true,
                    generating_secret: false,
                    qrcode: null,
                    current_code: "",
                    password: ""
                }
            }
        }
        case 'ACCOUNT_SAVE_2FA_SUCCESS': {
            return {
                ...state,
                two_factor_authentication: {
                    settingUp: false,
                    disabling: false,
                    enabling: false,
                    method: null,
                    google_authenticator_secret: null,
                    configured: true,
                    generating_secret: false,
                    qrcode: null,
                    current_code: "",
                    password: ""
                }
            }
        }
        case "ACCOUNT_DISABLE_2FA_PENDING": {
            return {
                ...state,
                two_factor_authentication: {
                    ...state.two_factor_authentication,
                    disabling: true
                }
            }
        }
        case "ACCOUNT_DISABLE_2FA_FULFILLED": {
            return {
                ...state,
                two_factor_authentication: {
                    settingUp: false,
                    disabling: false,
                    method: null,
                    google_authenticator_secret: null,
                    configured: false,
                    generating_secret: false,
                    qrcode: null,
                    current_code: "",
                    password: ""
                }
            }
        }
        case "ACCOUNT_DELETE_PENDING": {
            return {
                ...state,
                deletingAccount: true
            }
        }
        case "ACCOUNT_DELETE_REJECTED": {
            return {
                ...state,
                deletingAccount: false
            }
        }
        case "ACCOUNT_DELETE_FULFILLED": {
            window.location.href = Routing.generate('security_login');

            return {
                ...state,
            }
        }
        default: {
            return state;
        }
    }
};

export default accountReducer;
