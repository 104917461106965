import {combineReducers} from "redux";
import {reducer as toastrReducer} from "react-redux-toastr";
import {connectRouter} from 'connected-react-router'
import {reducer as formReducer} from 'redux-form'
import domainsReducer from "./domainsReducer";
import preRegistrationsReducer from "./preRegistrationReducer";
import pendingOrderRecuder from "./pendingOrdersReducer";
import cartReducer from "./cartReducer";
import toplevelDomainReducer from "./toplevelDomainReducer";
import toplevelDomainCategoriesReducer from "./toplevelDomainCategoriesReducer";
import toplevelDomainTeaserReducer from "./toplevelDomainTeaserReducer";
import searchResultReducer from "./searchResultReducer";
import csrfReducer from "./csrfReducer";
import claimReducer from "./claimReducer";
import upsellReducer from "./upsellReducer";
import accountReducer from "./accountReducer";
import contactReducer from "./contactReducer";
import dnsZoneReducer from "./dnsZoneReducer";
import paymentDataReducer from "./paymentDataReducer";
import invoiceReducer from "./invoiceReducer";
import mailForwardReducer from "./mailForwardReducer";

export default (history) => combineReducers({
    domains: domainsReducer,
    preRegistrations: preRegistrationsReducer,
    pendingOrders: pendingOrderRecuder,
    dnsZones: dnsZoneReducer,
    mailForward: mailForwardReducer,
    contacts: contactReducer,
    cart: cartReducer,
    toplevelDomains: toplevelDomainReducer,
    toplevelDomainTeaser: toplevelDomainTeaserReducer,
    toplevelDomainCategories: toplevelDomainCategoriesReducer,
    searchResult: searchResultReducer,
    paymentData: paymentDataReducer,
    claims: claimReducer,
    toastr: toastrReducer,
    csrf: csrfReducer,
    upsell: upsellReducer,
    account: accountReducer,
    form: formReducer,
    router: connectRouter(history),
    invoices: invoiceReducer,
})
