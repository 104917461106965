import React, {Component} from "react";
import {connect} from 'react-redux'
import {Field, FieldArray, formValueSelector, reduxForm, reset, SubmissionError} from "redux-form";
import RadioField from "../../Form/RadioField";
import {Button} from "react-bootstrap";
import {domainActions} from '../../../actions';
import NameserverField from "./NameserverField";

const validateAndUpdateNameservers = (values, dispatch) => {

    return dispatch(domainActions.setNameservers(values)).payload.then(result => {

        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            dispatch(domainActions.setNameserversSuccess({
                ...values,
                nameservers: result.nameservers,
                nameserverSet: result.nameserverSet
            })); //ps: this is same as dispatching RESET_USER_FIELDS

            dispatch(reset('NameserverConfigForm'))
        } else {
            let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }

            dispatch(domainActions.setNameserversFailure(values, errors));
            throw new SubmissionError(errors);
        }
    });
}

class renderNameservers extends Component {

    render() {

        const {fields, meta: {error, submitFailed}} = this.props;

        return <React.Fragment>
            <div className="form-group has-error">
                {submitFailed && error && <div className="help-block">{error}</div>}
            </div>
            {fields.map((member, index) => {
                return <Field
                    className="form-inline custom-nameserver"
                    name={member}
                    type="text"
                    key={"nameserver" + index}
                    component={NameserverField}
                    last={fields.length - 1 == index}
                    allowAdd={fields.length < 6}
                    add={() => fields.push('')}
                    showRemove={fields.length > 2}
                    remove={() => fields.remove(index)}
                />
            })
            }
        </React.Fragment>
    }
}

class NameserverConfigForm extends Component {

    render() {
        // how to determine, which group is checked
        // show other dns-config-forms only when our nameserver are used

        const {handleSubmit, submitting, pristine, reset, nameserverSet, nameservers} = this.props;

        return (
            <form onSubmit={handleSubmit(validateAndUpdateNameservers)}>
                <Field name="nameserverSet"
                       component={RadioField}
                       changeHandler={(nameserverSet) => {
                           if (nameserverSet == 'uniteddomains') {
                               this.props.change('nameservers', [
                                   'ns1a.dodns.net',
                                   'ns2a.dodns.net'
                               ]);
                           } else if (nameserverSet == 'sedo_parking') {
                               this.props.change('nameservers', [
                                   'ns1.sedoparking.com',
                                   'ns2.sedoparking.com'
                               ]);
                           } else if (nameserverSet == 'google_parking') {
                               this.props.change('nameservers', [
                                   'ns1.googleghs.com',
                                   'ns2.googleghs.com',
                                   'ns3.googleghs.com',
                                   'ns4.googleghs.com'
                               ]);
                           } else if (nameserverSet == 'other') {
                               this.props.change('nameservers', this.props.initialValues.nameservers)
                           }
                       }}
                       options={{
                           'uniteddomains': 'United Domains Nameservers',
                           'sedo_parking': 'Sedo Parking ( SEDO parking account required )',
                           'google_parking': 'Google Parking ( Google AdSense account required )',
                           'other': 'Other Nameservers'
                       }}
                />
                {(() => {
                    if (nameserverSet == 'other') {
                        return <FieldArray name="nameservers" component={renderNameservers}/>
                    } else {
                        /*if(nameservers) {
                            return nameservers.map((nameserver) => {
                                return <span key={nameserver}>{nameserver}<br/></span>
                            });
                        }*/
                        return null;
                    }
                })()}
                <Button bsStyle="primary" type="submit" disabled={pristine || submitting}>
                    {submitting?'Saving...':'Save'}
                </Button>{' '}
                <Button bsStyle="danger" type="button" disabled={pristine || submitting} onClick={reset}>
                    Reset
                </Button>
            </form>
        )
    }
}

NameserverConfigForm = reduxForm({
    form: 'NameserverConfigForm', // a unique identifier for this form
    onSubmit: validateAndUpdateNameservers,
    enableReinitialize: true
})(NameserverConfigForm)

const selector = formValueSelector('NameserverConfigForm');

export default connect(state => {
    const nameserverSet = selector(state, 'nameserverSet');
    const nameservers = selector(state, 'nameservers');

    return {
        nameserverSet,
        nameservers
    }
})(NameserverConfigForm);
