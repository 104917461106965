require('es6-promise').polyfill();
require('isomorphic-fetch');

import {toastr} from 'react-redux-toastr'
import {submit} from 'redux-form'

function updateAccount(props) {
    return (dispatch, getState) => {

        let formData = new FormData();

        for (name in props) {
            if (name != 'id') {
                formData.append('account[' + name + ']', props[name]);
            }
        }

        let state = getState();
        formData.append('account[_token]', state.csrf.token)

        let headers = new Headers({
            "Accept": "application/json"
        });

        return {
            type: 'ACCOUNT_SAVE',
            payload: fetch(Routing.generate('portfolio_account_save'), {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
                headers: headers
            }).then(response => {
                return response.json();
            })
        }
    }
}

function saveAccountSuccess() {
    toastr.success("Account Data saved successfully!");

    return {
        type: 'ACCOUNT_UPDATE_SUCCESS',
        payload: null
    };
}

function saveAccountFailure(values, errors) {
    toastr.error("Failed to save Account Data!");

    return {
        type: 'ACCOUNT_UPDATE_FAILED',
        payload: errors
    };
}

function updateExtraData(props) {
    return (dispatch, getState) => {

        let formData = new FormData();

        for (name in props) {
            formData.append('accountExtra[' + name + ']', props[name]);
        }

        let state = getState();
        formData.append('accountExtra[_token]', state.csrf.token)

        let headers = new Headers({
            "Accept": "application/json"
        });

        return {
            type: 'ACCOUNT_SAVE_EXTRA',
            payload: fetch(Routing.generate('portfolio_account_extra_save'), {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
                headers: headers
            }).then(response => {
                return response.json();
            })
        }
    }
}

function saveAccountExtraSuccess() {
    toastr.success("Account Extra Data saved successfully!");

    return {
        type: 'ACCOUNT_UPDATE_EXTRA_SUCCESS',
        payload: null
    };
}

function saveAccountExtraFailure(values, errors) {
    toastr.error("Failed to save Account Extra Data!");

    return {
        type: 'ACCOUNT_UPDATE_EXTRA_FAILED',
        payload: errors
    };
}

function setup2FA(method = true) {
    return (dispatch, getState) => {

        dispatch({
            type: 'ACCOUNT_SETUP_2FA',
            payload: method
        });

        if (method == 'google_authenticator') {
            dispatch({
                type: 'ACCOUNT_GET_2FA_CODE',
                payload: fetch(Routing.generate('portfolio_account_twofactorsecret'), {
                    credentials: 'same-origin',
                }).then(response => {
                    return response.json();
                }).catch(e => {
                    toastr.error("Failed to disable two-factor authentication!");
                    return e;
                })
            });
        }

    }
}

function disable2FA() {
    return (dispatch, getState) => {

        dispatch({
            type: 'ACCOUNT_DISABLE_2FA',
            payload: fetch(Routing.generate('portfolio_account_disabletwofactorauth'), {
                method: 'POST',
                credentials: 'same-origin',
            }).then(response => {
                return response.json();
            })
        })
    }
}

function cancel2FASetup() {
    return {
        type: 'ACCOUNT_CANCEL_2FA_SETUP',
        payload: null
    }
}

function submit2FAForm() {
    return submit('Enable2FA');
}

function finish2FASetup(method, authcode, password) {

    return (dispatch, getState) => {
        if (method == 'google_authenticator') {
            let secret = getState().account.two_factor_authentication.google_authenticator_secret;

            let formData = new FormData();

            formData.append('user_2fa[secret]', secret);
            formData.append('user_2fa[password]', password);
            formData.append('user_2fa[authcode]', authcode);

            let state = getState();
            formData.append('user_2fa[_token]', state.csrf.token)

            return {
                type: 'ACCOUNT_SAVE_2FA',
                payload: fetch(Routing.generate('portfolio_account_twofactorauth'), {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: formData
                }).then(response => {
                    return response.json();
                })
            };
        } else {
            // we only support google authenticator for now
        }
    }
}

function enable2FASuccess() {
    return {
        type: 'ACCOUNT_SAVE_2FA_SUCCESS',
        payload: null
    };
}

function enable2FAFailure(errors) {
    return {
        type: 'ACCOUNT_SAVE_2FA_FAILURE',
        payload: errors
    };
}


function updateAccountPassword(data) {
    return (dispatch, getState) => {

        let formData = new FormData();

        for (name in data) {
            if (name != 'plainPassword') {
                formData.append('account_password[' + name + ']', data[name]);
            } else {
                formData.append('account_password[plainPassword][first]', data.plainPassword.first);
                formData.append('account_password[plainPassword][second]', data.plainPassword.second);
            }
        }

        let state = getState();
        formData.append('account_password[_token]', state.csrf.token)

        let headers = new Headers({
            "Accept": "application/json"
        });

        return {
            type: 'ACCOUNT_UPDATE_PASSWORD',
            payload: fetch(Routing.generate('portfolio_account_updatepassword'), {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
                headers: headers
            }).then(response => {
                return response.json();
            })
        };
    }
}

function updateAccountPasswordSuccess() {
    toastr.success("Changed Password successfully!");

    return {
        type: 'ACCOUNT_UPDATE_PASSWORD_SUCCESS',
        payload: null
    };
}

function deleteAccount() {
    return (dispatch, getState) => {

        let headers = new Headers({
            "Accept": "application/json"
        });

        dispatch({
            type: 'ACCOUNT_DELETE',
            payload: fetch(Routing.generate('portfolio_account_delete', {'_token': getState().csrf.token}), {
                method: 'POST',
                credentials: 'same-origin',
                headers: headers
            }).then(response => {
                return response.json();
            }).then(response => {
                if (response.success == false) {
                    toastr.error(response.message);
                    throw response.message;
                }

                return response;
            })
        });
    }
}

export default {
    updateAccount,
    saveAccountFailure,
    saveAccountSuccess,
    updateExtraData,
    saveAccountExtraSuccess,
    saveAccountExtraFailure,
    setup2FA,
    cancel2FASetup,
    disable2FA,
    submit2FAForm,
    finish2FASetup,
    enable2FASuccess,
    enable2FAFailure,
    updateAccountPassword,
    updateAccountPasswordSuccess,
    deleteAccount,
}
