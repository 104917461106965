import React from "react";
import PropTypes from 'prop-types';
import MaterialIcon from "./MaterialIcon";
import {Tooltip, OverlayTrigger} from "react-bootstrap";

class ClipboardInput extends React.Component {

    constructor(props) {
        super(props);

        this.isSupported = this.isSupported.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.state = {
            tooltip: 'default'
        }
    }

    copyToClipboard() {
        if (this.isSupported()) {
            this.refs.input.select();
        }

        let succeeded;

        try {
            succeeded = document.execCommand(this.props.action);
        }
        catch (err) {
            succeeded = false;
        }

        this.setState((prevState, props) => {
            return {
                tooltip: (succeeded == true ? 'success' : 'failure')
            }
        });

        this.forceUpdate();

        this.timerID = setTimeout(
            () => this.resetTooltip(),
            2000
        );

        return;
    }

    resetTooltip() {
        this.setState((prevState, props) => {
            return {
                tooltip: 'default'
            }
        });

        this.forceUpdate();
    }

    isSupported() {
        let support = !!document.queryCommandSupported;
        support = support && !!document.queryCommandSupported(this.props.action);

        return support;
    }

    render() {
        let {action, ...props} = this.props;

        let tooltipText, key, failedTooltipText;

        const successTooltip = (
            <Tooltip id="copy_to_clipboard_tooltip_success"
                     placement="bottom">
                Copied!
            </Tooltip>
        )

        if (/Mac/i.test(navigator.userAgent)) {
            key = '&#8984;';
        } else {
            key = 'Ctrl';
        }
        failedTooltipText = "Press " + key + "-C to copy";

        const failedTooltip = (
            <Tooltip id="copy_to_clipboard_tooltip_failed" placement="bottom">Copied!</Tooltip>
        )

        if (this.isSupported()) {
            tooltipText = 'Copy to clipboard!'
        } else {
            tooltipText = failedTooltipText;
        }

        const tooltip = (
            <Tooltip id="copy_to_clipboard_tooltip" placement="bottom">{tooltipText}</Tooltip>
        )

        return (
            <div className="input-group">
                <input {...this.props}
                       type="text"
                       className="form-control"
                       aria-describedby="basic-addon1"
                       ref="input"/>
                <span className="input-group-addon" id="basic-addon1">
                    {(() => {
                        if (this.state.tooltip == 'success') {
                            return (
                                <OverlayTrigger placement="bottom" overlay={successTooltip}>
                                    <a onClick={() => {
                                        this.copyToClipboard()
                                    }}><MaterialIcon icon="content_copy"/>{successTooltip}</a>
                                </OverlayTrigger>)
                        } else if (this.state.tooltip == 'failure') {
                            return (
                                <OverlayTrigger placement="bottom" overlay={failedTooltip}>
                                    <a onClick={() => {
                                        this.copyToClipboard()
                                    }}><MaterialIcon icon="content_copy"/>{failedTooltip}</a>
                                </OverlayTrigger>
                               )
                        } else {
                            return (
                                <OverlayTrigger placement="bottom" overlay={tooltip}>
                                    <a onClick={() => {
                                        this.copyToClipboard()
                                    }}><MaterialIcon icon="content_copy"/>{tooltip}</a>
                                </OverlayTrigger>)
                        }
                    })()}
                </span>
            </div>
        )
    }

    static defaultProps = {
        action: 'copy'
    };

    static propTypes = {
        action: PropTypes.oneOf(['copy', 'cut']),
    }
}

export default ClipboardInput