import React from "react";
import PropTypes from "prop-types";
import {Button} from 'react-bootstrap';

class RedirectResourceRecord extends React.Component {


    getRedirectType(rdata) {
        let parts = rdata.split(' ');
        return parts[0].toUpperCase();
    }

    getRedirectTarget(rdata) {
        let parts = rdata.split(' ');
        return parts[1];
    }

    render() {
        const {className, resourceRecord, style, tdStyle} = this.props;

        return <tr key={this.props._key + '_0'} style={style} className={className}>
            <td style={tdStyle}>
                {resourceRecord.name}
                {' '}
                <span className="domain">.{this.props.domain}</span>
            </td>
            <td style={tdStyle}>{this.getRedirectType(resourceRecord.rdata[0])}</td>
            <td style={tdStyle}>{this.getRedirectTarget(resourceRecord.rdata[0])}</td>
            <td className="action" style={tdStyle}>
                <Button bsStyle="default"
                        onClick={() => this.props.deleteRecord(resourceRecord.name, resourceRecord.type, this.props._key)}>
                    delete
                </Button>
            </td>
            <td className="action" style={tdStyle}>
                <Button bsStyle="primary"
                        onClick={() => this.props.editRecord(resourceRecord.key)}>
                    edit
                </Button>
            </td>
        </tr>

    }

    static propTypes = {
        // actions
        editRecord: PropTypes.func.isRequired,
        deleteRecord: PropTypes.func.isRequired,

        // state data
        _key: PropTypes.string.isRequired,
        resourceRecord: PropTypes.object.isRequired,
        style: PropTypes.object.isRequired,
        className: PropTypes.string
    }
}

export default RedirectResourceRecord
