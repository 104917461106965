import React from "react";
import PropTypes from "prop-types";

class PromotionHint extends React.Component {

    render() {
        const {cart, toplevelDomains} = this.props;
        const hints = new Map();

        const hasPromotions = cart.dictionary.reduce((hasPromotions, cartDomain) => {
            let cartItem = cart.entities[cartDomain];
            let toplevelDomain = toplevelDomains.entities[cartItem.tld];

            if (toplevelDomain.promotion_price > 0) {
                return true
            }

            return hasPromotions;
        }, false);

        if (hasPromotions == true) {
            return (
                <div className="row">
                    <div className="col-sm-12">
                        <hr />
                        <div className="small">
                            <p><strong>* Please note:</strong><br/>
                                Offer applies to first year domain registration only. Not applicable to domain
                                renewals or transfers. Please see our{' '}
                                <a href={Routing.generate('domain_pricelist')}
                                   title="View Full Pricelist for Domain Renewal and Transfer Pricing"
                                   target="_blank">&raquo; price list</a>{' '}
                                for renewal and transfer prices.
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    static
    propTypes = {
        toplevelDomains: PropTypes.object.isRequired,
        cart: PropTypes.object.isRequired
    }
}

export default PromotionHint;