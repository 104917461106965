var param = require('jquery-param');

function getMeta() {
    return document.querySelector("meta[name=\"csrf-token\"]").content;
}

function fetchClaimInformation(lookupKey = null, domain = null) {
    return (dispatch, getState) => {

        var state = getState();

        if (state.claims.entities[domain] != undefined && state.claims.entities[domain].isFetching) {
            return null;
        }

        if (lookupKey == true) {
            lookupKey = null;
        }

        let body = {
            lookupKey: lookupKey,
            domain: domain,
            _token: getMeta()
        }

        return dispatch({
                type: 'CLAIM_FETCH_INFORMATION',
                meta: {
                    lookupKey: lookupKey,
                    domain: domain
                },
                payload: fetch(Routing.generate('claim_gettrademark'), {
                    credentials: 'same-origin',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: param(body)
                }).then(response => {
                    return response.json();
                }).then(claimData => {
                    return {
                        ...claimData,
                        domain: domain,
                        lookupKey: lookupKey,
                        validUntilDisplay: new Date(claimData.validUntil.date),
                        validUntil: claimData.validUntil.date
                    }
                })
            }
        )
    };
}

function rejectClaim() {
    return {
        type: 'CLAIM_REJECT',
    }
}

function toggleConfirmation(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    return {
        type: 'CLAIM_TOGGLE_CONFIRMATION',
        payload: value
    };
}

function confirmClaim() {
    return {
        type: 'CLAIM_CONFIRM'
    }
}

export default {
    fetchClaimInformation,
    rejectClaim,
    toggleConfirmation,
    confirmClaim,
}