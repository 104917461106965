import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from "../../MaterialIcon";
import {Button} from "react-bootstrap";

class TextField extends React.Component {

    render() {

        const {input, label, type, help, meta: {touched, error, invalid, warning}, children} = this.props;

        let labelClasses = "control-label";
        let containerClasses = '';

        if (this.props.horizontal > 0) {
            labelClasses += " col-sm-" + this.props.horizontal;
        }

        let groupClasses = 'form-group';

        if (this.props.className) {
            groupClasses += ' ' + this.props.className;
        }

        if (touched && invalid) {
            groupClasses += ' has-error';
        }

        if (this.props.size != null) {
            groupClasses += ' form-group-' + this.props.size;
        }

        return (<div className={groupClasses}>
            {label && <label className={labelClasses}>{label}</label>}
            <div>
                <input {...input} className="form-control" placeholder={label} type={type}/>
                {(() => {
                    if(this.props.showRemove == true) {
                        return <a onClick={this.props.remove} className="clear-icon"><MaterialIcon icon="clear"/></a>
                    }
                })()}
                {(() => {
                    if(this.props.last == true) {
                        if(this.props.showRemove == true) {
                            return <Button bsStyle="success" onClick={this.props.add} disabled={!this.props.allowAdd}>
                                Add Nameserver
                            </Button>
                        } else {
                            return <Button bsStyle="success" className="ml20" onClick={this.props.add}  disabled={!this.props.allowAdd}>
                                Add Nameserver
                            </Button>
                        }
                    }
                })()}
                <div className="help-block">
                    {(() => {
                        if (touched) {
                            if (error) {
                                return <span>{error}</span>
                            } else if (warning) {
                                return <span>{warning}</span>
                            }
                        }

                        if (help) {
                            return (<span>
                                {help}
                            </span>)
                        }
                    })()}
                </div>
            </div>
        </div>);
    }


    static defaultProps = {
        size: null
    };

    static propTypes = {
        size: PropTypes.string,
        horizontal: PropTypes.number,
    }
}

export default TextField;