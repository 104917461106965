import {addValidator} from "redux-form-validators";

/**
 * https://tools.ietf.org/html/rfc1912
 *
 * label: Allowable characters in a label for a host name are only ASCII
 *        letters, digits, and the `-' character.  Labels may not be all
 *        numbers, but may have a leading digit  (e.g., 3com.com).  Labels must
 *        end and begin only with a letter or digit.
 *        Note there are some Internet hostnames which violate this rule
 *        (411.org, 1776.com).  The presence of underscores in a label
 *        is allowed in [RFC 1033], except [RFC 1033] is informational
 *        only and was not defining a standard
 *
 * we do allow underscored for now
 */
export default addValidator({
    validator: function (options, value, allValues) {

        if (!value.match(/^(?![0-9]+$)(?!.*-$)(?!-)[a-zA-Z0-9-]{1,63}$/i)) {
            return {
                id: "form.errors.dns.invalid_glue_label",
                defaultMessage: "Invalid name"
            }
        }

        // duplicates labels
        if(options.allGlueRecords) {

            let duplicates = options.allGlueRecords.reduce((accumulator, currentValue) => {
                if (
                    currentValue.name == value &&
                    (currentValue.key != allValues.key || currentValue.key == '_new')
                ) {
                    return true;
                }

                return accumulator;
            }, false);

            if (duplicates == true) {
                return {
                    id: "form.errors.dns.duplicate_label",
                    defaultMessage: "Name already defined"
                }
            }
        }

        return true;
    }
})