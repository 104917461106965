import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class SelectField extends React.Component {

    render() {

        const {input, label, type, help, meta: {touched, error, invalid, warning}, children} = this.props;

        let labelClasses = "control-label";
        let containerClasses = '';
        let placeholder;

        if (this.props.horizontal > 0) {
            labelClasses += " col-sm-" + this.props.horizontal;
            containerClasses += " col-sm-" + (12 - this.props.horizontal);
        }

        let groupClasses = 'form-group';

        if (this.props.className) {
            groupClasses += ' ' + this.props.className;
        }

        if (touched && invalid) {
            groupClasses += ' has-error';
        }

        if (this.props.size != null) {
            groupClasses += ' form-group-' + this.props.size;
        }

        if (this.props.placeholder) {
            placeholder = this.props.placeholder;
        } else {
            placeholder = label;
        }

        return (<div className={groupClasses}>
            {label && <label className={labelClasses}>{label}</label>}
            <div className={containerClasses}>
                <Select {...input}
                        autosize={false}
                        value={this.props.input.value || ''}
                        className="form-control"
                        options={this.props.options}
                        clearable={false}
                        onBlur={() => {
                            return this.props.input.onBlur(this.props.input.value)
                        }}
                        onChange={(selectedOption) => {
                            return input.onChange(selectedOption.value);
                        }}
                />
                <div className="help-block">
                    {(() => {
                        if (touched) {
                            if (error) {
                                return <span>{error}</span>
                            } else if (warning) {
                                return <span>{warning}</span>
                            }
                        }

                        if (help) {
                            return (<span>
                            {help}
                        </span>)
                        }
                    })()}
                </div>
            </div>
        </div>);
    }


    static defaultProps = {
        size: null
    };

    static propTypes = {
        size: PropTypes.string,
        options: PropTypes.array.isRequired,
        horizontal: PropTypes.number,
    }
}

export default SelectField;