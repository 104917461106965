import React from "react";
import {Route} from "react-router";
import SearchResultPage from "./SearchResultPage";
import ThankYouPage from "./ThankYouPage";
import OrderReviewPage from "./OrderReviewPage";
import CartPage from "./CartPage";
import PaymentPage from "./PaymentPage";

class FrontendApp extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Route path='/searchresult/*' component={SearchResultPage}/>
                <Route exact path='/domain/namecheck' component={SearchResultPage}/>
                <Route exact path='/domain/domainr' component={SearchResultPage}/>
                <Route exact path='/domain/domaintyper' component={SearchResultPage}/>
                <Route exact path='/domain/500px' component={SearchResultPage}/>
                <Route exact path='/domain/wildnames' component={SearchResultPage}/>
                <Route exact path='/cart/' component={CartPage}/>
                <Route exact path='/payment' component={PaymentPage}/>
                <Route exact path='/order/review/' component={OrderReviewPage}/>
                <Route exact path='/order/thankyou' component={ThankYouPage}/>
            </React.Fragment>
        )
    }
}

export default FrontendApp
