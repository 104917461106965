
/**
 * The LOC record is expressed in a master file in the following format:
 * owner TTL class LOC d1 [m1 [s1]] {"N"|"S"} d2 [m2 [s2]] {"E"|"W"} alt["m"] [siz["m"] [hp["m"] [vp["m"]]]]
 *
 * where:
 *
 * d1:     [0 .. 90]            (degrees latitude)
 * d2:     [0 .. 180]           (degrees longitude)
 * m1, m2: [0 .. 59]            (minutes latitude/longitude)
 * s1, s2: [0 .. 59.999]        (seconds latitude/longitude)
 * alt:    [-100000.00 .. 42849672.95] BY .01 (altitude in meters)
 * siz, hp, vp: [0 .. 90000000.00] (size/precision in meters)
 *
 * If omitted, minutes and seconds default to zero, size defaults to 1m,
 *     horizontal precision defaults to 10000m, and vertical precision
 * defaults to 10m.  These defaults are chosen to represent typical
 * ZIP/postal code area sizes, since it is often easy to find
 * approximate geographical location by ZIP/postal code.
 **/
export function parse(value)
{
    let parts = value.split(" ");

    if(parts.length < 5) {
        throw new Error("Invalid location data");
    }

    // parse position
    let position = {
        latitude: {
            degrees: null,
            minutes: '0',
            seconds: '0',
            ns: null
        },
        longitude: {
            degrees: null,
            minutes: '0',
            seconds: '0',
            ew: null
        },
        size: '1m',
        hp: '10000m',
        vp: '10m'
    };

    position.latitude.degrees = parts.shift();

    if(parts[0] != "N" && parts[0] != "S") {
        position.latitude.minutes = parts.shift();
    }
    if(parts[0] != "N" && parts[0] != "S") {
        position.latitude.seconds = parts.shift();
    }
    if(parts[0] != "N" && parts[0] != "S") {
        throw new Error("Please specify direction of latitude, (N)orth or (S)outh");
    } else {
        position.latitude.ns = parts.shift();
    }

    position.longitude.degrees = parts.shift();

    if(parts[0] != "E" && parts[0] != "W") {
        position.longitude.minutes = parts.shift();
    }
    if(parts[0] != "E" && parts[0] != "W") {
        position.longitude.seconds = parts.shift();
    }
    if(parts[0] != "E" && parts[0] != "W") {
        throw new Error("Please specify direction of longitude, (E)ast or (W)est");
    } else {
        position.longitude.ew = parts.shift();
    }

    position.altitude = parts.shift();

    if(parts.length > 0) {
        position.size = parts.shift();

        if(parts.length > 0) {
            position.hp = parts.shift();

            if(parts.length > 0) {
                position.vp = parts.shift()
            }
        }
    }

    // validate position
    if(!position.latitude.degrees.match(/^(90|[1-8][0-9]|[0-9])$/))
    {
        throw new Error("Invalid latitude degree value (must be 0-90)");
    } else {
        position.latitude.degrees = parseInt(position.latitude.degrees);
    }

    if(!position.longitude.degrees.match(/^[0-9]{1,3}$/) ||
        parseInt(position.longitude.degrees) > 180
    )
    {
        throw new Error("Invalid longitude degree value (must be 0-180)");
    } else {
        position.longitude.degrees = parseInt(position.longitude.degrees);
    }

    if(!position.latitude.minutes.match(/^[1-5]?[0-9]$/))
    {
        throw new Error("Invalid latitude minute value (must be 0-59)");
    } else {
        position.latitude.minutes = parseInt(position.latitude.minutes);
    }

    if(!position.longitude.minutes.match(/^[1-5]?[0-9]$/))
    {
        throw new Error("Invalid longitude minute value (must be 0-59)");
    } else {
        position.longitude.minutes = parseInt(position.longitude.minutes);
    }

    // 0 .. 59.999
    if(!position.latitude.seconds.match(/^[1-5]?[0-9](\.[0-9]{1,3})?$/))
    {
        throw new Error("Invalid latitude seconds value (must be 0-59.999)");
    } else {
        position.latitude.seconds = parseFloat(position.latitude.seconds);
    }

    if(!position.longitude.seconds.match(/^[1-5]?[0-9](\.[0-9]{1,3})?$/))
    {
        throw new Error("Invalid longitude seconds value (must be 0-59.999)");
    } else {
        position.longitude.seconds = parseFloat(position.longitude.seconds);
    }

    if(!position.altitude.match(/^-?[0-9]{1,8}(\.[0-9]{1,2})?m?$/)) {
        throw new Error("Invalid altitude");
    } else {
        position.altitude = parseFloat(position.altitude.slice(0,-1));
        if(parseFloat(position.altitude) < -100000.00 || parseFloat(position.altitude) > 42849672.95) {
            throw new Error("Altitude value outside acceptable range (min: -100000.00m, max: 42849672.95m)");
        }
    }

    if(!position.size.match(/^[0-9]{1,8}(\.[0-9]{1,2})?m?$/)) {
        throw new Error("Invalid size");
    } else {
        position.size = parseFloat(position.size.slice(0,-1));
        if(parseFloat(position.size) < 0 || parseFloat(position.size) > 90000000.00)
        {
            throw new Error("Size outside acceptable range (min: 0m, max: 90000000)");
        }
    }

    if(!position.hp.match(/^[0-9]{1,8}(\.[0-9]{1,2})?m?$/)) {
        throw new Error("Invalid horizontal precision value");

    } else {
        position.hp = parseFloat(position.hp.slice(0,-1));
        if(parseFloat(position.hp) < 0 || parseFloat(position.hp) > 90000000.00)
        {
            throw new Error("Horizontal precision value outside acceptable range (min: 0m, max: 90000000m)");
        }
    }

    if(!position.vp.match(/^[0-9]{1,8}(\.[0-9]{1,2})?m?$/)) {
        throw new Error("Invalid vertical precision value");
    } else {
        position.vp = parseFloat(position.vp.slice(0,-1));
        if(parseFloat(position.vp) < 0 || parseFloat(position.vp) > 90000000.00)
        {
            throw new Error("Vertical precision value outside acceptable range (min: 0m, max: 90000000m)");
        }
    }

    return position;
}

export function decToDeg(decimal) {

    decimal = Math.abs(decimal);
    let degrees = Math.floor(decimal);
    let minutes = Math.floor(60 * (decimal - degrees));
    let seconds = 3600 * (decimal - degrees - (minutes / 60));

    return {
        degrees: degrees,
        minutes: minutes,
        seconds: seconds
    }
}