import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Panel, Tab, Tabs} from "react-bootstrap";
import {accountActions} from "../../actions";
import AccountForm from "../../components/Portfolio/Form/AccountForm";
import TwoFactorAuthenticationPanel from "../../components/Portfolio/TwoFactorAuthenticationPanel";
import ChangePasswordForm from "../../components/Portfolio/Form/ChangePasswordForm";
import ExtraDataForm from "../../components/Portfolio/Form/ExtraDataForm";
import DeleteAccount from "../../components/Portfolio/DeleteAccount";

class AccountPage extends React.Component {

    render() {
        let initialValues = Object.keys(this.props.data)
            .filter((key) => {
                return (key.indexOf('extra') == -1)
            })
            .reduce((obj, key) => {
                obj[key] = this.props.data[key];
                return obj;
            }, {})

        let initialExtraValues = Object.keys(this.props.data)
            .filter((key) => {
                return (key.indexOf('extra') == 0)
            })
            .reduce((obj, key) => {
                obj[key] = this.props.data[key];
                return obj;
            }, {})

        return (
            <section className="panel">
                <header className="panel-heading">
                    <h1>My Account</h1>
                </header>
                <div className="panel-body">
                    <Tabs defaultActiveKey={1} id="account_edit">
                        <Tab eventKey={1} title="Account Data">
                            <br/>
                            <AccountForm initialValues={initialValues}/>
                        </Tab>
                        <Tab eventKey={2} title="Security">
                            <br/>
                            <Panel header="Two factor authentication">
                                <TwoFactorAuthenticationPanel {...this.props.two_factor_authentication}
                                                              setup={this.props.setup2FA}
                                                              cancelSetup={this.props.cancel2FASetup}
                                                              submitForm={this.props.submit2FAForm}
                                                              downloadRecoveryCodes={this.props.downloadRecoveryCodes}
                                                              disable={this.props.disable2FA}
                                />
                            </Panel>
                            <Panel header="Password Change">
                                <ChangePasswordForm/>
                            </Panel>
                            <DeleteAccount
                                allowDelete={this.props.allowDelete}
                                deleteAccount={this.props.deleteAccount}
                                deletingAccount={this.props.deletingAccount}
                            />
                        </Tab>
                        {(() => {
                            if (this.props.tlds['xxx'] != undefined ||
                                this.props.tlds['es'] != undefined ||
                                this.props.tlds['moscow'] != undefined ||
                                this.props.tlds['hk'] != undefined
                            ) {
                                return (<Tab eventKey={3} title="TLD-Data">
                                    <br/>
                                    <ExtraDataForm initialValues={initialExtraValues}
                                                   tlds={this.props.tlds}
                                                   isOrganization={this.props.data.organization != null}
                                                   isRussianCustomer={this.props.data.country == 'RU'}/>
                                </Tab>);
                            }
                        })()}
                    </Tabs>
                </div>
            </section>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        ...state.account,
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(accountActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage)
