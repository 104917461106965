/**
 * Return a new array, a subset of `dictionary`, where the corresponding entities
 * are matching the given `filter`. Assumes an array of ids (dictionary) and a
 * corresponding entities-object (entities).
 * It cycles through the dictionary, get the corresponding entity, looks at
 * each property, and compares all string properties to the value of the
 * `filter` string, returning only those which contain an exact match.
 *
 * @param filter
 * @param dictionary
 * @param entities
 * @returns {dictionary}
 */
const filteredList = (filter = '', dictionary = [], entities = {}) => {

    if (filter) {
        return dictionary.filter((id) => {
            let el = entities[id];
            return Object.keys(el).some((prop) => {
                return el[prop] &&
                    typeof el[prop] === 'string' &&
                    el[prop].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
        });
    }

    return dictionary;
};

/**
 * Return `dictionary` sorted by `prop` in either ascending or decending order based
 * on the value of `order` (either 'asc' or 'desc').
 *
 * @param prop
 * @param order
 * @param dictionary
 * @param entities
 */
const sortedList = (prop = 'name', order = 'asc', dictionary = [], entities = {}) => {

    return dictionary.sort((idA, idB) => {

        if (prop == '_rand') {
            return .5 - Math.random();
        }

        let a = entities[idA];
        let b = entities[idB];

        if (a[prop] > b[prop]) return order === 'asc' ? 1 : -1;
        if (a[prop] < b[prop]) return order === 'asc' ? -1 : 1;

        return 0;
    });
};

/**
 * Return a new array that is the reverse of `list`.
 *
 * @param dictionary
 */
const reversedList = dictionary => {
    return dictionary.slice().reverse();
}

/**
 * Return the total number of pages that can be made from `list`.
 *
 * @param itemsPerPage
 * @param dictionary
 * @returns {number}
 */
const getTotalPages = (itemsPerPage = 10, dictionary = []) => {
    const total = Math.ceil(dictionary.length / itemsPerPage);

    return total ? total : 0;
};

/**
 * Return a slice of all `list` starting at `start` up to `itemsPerPage`
 * (or the length of list; whichever comes first).
 *
 * @param page
 * @param itemsPerPage
 * @param list
 * @returns {*}
 */
const slicedList = (page = 1, itemsPerPage = 10, list = []) => {
    const start = (page - 1) * itemsPerPage;
    const end = itemsPerPage === 0 ? list.length : start + itemsPerPage;

    return end === list.length ?
        list.slice(start) :
        list.slice(start, end);
};

// params:
// 1. the reducer being augmented
// 2. definitions of action types
// 3. options
const paginate = (reducer,
                  {
                      GOTO_PAGE = 'GOTO_PAGE',
                      NEXT_PAGE = 'NEXT_PAGE',
                      PREV_PAGE = 'PREV_PAGE',
                      FILTER = 'FILTER',
                      SORT = 'SORT',
                      SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE'
                  } = {},
                  {
                      defaultPage = 1,
                      defaultSortOrder = 'asc',
                      defaultSortBy = 'name',
                      defaultItemsPerPage = 10,
                      defaultFilter = '',
                      defaultTotal = 0,
                      defaultFilteredLength = 0,
                  } = {}) => {
    // NOTE: the reducer's array is named "list" at this point.
    // TODO: Is there a way to define the name of this property outside this module?
    // NOTE: _cacheList is a temporary cached array of sorted + filtered elements
    // from the total list so that it doesn't need to be re-calculated each time
    // the pagedList function is called.
    let parent = reducer(undefined, {});

    const initialState = {
        ...parent,
        pageList: [],
        page: defaultPage,
        totalPages: defaultTotal,
        filteredLength: defaultFilteredLength,
        itemsPerPage: defaultItemsPerPage,
        order: defaultSortOrder,
        orderBy: defaultSortBy,
        filter: defaultFilter,
        _cacheList: parent.dictionary,
    };

    return (state = initialState, action) => {
        const {dictionary, entities, _cacheList, page, itemsPerPage, order, orderBy, filter, totalPages} = state;

        // NOTE: I'm using blocks (i.e., statments wrapped in {}) for a few
        // conditions so that I can reuse the same variable const in different
        // blocks without causing a duplicate declaration conflicts.
        switch (action.type) {

            // Go to a specific page. Can be used to initialize the list into a certain
            // page state.
            case GOTO_PAGE:
                return {
                    ...state,
                    page: action.page,
                    pageList: slicedList(action.page, itemsPerPage, _cacheList)
                };

            // If the the action is fired whilst at the end of the list, swing around
            // back to the beginning.
            case NEXT_PAGE:
                let nextPage = page + 1;
                if (nextPage > totalPages)
                    nextPage = totalPages;

                return {
                    ...state,
                    page: nextPage,
                    pageList: slicedList(nextPage, itemsPerPage, _cacheList)
                };

            // If the action is fired whilst already at the beginning of the list,
            // swing around to the end of the list (this behaviour can be handled
            // differently through the UI if this is not the desired behaviour, for
            // example, by simply not presenting the user with the "prev" button at
            // all if already on the first page so it is not possible to wrap around).
            case PREV_PAGE:
                let prevPage = page - 1;
                if (prevPage < 0) prevPage = parent.entities.length - 1;

                return {
                    ...state,
                    page: prevPage,
                    pageList: slicedList(prevPage, itemsPerPage, _cacheList)
                };

            case SET_ITEMS_PER_PAGE:
                let total = getTotalPages(action.itemsPerPage, _cacheList);
                let currentPage = page;

                if (currentPage > total) {
                    currentPage = total;
                }

                return {
                    ...state,
                    page: currentPage,
                    itemsPerPage: action.itemsPerPage,
                    pageList: slicedList(page, action.itemsPerPage, _cacheList),
                    totalPages: total
                };

            // Reset page to 1 as this existing page has lost its meaning due to the
            // list changing form.
            case FILTER: {
                const newCache = sortedList(orderBy, order, filteredList(action.filter, dictionary, entities), entities);

                let total = getTotalPages(itemsPerPage, newCache);
                let page = state.page;

                if (page > total) {
                    page = total;
                }

                if (page == 0) {
                    page = 1;
                }

                return {
                    ...state,
                    page: page,
                    filter: action.filter,
                    _cacheList: newCache,
                    pageList: slicedList(1, itemsPerPage, newCache),
                    totalPages: getTotalPages(action.itemsPerPage, newCache),
                    filteredLength: newCache.length
                };
            }

            // There's a bit of optimization going on here. If the `by` hasn't changed
            // (meaning the user clicked on the currently active column), then simply
            // reverse the order of the _cacheList (which is cheaper than running through
            // the entire filter and sort functions). If the `by` has changed, *then*
            // run the _cacheList through the whole sort/filter combo to get a new list.
            case SORT: {
                const newOrder = action.orderBy === orderBy && order === 'asc' ? 'desc' : 'asc';
                const newCache = action.orderBy === orderBy ?
                    reversedList(_cacheList) :
                    sortedList(action.orderBy, newOrder, filteredList(filter, dictionary, entities), entities);

                return {
                    ...state,
                    orderBy: action.orderBy,
                    order: newOrder,
                    _cacheList: newCache,
                    pageList: slicedList(page, itemsPerPage, newCache)
                };
            }

            // Setup the default list and cache and calculate the total.
            default: {
                const newContainer = reducer(state, action);
                const newCache = sortedList(orderBy, order, filteredList(filter, newContainer.dictionary, newContainer.entities), newContainer.entities);

                return {
                    ...newContainer,
                    _cacheList: newCache,
                    pageList: slicedList(page, newContainer.itemsPerPage, newCache),
                    totalPages: getTotalPages(newContainer.itemsPerPage, newCache),
                    filteredLength: newCache.length,
                };

            }
        }
    };
};

export default paginate;
