import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {confirmable, createConfirmation} from 'react-confirm';

/**
 * WE also need
 * - altitude (elevationService)
 * - vertical and horizontal precision?
 * -
 */
class GdprModal extends React.Component {

    render() {
        const {
            proceed,
            dismiss,
            registry,
            tld
        } = this.props;

        if (registry.escrowServiceUsed == true) {
            return (
                <div className="static-modal">
                    <Modal show={true}
                           backdrop={true}
                           keyboard={true}
                           onHide={dismiss}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Data to be transmitted</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Note: Your data will be transferred to the ICANN Designated Escrow Agent
                            {' '}<a href="https://www.denic.de/service/escrow-services/" target="_blank">DENIC eG</a>{' '}
                            for your protection as required by ICANN regulation.
                        </Modal.Body>
                    </Modal>
                </div>
            );
        } else {
            return (
                <div className="static-modal">
                    <Modal show={true}
                           backdrop={true}
                           keyboard={true}
                           onHide={dismiss}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Data to be transmitted</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="content">
                                <p>
                                    The following data will be transferred to the registry:
                                </p>
                                <strong>{registry.registry.name}</strong><br/>
                                <span>
                                {registry.registry.address.split("\n").map((part, key) => {
                                    return (
                                        <span key={key}>
                                            {part}
                                            <br/>
                                        </span>
                                    )
                                })}
                            </span>
                                <br/>
                                <div className="mt20">
                                    <span className="displayBlock"><b>For private owners</b></span><br/>
                                    {registry.dataTransmittedForPrivatePersons.map((field) => {
                                        return <React.Fragment key={field}>
                                            - {field} <br/>
                                        </React.Fragment>
                                    })}
                                </div>
                                <div className="mt20">
                                    <span className="displayBlock"><b>For Companies</b></span><br/>
                                    {registry.dataTransmittedForCompanies.map((field) => {
                                        return <React.Fragment key={field}>
                                            - {field} <br/>
                                        </React.Fragment>
                                    })}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='button-l' bsStyle="primary" onClick={() => {
                                proceed()
                            }}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        }
    }
}

const defaultConfirmation = createConfirmation(confirmable(GdprModal), 1);

export function showGdprModal(confirmation, options = {}) {
    return defaultConfirmation({confirmation, ...options});
}
