import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {dnsActions, domainActions, mailForwardActions} from "../../actions";
import Link from "../Link";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomNameserverWarning from "../../components/Portfolio/CustomNameserverWarning";
import {Alert} from "react-bootstrap";
import MailForwardSet from "../../components/Portfolio/MailForwardSet";
import {confirm} from "../../util/confirm";
import {toastr} from "react-redux-toastr";
import MailForwardSetForm from "../../components/Portfolio/Form/MailForwardSetForm";

class ManageForwardsPage extends React.Component {

    componentDidMount() {
        this.props.domainActions.fetchData();
        this.props.dnsActions.fetchData(this.props.match.params.domain)
    }

    editRecord(local) {
        this.props.editMailForwardRecord(this.props.match.params.domain + ".", local);
    }

    cancelEditRecord(local) {
        this.props.cancelEditMailForwardRecord(this.props.match.params.domain + ".", local);
    }

    deleteRecord(local) {
        confirm(
            'All mail forwards from the address ' + local + "@" + this.props.match.params.domain + ' will be deleted. This action cannot be undone.', {
                'title': 'Delete mail forwards?'
            }).then(() => {

            return this.props.deleteMailForwards(this.props.match.params.domain + '.', local);
        }).then(({value, action}) => {
            if (value.data && value.data.success && value.data.success == true) {
                toastr.success("Mail Forwards deleted successfully!");
            }
        }).catch(error => {
            // confirmation denied
        })
    }

    testMailForward(local, domain) {
        this.props.testEmailForward(local, domain);
    }

    render() {
        return (
            <div className="panel panel-default" id="portfolio-config-email">
                <div className="panel-body">
                    <Link to={"/portfolio"}
                          className="btn btn-default btn-xs">
                        back
                    </Link>
                    <div className="row clearfix">
                        <div className="col-xs-12">
                            <h1>Email Forwarding Settings for {this.props.match.params.domain}</h1>
                        </div>
                    </div>
                    <div className="row cleafix mb20">
                        <div className="col-xs-12">
                            Create custom one-way email addresses on your domain that forward email to an existing inbox of your choosing.
                        </div>
                    </div>
                    {(() => {
                        if (!(
                            this.props.domains.isFetched &&
                            this.props.zones.entities.hasOwnProperty(this.props.match.params.domain + '.')
                        )) {
                            return (<div className="col-xs-12"><LoadingIndicator active={true}/></div>);
                        } else {
                            const domain = this.props.domains.entities[this.props.match.params.domain];
                            const zone = this.props.zones.entities[domain.domain + '.'];

                            const originalResourceRecord = zone.resourceRecords.find(rr => rr.type == 'x-smtp' && rr.name == '@');

                            const zoneMailForwards = this.props.mailForwards.entities[domain.domain + '.']
                            let disableEdit;

                            return (
                                <div className="row clearfix">
                                    <div className="col-xs-12">
                                        <CustomNameserverWarning show={domain.nameserverSet != 'uniteddomains'}
                                                                 text={<React.Fragment>It looks like you're using custom
                                                                     name servers.
                                                                     Since you aren't using United Domains' name
                                                                     servers, your cannot Manage Email-Forwarding here.
                                                                     You set your domain to use our Nameservers on
                                                                     the <Link
                                                                         to={"/portfolio/dns/" + domain.domain}>DNS-Page</Link>
                                                                 </React.Fragment>}
                                        />

                                        {(() => {
                                            if (domain.nameserverSet != "uniteddomains") {
                                                disableEdit = true;
                                            }
                                            if (domain.lock.dns == true) {
                                                disableEdit = true;
                                                return <Alert variant="info">
                                                    This domain is currently locked. You cannot make any changed to the
                                                    domain here. Please contact our customer service to resolve this
                                                    issue.
                                                </Alert>
                                            }
                                            if (domain.nameserverSet == "uniteddomains") {
                                                let usingCustomMX = false;
                                                for (let record of zone.resourceRecords) {
                                                    if ((record.name == "@" || record.name == "*") && record.type == "mx") {
                                                        usingCustomMX = true;
                                                        disableEdit = true;
                                                        break;
                                                    }
                                                }

                                                if (usingCustomMX == true) {
                                                    return <Alert variant="warning">
                                                        Your domain is already connected to email hosting. Email forward
                                                        records you make here will not work unless you first delete the
                                                        custom MX records that connect DOMAIN to email hosting.
                                                        <Link
                                                            to={"/portfolio/dns/" + domain.domain}
                                                            disabled={domain.lock.dns || domain.status == 'billingexpire'}>
                                                            Go to DNS settings.
                                                        </Link>{' '}
                                                    </Alert>
                                                }
                                            }

                                        })()}

                                        <table className="table" id="email-forwards">
                                            <thead>
                                                <MailForwardSetForm _key="_new"
                                                                    domain={domain}
                                                                    new={true}
                                                                    editRecord={this.editRecord.bind(this)}
                                                                    deleteRecord={this.deleteRecord.bind(this)}
                                                                    initialValues={{
                                                                        local: '',
                                                                        targets: [''],
                                                                        deleting: false,
                                                                        key: '_new'
                                                                    }}
                                                                    cancelEditRecord={this.cancelEditRecord.bind(this)}
                                                                    allForwards={zoneMailForwards}
                                                                    form="MailForwardsForm_New"
                                                                    originalResourceRecord={originalResourceRecord}
                                                                    saving={zoneMailForwards.saving}
                                                />
                                            <tr>
                                                <th colSpan={2}>Forward from</th>
                                                <th>to</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(() => {
                                                if (zoneMailForwards.dictionary.length == 0) {
                                                    return <tr>
                                                        <td colSpan={3}>No eMail-Forwards defined for
                                                            domain {domain.domain}</td>
                                                    </tr>
                                                } else {
                                                    return zoneMailForwards.dictionary.map((local, index) => {

                                                        let forwards = zoneMailForwards.entities[local];

                                                        if (
                                                            zoneMailForwards.edit != null &&
                                                            zoneMailForwards.edit == forwards.local
                                                        ) {
                                                            // editing = true;
                                                            return <MailForwardSetForm _key={forwards.local}
                                                                                       key={forwards.local}
                                                                                       mailForward={forwards}
                                                                                       domain={domain}
                                                                                       new={false}
                                                                                       editRecord={this.editRecord.bind(this)}
                                                                                       deleteRecord={this.deleteRecord.bind(this)}
                                                                                       initialValues={forwards}
                                                                                       cancelEditRecord={this.cancelEditRecord.bind(this)}
                                                                                       allForwards={zoneMailForwards}
                                                                                       form={"MailForwardsForm_" + forwards.local}
                                                                                       originalResourceRecord={originalResourceRecord}
                                                                                       saving={zoneMailForwards.saving}
                                                            />
                                                        } else {
                                                            // editing = false
                                                            return <MailForwardSet
                                                                _key={forwards.local}
                                                                key={forwards.local}
                                                                mailForward={forwards}
                                                                domain={domain}
                                                                deleting={forwards.deleting}
                                                                testing={forwards.testing}
                                                                editRecord={this.editRecord.bind(this)}
                                                                deleteRecord={this.deleteRecord.bind(this)}
                                                                disableEdit={disableEdit}
                                                                testMailForward={this.testMailForward.bind(this)}
                                                            />
                                                        }
                                                    });
                                                }
                                            })()}
                                            </tbody>
                                        </table>
                                        <br />
                                        Want to forward all emails to a single inbox? Enter an asterisk (the * symbol)
                                        in the first field to create a catchall email forward. Any email sent to an
                                        address on your domain (excluding those you've already set up) will forward
                                        to one email address. (For example, *@{domain.domain} to yourname@youremail.tld)
                                        <hr/>
                                        <div className="row clearfix">
                                            <div className="col-xs-12">
                                                <span>*Note: test emails can only be sent to an email forwarding address after you've created it.</span>
                                                <div className="pull-right">
                                                    {/*<Button bsStyle="primary" type="submit"
                                                            disabled={submitting || this.props.disabled == true}>
                                                        {submitting ? 'Saving...' : 'Save'}
                                                    </Button>
                                                    {' '}
                                                    <Button bsStyle="danger" type="button"
                                                            disabled={submitting || this.props.disabled == true}
                                                            onClick={reset}>
                                                        Reset
                                                    </Button>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })()}
                </div>
            </div>
        )
            ;
    }
}

const mapStateToProps = function (state) {
    return {
        zones: state.dnsZones,
        mailForwards: state.mailForward,
        domains: state.domains
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(mailForwardActions, dispatch),
    dnsActions: bindActionCreators(dnsActions, dispatch),
    domainActions: bindActionCreators(domainActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageForwardsPage)
