import React from "react";
import {Alert, Panel} from "react-bootstrap";

class DeleteAccount extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            confirmationText: ''
        }

        this.handleConfirmationInput = this.handleConfirmationInput.bind(this);
    }

    handleConfirmationInput(e) {
        this.setState({confirmationText: e.nativeEvent.target.value});
    }

    deleteAccount() {
        if(
            this.state.confirmationText == 'delete' &&
            this.props.allowDelete == true
        ) {
            this.props.deleteAccount();
        }
    }

    render() {
        return (
            <Panel header="Delete Account">
                {(() => {
                    if (this.props.allowDelete == true) {
                        return (
                            <div>
                                To delete your UD account, type the word "delete" below, then click <strong>Delete my account.</strong>
                                <br/>
                                <br/>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <div className="form-group">
                                            <input type="text"
                                                   placeholder="Type 'delete' here."
                                                   className="form-control"
                                                   value={this.state.confirmationText}
                                                   onChange={this.handleConfirmationInput.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-6">
                                        <button id="delete-account"
                                                className="btn btn-danger"
                                                disabled={this.state.confirmationText != 'delete' || this.props.deletingAccount}
                                                onClick={this.deleteAccount.bind(this)}
                                        >
                                            {this.props.deletingAccount?'Deleting...':'Delete my account'}
                                        </button>
                                    </div>
                                </div>
                            </div>);
                    } else {
                        return (
                            <Alert bsStyle="danger">
                                <h4>Account cannot be deleted</h4>
                                <p>
                                    This account contains at least one registered domain. Please contact{' '}
                                    <a href="mailto:support@uniteddomains.com">support@uniteddomains.com</a>.
                                </p>
                            </Alert>);
                    }
                })()}
            </Panel>
        )
    }

    static propTypes = {
        // actions
        //fetchData: PropTypes.func.isRequired,

        // state data
        //totalPages: PropTypes.number.isRequired,
    }
}

export default DeleteAccount
