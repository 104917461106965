import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {Field, formValueSelector, reduxForm, SubmissionError} from "redux-form";
import {required} from 'redux-form-validators'
import TextField from "../../Form/TextField";
import SelectField from "../../Form/SelectField";
import {dnsActions} from "../../../actions";
import label from "./Validators/label";
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr'

const validateAndUpdateRedirect = (values, dispatch, props) => {

    let name = values.name;
    if (name.endsWith('.')) {
        name = name.substr(0, name.indexOf(props.zone) - 1);
    }

    let valuesToSave = {
        name: name,
        ttl: 600,
        type: 'x-http',
        rdata: [
            values.redirectType + ' ' + values.redirectTarget
        ]
    };

    // we need to know if the submit was successful or not?
    dispatch(dnsActions.saveResourceRecordSet(
        props.zone,
        props.initialValues.name,
        props.initialValues.type,
        valuesToSave,
        props.initialValues.key == '_new',
        props.initialValues.key
    )).then(action => {
        let result = action.value.data;

        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            if (props.initialValues.key) {
                props.reset();
            }
            toastr.success("Redirect saved successfully!");
        } else {
            // since we cannot say which field caused the error, we just display a global error message
            /*let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }*/

            toastr.error("Failed to save Redirect!");
            throw new SubmissionError();
        }
    }).catch(error => {
        let msg = null;

        if (error.response && error.response.data && error.response.data.exception) {
            msg = error.response.data.exception;
        }
        toastr.error("Failed to save Redirect!", msg);
        throw new SubmissionError();
    })
}

class RedirectResourceRecordForm extends React.Component {

    render() {
        return (<tr key={this.props._key} className="editing">
            <td key='resource_record_group_name'>
                <Field name="name"
                       component={TextField}
                       placeholder="@"
                       type="text"
                       size="sm"
                       className="rname"
                       validate={[
                           required({
                               message: {
                                   id: 'form.errors.name.required',
                                   defaultMessage: "Please provide the name for this record"
                               }
                           }), label({
                               allResourceRecords: this.props.allResourceRecords,
                               key: this.props._key,
                               zone: this.props.zone,
                           })]}

                />
            </td>
            <td key='resource_record_group_type'>
                <Field name="redirectType"
                       component={SelectField}
                       type="select"
                       size="sm"
                       simpleValue={true}
                       options={[
                           {value: 'FRAME', label: 'Frame'},
                           {value: 'REDIRECT', label: 'Redirect'},
                       ]}
                />
            </td>
            <td>
                <Field name="redirectTarget"
                       component={TextField}
                       type="text"
                       size="sm"
                       placeholder="redirect-target"
                       className='rdata form-inline'
                       validate={[
                           required({
                               message: {
                                   id: 'form.errors.dns.rdata_required',
                                   defaultMessage: "Please provide the contents for this record"
                               }
                           }),
                           /*url({
                               allResourceRecords: props.allResourceRecords,
                               key: props._key,
                               zone: props.zone,
                           })*/
                       ]}
                >
                </Field>
            </td>
            <td className="action">
                {this.props.new == false &&
                <Button bsStyle="default"
                        onClick={this.props.cancelEditRecord}
                        disabled={this.props.saving == this.props._key}
                >
                    cancel
                </Button>}
            </td>
            <td className="action">
                <Button bsStyle="primary"
                        onClick={this.props.handleSubmit(validateAndUpdateRedirect)}
                        disabled={this.props.saving == this.props._key}>
                    {(() => {
                        if (this.props.saving == this.props._key) {
                            return (this.props.new ? 'Adding...' : 'Saving...');
                        }
                        else {
                            return (this.props.new ? 'Add' : 'Save');
                        }
                    })()}
                </Button>
            </td>
        </tr>);
    }

    static propTypes = {
        // state data
        _key: PropTypes.string.isRequired,
        allResourceRecords: PropTypes.array.isRequired,
        zone: PropTypes.string.isRequired
    }

    static defaultProps = {
        new: false
    }
}

RedirectResourceRecordForm = reduxForm({
    onSubmit: validateAndUpdateRedirect
})(RedirectResourceRecordForm)

const _formValueSelector = formValueSelector;

export default connect((state, props) => {
    const type = _formValueSelector(props.form)(state, 'type');

    return {
        type
    }
})(RedirectResourceRecordForm);