import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AnimateOnChange from "react-animate-on-change";
import {cartActions} from "../actions";

class CartBadge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            len: this.props.len
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.len == 0 && this.props.len > 0) {
            $('#cartMenu:not(.open) a.dropdown-toggle').dropdown('toggle'); // unsafe
        }
    }

    render() {
        return (
            <AnimateOnChange
                animate={this.props.len >= 0}
                baseClassName="badge"
                animationClassName="cart-badge-bounce">
                {this.props.len}
            </AnimateOnChange>
        )
    }

    static propTypes = {
        children: PropTypes.node
    }
}

function mapStateToProps(state) {
    return {
        len: state.cart.dictionary.length
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CartBadge)
