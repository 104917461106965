import React from "react";
import PropTypes from 'prop-types';
import {Modal, Button} from "react-bootstrap";
import FormattedCurrency from "./FormattedCurrency";

/**
 * Props:
 *  -> OK-Text
 *  -> Cancel-Text
 *  -> onConfirm
 *  -> onCancel
 */
class ConfirmActivateWPSCartModal extends React.Component {
    render() {
        let {cartItem, onConfirm, onCancel, wpsPrice} = this.props

        if (cartItem == null) {
            return null;
        }

        return (
            <Modal show={true} onHide={() => onCancel()} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Enable Domain Privacy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You are about to enable Domain Privacy for {cartItem.domain}{' '}
                        for <FormattedCurrency value={wpsPrice} addtax={true}/> pear year.
                    </p>
                    <p>
                        You can disable and enable Domain Privacy free of charge at any time during
                        your domain’s current registration term again.
                    </p>
                    <p>
                        After your Whois Protection settings are updated, please allow up to 48
                        hours for third-party Whois lookup services to reflect your updates.
                    </p>
                    <p>
                        By activating the service I confirm that I have read and accept the{' '}
                        <a href={ Routing.generate('main_terms_and_conditions') }>
                            general terms and conditions
                        </a> and the{' '}
                        <a href={ Routing.generate('main_terms_and_conditions_wps') }>
                            terms and conditions for private domain name registration
                        </a>.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                    <Button bsStyle="primary" onClick={() => onConfirm()} disabled={cartItem.savingWPS}>
                        {cartItem.savingWPS ? 'Saving...' : 'Activate'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ConfirmActivateWPSCartModal.propTypes = {
    // actions
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,

    wpsPrice: PropTypes.object.isRequired,
    cartItem: PropTypes.object,
}

export default ConfirmActivateWPSCartModal
