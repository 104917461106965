import Dinero from "dinero.js";
import React from "react";
import PropTypes from "prop-types";

function FormattedCurrency(props) {
    const {value, addtax, ...p} = props;

    let v;

    if(value.getAmount) {
        v = value;
    } else {
        if(typeof(value) == "object") {
            v = Dinero({amount: parseInt(value.amount, 10), currency: value.currency});
        } else {
            v = Dinero({amount: parseInt(value, 10)});
        }
    }

    if(window.taxRate != undefined && window.taxRate > 0 && addtax == true) {
        v = v.add(v.multiply(window.taxRate).divide(100));
    }

    return <span {...p}>{v.toFormat('$0,0.00')}</span>
}

FormattedCurrency.propTypes = {
    addtax: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

export default FormattedCurrency;
