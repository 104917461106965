import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {dnsActions, domainActions} from "../../actions";
import Link from "../Link";
import LoadingIndicator from "../../components/LoadingIndicator";
import CustomNameserverWarning from "../../components/Portfolio/CustomNameserverWarning";
import {Alert} from "react-bootstrap";
import DomainForwardConfigForm from "../../components/Portfolio/Form/DomainForwardConfigForm";

class ManageForwardsPage extends React.Component {

    componentDidMount() {
        this.props.domainActions.fetchData();
        this.props.fetchData(this.props.match.params.domain)
    }

    render() {
        return (
            <div className="panel panel-default" id="portfolio-config-dns">
                <div className="panel-body">
                    <Link to={"/portfolio"}
                          className="btn btn-default btn-xs">
                        back
                    </Link>
                    <div className="row clearfix">
                        <div className="col-xs-12">
                            <h1>Manage URL Forwarding for {this.props.match.params.domain}</h1>
                        </div>
                    </div>
                    {(() => {
                        if (!(
                            this.props.domains.isFetched &&
                            this.props.zones.entities.hasOwnProperty(this.props.match.params.domain + '.')
                        )) {
                            return (<div className="col-xs-12"><LoadingIndicator active={true}/></div>);
                        } else {
                            const domain = this.props.domains.entities[this.props.match.params.domain];
                            const zone = this.props.zones.entities[domain.domain + '.'];

                            let existingRedirectRecord = null;
                            let initialFormValues = {
                                key: '_new',
                                name: '@',
                                redirectType: 'FRAME'
                            };
                            let disableEdit = false;

                            for (let record of zone.resourceRecords) {
                                if ((record.name == "@" || record.name == '*') && record.type == "x-http") {
                                    existingRedirectRecord = record;

                                    let parts = record.rdata[0].split(" ");
                                    initialFormValues = {
                                        defaultOrCustom: parts[1] == "https://www.uniteddomains.com/defaultpage"?"default":"custom",
                                        redirectTarget: parts[1],
                                        redirectType: parts[0],
                                        key: existingRedirectRecord.key,
                                        name: existingRedirectRecord.name
                                    }
                                    break;
                                }
                            }

                            return (
                                    <div className="row clearfix">
                                        <div className="col-xs-12">
                                            <CustomNameserverWarning show={domain.nameserverSet != 'uniteddomains'}
                                                                     text={<React.Fragment>It looks like you're using custom
                                                                         name servers.
                                                                         Since you aren't using United Domains' name
                                                                         servers, your cannot Manage URL-Forwarding here.
                                                                         You set your domain to use our Nameservers on
                                                                         the <Link
                                                                             to={"/portfolio/dns/" + domain.domain}>DNS-Page</Link>
                                                                     </React.Fragment>}
                                            />

                                            {(() => {
                                                if (domain.nameserverSet != "uniteddomains") {
                                                    disableEdit = true;
                                                }
                                                if (domain.lock.dns == true) {
                                                    disableEdit = true;
                                                    return <Alert variant="info">
                                                        This domain is currently locked. You cannot make any changed to the
                                                        domain here. Please contact our customer service to resolve this
                                                        issue.
                                                    </Alert>
                                                }
                                                if (domain.nameserverSet == "uniteddomains") {
                                                    let usingCustomAorCname = false;
                                                    for (let record of zone.resourceRecords) {
                                                        if ((record.name == "@" || record.name == "*") && record.type == "a" || record.type == "cname") {
                                                            usingCustomAorCname = true;
                                                            disableEdit = true;
                                                            break;
                                                        }
                                                    }

                                                    if(usingCustomAorCname == true) {
                                                        return <Alert variant="warning">
                                                            You can't configure Web redirect when you use own A or CNAME
                                                            record.
                                                            You can Change your DNS-Settings <Link to={"/portfolio/dns/" + domain.domain}
                                                                  disabled={domain.lock.dns || domain.status == 'billingexpire'}>
                                                                here
                                                            </Link>{' '}
                                                        </Alert>
                                                    }
                                                }

                                            })()}
                                            <DomainForwardConfigForm initialValues={initialFormValues}
                                                                     domain={domain}
                                                                     zone={domain.domain + "."}
                                                                     disabled={disableEdit}
                                            />
                                        </div>
                                    </div>
                                )
                        }
                    })()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        zones: state.dnsZones,
        domains: state.domains
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(dnsActions, dispatch),
    domainActions: bindActionCreators(domainActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageForwardsPage)
