import {addValidator} from "redux-form-validators";

/**
 * When to use the dot in DNS
 *
 * This is a frequent mistake by our customers
 * http://www.zytrax.com/books/dns/apa/dot.html
 */
export default addValidator({
    validator: function (options, value, allValues) {

        if(value.substr(-1) != '.') {
            return {
                id: "form.errors.dns.trailing_dot",
                defaultMessage: "If your entry in this field does not end with a dot, this record will be saved as \"" + value + "." + options.zone + "\""
            };
        }
    }
})