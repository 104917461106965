import {push} from 'connected-react-router';
import {submit} from "redux-form";

function submitSearchForm()
{
    return (dispatch, getState) => {
        dispatch(submit('SearchForm'));
    }
}

function navigateTo(url) {
    return (dispatch, getState) => {
        dispatch(push(url));
    }
}

export default {
    submitSearchForm,
    navigateTo,
}
