import React from "react";

const TaxHint = (props) => {

    if(window.taxRate && window.taxRate > 0) {
        let formattedTaxRate = window.taxRate;
        return <div id="taxHint">All prices include {formattedTaxRate}% VAT.</div>
    } else {
        return '';
    }
}

export default TaxHint;
