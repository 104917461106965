import React from 'react';
import PropTypes from 'prop-types';
import {HelpBlock, Popover} from "react-bootstrap";
import MaterialIcon from "../MaterialIcon";

class SearchField extends React.Component {

    render() {

        const {input, label, type, help, meta: {touched, error, invalid, warning}, children} = this.props;

        let groupClasses = 'form-group';

        if (this.props.className) {
            groupClasses += ' ' + this.props.className;
        }

        if (touched && invalid) {
            groupClasses += ' has-error';
        }

        if (touched && warning) {
            groupClasses += ' has-warning';
        }

        let placeholder;
        if(this.props.placeholder) {
            placeholder = this.props.placeholder;
        }

        if(this.props.onKeyDown) {
            input.onKeyDown = this.props.onKeyDown;
        }

        return (
            <div className={groupClasses}>
                <div className="input-group input-group-lg">
                    <textarea {...input}
                              className="form-control"
                              placeholder={placeholder}
                    />
                    <div className="input-group-addon">
                        <a onClick={(e) => {e.preventDefault(); this.props.toggleBulkSearch}}
                           id="domainsearch-bulk-switch" className="hidden-xs">
                            <MaterialIcon icon="keyboard_arrow_down" />
                            Bulk search
                        </a>
                    </div>
                </div>
                {(() => {
                    if (touched && invalid) {
                        return <HelpBlock>
                            <Popover
                                positionLeft="30%"
                                id="validationPopover"
                                title="Invalid Search Term"
                                placement="bottom"
                            >
                                {error}
                            </Popover>
                        </HelpBlock>
                    }
                })()}
            </div>);
    }


    static defaultProps = {
        size: null
    };

    static propTypes = {
        size: PropTypes.string,
        horizontal: PropTypes.number,
    }
}

export default SearchField;
