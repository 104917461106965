function shouldFetchData(state) {
    return (Object.keys(state.pendingOrders.items).length == 0 && !state.pendingOrders.isFetching)
}

function fetchData() {
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch({
                type: 'PENDINGORDERLIST_FETCH',

                payload: fetch(Routing.generate('portfolio_domains_pendingorders'), {
                    credentials: 'same-origin'
                }).then(response => {
                    return response.json()
                }).then(pendingOrders => {
                    return pendingOrders.map(pendingOrder => {
                        return {
                            ...pendingOrder,
                            created_at: new Date(pendingOrder.created_at),
                        }
                    })

                })
            })
        }
    }
}

export default {
    fetchData
}
