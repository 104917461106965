import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import {Field, reduxForm, SubmissionError} from "redux-form";
import TextField from "../../Form/TextField";
import {contactActions} from '../../../actions';
import {addValidator, email, format, length, required} from 'redux-form-validators'
import {asYouType, isValidNumber} from 'libphonenumber-js'

const { getCodeList } = require('country-list');

const phone = addValidator({
    defaultMessage: {
        id: 'form.errors.phone_invalid',
        defaultMessage: 'This value is not a valid phone number'
    },
    validator: (options, value, allValues) => {
        if (value.trim() == "" || value == null || value == "null") {
            return true;
        }

        return isValidNumber(value);
    }
})

const validate = (values) => {
    const errors = {}
    if (!values.useFor == 'undefined') {
        errors.useFor = 'Required'
    }

    return errors
}

const validateAndUpdateContactData = (values, dispatch, props) => {

    const domain = props.domain;

    for (let i in values) {
        if (values[i] == null || i == 'domain') {
            delete values[i];
        }
    }

    return dispatch(contactActions.updateContact(values, domain)).payload.then(result => {
        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            dispatch(contactActions.saveContactSuccess(values, domain)); //ps: this is same as dispatching RESET_USER_FIELDS
        } else {
            let errors = {};

            if(result.errors != undefined) {
                for (let field in result.errors.children) {
                    if (result.errors.children[field] && result.errors.children[field].errors) {
                        errors[field] = result.errors.children[field].errors.join(', ');
                    }
                }
            }

            dispatch(contactActions.saveContactFailure(values, errors, result.message));
            throw new SubmissionError(errors);
        }
    });
}

class ContactForm extends Component {


    render() {
        const {handleSubmit, submitting, pristine, reset} = this.props;

        const fieldProps = {
            component: TextField,
            horizontal: 5,
            size: 'sm'
        }

        return (
            <Modal show={true} onHide={this.props.cancelEdit} id="editContactModal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal">
                        <div className="form-group form-group-sm">
                            <label className="control-label required col-sm-5">
                                Title
                            </label>
                            <div className="col-sm-7">
                                <label className="required radio-inline">
                                    <Field name="title" value="Mr" type="radio" component="input"/>Mr.
                                </label>
                                <label className="required radio-inline">
                                    <Field name="title" value="Ms" type="radio" component="input"/>Ms.
                                </label>
                            </div>
                            <div className="help-block"></div>
                        </div>
                        <Field name="email"
                               type="email"
                               {...fieldProps}
                               label="Email *"
                               validate={[required({
                                   message: {
                                       id: 'form.errors.email.required',
                                       defaultMessage: "Please provide a valid email address"
                                   }
                               }), email({
                                   message: {
                                       id: 'form.errors.email.invalid',
                                       defaultMessage: "Please enter a valid email address."
                                   }
                               })]}
                        />
                        <Field name="organization"
                               {...fieldProps}
                               size="sm"
                               label="Organization / Company"/>
                        <Field name="firstname"
                               {...fieldProps}
                               label="First Name *"
                               validate={required({
                                   message: {
                                       id: 'form.errors.firstname.required',
                                       defaultMessage: "Please provide your first name"
                                   }
                               })}
                        />
                        <Field name="middlename"
                               {...fieldProps}
                               label="Middle Name"
                        />
                        <Field name="lastname"
                               {...fieldProps}
                               label="Last Name *"
                               validate={required({
                                   message: {
                                       id: 'form.errors.lastname.required',
                                       defaultMessage: "Please provide your last name"
                                   }
                               })}
                        />
                        <Field name="phone"
                               {...fieldProps}
                               label="Phone (eg. +1 234 567 8910) *"
                               format={(value, name) => {
                                   return new asYouType().input(value)
                               }}
                               validate={[required({
                                   message: {
                                       id: 'form.errors.phone.required',
                                       defaultMessage: 'Please provide your phone-number'
                                   }
                               }), phone()]}
                        />
                        <Field name="fax"
                               {...fieldProps}
                               label="Fax (eg. +1 234 567 8911)"
                               format={(value, name) => {
                                   return new asYouType().input(value)
                               }}
                               validate={[phone({
                                   message: {
                                       id: 'form.errors.fax.invalid',
                                       defaultMessage: "This value is not a valid fax number"
                                   }
                               })]}
                        />
                        <Field name="street"
                               {...fieldProps}
                               label="Street Address (No P.O. Box) *"
                               validate={[
                                   required({
                                       message: {
                                           id: 'form.errors.street.required',
                                           defaultMessage: "Please provider your address"
                                       }
                                   }),
                                   length({
                                       min: 2,
                                       max: 128,
                                       message: {
                                           id: 'form.errors.street.too_long',
                                           defaultMessage: "Street is too long"
                                       }
                                   }),
                                   format({
                                       withOut: /^([a-zA-Z0-9\., -]+)$/u,
                                       message: {
                                           id: 'form.errors.street.invalid_chars',
                                           defaultMessage: "Street contains invalid characters. Please use only letters (A-Z), numbers, (0-9), periods, (.) and commas (,)."
                                       }
                                   }),
                                   format({
                                       withOut: /^(Floor|floor|Apartment|apartment|Appartment|appartment|Suite|suite|Apt|apt|Fl|fl)/,
                                       message: {
                                           id: 'form.errors.street.invalid_terms',
                                           defaultMessage: "Street contains invalid terms (e.g. “suite”, “apt”, “floor”). Please enter terms in Address 2"
                                       }
                                   })
                               ]}
                        />
                        <Field name="city"
                               {...fieldProps}
                               label="City *"
                               validate={[
                                   required({message: "Please provider your city"}),
                                   length({min: 3, max: 64, message: "City is too short or too long"}),
                                   format({
                                       withOut: /^([a-zA-Z\., ]+)$/u,
                                       message: {
                                           id: 'form.errors.city.invalid_chars',
                                           defaultMessage: "City contains invalid characters"
                                       }
                                   })
                               ]}
                        />
                        <Field name="zip"
                               {...fieldProps}
                               label="ZIP Code *"
                               validate={[required(), length({
                                   min: 1,
                                   max: 16,
                                   message: {
                                       id: 'form.errors.zip.invalid',
                                       defaultMessage: "Please provide a valid ZIP code"
                                   }
                               })]}
                        />
                        <Field name="country"
                               {...fieldProps}
                               label="Country *"
                               type="select"
                               validate={[required({
                                   message: {
                                       id: 'form.errors.country.required',
                                       defaultMessage: "Please provider your country"
                                   }
                               })]}
                        >
                            {(() => {
                                let countries = getCodeList();

                                var cs = Object.keys(countries).filter((c) => {
                                    return c != 'us' && c != 'ca'
                                });
                                cs.unshift('ca');
                                cs.unshift('us');

                                var ret = cs.map((c) => {
                                    return <option value={c.toUpperCase()} key={c}>{countries[c]}</option>;
                                });

                                ret.unshift(<option value="" key='empty'>-- select country --</option>)

                                return ret;
                            })()}
                        </Field>
                        <div className="form-group form-group-sm">
                            <label className="control-label required col-sm-5">
                                Use for
                            </label>
                            <div className="col-sm-7">
                                <label className="required radio-inline">
                                    <Field name="useFor" value="adminC" type="radio" component="input"/>Admin-C
                                </label>
                                <label className="required radio-inline">
                                    <Field name="useFor" value="ownerC" type="radio" component="input"/>Owner-C
                                </label>
                                <label className="required radio-inline">
                                    <Field name="useFor" value="bothC" type="radio" component="input"/>Both Contacts
                                </label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.cancelEdit}>Cancel</Button>
                    <Button bsStyle="primary"
                            onClick={() => {
                                this.props.submitEditForm()
                            }}
                            disabled={submitting}
                    >{submitting==true?"Saving...":"Save"}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'ContactForm', // a unique identifier for this form
    onSubmit: validateAndUpdateContactData,
    validate: validate //<--- validation function given to redux-form
    //asyncValidate*/
})(ContactForm)
