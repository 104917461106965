import React from "react";
import PropTypes from 'prop-types';
import MaterialIcon from "./MaterialIcon";

class SearchFilters extends React.Component {

    constructor(props) {
        super(props);

        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.toggleAvailabilityFilters = this.toggleAvailabilityFilters.bind(this);
        this.toggleCategoryFilters = this.toggleCategoryFilters.bind(this);

        this.state = {
            expanded: true,
            availabilityExpanded: true,
            categoriesExpanded: true
        };
    }

    toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    toggleAvailabilityFilters() {
        this.setState({
            availabilityExpanded: !this.state.availabilityExpanded
        });
    }

    toggleCategoryFilters() {
        this.setState({
            categoriesExpanded: !this.state.categoriesExpanded
        });
    }

    render() {
        return (
            <div id="search-filters">
                <ul>
                    <li className="dropdown">
                        <strong>Filter options</strong>
                        <div>
                            <ul>
                                <li>
                                    <a onClick={(e) => {e.preventDefault(); this.props.toggleTakenDomainsFilter}}>
                                        <MaterialIcon
                                            icon={this.props.filters.hideTakenDomains ? 'check_box' : 'check_box_outline_blank'}/>
                                        <span>hide taken domains</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => {e.preventDefault(); this.props.togglePromoDomainsFilter}}>
                                        <MaterialIcon
                                            icon={this.props.filters.promos == true ? 'check_box' : 'check_box_outline_blank'}/>
                                        <span>Deals</span>
                                    </a>
                                </li>
                                <li className="dropdown">
                                    <strong>Categories</strong>
                                    <div expanded={this.state.categoriesExpanded}>
                                        <ul>
                                            {(() => {
                                                return this.props.categories.dictionary.map((categoryId) => {
                                                    return (
                                                        <li key={categoryId}>
                                                            <a onClick={(e) => {e.preventDefault(); this.props.toggleCategoryFilter(categoryId)}}>
                                                                <MaterialIcon
                                                                    icon={this.props.filters.categories[categoryId] == true ? 'check_box' : 'check_box_outline_blank'}/>
                                                                <span>{this.props.categories.entities[categoryId].name}</span>
                                                            </a>
                                                        </li>
                                                    )
                                                });
                                            })()}
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }

    static propTypes = {
        filters: PropTypes.object.isRequired,
        toggleTakenDomainsFilter: PropTypes.func.isRequired,
        togglePromoDomainsFilter: PropTypes.func.isRequired,
        toggleStatusFilter: PropTypes.func.isRequired,
        toggleCategoryFilter: PropTypes.func.isRequired,
    }

}

export default SearchFilters;
