import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button, ProgressBar} from "react-bootstrap";
import SearchFilters from "../components/SearchFilters";
import SearchResults from "../components/SearchResults";
import ClaimConfirmationModal from "./ClaimConfirmationModal"
import {cartActions, searchResultActions} from "../actions";
import 'url-search-params-polyfill';
import MaterialIcon from "../components/MaterialIcon";
import TaxHint from "../components/TaxHint";

class SearchResultPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // first time
        const query = new URLSearchParams(this.props.router.location.search);
        this.props.startSearch(query.get('domain'));
    }

    componentDidUpdate(prevProps) {
        // after navigation

        if (this.props.router.location.pathname == '/searchresult/' && prevProps.router.location.search != this.props.router.location.search) {
            const query = new URLSearchParams(this.props.router.location.search);
            this.props.startSearch(query.get('domain'));
        }
    }

    render() {
        return (
            <div id="SearchResultPage" className="container">
                <div className="row">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-9">
                        <h3>Results</h3>
                        <span className="small pull-right"><TaxHint/></span>
                        {(() => {
                            if (this.props.current.isFetching || this.props.current.isExpanding) {
                                if (this.props.current.expectedResultCount == 0) {
                                    return (<ProgressBar now={0} label={'0 %'} striped active/>)
                                } else {
                                    return (<ProgressBar
                                        now={Math.round(this.props.current.dictionary.length / this.props.current.expectedResultCount * 100) }
                                        label={Math.round(this.props.current.dictionary.length / this.props.current.expectedResultCount * 100) + '%'}
                                        striped active/>)
                                }
                            }
                        })()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <SearchFilters filters={this.props.filters}
                                       categories={this.props.toplevelDomainCategories}
                                       toggleTakenDomainsFilter={this.props.toggleTakenDomainsFilter}
                                       toggleStatusFilter={this.props.toggleStatusFilter}
                                       toggleCategoryFilter={this.props.toggleCategoryFilter}
                                       togglePromoDomainsFilter={this.props.togglePromoDomainsFilter}
                        />
                    </div>
                    <div className="col-md-9">
                        <SearchResults {...this.props}/>

                        {(() => {
                            if (this.props.current.isExpandable && !this.props.current.isExpanded) {
                                return (
                                    <Button
                                        id="expandSearchResult"
                                        bsStyle="default"
                                        disabled={this.props.current.isExpanding}
                                        onClick={!this.props.current.isExpanding ? this.props.expandSearch : null}>
                                        {this.props.current.isExpanding ? 'Loading all results...' : 'Show all results'}
                                    </Button>
                                );
                            }
                        })()}
                        <a href="/cart" className="btn btn-success pull-right hidden-md hidden-lg">
                            Checkout
                            <MaterialIcon icon="trending_flat"/>
                        </a>
                    </div>
                </div>
                <ClaimConfirmationModal />
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        ...state.searchResult,
        cart: state.cart,
        claims: state.claims,
        toplevelDomains: state.toplevelDomains,
        toplevelDomainCategories: state.toplevelDomainCategories,
        router: state.router
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(searchResultActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultPage)
