import {toastr} from "react-redux-toastr";
import dnsActions from "./dnsActions";

function editMailForwardRecord(zone, local) {
    return {
        type: 'MAILFORWARD_EDIT',
        payload: {
            zone: zone,
            local: local
        }
    }
}

function cancelEditMailForwardRecord(zone, local) {
    return {
        type: 'MAILFORWARD_CANCEL_EDIT',
        payload: {
            zone: zone,
            local: local
        }
    }
}

function deleteMailForwards(zone, local) {

    // entry in dictionary is not deleted, checking why
    // adding new forwards
    // forwards-page crashes when no x-smtp-record exists in the zone

    return (dispatch, getState) => {

        let state = getState();
        let zoneData = state.dnsZones.entities[zone];
        let resourceRecordSet = zoneData.resourceRecords.find(rr => rr.type == 'x-smtp' && rr.name == '@');

        if (resourceRecordSet == undefined) {
            return null;
        }

        resourceRecordSet.rdata = resourceRecordSet.rdata.filter(recordData => (recordData.indexOf(local + '@ MAILFORWARD') == -1));

        if (resourceRecordSet.rdata.length == 0) {
            return dispatch(dnsActions.deleteResourceRecord(
                zone,
                resourceRecordSet.name,
                resourceRecordSet.type,
                resourceRecordSet.key,
                {
                    mailForwardOp: {
                        type: 'delete',
                        local: local
                    }
                }
            ))
        } else {
            return dispatch(dnsActions.saveResourceRecordSet(
                zone,
                resourceRecordSet.name,
                resourceRecordSet.type,
                resourceRecordSet,
                false,
                resourceRecordSet.key,
                {
                    mailForwardOp: {
                        type: 'delete',
                        local: local
                    }
                }
            ));
        }
    }
}

function saveForwards(zone, initialLocal, data, originalResourceRecordSet) {
    let resourceRecordSet = {
        name: '@',
        type: 'x-smtp',
        key: '_new',
        ttl: 3600
    };

    if (originalResourceRecordSet != undefined) {
        resourceRecordSet.key = originalResourceRecordSet.key;
        if (initialLocal != "") {
            resourceRecordSet.rdata = originalResourceRecordSet.rdata.filter(recordData => {
                return recordData.indexOf(initialLocal + '@ MAILFORWARD') == -1;
            });
        } else {
            resourceRecordSet.rdata = originalResourceRecordSet.rdata;
        }
    } else {
        resourceRecordSet.rdata = [];
    }

    if (originalResourceRecordSet == undefined) {
        debugger;
    }

    for (let target of data.targets) {
        resourceRecordSet.rdata.push(data.local + '@ MAILFORWARD ' + target);
    }

    return dnsActions.saveResourceRecordSet(
        zone,
        resourceRecordSet.name,
        resourceRecordSet.type,
        resourceRecordSet,
        resourceRecordSet.key == '_new',
        resourceRecordSet.key,
        {
            mailForwardOp: {
                type: 'save',
                initialLocal: initialLocal,
                local: data.local,
                key: resourceRecordSet.key
            }
        }
    );
}

function testEmailForward(local, domain) {

    return (dispatch, getState) => {
        let to = local + "@" + domain;

        return dispatch({
            type: 'MAILFORWARD_TEST_FORWARD',
            meta: {
                domain: domain,
                local: local
            },
            payload: fetch(Routing.generate('portfolio_mail_testforward'), {
                credentials: 'same-origin',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({to: to})
            }).then(response => {
                if (response.headers.get('Content-Type').indexOf("json") > 0) {
                    return response.json();
                } else {
                    toastr.error(response.statusText);
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                }
            }).then(ret => {
                    if (ret.success == false) {
                        toastr.error(ret.message);
                        throw Error(ret.message);
                    }

                    if(ret.hasOwnProperty('message')) {
                        toastr.success(ret.message);
                    }

                    return ret
            })
        })
    }


    /**
     * return dispatch({
            type: 'CART_ADD_DOMAIN',
            meta: {
                domain: domain,
                toplevelDomain: toplevelDomain,
            },
            payload: fetch(Routing.generate('cart_adddomain'), {
                credentials: 'same-origin',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: param(body)
            }).then(response => {
                if (response.headers.get('Content-Type').indexOf("json") > 0) {
                    return response.json();
                } else {
                    toastr.error(response.statusText);
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                }
            }).then(ret => {
                if (ret.status == false) {
                    toastr.error(ret.message);
                    throw Error(ret.message);
                }

                if(ret.hasOwnProperty('message')) {
                    toastr.success(ret.message);
                }

                return {
                    domain: domain,
                    domainName: ret.domainName,
                    tld: ret.tld,
                    price: ret.price,
                    id: ret.id,
                }
            })
        })
     */
}

export default {
    deleteMailForwards,
    editMailForwardRecord,
    cancelEditMailForwardRecord,
    saveForwards,
    testEmailForward
}
