import {toastr} from 'react-redux-toastr'

var timer;

function savePaymentData(values) {
    return startChecking(values['ud_transaction_id']);
}

function startChecking(transactionId) {
    return (dispatch, getState) => {
        clearInterval(timer);
        timer = setInterval(() => {
            dispatch(checkTransaction(transactionId));
        }, 5000);
        dispatch(checkTransaction(transactionId));
    }
}

function stopChecking() {
    clearInterval(timer);

    return {
        type: 'PAYMENTDATA_SAVE_ACK',
        payload: {}
    }
}

function checkTransaction(transactionId) {
    return (dispatch, getState) => {

        let state = getState();

        if (state.paymentData.checkCount > 15) {
            stopChecking();
        }

        return dispatch({
            type: 'PAYMENTDATA_CHECK',
            payload: fetch(Routing.generate('payment_checktransaction', {'id': transactionId}), {
                credentials: 'same-origin',
            }).then(response => {
                return response.json()
            }).then(ret => {
                if (ret.finished == true) {
                    if (ret.error > 0) {
                        toastr.error(ret.errorMessage);
                    } else {
                        toastr.success('Creditcard successfully updated!')
                    }

                    stopChecking();
                }

                return ret;
            })
        })
    };
}



export default {
    savePaymentData,
    startChecking,
    stopChecking
}
