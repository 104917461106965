import paginate from "./decorators/paginate";

const domainsReducer = (state = {
    entities: {},
    dictionary: [],
    isFetching: false,
    isFetched: false,
    error: null,
    currentStateDomain: null,
    currentWPSDomain: null
}, action) => {

    switch (action.type) {
        case "DOMAINLIST_FETCH_PENDING":
            return {...state, isFetching: true}
        case "DOMAINLIST_FETCH_REJECTED":
            return {...state, isFetching: false, error: action.payload}
        case "DOMAINLIST_FETCH_FULFILLED":
            let itemsPerPage;

            if (action.payload.result.length <= 20) {
                itemsPerPage = 20;
            } else {
                itemsPerPage = 10;
            }

            return {
                ...state,
                isFetching: false,
                isFetched: true,
                entities: action.payload.entities.domain,
                dictionary: action.payload.result,
                currentStateDomain: null,
                itemsPerPage: itemsPerPage
            };

        case "DOMAINLIST_SET_DOMAIN_CHANGESTATUS":
            return {
                ...state,
                currentStateDomain: action.payload

            };
        case "DOMAINSTATUS_SAVE_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.domain]: {
                        ...state.entities[action.payload.domain],
                        savingStatus: true

                    }
                }
            };
        case "DOMAINSTATUS_SAVE_REJECTED":
            return {
                ...state,
                currentStateDomain: null,
                entities: {
                    ...state.entities,
                    [action.payload.domain]: {
                        ...state.entities[action.payload.domain],
                        savingStatus: false
                    }
                },
            };
        case "DOMAINSTATUS_SAVE_FULFILLED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.domain]: {
                        ...state.entities[action.payload.domain],
                        status: action.payload.status,
                        savingStatus: false,
                    }
                },
                currentStateDomain: null
            };
        case "DOMAINLIST_SET_DOMAIN_WPS":
            return {
                ...state,
                currentWPSDomain: action.payload
            };
        case "DOMAINWPS_SAVE_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        savingWPS: true

                    }
                },
            };
        case "DOMAINWPS_SAVE_REJECTED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        savingWPS: false
                    }
                },
                currentWPSDomain: null

            };
        case "DOMAINWPS_SAVE_FULFILLED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.payload.domain],
                        wps: action.payload.wps == false ? "disabled" : "enabled",
                        savingWPS: false
                    }
                },
                currentWPSDomain: null
            };
        case "DOMAIN_SET_NAMESERVER_SUCCESS":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: action.payload
                }
            };
        case "CONTACT_UPDATE_SUCCESS": {
            // this is a special case
            // => if a contact is updated, a new one is created, so we have to change the handle
            //    in the domain object
            let contacts = {};

            if (action.payload.contact.useFor == 'adminC' || action.payload.contact.useFor == 'bothC') {
                contacts.adminContact = action.payload.contact.handle;
            }
            if (action.payload.contact.useFor == 'ownerC' || action.payload.contact.useFor == 'bothC') {
                contacts.ownerContact = action.payload.contact.handle;
            }


            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.domain]: {
                        ...state.entities[action.payload.domain],
                        ...contacts
                    }
                }
            };
        }
        case "DOMAIN_AUTHCODE_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        loadingAuthcode: true
                    }
                }
            }
        case "DOMAIN_AUTHCODE_REJECTED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        loadingAuthcode: false
                    }
                }
            }
        case "DOMAIN_AUTHCODE_FULFILLED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        loadingAuthcode: false,
                        authcode: action.payload.authcode
                    }
                }
            }
        case "DOMAIN_RENEW_NOW_PENDING":

        case "DOMAIN_RENEW_NOW_REJECTED":
        case "DOMAIN_RENEW_NOW_FULFILLED":

        default:
            return state;
    }
};

const paginateDomains = paginate(domainsReducer, {
    GOTO_PAGE: 'DOMAINLIST_GOTO_PAGE',
    NEXT_PAGE: 'NEXT_DOMAIN_PAGE',
    PREV_PAGE: 'PREV_DOMAIN_PAGE',
    FILTER: 'DOMAINLIST_FILTER',
    SORT: 'DOMAINLIST_SORT',
    SET_ITEMS_PER_PAGE: 'DOMAINLIST_SET_ITEMS_PER_PAGE'
}, {
    defaultSortBy: 'domain'
});

export default paginateDomains;
