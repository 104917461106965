import {normalize} from "normalizr";
import * as schema from "../schema";

import {toastr} from 'react-redux-toastr'
import {change, submit} from "redux-form";

function getMeta() {
    return document.querySelector("meta[name=\"csrf-token\"]").content;
}

function shouldFetchData(state) {
    return (Object.keys(state.domains.dictionary).length == 0 && !state.domains.isFetching)
}

function fetchData() {
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch({
                type: 'DOMAINLIST_FETCH',

                payload: fetch(Routing.generate('portfolio_domains_json'), {
                    credentials: 'same-origin'
                }).then(response => {
                        return response.json()
                    }
                ).then(domains => {
                    let d = domains.map(domain => {

                        return {
                            ...domain,
                            expirationdate: new Date(domain.expirationdate),
                        }
                    })

                    if (d.length == 0) {
                        return {
                            entities: {
                                domain: {}
                            },
                            result: []
                        }
                    } else {
                        return normalize(d, schema.arrayOfDomains);
                    }
                })
            })
        }
    }
}

function sortBy(sortKey) {
    return {
        type: 'DOMAINLIST_SORT',
        orderBy: sortKey
    }
}

function gotoPage(page) {
    return {
        type: "DOMAINLIST_GOTO_PAGE",
        page: page
    }
}

function setItemsPerPage(itemsPerPage) {
    return {
        type: "DOMAINLIST_SET_ITEMS_PER_PAGE",
        itemsPerPage: itemsPerPage
    }
}

function filterBy(filter) {
    return {
        type: "DOMAINLIST_FILTER",
        filter: filter
    }
}

function changeDomainStatus(domain) {
    if (domain == null) {
        return {
            type: 'DOMAINLIST_SET_DOMAIN_CHANGESTATUS',
            payload: null
        };
    } else {
        return {
            type: 'DOMAINLIST_SET_DOMAIN_CHANGESTATUS',
            payload: domain.domain
        };
    }
}

function changeWPSDomain(domain) {
    if (domain == null) {
        return {
            type: 'DOMAINLIST_SET_DOMAIN_WPS',
            payload: null
        };
    } else {
        return {
            type: 'DOMAINLIST_SET_DOMAIN_WPS',
            payload: domain.domain
        };
    }
}

function setWps(domain, enable) {
    return (dispatch) => {

        let body = [];
        body.push('enabled=' + (enable == true ? 'true' : 'false'));

        let meta = getMeta();

        body.push('_token=' + meta);

        return dispatch({
            type: 'DOMAINWPS_SAVE',
            meta: domain,
            payload: fetch(Routing.generate('portfolio_domain_wps', {domain: domain.domain}), {
                credentials: 'same-origin',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body.join("&")
            }).then(response => {
                return response.json().then(json => {
                    return response.ok ? json : Promise.reject(json);
                });
            }).then(json => {
                toastr.success(json.message);
                return json;
            }).catch((json) => {
                toastr.error(json.message);
                throw {
                    domain: domain,
                    ...json
                };
            })
        })
    }
}

function saveDomainStatus(domain, status) {
    return (dispatch) => {

        let body = [];
        body.push('domain=' + encodeURIComponent(domain.domain));

        if (status != 'expire') {
            body.push('autorenew=1');
        }

        dispatch(changeDomainStatus(domain));

        body.push('_token=' + getMeta());

        return dispatch({
            type: 'DOMAINSTATUS_SAVE',
            payload: {
                data: domain,
                promise: fetch(Routing.generate('portfolio_billing_setrenewalmode'), {
                    credentials: 'same-origin',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: body.join("&")
                }).then(response => {
                    let result = response.json();

                    result.expirationdate = new Date(domain.expirationdate);

                    return result.then(json => {
                        return response.ok ? json : Promise.reject(json);
                    });
                }).then(json => {
                    toastr.success(json.message);
                    return json;
                }).catch((json) => {
                    toastr.error(json.message);
                    throw {
                        domain: domain.domain,
                        ...json
                    };
                })
            }
        })
    }
}

function loadAuthCode(domain) {
    return (dispatch, getState) => {

        let state = getState()

        let body = {
            _token: state.csrf.token,
            domain: domain
        };

        dispatch({
            type: 'DOMAIN_AUTHCODE',
            payload: fetch(Routing.generate('portfolio_billing_authcode'), {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then(response => {
                return response.json().then(json => {
                    return response.ok ? json : Promise.reject(json);
                });
            }).then(json => {
                return json;
            }).catch((json) => {
                let msg = "Getting authcode for " + domain + " failed\n\n";

                if(json.hasOwnProperty('message')) {
                    msg += ": " + json.message;
                }

                toastr.error(msg);
                throw {
                    ...json
                };
            }),
            meta: {
                domain: domain
            }
        });
    }
}

function setNameservers(props) {
    return (dispatch, getState) => {

        let formData = new FormData();

        formData.append('nameservers[nameserverSet]', props.nameserverSet);

        if (props.nameserverSet == 'other') {
            let i = 0;
            props.nameservers.forEach((nameserver) => {
                formData.append('nameservers[nameservers][' + i++ + ']', nameserver);
            });
        }

        let state = getState();
        formData.append('nameservers[_token]', state.csrf.token);

        let headers = new Headers({
            "Accept": "application/json"
        });

        return {
            type: 'DOMAIN_SET_NAMESERVERS',
            payload: fetch(Routing.generate('portfolio_dns_setservers', {'domain': props.domain}), {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
                headers: headers,
            }).then(response => {
                return response.json();
            })
        }
    }
}

function restoreNameservers(domain) {
    return dispatch => {

        return dispatch({
            type: 'DOMAIN_RESTORE_NAMESERVERS',
            payload: new Promise(resolve => {
                resolve()
            })
        }).then(() => dispatch(change('NameserverConfigForm', 'nameserverSet', 'uniteddomains')))
            .then(() => dispatch(submit('NameserverConfigForm')));
    }
}

function setNameserversSuccess(values) {
    toastr.success("Successfully changed DNS-Servers for " + values.domain + "!");

    return {
        type: 'DOMAIN_SET_NAMESERVER_SUCCESS',
        meta: {
            domain: values.domain
        },
        payload: values
    };
}

function setNameserversFailure(values, errors) {
    toastr.error("Failed to set DNS-Servers!");

    return {
        type: 'DOMAIN_SET_NAMESERVER_FAILURE',
        payload: errors
    };
}

function renewDomain(domain) {

    let map = new Map();
    map.set('_token', null);
    map.set('domain', domain);

    return {
        type: 'DOMAIN_RENEW_NOW',
        payload: {
            data: domain,
            request: {
                method: 'post',
                url: Routing.generate('portfolio_billing_renew'),
                data: map,
            }
        },
        meta: {
            domain: domain
        }
    };
}

export default {
    fetchData,
    filterBy,
    sortBy,
    gotoPage,
    setItemsPerPage,
    changeDomainStatus,
    saveDomainStatus,
    changeWPSDomain,
    setWps,
    setNameservers,
    setNameserversSuccess,
    setNameserversFailure,
    restoreNameservers,
    loadAuthCode,
    renewDomain,
}
