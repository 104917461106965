import React from "react";
import ManageDomainsTable from "../../components/Portfolio/ManageDomainsTable";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {cartActions, domainActions, pendingOrderActions} from "../../actions";

class ManageDomainsPage extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.fetchPendingOrders()
    }

    getPendingDomainList() {
        if (this.props.pendingOrders.items.length > 0) {
            return (
                <section className="panel">
                    <header className="panel-heading">
                        <h1>Pending Order{this.props.pendingOrders.items.length > 1 ? 's' : ''}</h1>
                    </header>
                    <div className="panel-body progress-panel">
                        <div className="row">
                            <div className="col-xs-12">
                                <p>The following order{this.props.pendingOrders.items.length > 1 ? 's have ' : ' has '}
                                    been received
                                    and will be processed shortly:</p>
                                <table className="table table-striped table-hover table-processing-domains">
                                    <thead>
                                    <tr>
                                        <th>Domain</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.pendingOrders.items.map((item) => {
                                        return (<tr key={item.domain}>
                                            <td>{item.domain}</td>
                                            <td>{item.type_as_string}</td>
                                            <td>processing</td>
                                        </tr>);
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>)
        }
    }

    getUnpaidHint() {
        const unpaidDomains = this.props.dictionary.filter((domainName) => {
            let domain = this.props.entities[domainName];

            if (domain.status == 'billingexpire') {
                return true
            }
        });

        if (unpaidDomains.length > 0) {
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="alert alert-danger mtb25">
                            <h4><strong>
                                {unpaidDomains.length == 1 ? 'One of your domains is locked ' : unpaidDomains.length + ' of your domains are locked '}due
                                to failed Payment. To deliver payment, please do the following:
                            </strong></h4>
                            <br/>
                            <ol>
                                <li>Click the <a href={Routing.generate('portfolio_billing_index')}>Billing/Renewal</a>
                                    {' '}tab above and update your credit card. (If your credit card on file is
                                    currently
                                    active, please contact your bank for assistance.)
                                </li>
                                <li>Scroll to <i>"Your Invoices"</i> further down the Billing/Renewal section, and
                                    charge any failed invoices manually by clicking the <i>"Recharge"</i> button next to
                                    each failed invoice.
                                </li>
                                <li>After all failed invoices are charged, click the Manage Domains tab to return to
                                    this page. If this error message is still visible, please{' '}
                                    <a href={Routing.generate('contact_index')}>contact us</a> for assistance.
                                </li>
                            </ol>
                            <p>The following domain names are UNPAID:</p>
                            <ul>
                                {unpaidDomains.map((domain) => {
                                    return <li key={domain}>{domain}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
    }

    getLockedHint() {
        const locks = this.props.dictionary.filter((domainName) => {
            let domain = this.props.entities[domainName];

            if (domain.lock.hasLocks == true) {
                return true
            }
        });

        if (locks.length > 0) {
            let link = "mailto:United Domains Customer Support <support@uniteddomains.com>";
            link += "?subject=Locked Domains";
            link += "&body=<Your Message>\n\n";
            link += "Locked Domains:\n";
            link += locks.map(domain => domain.domain).reduce((previousValue, currentValue) => {
                return previousValue + " - " + currentValue + "\n";
            }, "");

            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="alert alert-danger">
                            <h4>
                                <strong>{locks.length == 1 ? 'One of your domains is locked' : locks.length + ' of your domains are locked'}.</strong>
                            </h4>
                            <p>
                                Please contact our <a href={encodeURI(link)}>Customer Support</a> to resolve the
                                problem. <br />
                                The following domain name{locks.length > 1 ? 's are ' : ' is '}LOCKED:</p>
                            <ul>
                                {locks.map((domain) => {
                                    return <li key={domain}>{domain}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            )
                ;
        }
    }

    render() {
        return (
            <div id="portfolio-domains">
                {this.getPendingDomainList()}
                {this.getUnpaidHint()}
                {this.getLockedHint()}
                <ManageDomainsTable {...this.props} />
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        ...state.domains,
        length: state.domains.dictionary.length,
        pendingOrders: state.pendingOrders,
        router: state.router,
        cart: state.cart
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(domainActions, dispatch),
    fetchPendingOrders: bindActionCreators(pendingOrderActions.fetchData, dispatch),
    addToCart: bindActionCreators(cartActions.addDomainToCart, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDomainsPage)
