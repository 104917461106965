import React from "react";
import PropTypes from "prop-types";
import {MenuItem, OverlayTrigger, Popover, Table} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import FormattedCurrency from "../FormattedCurrency"
import saveAs from "save-as";
import dateFormat from "dateformat";
import MaterialIcon from "../MaterialIcon";

class ManagePreRegistrationsTable extends React.Component {

    constructor(props) {
        super(props)
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.exportList = this.exportList.bind(this);
        this.getItemsPerPageOptions = this.getItemsPerPageOptions.bind(this);
    }

    getItemsPerPageOptions() {
        // only show this when we have more than 20 pages, show only the useful items
        return [10, 20, 50, 100, 200, 500].map((ipp) => {
            if (ipp <= 2.5 * this.props.dictionary.length) {
                return (
                    <MenuItem key={ipp}
                              onSelect={ () => this.props.setItemsPerPage(ipp) }>
                        {ipp}
                    </MenuItem>);
            }
        });
    }

    handleFilterChange(event) {
        this.props.filterBy(event.target.value);
    }

    handleSort(sortBy) {
        this.props.sortBy(sortBy);
    }

    exportList() {
        let csv;

        csv = this.props.pageList.map((domain) => {
            let domainData = this.props.entities[domain];
            return '"' + domainData.domain + '";"' + domainData.status + '";"' + dateFormat(domainData.expirationdate, "yyyy-mm-dd") + '";"' + domainData.wps + '"' + "\r\n";
        });

        csv.unshift('"Domain";"Status";"Whois Privacy";"Installed WebApps"' + "\n");

        let blob = new Blob(csv, {type: "text/plain;charset=utf-8"});
        saveAs(blob, "domains.csv");
    }

    getFooterText() {
        const {isFetching, dictionary, filteredLength, page, itemsPerPage} = this.props;
        let ret;

        if (dictionary.length == 0) {
            if (isFetching) {
                ret = "Pre-Orders are loading..."
            } else {
                ret = "You have no pre-ordered domains."
            }
        } else {
            if (filteredLength == 0) {
                return 'None of the ' + length + ' Pre-Orders match your filter';
            }

            let first = ((page - 1) * itemsPerPage + 1);
            let last = (page * itemsPerPage);

            if (last > filteredLength) {
                last = filteredLength;
            }
            ret = "Showing " + first + " to " + last + " of " + filteredLength + " Domains"

            if (filteredLength < length) {
                ret += " (filtered from " + length + " total Domains)";
            }
        }

        return ret;
    }

    // implement PopOvers and OverlayTriggers

    getStatusPremiumPopover(preRegistration, toplevelDomain) {
        if (preRegistration.bound_at) {
            if (preRegistration.price > toplevelDomain.price) {

                const popover = (
                    <Popover id="preordered_premium" title="Status: pre-ordered premium name">
                        This is a binding pre-order for your new domain. This domain is classified as a premium domain,
                        which means that the yearly registration fee is higher than the standard registration fee. Once
                        the domain extension launches we will automatically submit your order to the domain registry.
                        Your credit card will be charged for the domain registration fee about two weeks before launch,
                        so please make sure your billing information is up to date. United Domains has successfully
                        registered over 70% of all pre-orders submitted for previous domain launches. However, you will
                        be completely refunded for any pre-order we cannot register successfully.
                    </Popover>
                );

                return (<OverlayTrigger overlay={popover}>
                    <a href="#">
                        <MaterialIcon icon="info"/>
                    </a>
                </OverlayTrigger>);
            } else {
                const popover = (
                    <Popover id="preordered_premium" title="Status: pre-ordered">
                        This is a binding pre-order for your new domain. Once the domain extension launches we will
                        automatically submit your order to the domain registry. Your credit card will be charged for the
                        domain registration fee about two weeks before launch, so please make sure your billing
                        information is up to date. United Domains has successfully registered over 70% of all pre-orders
                        submitted for previous domain launches. However, you will be completely refunded for any
                        pre-order we cannot register successfully.
                    </Popover>
                );

                return (<OverlayTrigger overlay={popover}>
                    <a href="#">
                        <MaterialIcon icon="info" className="color-green"/>
                    </a>
                </OverlayTrigger>);
            }
        } else {
            if (toplevelDomain != undefined && toplevelDomain.status == 1) {
                if (preRegistration.price > 0 && preRegistration.price > toplevelDomain.price) {
                    const popover = (
                        <Popover id="preordered_premium"
                                 title="Please confirm your pre-registration (premium name)">
                            This domain is classified as a premium domain, which means that the yearly registration
                            fee
                            is higher than the standard registration fee. Please note that the registry not United
                            Domains designates premium domain names. Please confirm your non-binding
                            pre-registration.
                            Once the domain extension launches we will automatically submit your order to the domain
                            registry. Your credit card will be charged for the domain registration fee about two
                            weeks
                            before launch. Please make sure your billing information is up to date. United Domains
                            has
                            successfully registered over 70% of all pre-orders submitted for previous domain
                            launches.
                            However, you will be completely refunded for any pre-order we cannot register
                            successfully.
                        </Popover>
                    );

                    return (<OverlayTrigger overlay={popover}>
                        <a href="#">
                            <MaterialIcon icon="info" className="color-green"/>
                        </a>
                    </OverlayTrigger>);
                } else {
                    const popover = (
                        <Popover id="preordered_premium" title="Please confirm your pre-registration">
                            Please confirm your binding pre-order. Once the domain extension launches we will
                            automatically submit your order to the domain registry. Your credit card will be charged
                            for
                            the domain registration fee about two weeks before launch. Please make sure your billing
                            information is up to date. United Domains has successfully registered over 70% of all
                            pre-orders submitted for previous domain launches. However, you will be completely
                            refunded
                            for any pre-order we cannot register successfully.
                        </Popover>
                    );

                    return (<OverlayTrigger overlay={popover}>
                        <a href="#">
                            <MaterialIcon icon="info" className="color-orange"/>
                        </a>
                    </OverlayTrigger>);
                }
            } else {
                const popover = (
                    <Popover id="preordered_premium" title="Status: free pre-registrations">
                        This is a free and non-binding pre-registration. We will contact you by email once your
                        domain
                        extension’s official launch date and pricing information is announced. You will then be able
                        to
                        pre-order your domain from your United Domains’ account.
                    </Popover>
                );

                return (<OverlayTrigger overlay={popover}>
                    <a href="#">
                        <MaterialIcon icon="info" className="color-grey"/>
                    </a>
                </OverlayTrigger>);
            }
        }
    }

    handleResultClick(e, preRegistration) {
        if (preRegistration.claimConfirmationRequired) {
            var claimData = this.props.claims.entities[preRegistration.domain];

            if (claimData != undefined) {
                var options = {
                    claimConfirmation: {
                        'domain': preRegistration.domain,
                        'lookupKey': claimData.lookupKey,
                        'confirm': true,
                        'validUntil': claimData.validUntil,
                        'noticeId': claimData.noticeId
                    }
                }

                this.props.cartActions.addDomainToCart(
                    preRegistration.domain,
                    preRegistration.tld,
                    claimData.lookupKey,
                    options,
                );
            } else {
                this.props.cartActions.addDomainToCart(
                    preRegistration.domain,
                    preRegistration.tld,
                    true,
                    {}
                );
            }
        } else {
            this.props.cartActions.addDomainToCart(
                preRegistration.domain,
                preRegistration.tld
            );
        }
    }


    getActions(preRegistration, toplevelDomain) {
        if (toplevelDomain != undefined && toplevelDomain.status == 1) {
            if (this.props.cart.entities && this.props.cart.entities[preRegistration.domain]) {
                if (this.props.cart.entities[preRegistration.domain].adding == true) {
                    return <button className="btn btn-warning btn-xs"
                                   title="Please confirm your non-binding pre-registration."
                                   disabled={true}>
                        Adding domain to your cart...
                    </button>
                } else {
                    return <a href="{{ url('cart_index') }}" className="btn btn-info btn-xs">
                        In your cart
                    </a>
                }
            } else {
                if (preRegistration.bound_at == undefined || preRegistration.bound_at == null) {
                    return <button onClick={(e) => this.handleResultClick(e, preRegistration)}
                                   className="btn btn-success btn-xs"
                                   title="Please confirm your non-binding pre-registration."
                                   disabled={preRegistration.deleting == true}>
                        Confirm now (pre-order)
                    </button>
                } else {
                    if (preRegistration.claimConfirmationRequired) {
                        if (preRegistration.confirmingClaim) {
                            return (<button className="btn btn-success btn-xs" disabled={true}>Confirming Trademark
                                Claim...</button>)
                        } else {
                            return (<button
                                onClick={(e) => this.handleResultClick(e, preRegistration)}
                                className="btn btn-success btn-xs"
                                disabled={preRegistration.deleting == true}>Confirm Trademark Claim
                            </button>)
                        }
                    } else if (preRegistration.trademark_claim == true) {
                        return (<span>Claim Already confirmed</span>)
                    }
                }
            }
        }
    }

    getTableContents() {
        return this.props.pageList.map((domainName) => {
            let preRegistration = this.props.entities[domainName];
            let toplevelDomain = this.props.toplevelDomains.entities[preRegistration.tld];

            return (
                <tr key={preRegistration.domain}>
                    <td>
                        {preRegistration.domain}
                    </td>
                    <td>
                        {(() => {
                            if (preRegistration.bound_at != undefined) {
                                return <span className="color-green">pre-ordered</span>

                            } else {
                                return <span className="color-orange">non-binding</span>;
                            }
                        })()}
                        &nbsp;
                        {this.getStatusPremiumPopover(preRegistration, toplevelDomain)}
                    </td>
                    <td>
                        {(() => {
                            if (toplevelDomain != undefined) {
                                if (toplevelDomain.ga_date) {
                                    return <FormattedDate value={toplevelDomain.ga_date.substr(0, toplevelDomain.ga_date.indexOf('+'))}
                                                          year='numeric'
                                                          month='long'
                                                          day='2-digit'
                                    />
                                } else {
                                    return <span>{toplevelDomain.public_start}</span>
                                }
                            } else {
                                return <span>TBA</span>
                            }
                        })()}
                    </td>
                    <td>
                        {(() => {
                            if (preRegistration.price) {
                                return <FormattedCurrency value={preRegistration.price} addtax={true}/>
                            }
                        })()}
                    </td>
                    <td>
                        {this.getActions(preRegistration, toplevelDomain)}
                        {(() => {
                            if ((preRegistration.bound_at == undefined || preRegistration.bound_at == null) &&
                                !(this.props.cart.entities && this.props.cart.entities[preRegistration.domain])
                            ) {
                                return <button onClick={(e) => this.props.deletePreRegistration(preRegistration)}
                                               className="btn btn-gray btn-xs"
                                               disabled={preRegistration.deleting == true}>
                                    {preRegistration.deleting ? 'Deleting...' : 'Delete'}
                                </button>
                            }
                        })()}
                    </td>
                </tr>
            )
        });
    }

    render() {
        const {totalPages, isFetching, page, itemsPerPage, currentStateDomain, currentWPSDomain, error, orderBy, order, dictionary} = this.props;

        return (

            <Table responsive hover striped>
                <thead>
                <tr>
                    <th onClick={() => this.handleSort('domain')}
                        className={orderBy == 'domain' ? 'sorting-' + order : 'sorting'}>My New Domains&nbsp;</th>
                    <th onClick={() => this.handleSort('status')}
                        className={orderBy == 'status' ? 'sorting-' + order : 'sorting'}>Status&nbsp;</th>
                    <th>Start Date&nbsp;</th>
                    <th onClick={() => this.handleSort('price')}
                        className={orderBy == 'price' ? 'sorting-' + order : 'sorting'}>Price&nbsp;
                    </th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tfoot>
                <tr>
                    <td colSpan="6" className="text-center">
                        {this.getFooterText()}
                    </td>
                </tr>
                </tfoot>
                <tbody>
                {this.getTableContents()}
                </tbody>
            </Table>
        )
    }

    static
    propTypes = {
        // actions
        fetchData: PropTypes.func.isRequired,
        gotoPage: PropTypes.func.isRequired,
        setItemsPerPage: PropTypes.func.isRequired,
        sortBy: PropTypes.func.isRequired,

        // state data
        toplevelDomains: PropTypes.object.isRequired,
        cart: PropTypes.object.isRequired,
        claims: PropTypes.object.isRequired,
        totalPages: PropTypes.number.isRequired,
        filteredLength: PropTypes.number.isRequired,
        pageList: PropTypes.array.isRequired,
        entities: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        page: PropTypes.number.isRequired
    }
}

export default ManagePreRegistrationsTable
