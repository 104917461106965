import {addValidator} from "redux-form-validators";

/**
 * https://tools.ietf.org/html/rfc1912
 *
 * label: Allowable characters in a label for a host name are only ASCII
 *        letters, digits, and the `-' character.  Labels may not be all
 *        numbers, but may have a leading digit  (e.g., 3com.com).  Labels must
 *        end and begin only with a letter or digit.
 *        Note there are some Internet hostnames which violate this rule
 *        (411.org, 1776.com).  The presence of underscores in a label
 *        is allowed in [RFC 1033], except [RFC 1033] is informational
 *        only and was not defining a standard
 *
 * we do allow underscored for now
 */
export default addValidator({
    validator: function (options, value, allValues) {
        let zone = options.zone;

        //if (value != '@' && !value.match(/^(?![0-9]+$)(?!.*-$)(?!-)(?!.*\.\..*)(\*.)?[a-zA-Z0-9-_\.]{1,63}$/g)) {
        if (value != '@' &&
            value != '*' &&
            !value.match(/^(\*\.)?(([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9\-_]*[a-zA-Z0-9_])\.)*([A-Za-z0-9_]|[A-Za-z0-9_][A-Za-z0-9\-_]*[A-Za-z0-9_])\.?$/i)) {
            return {
                id: "form.errors.dns.invalid_label",
                defaultMessage: "Invalid name"
            }
        }

        if (value == zone) {
            value = '@'
        }

        if (value.endsWith('.')) {
            if (value.endsWith(zone)) {
                value = value.substr(0, value.indexOf(zone) - 1);
            } else {
                return {
                    id: "form.errors.dns.wrong_origin_label",
                    defaultMessage: "You can only define Records for " + zone
                }
            }
        }

        // duplicates labels
        let duplicates = options.allResourceRecords.reduce((accumulator, currentValue) => {
            if (
                currentValue.type == allValues.type &&
                currentValue.name == value &&
                (currentValue.key != allValues.key || currentValue.key == '_new')
            ) {
                return true;
            }

            return accumulator;
        }, false);

        if (duplicates == true) {
            return {
                id: "form.errors.dns.duplicate_label",
                defaultMessage: "Name already defined with this record type. To add new records of this type, click \"Edit\" next to existing record of this type, then click \"+\""
            }
        }

        let cnameConflict = options.allResourceRecords.reduce((accumulator, currentValue) => {
            if (value == currentValue.name &&
                (
                    (currentValue.type == 'cname' ||
                        allValues.type == 'cname') &&
                    currentValue.type != allValues.type
                ) &&
                currentValue.key != allValues.key
            ) {
                return true;
            }

            return accumulator;
        }, false);

        if (cnameConflict == true) {
            return {
                id: "form.errors.dns.cname_conflict",
                defaultMessage: "While a CNAME exists for this domain name level, no other DNS records should be defined for this level"
            }
        }

        if (
            allValues.type == 'ns'
        ) {
            if (value == "@") {
                return {
                    id: "form.errors.dns.ns_only_for_subdomains",
                    defaultMessage: "You can only change NS records for the root domain from the Name Servers section"
                }
            }

            if (
                value == '*' ||
                value.startsWith('*.')
            ) {
                return {
                    id: "form.errors.dns.no_wildcards_for_ns",
                    defaultMessage: "Wildcard-Records are not supported for NS Record Type"
                }
            }
        }

        return true;
    }
})
