import React from "react";
import PropTypes from "prop-types";
import TTL from './TTL';
import {Button} from 'react-bootstrap';

class ResourceRecordSet extends React.Component {

    render() {
        const {className, resourceRecord, style, tdStyle} = this.props;

        return resourceRecord.rdata.map((record, index) => {
            if (index > 0) {
                return <tr key={this.props._key + '_' + index} style={style} className={className}>
                    <td style={this.props.tdStyle}>{record.split('\n').map((item, key) => {
                        return <span key={key}>{item}<br/></span>
                    })}</td>
                    <td className="filler" style={tdStyle}></td>
                </tr>
            } else {
                return <tr key={this.props._key + '_' + index} style={style} className={className}>
                    <td style={tdStyle} rowSpan={resourceRecord.rdata.length}>{resourceRecord.name}</td>
                    <td style={tdStyle} rowSpan={resourceRecord.rdata.length}>{resourceRecord.type.toUpperCase()}</td>
                    <td style={tdStyle} rowSpan={resourceRecord.rdata.length}><TTL seconds={parseInt(resourceRecord.ttl)}/></td>
                    <td style={tdStyle}>{record.split('\n').map((item, key) => {
                        return <span key={key} className="recordData">{item}<br/></span>
                    })}</td>
                    <td className="action" rowSpan={resourceRecord.rdata.length} style={tdStyle}>
                        <Button bsStyle="default" disabled={this.props.deleting}
                                onClick={() => this.props.deleteRecord(resourceRecord.name, resourceRecord.type, this.props._key)}>
                            {this.props.deleting?'deleting...':'delete'}
                        </Button>
                    </td>
                    <td className="action" rowSpan={resourceRecord.rdata.length} style={tdStyle}>
                        <Button bsStyle="primary"
                                onClick={() => this.props.editRecord(resourceRecord.key)}>
                            edit
                        </Button>
                    </td>
                </tr>
            }
        });
    }

    static propTypes = {
        // actions
        editRecord: PropTypes.func.isRequired,
        deleteRecord: PropTypes.func.isRequired,

        // state data
        _key: PropTypes.string.isRequired,
        resourceRecord: PropTypes.object.isRequired,
        style: PropTypes.object.isRequired,
        className: PropTypes.string
    }
}

export default ResourceRecordSet
