import React from "react";
import PropTypes from "prop-types"
import {showGdprModal} from "./GdprModal";

class GdrpHints extends React.Component {

    showModal(registry, tld) {
        showGdprModal("", {
            registry: registry,
            tld: tld
        }).then(() => {
        });
    }

    getRegistryLinks() {
        // we should push this into the constructor or receiveNewProps
        let tlds = [];

        let tldRegistries = this.props.cart.dictionary.reduce((acc, cartDomain) => {
            let cartItem = this.props.cart.entities[cartDomain];

            if (!acc.hasOwnProperty(cartItem.tld) && (cartItem.registry != null || cartItem.escrowServiceUsed == true)) {
                tlds.push(cartItem.tld);

                acc[cartItem.tld] = {
                    tld: cartItem.tld,
                    registry: cartItem.registry,
                    escrowServiceUsed: cartItem.escrowServiceUsed,
                    dataTransmittedForCompanies: cartItem.dataTransmittedForCompanies,
                    dataTransmittedForPrivatePersons: cartItem.dataTransmittedForCompanies,
                };
            }

            return acc;
        }, {});

        return tlds.sort().map((tld, index) => {
            return <React.Fragment key={'registry_' + index}>
                <a onClick={(e) => {e.preventDefault(); this.showModal(tldRegistries[tld], tld)}}>
                    <span>.{tld}-Domain</span>
                </a>
                <br/>
            </React.Fragment>
        });
    }

    render() {
        // nur wenn live-Domains/verbindliche Vorbestellungen im Cart, wie schauts mit renewals aus?

        return <React.Fragment>
            <p>
                Upon registration of the above domain(s), your provided domain owner (registrant) data will be transferred to the following registries respectively:
                <br/>
                <br/>
                {this.getRegistryLinks()}
                <br/>
                Please be aware that some registries are located outside the European Union and a different level of
                data protection may apply.
                <br/>
                If you have any questions regarding the transfer of your data, please contact us before you finish your
                order.
            </p>
        </React.Fragment>
    }

    static propTypes = {
        cart: PropTypes.object.isRequired
    }
}

export default GdrpHints;
