import React from 'react';
import {Circle, GoogleMap, withGoogleMap, withScriptjs} from "react-google-maps"


class Map extends React.Component {

    constructor(props) {
        super(props);
        this.onRadiusChanged = this.onRadiusChanged.bind(this);
        this.onCenterChanged = this.onCenterChanged.bind(this);
    }

    onRadiusChanged() {
        this.props.onRadiusChanged(this.circle.getRadius());
    }

    onCenterChanged() {
        let request = {
            locations: [this.circle.getCenter()]
        };
        this.elevator.getElevationForLocations(request, function (results, status) {
            let altitude = 100;
            if (status == google.maps.ElevationStatus.OK && results[0]) {
                altitude = results[0].elevation;
            }

            this.props.onCenterChanged({
                location: {
                    lat: this.circle.getCenter().lat(),
                    lng: this.circle.getCenter().lng()
                },
                altitude: altitude
            });
        }.bind(this));
    }

    render() {

        let location = this.props.location;

        let center = {
                lat: 38.5507407822633,
                lng: -101.03143749999998
            },
            size = 1000000;

        if (location != null) {
            center.lat = location.latitude.degrees + location.latitude.minutes / 60 + location.latitude.seconds / 3600;
            center.lng = location.longitude.degrees + location.longitude.minutes / 60 + location.longitude.seconds / 3600;

            if (location.latitude.ns == 'S') {
                center.lat *= -1;
            }
            if(location.longitude.ew == "W") {
                center.lng *= -1;
            }
            size = location.size;
        }

        return <GoogleMap
            defaultZoom={10}
            defaultCenter={center}
            ref={((map) => {
                if (this.map == null) {
                    this.map = map
                    map.fitBounds(this.circle.getBounds());

                    this.elevator = new google.maps.ElevationService();
                }
            }).bind(this)}
            onDblClick={this.onCenterChanged}
        >
            <Circle radius={size}
                    center={center}
                    draggable={true}
                    editable={true}
                    ref={(circle) => {
                        this.circle = circle;
                    }}
                    tooltip={true}
                    onCenterChanged={this.onCenterChanged}
                    onRadiusChanged={this.onRadiusChanged}
            />
        </GoogleMap>

    }
}

export default withScriptjs(withGoogleMap(Map))