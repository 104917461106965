import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedTime} from "react-intl";
import {Button, Modal} from "react-bootstrap";
import {cartActions, claimActions} from "../actions";
import LoadingIndicator from "../components/LoadingIndicator";

class ClaimConfirmationModal extends React.Component {

    componentDidMount() {
        if (this.props.claims.current != null &&
            this.props.claims.entities[this.props.claims.current.domain] == undefined) {

            this.props.fetchClaimInformation(
                this.props.claims.current.lookupKey,
                this.props.claims.current.domain,
            )
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.claims.current != null &&
            this.props.claims.entities[this.props.claims.current.domain] == undefined) {

            this.props.fetchClaimInformation(
                this.props.claims.current.lookupKey,
                this.props.claims.current.domain,
            )
        }
    }

    render() {
        var claimData;

        if (this.props.claims.current != null &&
            this.props.claims.entities[this.props.claims.current.domain] != undefined) {
            claimData = this.props.claims.entities[this.props.claims.current.domain];
        }
        else {
            claimData = null
        }
        if (this.props.claims.current != undefined) {
            return (
                <Modal show={true} onHide={this.props.rejectClaim} bsSize="large">
                    <Modal.Header closeButton>
                        <Modal.Title>Trademark Claims Notice for {this.props.claims.current.domain}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(() => {
                            if (claimData != null) {
                                return (<div>
                                    <p>You have received this Trademark Notice because you have applied for the domain
                                        name {this.props.claims.current.domain} which matches at least one trademark
                                        record
                                        submitted to the Trademark Clearinghouse.</p>
                                    <p>***You may or may not be entitled to register the domain name depending on your
                                        intended use and whether it is the same or significantly overlaps with the
                                        trademarks listed below. Your rights to register this domain name may or may not
                                        be protected as noncommercial use or “fair use” by the laws of your
                                        country.***</p>
                                    <p>Please read the trademark information below carefully, including the trademarks,
                                        jurisdictions, and goods and services for which the trademarks are registered.
                                        Please be aware that not all jurisdictions review trademark applications
                                        closely, so some of the trademark information below may exist in a national or
                                        regional registry which does not conduct a thorough or substantive review of
                                        trademark rights prior to registration. If you have questions, you may want to
                                        consult an attorney or legal expert on trademarks and intellectual property for
                                        guidance.</p>
                                    <p>If you continue with this registration, you represent that, you have received and
                                        you understand this notice and to the best of your knowledge, your registration
                                        and use of the requested domain name will not infringe on the trademark rights
                                        listed below. The following marks are listed in the Trademark Clearinghouse:</p>
                                    <div id="trademark-description"
                                         style={{
                                             position: 'relative',
                                             overflowY: 'scroll',
                                             height: '120px',
                                             padding: '10px',
                                             backgroundColor: '#eaeaea',
                                             border: '1px solid #aaaaaa',
                                             borderRadius: '2px'
                                         }}>
                                        {(() => {
                                            if (claimData.isFetching) {
                                                return <LoadingIndicator active={true} size="large"/>
                                            } else {
                                                return <div dangerouslySetInnerHTML={{__html: claimData.htmlContent}}/>
                                            }
                                        })()}
                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" id="confirmation" className="terms left"
                                                       onChange={this.props.toggleConfirmation}/>
                                                I hereby confirm that I am not infringing upon the trademarked claim
                                                above,
                                                and understand that any use of the domain name in a manner that does
                                                infringe on the trademark may result in the loss of the domain without a
                                                refund.
                                            </label>
                                        </div>
                                    </div>
                                    <br />
                                    {(() => {
                                        if (claimData.isFetching == false) {
                                            return (<div>
                                                Your confirmation is valid
                                                until&nbsp;
                                                <FormattedTime value={claimData.validUntilDisplay}
                                                               year='numeric'
                                                               month='long'
                                                               day='2-digit'/>.
                                                If the domain is registered after this date,
                                                you have to confirm this claim again.
                                            </div>)
                                        }
                                    })()}
                                </div>)
                            } else {
                                return (<LoadingIndicator active={true}/>)
                            }
                        })()}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary"
                                disabled={claimData == null || claimData.isFetching == true || claimData.confirmChecked == false}
                                onClick={() => {
                                    this.props.confirmClaim();
                                    if (this.props.addToCart == true) {
                                        this.props.cartActions.addDomainToCart(
                                            this.props.claims.current.domain,
                                            this.props.claims.current.toplevelDomain,
                                            claimData.lookupKey,
                                            {
                                                claimConfirmation: {
                                                    'domain': this.props.claims.current.domain,
                                                    'lookupKey': claimData.lookupKey,
                                                    'confirm': true,
                                                    'validUntil': claimData.validUntil,
                                                    'noticeId': claimData.noticeId
                                                }
                                            }
                                        );
                                    }
                                    if (this.props.onConfirm) {
                                        this.props.onConfirm(claimData);
                                    }
                                }}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return null;
        }
    }

    static propTypes = {
        addToCart: PropTypes.bool.isRequired,
        onConfirm: PropTypes.func
    }

    static defaultProps = {
        addToCart: true
    };
}

function mapStateToProps(state) {
    return {
        claims: state.claims
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(claimActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ClaimConfirmationModal)
