import {addValidator} from "redux-form-validators";

/**
 *
 * https://tools.ietf.org/html/rfc2915
 * https://tools.ietf.org/html/rfc4848
 */
export default addValidator({
    validator: (options, value, allValues) => {

        let parts = value.match(/^([0-9]{1,5})\s+([0-9]{1,5})\s+"([A-Za-z0-9]+)"\s+"(.*?)"\s+"(.*?)"\s+(\S+)$/);

        if (parts == null || parts.length != 7) {
            return {
                id: "form.errors.dns.invalid_naptr",
                defaultMessage: "Invalid data"
            }
        }

        /**
         * Order (integer between 0 and 65535)
         *
         * A 16-bit unsigned integer specifying the order in which the NAPTR
         * records MUST be processed to ensure the correct ordering of
         * rules.  Low numbers are processed before high numbers, and once a
         * NAPTR is found whose rule "matches" the target, the client MUST
         * NOT consider any NAPTRs with a higher value for order (except as
         * noted below for the Flags field).
         */
        let order = parseInt(parts[1]);
        if (order < 0 || order > 65535) {
            // invalid order
            return {
                id: "form.errors.dns.invalid_naptr",
                defaultMessage: "Invalid Order value"
            }
        }

        /**
         * Preference (integer between 0 and 65535)
         *
         * A 16-bit unsigned integer that specifies the order in which NAPTR
         * records with equal "order" values SHOULD be processed, low
         * numbers being processed before high numbers.  This is similar to
         * the preference field in an MX record, and is used so domain
         * administrators can direct clients towards more capable hosts or
         * lighter weight protocols.  A client MAY look at records with
         * higher preference values if it has a good reason to do so such as
         * not understanding the preferred protocol or service.
         *
         * The important difference between Order and Preference is that
         * once a match is found the client MUST NOT consider records with a
         * different Order but they MAY process records with the same Order
         * but different Preferences.  I.e., Preference is used to give weight
         * to rules that are considered the same from an authority
         * standpoint but not from a simple load balancing standpoint.*
         */
        let preference = parseInt(parts[2]);
        if (preference < 0 || preference > 65535) {
            // invalid preference
            return {
                id: "form.errors.dns.invalid_naptr",
                defaultMessage: "Invalid Preference Value"
            }
        }

        /**
         * Flags
         *
         * A <character-string> containing flags to control aspects of the
         * rewriting and interpretation of the fields in the record.  Flags
         * are single characters from the set [A-Z0-9].  The case of the
         * alphabetic characters is not significant.
         *
         * At this time only four flags, "S", "A", "U", and "P", are
         * defined.  The "S", "A" and "U" flags denote a terminal lookup.
         * This means that this NAPTR record is the last one and that the
         * flag determines what the next stage should be.  The "S" flag
         * means that the next lookup should be for SRV records [4].  See
         * Section 5 for additional information on how NAPTR uses the SRV
         * record type.  "A" means that the next lookup should be for either
         * an A, AAAA, or A6 record.  The "U" flag means that the next step
         * is not a DNS lookup but that the output of the Regexp field is an
         * URI that adheres to the 'absoluteURI' production found in the
         * ABNF of RFC 2396 [9].  Since there may be applications that use
         * NAPTR to also lookup aspects of URIs, implementors should be
         * aware that this may cause loop conditions and should act
         * accordingly.
         *
         * The "P" flag says that the remainder of the application side
         * algorithm shall be carried out in a Protocol-specific fashion.
         * The new set of rules is identified by the Protocol specified in
         * the Services field.  The record that contains the 'P' flag is the
         * last record that is interpreted by the rules specified in this
         * document.  The new rules are dependent on the application for
         * which they are being used and the protocol specified.  For
         * example, if the application is a URI RDS and the protocol is WIRE
         * then the new set of rules are governed by the algorithms
         * surrounding the WIRE HTTP specification and not this document.
         *
         * The remaining alphabetic flags are reserved for future versions
         * of the NAPTR specification.  The numeric flags may be used for
         * local experimentation.  The S, A, U and P flags are all mutually
         * exclusive, and resolution libraries MAY signal an error if more
         * than one is given.  (Experimental code and code for assisting in
         * the creation of NAPTRs would be more likely to signal such an
         * error than a client such as a browser).  It is anticipated that
         * multiple flags will be allowed in the future, so implementers
         * MUST NOT assume that the flags field can only contain 0 or 1
         * characters.  Finally, if a client encounters a record with an
         * unknown flag, it MUST ignore it and move to the next record.  This
         * test takes precedence even over the "order" field.  Since flags
         * can control the interpretation placed on fields, a novel flag
         * might change the interpretation of the regexp and/or replacement
         * fields such that it is impossible to determine if a record
         * matched a given target.
         *
         * The "S", "A", and "U"  flags are called 'terminal' flags since
         * they halt the looping rewrite algorithm.  If those flags are not
         * present, clients may assume that another NAPTR RR exists at the
         * domain name produced by the current rewrite rule.  Since the "P"
         * flag specifies a new algorithm, it may or may not be 'terminal'.
         * Thus, the client cannot assume that another NAPTR exists since
         * this case is determined elsewhere.
         *
         * DNS servers MAY interpret these flags and values and use that
         * information to include appropriate SRV and A,AAAA, or A6 records
         * in the additional information portion of the DNS packet.  Clients
         * are encouraged to check for additional information but are not
         * required to do so.*
         */
        let flags = parts[3].toUpperCase();
        let flagsArray = [...new Set(flags.split(''))];
        if (!flags.match(/[SAUP]*/i) || flags.length != flagsArray.length) {
            // invalid flags
            return {
                id: "form.errors.dns.invalid_naptr",
                defaultMessage: "Invalid Flag found"
            }
        }

        /**
         * Service
         *
         * Specifies the service(s) available down this rewrite path.  It may
         * also specify the particular protocol that is used to talk with a
         * service.  A protocol MUST be specified if the flags field states
         * that the NAPTR is terminal.  If a protocol is specified, but the
         * flags field does not state that the NAPTR is terminal, the next
         * lookup MUST be for a NAPTR.  The client MAY choose not to perform
         * the next lookup if the protocol is unknown, but that behavior
         * MUST NOT be relied upon.
         *
         * The service field may take any of the values below (using the
         * Augmented BNF of RFC 2234 [5]):
         *
         * service_field = [ [protocol] *("+" rs)]
         * protocol      = ALPHA *31ALPHANUM
         * rs            = ALPHA *31ALPHANUM
         * ; The protocol and rs fields are limited to 32
         * ; characters and must start with an alphabetic.
         *
         * For example, an optional protocol specification followed by 0 or
         * more resolution services.  Each resolution service is indicated by
         * an initial '+' character.
         *
         * Note that the empty string is also a valid service field.  This
         * will typically be seen at the beginning of a series of rules,
         * when it is impossible to know what services and protocols will be
         * offered by a particular service.
         *
         * The actual format of the service request and response will be
         * determined by the resolution protocol, and is the subject for
         * other documents.  Protocols need not offer all services.  The
         * labels for service requests shall be formed from the set of
         * characters [A-Z0-9].  The case of the alphabetic characters is
         * not significant.
         *
         * The list of "valid" protocols for any given NAPTR record is any
         * protocol that implements some or all of the services defined for
         * a NAPTR application.  Currently, THTTP [6] is the only protocol
         * that is known to make that claim at the time of publication.  Any
         * other protocol that is to be used must have documentation
         * specifying:
         *
         * *  how it implements the services of the application
         *
         * *  how it is to appear in the NAPTR record (i.e., the string id
         * of the protocol)
         *
         * The list of valid Resolution Services is defined by the documents
         * that specify individual NAPTR based applications.
         *
         * It is worth noting that the interpretation of this field is
         * subject to being changed by new flags, and that the current
         *  * specification is oriented towards telling clients how to talk
         * with a URN resolver.
         */
        let service = parts[4];

        if (!service.match(/^([a-z][a-z0-9]{1,31})(\+[a-z][a-z0-9]{1,31})+$/i)) {
            return {
                id: "form.errors.dns.invalid_naptr",
                defaultMessage: "Invalid Service value"
            }
        }

        /**
         * Regexp
         *
         * A STRING containing a substitution expression that is applied to
         * the original string held by the client in order to construct the
         * next domain name to lookup.  The grammar of the substitution
         * expression is given in the next section.
         *
         * The regular expressions MUST NOT be used in a cumulative fashion,
         * that is, they should only be applied to the original string held
         * by the client, never to the domain name produced by a previous
         * NAPTR rewrite.  The latter is tempting in some applications but
         * experience has shown such use to be extremely fault sensitive,
         * very error prone, and extremely difficult to debug.
         *
         * subst_expr   = delim-char  ere  delim-char  repl  delim-char  *flags
         * delim-char   = "/" / "!" / ... <Any non-digit or non-flag character
         * other than backslash '\'. All occurances of a delim_char
         * in a subst_expr must be the same character.>
         * ere          = POSIX Extended Regular Expression
         * repl         = 1 * ( OCTET /  backref )
         * backref      = "\" 1POS_DIGIT
         * flags        = "i"
         * POS_DIGIT    = %x31-39 (1-9, 0 is not allowed)
         */
        let regexp = parts[5];

        if (regexp != "") {
            let delimit = regexp.charAt(0);
            let regexParts = regexp.split(delimit);

            if(regexParts.length != 4) {
                return {
                    id: "form.errors.dns.invalid_naptr",
                    defaultMessage: "Invalid Regular Expression"
                }
            }

            // we do not validate the regular expression and repl yet, this is very complicated
            let ere = regexParts[1];
            let repl = regexParts[2];


            let flags = regexParts[3];
            if (flags != null && flags != "i" && flags != "") {
                return {
                    id: "form.errors.dns.invalid_naptr",
                    defaultMessage: "Invalid Regular Expression Modifier. Only 'i' is allowed"
                }
            }
        }

        /**
         * Replacement
         *
         * A STRING containing a substitution expression that is applied to
         * the original string held by the client in order to construct the
         * next domain name to lookup.  The grammar of the substitution
         * expression is given in the next section.
         *
         * The regular expressions MUST NOT be used in a cumulative fashion,
         * that is, they should only be applied to the original string held
         * by the client, never to the domain name produced by a previous
         * NAPTR rewrite.  The latter is tempting in some applications but
         * experience has shown such use to be extremely fault sensitive,
         * very error prone, and extremely difficult to debug.
         */
        let replacement = parts[6];

        if(replacement != '.' && !replacement.match(/^(?!:\/\/)(?=\S{1,255}$)((\S{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.)$/i)) {
            return {
                id: "form.errors.dns.invalid_naptr",
                defaultMessage: "Invalid Replacement. A fully qualified domain name, including the trailing dot, is required"
            }
        }

        return true;
    }
});
