import React from 'react';
import PropTypes from 'prop-types';

class TextField extends React.Component {

    render() {

        const {input, label, type, help, meta: {touched, error, invalid, warning}, children} = this.props;

        let labelClasses = this.props.labelClasses || "control-label";
        let containerClasses = this.props.containerClasses != null ? this.props.containerClasses : '';
        let placeholder;

        if (this.props.horizontal > 0) {
            labelClasses += " col-sm-" + this.props.horizontal;
            containerClasses += " col-sm-" + (12 - this.props.horizontal);
        }

        let groupClasses = 'form-group';

        if (this.props.className) {
            groupClasses += ' ' + this.props.className;
        }

        if (touched && invalid) {
            groupClasses += ' has-error';
        }

        if (touched && warning) {
            groupClasses += ' has-warning';
        }

        if (this.props.size != null) {
            groupClasses += ' form-group-' + this.props.size;
        }

        if (this.props.placeholder) {
            placeholder = this.props.placeholder;
        } else {
            placeholder = label;
        }

        if (this.props.disabled) {
            input.disabled = this.props.disabled;
        }

        let className;
        if (this.props.inputClassName) {
            if (typeof (this.props.inputClassName) == "function") {
                className = this.props.inputClassName(input.value)
            } else {
                className = this.props.inputClassName
            }
        } else {
            className = "form-control";
        }

        return (<div className={groupClasses}>
            {label && <label className={labelClasses}>{label}</label>}
            <div className={containerClasses}>
                {this.props.prepend}
                {(() => {
                    if (type == 'select') {
                        return (
                            <select {...input} className={className} type={type}>
                                {children}
                            </select>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                <input {...input} className={className} placeholder={placeholder} type={type}/>
                                {children}
                            </React.Fragment>
                        )
                    }
                })()}
                <div className="help-block">
                    {(() => {
                        if (touched) {
                            if (error) {
                                return <span>{error}</span>
                            } else if (warning) {
                                return <span>{warning}</span>
                            }
                        }

                        if (help) {
                            return (<span>
                            {help}
                        </span>)
                        }
                    })()}
                </div>
            </div>
        </div>);
    }


    static defaultProps = {
        size: null
    };

    static propTypes = {
        size: PropTypes.string,
        horizontal: PropTypes.number,
    }
}

export default TextField;
