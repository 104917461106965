const cartReducer = (state = {
    entities: {},
    dictionary: [],
    isFetching: false,
    isFetched: false,
    domainAdded: false,
    currentWPSDomain: null,
    preRegsToConfirmDictionary: [],
    preRegsToConfirmEntities: {},
    preRegsConfirming: false
}, action) => {

    switch (action.type) {
        case "CART_ADD_DOMAIN_PENDING": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        domain: action.meta.domain,
                        price: null,
                        adding: true,
                    }
                }
            }
        }
        case "CART_ADD_DOMAIN_REJECTED": {
            let ret = {
                ...state,
                dictionary: state.dictionary.filter(domain => domain !== action.meta.domain),
            };

            delete ret.entities[action.meta.domain];

            return ret;
        }
        case "CART_ADD_DOMAIN_FULFILLED": {
            return {
                ...state,
                dictionary: [
                    ...state.dictionary,
                    action.meta.domain,
                ],
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        domain: action.meta.domain,
                        domainName: action.payload.domainName,
                        tld: action.payload.tld,
                        price: action.payload.price,
                        adding: false,
                        removing: false
                    }
                },
                domainAdded: true
            }
        }
        case "CART_REMOVE_DOMAIN_PENDING": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        removing: true
                    }
                }
            }
        }
        case "CART_REMOVE_DOMAIN_REJECTED": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        removing: false
                    }
                }
            }
        }
        case "CART_REMOVE_DOMAIN_FULFILLED": {
            let ret = {
                ...state,
                dictionary: state.dictionary.filter(domain => domain !== action.meta.domain),
            };

            delete ret.entities[action.meta.domain];

            return ret;
        }
        case "CART_SET_DOMAIN_WPS": {
            return {
                ...state,
                currentWPSDomain: action.payload
            }
        }
        case "CART_WPS_SAVE_PENDING": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta]: {
                        ...state.entities[action.meta],
                        savingWPS: true
                    }
                }
            }
        }
        case "CART_WPS_SAVE_REJECTED": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta]: {
                        ...state.entities[action.meta],
                        savingWPS: false
                    }
                },
                currentWPSDomain: null
            }
        }
        case "CART_WPS_SAVE_FULFILLED": {
            if (action.payload.success == true) {
                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        [action.meta]: {
                            ...state.entities[action.meta],
                            wps: action.payload.wps,
                            savingWPS: false,
                            price: action.payload.price
                        }
                    },
                    currentWPSDomain: null
                }
            } else {
                return {
                    ...state,
                    currentWPSDomain: null
                };
            }
        }
        case "CART_CANCEL_PREREGS": {
            return {
                ...state,
                preRegsToConfirmDictionary: [],
                preRegsToConfirmEntities: {}
            }
        }
        case "CART_SET_PREREG_CONFIRM": {
            return {
                ...state,
                preRegsToConfirmEntities: {
                    ...state.preRegsToConfirmEntities,
                    [action.payload.preReg.domain]: {
                        ...state.preRegsToConfirmEntities[action.payload.preReg.domain],
                        checked: action.payload.confirm
                    }
                }
            }
        }
        case "CART_SET_ALL_PREREGS_CONFIRM": {
            let preRegsToConfirmEntities = {};


            o94l8a
            state.preRegsToConfirmDictionary.forEach((preRegDomain) => {
                preRegsToConfirmEntities[preRegDomain] = {
                    ...state.preRegsToConfirmEntities[preRegDomain],
                    checked: action.payload
                }
            });

            return {
                ...state,
                preRegsToConfirmEntities: preRegsToConfirmEntities
            }
        }
        case "CART_PREREGS_CONFIRM_PENDING": {
            return {
                ...state,
                preRegsConfirming: true
            }
        }
        case "CART_PREREGS_CONFIRM_REJECTED": {
            // handle this
            return {
                ...state,
                preRegsConfirming: false
            }
        }
        case "CART_PREREGS_CONFIRM_FULFILLED": {
            // add cart-items to cart
            return {
                ...state,
                entities: {
                    ...state.entities,
                    ...action.payload.added
                },
                dictionary: [
                    ...state.dictionary,
                    ...Object.keys(action.payload.added)
                ],
                preRegsToConfirmEntities: {},
                preRegsToConfirmDictionary: [],
                preRegsConfirming: false
            }
        }
        default: {
            return state;
        }
    }
};

export default cartReducer;
