import React from "react";
import {Button, FormControl} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toplevelDomainTeaserActions} from "../actions";

class ToplevelDomainTeaser extends React.Component {

    constructor(props) {
        super(props)
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.getTldLinks = this.getTldLinks.bind(this);
    }

    componentDidMount() {
        this.props.initTeaser(this.props.toplevelDomains, this.props.tldType);
    }

    handleFilterChange(event) {
        this.props.filterBy(event.target.value);
    }

    getTldLinks() {
        if (this.props.list.length == 0) {
            return <span className="not-found">No TLDs containing "{this.props.filter}" found.</span>
        }

        return this.props.list.map((tld) => {
            return (<a key={tld} href={Routing.generate('domain_tld-promo', {'tld': tld})}
                       title={'.' + tld + ' domain'}>.{tld}</a>);
        });
    }

    submit(e) {
        e.preventDefault();
    }

    render() {
        return (
            <div>
                <h2 className="coral">
                    <span className="catchline">Domain Names:</span>
                    More than 400 great domain extensions, go and grab yours
                </h2>
                <form className="form-inline mtb25" onSubmit={this.submit}>
                    <div className="form-group">
                        <FormControl
                            type="search"
                            bsSize="large"
                            placeholder="Search Extensions"
                            onChange={this.handleFilterChange}
                            className="input-lg"
                        />
                    </div>
                    <Button bsStyle="warning" className="ml10 input-lg"><i className="material-icons md-24 mirror">search</i></Button>
                </form>

                <div className="domainlist">
                    {this.getTldLinks()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        ...state.toplevelDomainTeaser,
        toplevelDomains: state.toplevelDomains
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(toplevelDomainTeaserActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ToplevelDomainTeaser)
