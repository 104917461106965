const claimReducer = (state = {
        entities: {},
        current: null
    }, action) => {

        switch (action.type) {
            case 'CLAIM_CONFIRMATION_MISSING': {
                return {
                    ...state,
                    entities: state.entities,
                    current: action.payload
                }
                break;
            }
            case 'CLAIM_FETCH_INFORMATION_PENDING': {
                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        [action.meta.domain]: {
                            ...state.entities[action.meta.domain],
                            isFetching: true,
                            confirmChecked: false,
                            confirmed: false,
                        }
                    }
                };
            }
            case 'CLAIM_FETCH_INFORMATION_FULFILLED': {
                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        [action.meta.domain]: {
                            ...state.entities[action.meta.domain],
                            ...action.payload,
                            isFetching: false,
                            confirmChecked: false,
                            confirmed: false,
                        }
                    },
                }
            }
            case 'CLAIM_TOGGLE_CONFIRMATION': {
                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        [state.current.domain]: {
                            ...state.entities[state.current.domain],
                            confirmChecked: action.payload,
                        }
                    },
                }
            }
            case 'CLAIM_CONFIRM': {
                return {
                    ...state,
                    current: null,
                    entities: {
                        ...state.entities,
                        [state.current.domain]: {
                            ...state.entities[state.current.domain],
                            confirmed: new Date()
                        }
                    },
                }
            }
            case 'CLAIM_REJECT': {
                return {
                    ...state,
                    current: null
                }
            }
            default:
                return state;
        }
        return state;
    }
;

export default claimReducer;