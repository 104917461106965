import {addValidator} from "redux-form-validators";

/**
 * People appear to sometimes look only at STD 11, RFC 822 to determine
 * whether a particular hostname is correct or not. Hostnames should
 * strictly conform to the syntax given in STD 13, RFC 1034 (page 11),
 * with *addresses* in addition conforming to RFC 822. As an example
 * take "c&w.blues" which is perfectly legal according to RFC 822, but
 * which can have quite surprising effects on particular systems, e.g.,
 * "telnet c&w.blues" on a Unix system.
 */
export default addValidator({
    validator: (options, value, allValues) => {

        let re = /^([A-Z][A-Z0-9-/]+)\s([A-Z][A-Z0-9-/]+)$/;

        if (!value.match(re)) {
            return {
                id: "form.errors.dns.invalid_hinfo",
                defaultMessage: "Invalid HINFO data"
            }
        }

        let parts = value.split(' ');
        if(parts.length != 2) {
            return {
                id: "form.errors.dns.invalid_hinfo",
                defaultMessage: "Machine and OS info need to be specified and they need to be separated by exactly one space"
            }
        }

        if(parts[0].length > 40 || parts[1].length > 40) {
            return {
                id: "form.errors.dns.invalid_hinfo",
                defaultMessage: "Machine and OS info must contain 40 characters or less"
            }
        }


        if(options.hasOwnProperty('strict') && options.strict == true) {

            const allowedMachines = {
                'AMIGA-500': true,
                'AMIGA-500/010': true,
                'AMIGA-500/020': true,
                'AMIGA-500/EC030': true,
                'AMIGA-500/030': true,
                'AMIGA-600': true,
                'AMIGA-1000': true,
                'AMIGA-1000/010': true,
                'AMIGA-1000/020': true,
                'AMIGA-1000/EC030': true,
                'AMIGA-1000/030': true,
                'AMIGA-1200': true,
                'AMIGA-1200/EC030': true,
                'AMIGA-1200/030': true,
                'AMIGA-1200/EC040': true,
                'AMIGA-1200/LC040': true,
                'AMIGA-1200/040': true,
                'AMIGA-2000': true,
                'AMIGA-2000/010': true,
                'AMIGA-2000/020': true,
                'AMIGA-2000/EC030': true,
                'AMIGA-2000/030': true,
                'AMIGA-2000/LC040': true,
                'AMIGA-2000/EC040': true,
                'AMIGA-2000/040': true,
                'AMIGA-3000': true,
                'AMIGA-3000/EC040': true,
                'AMIGA-3000/LC040': true,
                'AMIGA-3000/040': true,
                'AMIGA-3000/060': true,
                'AMIGA-4000/EC030': true,
                'AMIGA-4000/030': true,
                'AMIGA-4000/LC040': true,
                'AMIGA-4000/040': true,
                'AMIGA-4000/060': true,
                'ALTO': true,
                'ALTOS-6800': true,
                'AMDAHL-V7': true,
                'APOLLO': true,
                'APPLE-MACINTOSH': true,
                'APPLE-POWERBOOK': true,
                'ATARI-104ST': true,
                'ATT-3B1': true,
                'ATT-3B2': true,
                'ATT-3B20': true,
                'ATT-7300': true,
                'AXP': true,
                'BBN-C/60': true,
                'BURROUGHS-B/29': true,
                'BURROUGHS-B/4800': true,
                'BUTTERFLY': true,
                'C/30': true,
                'C/70': true,
                'CADLINC': true,
                'CADR': true,
                'CDC-170': true,
                'CDC-170/750': true,
                'CDC-173': true,
                'CDTV': true,
                'CDTV/060': true,
                'CD32': true,
                'CELERITY-1200': true,
                'CLUB-386': true,
                'COMPAQ-386/20': true,
                'COMTEN-3690': true,
                'CP8040': true,
                'CRAY-1': true,
                'CRAY-X/MP': true,
                'CRAY-2': true,
                'CTIWS-117': true,
                'DANDELION': true,
                'DEC-10': true,
                'DEC-1050': true,
                'DEC-1077': true,
                'DEC-1080': true,
                'DEC-1090': true,
                'DEC-1090B': true,
                'DEC-1090T': true,
                'DEC-2020T': true,
                'DEC-2040': true,
                'DEC-2040T': true,
                'DEC-2050T': true,
                'DEC-2060': true,
                'DEC-2060T': true,
                'DEC-2065': true,
                'DEC-AXP': true,
                'DEC-FALCON': true,
                'DEC-KS10': true,
                'DECSTATION': true,
                'DEC-VAX': true,
                'DEC-VAXCLUSTER': true,
                'DEC-VAXSTATION': true,
                'DEC-VAX-11730': true,
                'DORADO': true,
                'DPS8/70M': true,
                'ELXSI-6400': true,
                'EVEREX-386': true,
                'FOONLY-F2': true,
                'FOONLY-F3': true,
                'FOONLY-F4': true,
                'GOULD': true,
                'GOULD-6050': true,
                'GOULD-6080': true,
                'GOULD-9050': true,
                'GOULD-9080': true,
                'H-316': true,
                'H-60/68': true,
                'H-68': true,
                'H-68/80': true,
                'H-89': true,
                'HONEYWELL-DPS-6': true,
                'HONEYWELL-DPS-8/70': true,
                'HP3000': true,
                'HP3000/64': true,
                'IBM-158': true,
                'IBM-360/67': true,
                'IBM-370/3033': true,
                'IBM-3081': true,
                'IBM-3084QX': true,
                'IBM-3101': true,
                'IBM-4331': true,
                'IBM-4341': true,
                'IBM-4361': true,
                'IBM-4381': true,
                'IBM-4956': true,
                'IBM-6152': true,
                'IBM-PC': true,
                'IBM-PC/AT': true,
                'IBM-PC/RT': true,
                'IBM-PC/XT': true,
                'IBM-RS/6000': true,
                'IBM-SERIES/1': true,
                'IMAGEN': true,
                'IMAGEN-8/300': true,
                'IMSAI': true,
                'INTEGRATED-SOLUTIONS': true,
                'INTEGRATED-SOLUTIONS-68K': true,
                'INTEGRATED-SOLUTIONS-CREATOR': true,
                'INTEGRATED-SOLUTIONS-CREATOR-8': true,
                'INTEL-386': true,
                'INTEL-IPSC': true,
                'IS-1': true,
                'IS-68010': true,
                'LMI': true,
                'LSI-11': true,
                'LSI-11/2': true,
                'LSI-11/23': true,
                'LSI-11/73': true,
                'M68000': true,
                'MAC-II': true,
                'MAC-POWERBOOK': true,
                'MACINTOSH': true,
                'MASSCOMP': true,
                'MC500': true,
                'MC68000': true,
                'MICROPORT': true,
                'MICROVAX': true,
                'MICROVAX-I': true,
                'MV/8000': true,
                'NAS3-5': true,
                'NCR-COMTEN-3690': true,
                'NEXT/N1000-316': true,
                'NOW': true,
                'ONYX-Z8000': true,
                'PDP-11': true,
                'PDP-11/3': true,
                'PDP-11/23': true,
                'PDP-11/24': true,
                'PDP-11/34': true,
                'PDP-11/40': true,
                'PDP-11/44': true,
                'PDP-11/45': true,
                'PDP-11/50': true,
                'PDP-11/70': true,
                'PDP-11/73': true,
                'PE-7/32': true,
                'PE-3205': true,
                'PERQ': true,
                'PLEXUS-P/60': true,
                'PLI': true,
                'PLURIBUS': true,
                'PRIME-2350': true,
                'PRIME-2450': true,
                'PRIME-2755': true,
                'PRIME-9655': true,
                'PRIME-9755': true,
                'PRIME-9955II': true,
                'PRIME-2655': true,
                'PRIME-9955': true,
                'PRIME-9950': true,
                'PRIME-9650': true,
                'PRIME-9750': true,
                'PRIME-2250': true,
                'PRIME-750': true,
                'PRIME-850': true,
                'PRIME-550II': true,
                'PYRAMID-90': true,
                'PYRAMID-90MX': true,
                'PYRAMID-90X': true,
                'RIDGE': true,
                'RIDGE-32': true,
                'RIDGE-32C': true,
                'ROLM-1666': true,
                'RS/6000': true,
                'S1-MKIIA': true,
                'SMI': true,
                'SEQUENT-BALANCE-8000': true,
                'SIEMENS': true,
                'SILICON-GRAPHICS': true,
                'SILICON-GRAPHICS-IRIS': true,
                'SGI-IRIS-2400': true,
                'SGI-IRIS-2500': true,
                'SGI-IRIS-3010': true,
                'SGI-IRIS-3020': true,
                'SGI-IRIS-3030': true,
                'SGI-IRIS-3110': true,
                'SGI-IRIS-3115': true,
                'SGI-IRIS-3120': true,
                'SGI-IRIS-3130': true,
                'SGI-IRIS-4D/20': true,
                'SGI-IRIS-4D/20G': true,
                'SGI-IRIS-4D/25': true,
                'SGI-IRIS-4D/25G': true,
                'SGI-IRIS-4D/25S': true,
                'SGI-IRIS-4D/50': true,
                'SGI-IRIS-4D/50G': true,
                'SGI-IRIS-4D/50GT': true,
                'SGI-IRIS-4D/60': true,
                'SGI-IRIS-4D/60G': true,
                'SGI-IRIS-4D/60T': true,
                'SGI-IRIS-4D/60GT': true,
                'SGI-IRIS-4D/70': true,
                'SGI-IRIS-4D/70G': true,
                'SGI-IRIS-4D/70GT': true,
                'SGI-IRIS-4D/80GT': true,
                'SGI-IRIS-4D/80S': true,
                'SGI-IRIS-4D/120GTX': true,
                'SGI-IRIS-4D/120S': true,
                'SGI-IRIS-4D/210GTX': true,
                'SGI-IRIS-4D/210S': true,
                'SGI-IRIS-4D/220GTX': true,
                'SGI-IRIS-4D/220S': true,
                'SGI-IRIS-4D/240GTX': true,
                'SGI-IRIS-4D/240S': true,
                'SGI-IRIS-4D/280GTX': true,
                'SGI-IRIS-4D/280S': true,
                'SGI-IRIS-CS/12': true,
                'SGI-IRIS-4SERVER-8': true,
                'SPERRY-DCP/10': true,
                'SUN': true,
                'SUN-2': true,
                'SUN-2/50': true,
                'SUN-2/100': true,
                'SUN-2/120': true,
                'SUN-2/130': true,
                'SUN-2/140': true,
                'SUN-2/150': true,
                'SUN-2/160': true,
                'SUN-2/170': true,
                'SUN-3/50': true,
                'SUN-3/60': true,
                'SUN-3/75': true,
                'SUN-3/80': true,
                'SUN-3/110': true,
                'SUN-3/140': true,
                'SUN-3/150': true,
                'SUN-3/160': true,
                'SUN-3/180': true,
                'SUN-3/200': true,
                'SUN-3/260': true,
                'SUN-3/280': true,
                'SUN-3/470': true,
                'SUN-3/480': true,
                'SUN-4/60': true,
                'SUN-4/110': true,
                'SUN-4/150': true,
                'SUN-4/200': true,
                'SUN-4/260': true,
                'SUN-4/280': true,
                'SUN-4/330': true,
                'SUN-4/370': true,
                'SUN-4/390': true,
                'SUN-50': true,
                'SUN-100': true,
                'SUN-120': true,
                'SUN-130': true,
                'SUN-150': true,
                'SUN-170': true,
                'SUN-386i/250': true,
                'SUN-68000': true,
                'SYMBOLICS-3600': true,
                'SYMBOLICS-3670': true,
                'SYMMETRIC-375': true,
                'SYMULT': true,
                'TANDEM-TXP': true,
                'TANDY-6000': true,
                'TEK-6130': true,
                'TI-EXPLORER': true,
                'TP-4000': true,
                'TRS-80': true,
                'UNIVAC-1100': true,
                'UNIVAC-1100/60': true,
                'UNIVAC-1100/62': true,
                'UNIVAC-1100/63': true,
                'UNIVAC-1100/64': true,
                'UNIVAC-1100/70': true,
                'UNIVAC-1160': true,
                'UNKNOWN': true,
                'VAX': true,
                'VAX-11/725': true,
                'VAX-11/730': true,
                'VAX-11/750': true,
                'VAX-11/780': true,
                'VAX-11/785': true,
                'VAX-11/790': true,
                'VAX-11/8600': true,
                'VAX-8600': true,
                'VAXCLUSTER': true,
                'VAXSTATION': true,
                'WANG-PC002': true,
                'WANG-VS100': true,
                'WANG-VS400': true,
                'WYSE-386': true,
                'WYSE-WN5004': true,
                'WYSE-WN5008': true,
                'WYSE-WN5104': true,
                'WYSE-WN5108': true,
                'WYSE-WX15C': true,
                'WYSE-WX17C': true,
                'WYSE-WX17M': true,
                'WYSE-WX19C': true,
                'WYSE-WX19M': true,
                'WYSE-WYX14M': true,
                'WYSE-WYX5': true,
                'XEROX-1108': true,
                'XEROX-8010': true,
                'ZENITH-148': true
            };

            if(!allowedMachines.hasOwnProperty(parts[0]) || allowedMachines[parts[0]] !== true) {
                return {
                    id: "form.errors.dns.invalid_hinfo",
                    defaultMessage: "Invalid machine info"
                }
            }

            const allowedSystems = {
                'AEGIS': true,
                'AMIGA-OS-1.2': true,
                'AMIGA-OS-1.3': true,
                'AMIGA-OS-2.0': true,
                'AMIGA-OS-2.1': true,
                'AMIGA-OS-3.0': true,
                'AMIGA-OS-3.1': true,
                'APOLLO': true,
                'AIX/370': true,
                'AIX-PS/2': true,
                'BS-2000': true,
                'CEDAR': true,
                'CGW': true,
                'CHORUS': true,
                'CHRYSALIS': true,
                'CMOS': true,
                'CMS': true,
                'COS': true,
                'CPIX': true,
                'CTOS': true,
                'CTSS': true,
                'DCN': true,
                'DDNOS': true,
                'DOMAIN': true,
                'DOS': true,
                'EDX': true,
                'ELF': true,
                'EMBOS': true,
                'EMMOS': true,
                'EPOS': true,
                'FOONEX': true,
                'FORTH': true,
                'FUZZ': true,
                'GCOS': true,
                'GPOS': true,
                'HDOS': true,
                'IMAGEN': true,
                'INTERCOM': true,
                'IMPRESS': true,
                'INTERLISP': true,
                'IOS': true,
                'IRIX': true,
                'ISI-68020': true,
                'ITS': true,
                'LISP': true,
                'LISPM': true,
                'LOCUS': true,
                'MACOS': true,
                'MINOS': true,
                'MOS': true,
                'MPE5': true,
                'MPE/V': true,
                'MPE/IX': true,
                'MSDOS': true,
                'MULTICS': true,
                'MUSIC': true,
                'MUSIC/SP': true,
                'MVS': true,
                'MVS/SP': true,
                'NEXUS': true,
                'NMS': true,
                'NONSTOP': true,
                'NOS-2': true,
                'NTOS': true,
                'OPENVMS': true,
                'OS/DDP': true,
                'OS/2': true,
                'OS4': true,
                'OS86': true,
                'OSX': true,
                'PCDOS': true,
                'PERQ/OS': true,
                'PLI': true,
                'PSDOS/MIT': true,
                'PRIMOS': true,
                'RMX/RDOS': true,
                'ROS': true,
                'RSX11M': true,
                'RTE-A': true,
                'SATOPS': true,
                'SCO-OPEN-DESKTOP-1.0': true,
                'SCO-OPEN-DESKTOP-1.1': true,
                'SCO-OPEN-DESKTOP-2.0': true,
                'SCO-OPEN-DESKTOP-3.0': true,
                'SCO-OPEN-DESKTOP-LITE-3.0': true,
                'SCO-OPEN-SERVER-3.0': true,
                'SCO-UNIX-3.2.0': true,
                'SCO-UNIX-3.2V2.0': true,
                'SCO-UNIX-3.2V2.1': true,
                'SCO-UNIX-3.2V4.0': true,
                'SCO-UNIX-3.2V4.1': true,
                'SCO-UNIX-3.2V4.2': true,
                'SCO-XENIX-386-2.3.2': true,
                'SCO-XENIX-386-2.3.3': true,
                'SCO-XENIX-386-2.3.4': true,
                'SCS': true,
                'SIMP': true,
                'SUN': true,
                'SUN-OS-3.5': true,
                'SUN-OS-4.0': true,
                'SWIFT': true,
                'TAC': true,
                'TANDEM': true,
                'TENEX': true,
                'THE-MAJOR-BBS': true,
                'TOPS10': true,
                'TOPS20': true,
                'TOS': true,
                'TP3010': true,
                'TRSDOS': true,
                'ULTRIX': true,
                'UNIX': true,
                'UNIX-BSD': true,
                'UNIX-V1AT': true,
                'UNIX-V': true,
                'UNIX-V.1': true,
                'UNIX-V.2': true,
                'UNIX-V.3': true,
                'UNIX-PC': true,
                'UNKNOWN': true,
                'UT2D': true,
                'V': true,
                'VM': true,
                'VM/370': true,
                'VM/CMS': true,
                'VM/SP': true,
                'VMS': true,
                'VMS/EUNICE': true,
                'VRTX': true,
                'WAITS': true,
                'WANG': true,
                'WIN32': true,
                'WYSE-WYXWARE': true,
                'X11R3': true,
                'XDE': true,
                'XENIX': true
            };

            if(!allowedSystems.hasOwnProperty(parts[1]) || allowedSystems[parts[1]] !== true) {
                return {
                    id: "form.errors.dns.invalid_hinfo",
                    defaultMessage: "Invalid system info"
                }
            }
        }

        return true;
    }
});
