import React from "react";
import PropTypes from 'prop-types';
import FormattedCurrency from "./FormattedCurrency";
import LoadingIndicator from "../components/LoadingIndicator"

class CartMenuElement extends React.Component {

    render() {
        const {item} = this.props;

        /**
         * ref={(cartMenuEl) => {
            this.cartMenuEl = cartMenuEl
           }}
         */
        return (<li key={item.domain} className="cart-item" style={this.props.style}>
            <div className="domain">{item.domainName}<strong>.{item.tld}</strong></div>
            <div className="price">
                <FormattedCurrency id="wps-partial-price" value={item.price}  addtax={true}/>
            </div>
            <div className="actions">
                {(() => {
                    if (item.adding == true || item.removing == true) {
                        return (<LoadingIndicator active size="small"/>)
                    } else {
                        return (<a onClick={(e) => {e.preventDefault()}}>
                            <i className="material-icons md-12" onClick={() => this.props.removeDomainFromCart(item)}>close</i>
                        </a>)
                    }
                })()}
            </div>
        </li>);
    }

    static propTypes = {
        item: PropTypes.object.isRequired
    }
}

export default CartMenuElement
