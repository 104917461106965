import React from "react";
import PropTypes from 'prop-types';

class MaterialIcon extends React.Component {

    render() {
        const {icon, size, _props} = this.props;

        let classes = ['material-icons'];

        if(this.props.className) {
            classes.push(this.props.className);
        }
        classes.push(('md-' + this.props.size));

        return (
            <i className={classes.join(' ')}>{this.props.icon}</i>
        )
    }
}

MaterialIcon.defaultProps = {
    size: '18'
};
MaterialIcon.propTypes = {
    size: PropTypes.string,
    icon: PropTypes.string,
}

export default MaterialIcon
