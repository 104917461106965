import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {Field, FieldArray, formValueSelector, reduxForm, SubmissionError} from "redux-form";
import {addValidator, required} from 'redux-form-validators'
import TextField from "../../Form/TextField";
import SelectField from "../../Form/SelectField";
import MaterialIcon from "../../MaterialIcon";
import {dnsActions} from "../../../actions";
import ttl from "./Validators/ttl";
import label from "./Validators/label";
import ipv4 from "./Validators/ipv4";
import ipv6 from "./Validators/ipv6";
import domain from "./Validators/domain";
import hinfo from "./Validators/hinfo";
import mx from "./Validators/mx";
import loc from "./Validators/loc";
import naptr from "./Validators/naptr";
import srv from "./Validators/srv";
import uri from "./Validators/uri";
import rp from "./Validators/rp";
import caa from "./Validators/caa";
import trailingDot from "./Validators/trailingDot";
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr'
import getDuplicates from '../../../util/array-duplicates';
import {selectLocation} from "../MapsLocationField";
import timestring from "../../../util/timestring";

/**
 * x validate duration
 * x adding deleting rdata fields
 * x placeholder
 * x something like select2 for type
 * i validate rdata according to type
 * x saving dns records
 *
 * split up the code here
 * split the dns part into separate js file
 *
 * Raw Zone-File GUI Editor based on ace, SOA and origin not editable (https://stackoverflow.com/questions/39640328/how-to-make-multiple-chunk-of-lines-readonly-in-ace-editor)
 * - zone file parser
 * - zone file editor
 * - zone file importer
 * x zone file export
 *
 * x glue records
 * - copy settings from another zone/domain
 *
 * global validation:
 * x duplicates
 * x ns only for subdomains
 * x other validation: https://www.howtoforge.com/troubleshooting-common-dns-misconfiguration-errors#-no-cname-pointing-to-ns-records
 * x no merging records
 *
 * ###
 * if((rr.qtype.getCode() == QType::A || rr.qtype.getCode() == QType::AAAA) && !rr.qname.isWildcard() && !rr.qname.isHostname())
 *     cout<<"[Info] "<<rr.qname.toString()<<" record for '"<<rr.qtype.getName()<<"' is not a valid hostname."<<endl;
 *
 * #######
 * // Check if the DNSNames that should be hostnames, are hostnames
 * if (rr.qtype.getCode() == QType::NS || rr.qtype.getCode() == QType::MX || rr.qtype.getCode() == QType::SRV) {
      DNSName toCheck;
      if (rr.qtype.getCode() == QType::SRV) {
        vector<string> parts;
        stringtok(parts, rr.getZoneRepresentation());
        if (parts.size() == 4) toCheck = DNSName(parts[3]);
      } else if (rr.qtype.getCode() == QType::MX) {
        vector<string> parts;
        stringtok(parts, rr.getZoneRepresentation());
        if (parts.size() == 2) toCheck = DNSName(parts[1]);
      } else {
        toCheck = DNSName(rr.content);
      }

      if (toCheck.empty())
        cout<<"[Warning] "<<rr.qtype.getName()<<" record in zone '"<<zone<<"': unable to extract hostname from content."<<endl;
      else if(!toCheck.isHostname()) {
        cout<<"[Warning] "<<rr.qtype.getName()<<" record in zone '"<<zone<<"' has non-hostname content '"<<toCheck.toString()<<"'."<<endl;
        numwarnings++;
      }
    }

 bool DNSName::isHostname() const
 {
   static Regex hostNameRegex = Regex("^(([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])?)\\.)+$");
   return hostNameRegex.match(this->toString());
 }

 #########################

 for(auto &i: cnames) {
    if (noncnames.find(i) != noncnames.end()) {
      cout<<"[Error] CNAME "<<i<<" found, but other records with same label exist."<<endl;
      numerrors++;
    }
  }

 ########################

 for(const auto &i: tlsas) {
    DNSName name = DNSName(i);
    name.trimToLabels(name.countLabels()-2);
    if (cnames.find(name) == cnames.end() && noncnames.find(name) == noncnames.end()) {
      // No specific record for the name in the TLSA record exists, this
      // is already worth emitting a warning. Let's see if a wildcard exist.
      cout<<"[Warning] ";
      DNSName wcname(name);
      wcname.chopOff();
      wcname.prependRawLabel("*");
      if (cnames.find(wcname) != cnames.end() || noncnames.find(wcname) != noncnames.end()) {
        cout<<"A wildcard record exist for '"<<wcname<<"' and a TLSA record for '"<<i<<"'.";
      } else {
        cout<<"No record for '"<<name<<"' exists, but a TLSA record for '"<<i<<"' does.";
      }
      numwarnings++;
      cout<<" A query for '"<<name<<"' will yield an empty response. This is most likely a mistake, please create records for '"<<name<<"'."<<endl;
    }
  }

 ###################

 for(const auto &qname : checkglue) {
    if (!glue.count(qname)) {
      cout<<"[Warning] Missing glue for '"<<qname<<"' in zone '"<<zone<<"'"<<endl;
      numwarnings++;
    }
  }
 *
 */

const rdataValidator = addValidator({
    defaultMessage: {
        id: "form.errors.dns.invalid_rdata",
        defaultMessage: "Invalid RDATA"
    },

    validator: function (options, value, allValues) {

        let validator;
        switch (allValues.type) {
            case 'a':
                validator = ipv4();
                break;
            case 'aaaa':
                validator = ipv6();
                break;
            case 'cname':
                // name can't have other records
                validator = domain();
                break;
            case 'hinfo':
                validator = hinfo();
                break;
            case 'loc': // https://tools.ietf.org/html/rfc1876
                validator = loc();
                break;
            case 'mx':
                validator = mx({
                    allResourceRecords: options.allResourceRecords,
                    key: options.key,
                    zone: options.zone
                });
                break;
            case 'naptr':
                validator = naptr();
                // https://www.wikiwand.com/en/NAPTR_record
                // https://tools.ietf.org/html/rfc3403#page-7
                break;
            case 'ns':
                // only for subdomains
                validator = domain();
                break;
            case 'spf': // discontinued in RFC4408
                return true;
            case 'srv': // https://tools.ietf.org/html/rfc2782
                validator = srv();
                break;
            case 'txt': // not empty
                return true;
            case 'uri': // https://tools.ietf.org/html/rfc7553#page-7
                // a bit like srv
                validator = uri();
                break;
            case 'rp': // https://tools.ietf.org/html/rfc1183#page-3
                validator = rp({
                    allResourceRecords: options.allResourceRecords,
                    key: options.key,
                    zone: options.zone
                });
                break;
            case 'caa': // https://tools.ietf.org/html/rfc6844#page-5
                validator = caa();
                break;
            case 'cert': // https://tools.ietf.org/html/rfc4398#page-3
                return true;
                break;
            case 'openpgpkey': // https://tools.ietf.org/html/rfc7929#page-5
                return true;
                break;
            case 'smimea': // https://tools.ietf.org/html/rfc8162#page-4
                return true;
                break;
            case 'sshfp': // https://tools.ietf.org/html/rfc4255#page-3
                return true;
                break;
            case 'tlsa': // https://tools.ietf.org/html/rfc6698#page-7
                return true;
                break;
            case 'ds': // https://tools.ietf.org/html/rfc4034#page-15
                return true;
                break;
            case 'key': // https://tools.ietf.org/html/rfc2930
                return true;
                break;
            case 'x-http': // valid url with protocol
                return true;
                break;
            case 'x-smtp': // valid eMail-Adress
                return true;
                break;
            default:
                return true;
        }

        let e = validator(value, allValues);

        if (e == undefined) {
            // we should only check for duplicates when other validation returns no errors
            let duplicates = getDuplicates(allValues.rdata.map((v) => {
                if (v == undefined) {
                    return "";

                }
                return v.trim()
            }));

            if (duplicates.length > 0) {
                return {
                    id: "form.errors.dns.dupliate_rdata",
                    defaultMessage: "Duplicate value. Please ensure this value only appears once in this record-set"
                }
            }

            return true;
        }

        return {
            id: "form.errors.dns.invalid_rdata",
            defaultMessage: e
        }
    }
});

const rdataWarner = addValidator({
    defaultMessage: {
        id: "form.errors.dns.invalid_rdata",
        defaultMessage: "Warning"
    },

    validator: function (options, value, allValues) {

        let validator;
        switch (allValues.type) {
            case 'hinfo':
                validator = hinfo({strict: true});
                break;
            case 'mx':
                validator = trailingDot({
                    zone: options.zone
                });
                break;
            case 'cname':
                validator = trailingDot({
                    zone: options.zone
                });
                break;
            default:
                return true;
        }

        let e = validator(value, allValues);

        if (e == undefined) {
            return true;
        }

        return {
            id: "form.errors.dns.invalid_rdata",
            defaultMessage:  e
        }
    }
})
const validateAndUpdateResourceRecordSet = (values, dispatch, props) => {

    let seconds;
    if (Number.isInteger(values.ttl)) {
        seconds = values.ttl;
    }
    if (/^\d+$/.test(values.ttl)) {
        seconds = parseInt(values.ttl);
    } else {
        seconds = timestring(values.ttl);
    }

    let name = values.name;
    if (name.endsWith('.')) {
        name = name.substr(0, name.indexOf(props.zone) - 1);
    }

    // we need to know if the submit was successful or not?
    dispatch(dnsActions.saveResourceRecordSet(
        props.zone,
        props.initialValues.name,
        props.initialValues.type,
        {
            ...values,
            ttl: seconds,
            name: name
        },
        props.initialValues.key == '_new',
        props.initialValues.key
    )).then(action => {
        let result = action.value.data;

        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            if (props.initialValues.key) {
                props.reset();
            }
            toastr.success("DNS Records saved successfully!");
        } else {
            // since we cannot say which field caused the error, we just display a global error message
            /*let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }*/

            toastr.error("Failed to save DNS Records!");
            throw new SubmissionError();
        }
    }).catch(error => {
        let msg = null;

        if (error.response && error.response.data && error.response.data.exception) {
            msg = error.response.data.exception;
        }
        toastr.error("Failed to save DNS Records!", msg);
        throw new SubmissionError();
    })
}

const validateResourceRecordSet = (values) => {

    const errors = {}
    //errors.name = 'Required'

    return errors
}

class ResourceRecordSetForm extends React.Component {

    constructor(props) {
        super(props);

        this.rrInputs = {};
        this.renderRecords = this.renderRecords.bind(this);
    }

    selectLocation(currentValue, name, change) {
        selectLocation("Use the map below to find your location and create your own LOC record. " +
            "You can pan and zoom the map as usual. Click on the map to get the loc record for the given location. " +
            "Accuracy defaults to 100m for horizontal and 10m for vertical.", {
            options: {
                loc: currentValue,
                enableEsc: false
            }
        }).then((locRR) => {
            // how to get current value
            change(name, locRR);
        });
    }

    getPlaceholder(recordType) {
        if (recordType == undefined) {
            return null;
        }

        switch (recordType) {
            case "a":
                return "IPv4 address";
            case "aaaa":
                return "IPv6 address";
            case "cname":
                return "Domain name";
            case "hinfo":
                return "CPU-Type Operating-System"
            case "mx":
                return "Mail server";
            case "ns":
                return "Name server";
            case "spf":
                return "Policy parameters";
            case "srv":
                return "Service parameters";
            case "txt":
                return "Text";
            default:
                return recordType.toUpperCase() + ' parameters';
        }
    }

    renderRecords(props) {
        const {fields, meta: {error}} = props;

        let inputClassnames = ['rdata', 'form-inline'];

        if (fields.length > 1) {
            inputClassnames.push('mto');
        }

        if (props.recordType == 'loc') {
            inputClassnames.push('rdata-loc');
        }

        const locEdit = ((name) => {
            // name is not defined, when a new
            if (this.rrInputs[name]) {
                let value = this.rrInputs[name].value;

                return <a onClick={() => {
                    props.selectLocation(value, name, props.change)
                }} className="loc-edit-icon">
                    <MaterialIcon icon="edit_location"/>
                </a>
            }
        }).bind(this);

        return fields.map((record, index) => {

            return (<tr key={props._key + '_' + index} className="editing">
                {(() => {
                    if (index == 0) {
                        return props.groupFields(fields.length)
                    }

                    return null;
                })()}

                <td>

                    <Field name={record}
                           component={TextField}
                           type="text"
                           size="sm"
                           placeholder={props.getPlaceholder(props.recordType)}
                           className={inputClassnames.join(' ')}
                           ref={(ref) => {
                               this.rrInputs[record] = ref;
                           }}
                           prepend={props.recordType == 'loc' ? locEdit(record) : null}
                           validate={[
                               required({
                                   message: {
                                       id: 'form.errors.dns.rdata_required',
                                       defaultMessage: "Please provide the contents for this record"
                                   }
                               }),
                               rdataValidator({
                                   allResourceRecords: props.allResourceRecords,
                                   key: props._key,
                                   zone: props.zone,
                               })
                           ]}
                           warn={[
                               rdataWarner({
                                   allResourceRecords: props.allResourceRecords,
                                   key: props._key,
                                   zone: props.zone,
                               })
                           ]}
                    >
                        {(() => {
                            if (fields.length > 1) {
                                return <a onClick={() => fields.remove(index)} className="clear-icon">
                                    <MaterialIcon icon="clear"/>
                                </a>
                            }
                        })()}
                        {(() => {
                            if (fields.length - 1 == index) {
                                let p = {
                                    onClick: () => {
                                        fields.push('')
                                    }
                                }
                                if (fields.length == 1) {
                                    p.className = 'add-icon ml20';
                                } else {
                                    p.className = 'add-icon';
                                }
                                return (
                                    <a {...p}>
                                        <MaterialIcon icon="add"/>
                                    </a>
                                );
                            }
                        })()}
                    </Field>
                </td>
                {(() => {
                    if (index == 0) {
                        return <React.Fragment>
                            <td className="action" rowSpan={fields.length}>
                                {props.showCancel &&
                                <Button bsStyle="default"
                                        onClick={props.cancelEditRecord}
                                        disabled={props.saving == props._key}
                                >
                                    cancel
                                </Button>}
                            </td>
                            <td className="action" rowSpan={fields.length}>
                                {props.save}
                            </td>
                        </React.Fragment>
                    } else {
                        return null; //<td className="filler"></td>
                    }
                })()}
            </tr>);
        }, this);
    }


    render() {
        let groupFields = (rowSpan) => {
            return <React.Fragment>
                <td key='resource_record_group_name' rowSpan={rowSpan}>
                    <Field name="name"
                           component={TextField}
                           placeholder="@"
                           type="text"
                           size="sm"
                           className="rname"
                           validate={[
                               required({
                                   message: {
                                       id: 'form.errors.name.required',
                                       defaultMessage: "Please provide the name for this record"
                                   }
                               }), label({
                                   allResourceRecords: this.props.allResourceRecords,
                                   key: this.props._key,
                                   zone: this.props.zone,
                               })]}

                    />
                </td>
                <td key='resource_record_group_type' rowSpan={rowSpan}>
                    <Field name="type"
                           component={SelectField}
                           type="select"
                           size="sm"
                           simpleValue={true}
                           options={[
                               {value: 'a', label: 'A'},
                               {value: 'aaaa', label: 'AAAA'},
                               {value: 'caa', label: 'CAA'},
                               {value: 'cert', label: 'CERT'},
                               {value: 'cname', label: 'CNAME'},
                               {value: 'hinfo', label: 'HINFO'},
                               {value: 'loc', label: 'LOC'},
                               {value: 'mx', label: 'MX'},
                               {value: 'naptr', label: 'NAPTR'},
                               {value: 'ns', label: 'NS'},
                               {value: 'openpgpkey', label: 'OPENPGPKEY'},
                               {value: 'rp', label: 'RP'},
                               {value: 'smimea', label: 'SMIMEA'},
                               {value: 'spf', label: 'SPF'},
                               {value: 'srv', label: 'SRV'},
                               {value: 'sshfp', label: 'SSHFP'},
                               {value: 'tlsa', label: 'TLSA'},
                               {value: 'txt', label: 'TXT'},
                               {value: 'uri', label: 'URI'},
                               //{value: 'x-http', label: 'X-HTTP'},
                               //{value: 'x-smtp', label: 'X-SMTP'},
                           ]}
                    />
                </td>
                <td key='resource_record_group_ttl' rowSpan={rowSpan}>
                    <Field name="ttl"
                           component={TextField}
                           size="sm"
                           type="text"
                           placeholder="1h"
                           validate={[
                               required({
                                   message: {
                                       id: 'form.errors.ttl.required',
                                       defaultMessage: "Please provide a TTL (Time to live) for this record"
                                   }
                               }),
                               ttl({
                                   message: {
                                       id: 'form.errors.dns.invalid_ttl',
                                       defaultMessage: "The TTL for a record needs to be between 300 seconds (5m) and 86400 seconds (1d)"
                                   }
                               })
                           ]}
                    />
                </td>
            </React.Fragment>
        }

        let save = <Button bsStyle="primary"
                           onClick={this.props.handleSubmit(validateAndUpdateResourceRecordSet)}
                           disabled={this.props.saving == this.props._key}>
            {(() => {
                if (this.props.saving == this.props._key) {
                    return (this.props.new ? 'Adding...' : 'Saving...');
                }
                else {
                    return (this.props.new ? 'Add' : 'Save');
                }
            })()}
        </Button>;

        return <FieldArray name="rdata"
                           component={this.renderRecords}
                           props={{groupFields: groupFields, selectLocation: this.selectLocation}}
                           save={save}
                           recordType={this.props.type}
                           showCancel={this.props.new == false}
                           _key={this.props._key}
                           getPlaceholder={this.getPlaceholder}
                           cancelEditRecord={this.props.cancelEditRecord}
                           allResourceRecords={this.props.allResourceRecords}
                           zone={this.props.zone}
                           saving={this.props.saving}
                           change={this.props.change}
        />
    }

    static propTypes = {
        // state data
        _key: PropTypes.string.isRequired,
        allResourceRecords: PropTypes.array.isRequired,
        zone: PropTypes.string.isRequired
    }

    static defaultProps = {
        new: false
    }
}

ResourceRecordSetForm = reduxForm({
    validate: validateResourceRecordSet,
    onSubmit: validateAndUpdateResourceRecordSet
})(ResourceRecordSetForm)

const _formValueSelector = formValueSelector;

export default connect((state, props) => {

    const type = _formValueSelector(props.form)(state, 'type');
    const rdata = _formValueSelector(props.form)(state, 'rdata');

    return {
        type,
        rdata
    }
})(ResourceRecordSetForm);
