import paginate from "./decorators/paginate";

const invoiceReducer = (state = {
    entities: {},
    dictionary: [],
    isFetching: false,
    isFetched: false
}, action) => {
    switch(action.type) {
        case "INVOICE_CHARGE_PENDING":
            return {
                ...state,
                entities: {
                    [action.meta.id]: {
                        ...state.entities[action.meta.id],
                        charging: true
                    }
                }
            };
        case "INVOICE_CHARGE_REJECTED":
            return {
                ...state,
                entities: {
                    [action.meta.id]: {
                        ...state.entities[action.meta.id],
                        charging: false
                    }
                }
            };
        case "INVOICE_CHARGE_FULFILLED":
            return {
                ...state,
                entities: {
                    [action.meta.id]: {
                        ...state.entities[action.meta.id],
                        charging: false,
                        paid: true
                    }
                }
            };
        default:
            return state;
    }
};

const paginateInvoices = paginate(invoiceReducer, {
    GOTO_PAGE: 'INVOICELIST_GOTO_PAGE',
    NEXT_PAGE: 'NEXT_INVOICE_PAGE',
    PREV_PAGE: 'PREV_INVOICE_PAGE',
    FILTER: 'INVOICELIST_FILTER',
    SORT: 'INVOICELIST_SORT',
    SET_ITEMS_PER_PAGE: 'INVOICELIST_SET_ITEMS_PER_PAGE'
}, {
    defaultSortBy: 'issue_date',
    defaultSortOrder: 'desc',
});

export default paginateInvoices;
