import React from 'react';
import {Field} from 'redux-form';

const RadioField = ({input, label, options, help, meta: {touched, error, invalid, warning}, changeHandler, ...props}) => {

    let fieldProps = {}
    if(props.disabled) {
        fieldProps.disabled = props.disabled;
    }

    if (input && options) {
        const renderRadioButtons = (key, index) => {
            return (
                <div key={index} className={`form-group ${touched && invalid ? 'has-error' : ''}`}>
                    <div className="radio">
                        <label htmlFor={`${input.name}-${index}`}>
                            <Field
                                id={`${input.name}-${index}`}
                                component="input"
                                name={input.name}
                                type="radio"
                                value={key}
                                {...fieldProps}
                                onChange={(e) => {
                                    if (changeHandler) {
                                        changeHandler(e.target.value)
                                    }

                                    input.onChange(e);
                                }}
                            />
                            {options[key]}
                        </label>
                    </div>
                </div>
            )
        };

        return (<React.Fragment>
            <div className="form-group has-error">
                <div className="help-block">
                    {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
                </div>
            </div>
            {Object.keys(options).map(renderRadioButtons)}
        </React.Fragment>)

    } else {
        return <div></div>
    }
}

export default RadioField;
