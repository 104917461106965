import React from "react";
import PropTypes from 'prop-types';
import {Alert, Button} from "react-bootstrap";

class CustomNameserverWarning extends React.Component {

    constructor(...props) {
        super(...props);

        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);

        this.state = {
            alertVisible: true
        };
    }

    handleAlertDismiss() {
        this.setState({alertVisible: false});
    }

    render() {
        if (this.state.alertVisible && this.props.show == true) {
            return (
                <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                    <h4>It looks like you've changed your name servers.</h4>
                    <p>
                        {this.props.text}
                    </p>
                    {this.props.restoreNameserver &&
                        <p>
                            <Button bsStyle="danger" onClick={this.props.restoreNameservers}
                                    disabled={this.props.submitting}>
                                Restore UD Nameserver
                            </Button>
                        </p>
                    }
                </Alert>
            );
        }

        return null;
    }

    static propTypes = {
        show: PropTypes.bool.isRequired
    }

    static defaultProps = {
        text: "It looks like you're using custom name servers. " +
            "Since you aren't using United Domains' name servers, your domain's DNS settings cannot be " +
            "managed from here. You can manage your DNS records through the service that manages your " +
            "domain's name servers. To enable this domain's DNS management from this page, you must delegate " +
            "the domain to United Domains' name servers."
    }
}

export default CustomNameserverWarning
