import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {dnsActions, domainActions} from "../../actions";
import Link from "../Link";
import LoadingIndicator from "../../components/LoadingIndicator";
import NameserverConfigForm from "../../components/Portfolio/Form/NameserverConfigForm";
import CustomResourceRecords from "../../components/Portfolio/CustomResourceRecords";
import CustomNameserverWarning from "../../components/Portfolio/CustomNameserverWarning";
import SyntheticRecords from "../../components/Portfolio/SyntheticRecords";
import GlueRecords from "../../components/Portfolio/GlueRecords";

class ManageDNSPage extends React.Component {

    componentDidMount() {
        this.props.domainActions.fetchData();
        this.props.fetchData(this.props.match.params.domain)
    }

    render() {
        return (
            <div className="panel panel-default" id="portfolio-config-dns">
                <div className="panel-body">
                    <Link to={"/portfolio"}
                          className="btn btn-default btn-xs">
                        back
                    </Link>
                    <div className="row clearfix">
                        <div className="col-xs-12">
                            <h1>Manage DNS / NS for {this.props.match.params.domain}</h1>
                        </div>
                    </div>
                    {(() => {
                        if (!(
                                this.props.domains.isFetched &&
                                this.props.zones.entities.hasOwnProperty(this.props.match.params.domain + '.')
                            )) {
                            return (<div className="col-xs-12"><LoadingIndicator active={true}/></div>);
                        } else {
                            const domain = this.props.domains.entities[this.props.match.params.domain];
                            const zone = this.props.zones.entities[domain.domain + '.'];

                            return (
                                <React.Fragment>
                                    <h3>Name Servers</h3>
                                    <NameserverConfigForm initialValues={domain}/>

                                    <br/>
                                    <CustomNameserverWarning show={domain.nameserverSet != 'uniteddomains'}
                                                             restoreNameservers={() => {
                                                                 this.props.domainActions.restoreNameservers()
                                                             }}
                                                             submitting={this.props.nameserverConfigFormSubmitting}
                                    />
                                    <br/>
                                    {
                                        (domain.nameserverSet == 'uniteddomains' && (
                                            <React.Fragment>
                                                <h3>Glue Records</h3>
                                                Add IP addresses of public hosts on your domain so they can be found
                                                without first resolving your domain in the DNS.
                                                <GlueRecords domain={domain}
                                                             zone={zone}
                                                             deleteGlueRecord={this.props.deleteGlueRecord}
                                                             editGlueRecord={this.props.editGlueRecord}
                                                             cancelEditGlueRecord={this.props.cancelEditGlueRecord}
                                                />

                                                <br/>
                                                <h3>Domain Forwarding</h3>
                                                Forward your domain name to another URL.
                                                <SyntheticRecords domain={domain}
                                                                  zone={zone}
                                                                  deleteResourceRecord={this.props.deleteResourceRecord}
                                                                  editResourceRecord={this.props.editResourceRecord}
                                                                  cancelEditResourceRecord={this.props.cancelEditResourceRecord}
                                                />
                                                <br/>
                                                <CustomResourceRecords domain={domain}
                                                                       zone={zone}
                                                                       sortResourceRecords={this.props.sortResourceRecords}
                                                                       deleteResourceRecord={this.props.deleteResourceRecord}
                                                                       editResourceRecord={this.props.editResourceRecord}
                                                                       cancelEditResourceRecord={this.props.cancelEditResourceRecord}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </React.Fragment>
                            )
                        }
                    })()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        zones: state.dnsZones,
        domains: state.domains,
        nameserverConfigFormSubmitting: state.form['NameserverConfigForm'] && state.form.NameserverConfigForm['submitting'] ? state.form.NameserverConfigForm.submitting : false
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(dnsActions, dispatch),
    domainActions: bindActionCreators(domainActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDNSPage)
