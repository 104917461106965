
export default function (arr, ignoreEmptyItems = true) {
    var duplicates = {};
    let duplicateIndizies = [];
    for (var i = 0; i < arr.length; i++) {
        if(arr[i] == null || arr[i] == undefined || arr[i] == "") {
            continue;
        }
        if(duplicates.hasOwnProperty(arr[i])) {
            duplicates[arr[i]].push(i);
            duplicateIndizies.push(i);
        } else if (arr.lastIndexOf(arr[i]) !== i) {
            duplicates[arr[i]] = [i];
        }
    }

    return duplicateIndizies;
};