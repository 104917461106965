import React, {Component} from "react";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Panel} from "react-bootstrap";
import TextField from "../../Form/TextField";
import {accountActions} from '../../../actions';
import {addValidator, email, format, length, required} from 'redux-form-validators'
import {asYouType, isValidNumber} from 'libphonenumber-js'

const { getCodeList } = require('country-list');

const phone = addValidator({
    defaultMessage: {
        id: 'form.errors.phone_invalid',
        defaultMessage: 'This value is not a valid phone number'
    },
    validator: (options, value, allValues) => {
        if (value.trim() == "" || value == null || value == "null") {
            return true;
        }

        return isValidNumber(value);
    }
});

const validate = (values) => {
    let errors = {};

    if (
        values.organization == undefined ||
        values.organization == null ||
        values.organization == "") {
        if (
            values.vatId != undefined &&
            values.vatId != null &&
            values.vatId != ""
        ) {
            errors.vatId = "The VAT identification number can only be entered for organizations"
        }
    }

    return errors;
}

const validateAndUpdateAccountData = (values, dispatch) => {

    for (let i in values) {
        if (values[i] == null) {
            delete values[i];
        }
    }

    return dispatch(accountActions.updateAccount(values)).payload.then(result => {
        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            dispatch(accountActions.saveAccountSuccess(values)); //ps: this is same as dispatching RESET_USER_FIELDS
        } else {
            let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }

            dispatch(accountActions.saveAccountFailure(values, errors));
            throw new SubmissionError(errors);
        }
    });
}

class AccountForm extends Component {
    render() {
        //{this.renderError(newPost)}

        const {handleSubmit, submitting, pristine, reset} = this.props;
        return (
            <form onSubmit={handleSubmit(validateAndUpdateAccountData)}>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <Panel header="Address">
                            <div className="form-group">
                                <label className="control-label">Customer Number</label>
                                <input type="text" className="form-control" disabled={true} readOnly={true}
                                       value={this.props.initialValues.id}/>
                            </div>
                            <div className="form-group ">
                                <label className="control-label required">
                                    Title
                                </label>
                                &nbsp;
                                <label className="required radio-inline">
                                    <Field name="salutation" value="Mr" type="radio" component="input"/>Mr.
                                </label>
                                <label className="required radio-inline">
                                    <Field name="salutation" value="Ms" type="radio" component="input"/>Ms.
                                </label>
                                <div className="help-block"></div>
                            </div>
                            <Field name="username"
                                   type="email"
                                   component={TextField}
                                   label="Email *"
                                   validate={[required({
                                       message: {
                                           id: 'form.errors.email.required',
                                           defaultMessage: "Please provide a valid email address"
                                       }
                                   }), email({
                                       message: {
                                           id: 'form.errors.email.invalid',
                                           defaultMessage: "Please enter a valid email address."
                                       }
                                   })]}
                            />
                            <Field name="organization" component={TextField} label="Organization / Company"/>
                            <Field name="vatId" component={TextField} label="VAT identification number"/>
                            <Field name="firstname"
                                   component={TextField}
                                   label="First Name *"
                                   validate={required({
                                       message: {
                                           id: 'form.errors.firstname.required',
                                           defaultMessage: "Please provide your first name"
                                       }
                                   })}
                            />
                            <Field name="lastname"
                                   component={TextField}
                                   label="Last Name *"
                                   validate={required({
                                       message: {
                                           id: 'form.errors.lastname.required',
                                           defaultMessage: "Please provide your last name"
                                       }
                                   })}
                            />
                            <Field name="phone"
                                   component={TextField}
                                   label="Phone (eg. +1 234 567 8910) *"
                                   format={(value, name) => {
                                       return new asYouType().input(value)
                                   }}
                                   validate={[required({
                                       message: {
                                           id: 'form.errors.phone.required',
                                           defaultMessage: 'Please provide your phone-number'
                                       }
                                   }), phone()]}
                            />
                            <Field name="fax"
                                   component={TextField}
                                   required={false}
                                   label="Fax (eg. +1 234 567 8911) *"
                                   format={(value, name) => {
                                       return new asYouType().input(value)
                                   }}
                                   validate={phone()}
                            />
                        </Panel>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <Panel header="Address">
                            <Field name="address1"
                                   component={TextField}
                                   label="Street Address (No P.O. Box) *"
                                   validate={[
                                       required({
                                           message: {
                                               id: 'form.errors.street.required',
                                               defaultMessage: "Please provider your address"
                                           }
                                       }),
                                       length({
                                           min: 2,
                                           max: 128,
                                           message: {
                                               id: 'form.errors.street.too_long',
                                               defaultMessage: "Street is too long"
                                           }
                                       }),
                                       format({
                                           withOut: /^([a-zA-Z0-9\., -]+)$/u,
                                           message: {
                                               id: 'form.errors.street.invalid_chars',
                                               defaultMessage: "Street contains invalid characters. Please use only letters (A-Z), numbers, (0-9), periods, (.) and commas (,)."
                                           }
                                       }),
                                       format({
                                           withOut: /^(Floor|floor|Apartment|apartment|Appartment|appartment|Suite|suite|Apt|apt|Fl|fl)/,
                                           message: {
                                               id: 'form.errors.street.invalid_terms',
                                               defaultMessage: "Street contains invalid terms (e.g. “suite”, “apt”, “floor”). Please enter terms in Address 2"
                                           }
                                       })
                                   ]}
                            />
                            <Field name="address2" component={TextField}
                                   label="Address 2 (Suite, Apt #, etc.)"
                                   validate={[length({
                                       max: 128,
                                       allowBlank: true,
                                       message: {
                                           id: 'form.errors.street2.too_long',
                                           defaultMessage: "Address 2 is too long"
                                       }
                                   })]}
                            />
                            <Field name="city"
                                   component={TextField} label="City *"
                                   validate={[
                                       required({message: "Please provider your city"}),
                                       length({min: 3, max: 64, message: "City is too short or too long"}),
                                       format({
                                           withOut: /^([a-zA-Z\., ]+)$/u,
                                           message: {
                                               id: 'form.errors.city.invalid_chars',
                                               defaultMessage: "City contains invalid characters"
                                           }
                                       })
                                   ]}
                            />
                            <Field name="zip"
                                   component={TextField}
                                   label="ZIP Code *"
                                   validate={[required(), length({
                                       min: 1,
                                       max: 16,
                                       message: {
                                           id: 'form.errors.zip.invalid',
                                           defaultMessage: "Please provide a valid ZIP code"
                                       }
                                   })]}
                            />
                            <Field name="country"
                                   component={TextField}
                                   label="Country *"
                                   type="select"
                                   validate={[required({
                                       message: {
                                           id: 'form.errors.country.required',
                                           defaultMessage: "Please provider your country"
                                       }
                                   })]}
                            >
                                {(() => {
                                    let countries = getCodeList();

                                    var cs = Object.keys(countries).filter((c) => {
                                        return c != 'us' && c != 'ca' && c != 'un'
                                    });
                                    cs.unshift('ca');
                                    cs.unshift('us');

                                    var ret = cs.map((c) => {
                                        return <option value={c.toUpperCase()} key={c}>{countries[c]}</option>;
                                    });

                                    ret.unshift(<option value="" key='empty'>-- select country --</option>)

                                    return ret;
                                })()}
                            </Field>
                        </Panel>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Button type="submit"
                                bsStyle="primary"
                                className="pull-right"
                                disabled={submitting}>
                            {submitting ? 'Saving...' : 'Save all'}
                        </Button>
                        {' '}
                        <Button type="button"
                                bsStyle="default"
                                className="pull-left"
                                disabled={pristine || submitting}
                                onClick={reset}>
                            Reset
                        </Button>
                    </div>
                </div>
            </form>
        )
    }
}


export default reduxForm({
    form: 'AccountForm', // a unique identifier for this form
    validate, //<--- validation function given to redux-form
    //asyncValidate*/
})(AccountForm)
