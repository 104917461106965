import React from "react";
import PropTypes from 'prop-types';
import {Modal, Button} from "react-bootstrap";
import dateformat from "dateformat";

/**
 * Props:
 *  -> OK-Text
 *  -> Cancel-Text
 *  -> onConfirm
 *  -> onCancel
 */
class ConfirmDomainExpirationModal extends React.Component {
    render() {
        let {domain, onConfirm, onCancel} = this.props

        if (domain == null || domain.status != 'autorenew') {
            return null;
        }

        return (
            <Modal show={true} onHide={() => onCancel()}>
                <Modal.Header closeButton>
                    <Modal.Title>Domain {domain.domain} will expire
                        on {dateformat(domain.expirationdate, "mmmm dS, yyyy")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deactivating auto-renew will cause {domain.domain} to expire on Jul 5, 2017, unless you either
                    manually renew this domain or reactivate auto-renew beforehand. Upon expiring, the domain will be
                    scheduled for deletion and disconnected from all services related to it. Are you sure you wish to
                    proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                    <Button bsStyle="primary"
                            onClick={() => onConfirm()}
                            disabled={domain.savingStatus}>
                        {domain.savingStatus ? 'Saving...' : 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ConfirmDomainExpirationModal.propTypes = {
    // actions
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ConfirmDomainExpirationModal