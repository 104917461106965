import React, {Component} from "react";
import {OverlayTrigger, Popover, Table} from "react-bootstrap";
import {spring, TransitionMotion} from "react-motion";
import MaterialIcon from "../MaterialIcon";
import RedirectResourceRecord from "./RedirectResourceRecord";
import RedirectResourceRecordForm from "./Form/RedirectResourceRecordForm";
import {toastr} from "react-redux-toastr";
import {confirm} from "../../util/confirm";


/**
 * Manage URL Forwarding for glckodex.com
 * Note: saving a Forward from this page will cause any existing A, CNAME, or Forwards on your domain to be deleted.
 * You can't configure Web redirect when you use own A or CNAME record. Change DNS / NS
 *
 * o Forward to our default page (preview)
 * o Forward glckodex.com to (preview)
 *      FRAME or 301 to => http://...
 *
 * Check, if we can use other redirect-code(s) (302)
 * Check, if we can redirect sub-domain
 * Do we want DynDNS?
 *
 */
class SyntheticRecords extends Component {


    constructor(props) {
        super(props);

        this.editRecord = this.editRecord.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
        this.cancelEditRecord = this.cancelEditRecord.bind(this);
    }

    // actual animation-related logic
    getDefaultStyles = () => {
        return this.props.zone.resourceRecords.filter((resourceRecord) => {
            return SyntheticRecords.typesToShow[resourceRecord.type] == true;
        }).map((resourceRecord, index) => ({
            data: resourceRecord,
            key: resourceRecord.key,
            style: {
                height: 40,
                opacity: 1,
                fontSize: 14,
                paddingTop: 8,
                paddingBottom: 8
            }
        }));
    };

    getStyles = () => {
        return this.props.zone.resourceRecords.filter((resourceRecord) => {
            return SyntheticRecords.typesToShow[resourceRecord.type] == true;
        }).map((resourceRecord, index) => {
            return {
                data: resourceRecord,
                key: resourceRecord.key,
                style: {
                    height: spring(40, {stiffness: 90, damping: 14}),
                    opacity: spring(1, {stiffness: 90, damping: 14}),
                    fontSize: spring(14, {stiffness: 90, damping: 14}),
                    paddingTop: spring(8, {stiffness: 90, damping: 14}),
                    paddingBottom: spring(8, {stiffness: 90, damping: 14}),
                }
            };
        });
    };

    deleteRecord(name, type, key) {
        confirm('All resource records in the set will be deleted. This action cannot be undone.', {
            'title': 'Delete resource records?'
        }).then(() => {
            return this.props.deleteResourceRecord(this.props.domain.domain + '.', name, type, key);
        }).then(({value, action}) => {
            if (value.data && value.data.success && value.data.success == true) {
                toastr.success("DNS RecordsSet deleted successfully!");
            }
        }).catch(error => {
            // confirmation denied
        })
    }

    editRecord(key) {
        this.props.editResourceRecord(this.props.domain.domain + ".", key);
    }

    cancelEditRecord() {
        this.props.cancelEditResourceRecord(this.props.domain.domain + ".");
    }

    willLeave() {
        return {
            height: spring(0, {stiffness: 90, damping: 14}),
            opacity: spring(0, {stiffness: 90, damping: 14}),
            fontSize: spring(0, {stiffness: 90, damping: 14}),
            paddingTop: spring(0, {stiffness: 90, damping: 14}),
            paddingBottom: spring(0, {stiffness: 90, damping: 14}),
        };
    };

    willEnter() {
        return {
            height: 0,
            opacity: 0,
            fontSize: 0,
            paddingTop: 0,
            paddingBottom: 0,
        };
    };

    render() {
        const {orderBy, order, edit} = this.props.zone;

        const subDomainHelpPopover = (
            <Popover id="rr-subdomain-help">
                The Subdomain you want to forward
            </Popover>
        );

        const typeHelpPopover = (
            <Popover id="rr-redirect-type-help">
                Choose REDIRECT to redirect the subdomain to your target. Using FRAME would result in opening the
                target,
                but still showing your subdomain in the browsers address line
            </Popover>
        );

        const targetHelpPopover = (
            <Popover id="rr-target-help">
                The target to redirect to
            </Popover>
        );

        return (
            <React.Fragment>
                <Table id="redirect-resource-records">
                    <thead>
                    <RedirectResourceRecordForm new={true}
                                                _key="_new"
                                                initialValues={{
                                                    name: '',
                                                    redirectType: 'REDIRECT',
                                                    rdata: [''],
                                                    key: '_new'
                                                }}
                                                allResourceRecords={this.props.zone.resourceRecords}
                                                zone={this.props.domain.domain + '.'}
                                                form="NewRedirectResourceRecordForm"
                                                saving={this.props.zone.saving}
                    />
                    <tr>
                        <th>
                            Subdomain{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={subDomainHelpPopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th>
                            Redirect-Type{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={typeHelpPopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th>
                            Redirect to{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={targetHelpPopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <TransitionMotion
                        defaultStyles={this.getDefaultStyles()}
                        styles={this.getStyles()}
                        willLeave={this.willLeave}
                        willEnter={this.willEnter}
                    >
                        {styles =>
                            <tbody>
                            {(() => {
                                if (styles.length) {
                                    return styles.map((d, index) => {
                                        let className;

                                        if (d.style.height < 40) {
                                            className = "removing";
                                        }

                                        if (
                                            edit != null &&
                                            edit == d.data.key
                                        ) {
                                            // editing = true;
                                            let parts = d.data.rdata[0].split(' ');

                                            return <RedirectResourceRecordForm key={d.key}
                                                                               _key={d.key}
                                                                               initialValues={{
                                                                                   name: d.data.name,
                                                                                   redirectType: parts[0],
                                                                                   redirectTarget: parts[1],
                                                                               }}
                                                                               cancelEditRecord={this.cancelEditRecord}
                                                                               zone={this.props.domain.domain + '.'}
                                                                               allResourceRecords={this.props.zone.resourceRecords}
                                                                               form={"ResourceRecordSet_" + d.key}
                                                                               saving={this.props.zone.saving}
                                            />
                                        } else {
                                            let {paddingTop, paddingBottom, ...s} = d.style;

                                            return <RedirectResourceRecord key={d.key}
                                                                           _key={d.key}
                                                                           style={s}
                                                                           tdStyle={{
                                                                               paddingBottom: paddingBottom,
                                                                               paddingTop: paddingTop
                                                                           }}
                                                                           domain={this.props.domain.domain}
                                                                           resourceRecord={d.data}
                                                                           className={className}
                                                                           editRecord={this.editRecord}
                                                                           deleteRecord={this.deleteRecord}
                                            />
                                        }

                                    })
                                } else {
                                    return <tr>
                                        <td colSpan={5}>
                                            No Redirect-Records found
                                        </td>
                                    </tr>
                                }
                            })()}
                            </tbody>
                        }
                    </TransitionMotion>
                </Table>
            </React.Fragment>
        )
    }

    static typesToShow = {
        // we should probably move this to another section
        'x-http': true, // frame redirect
    };
}

export default SyntheticRecords
