import React, {Component, PropTypes} from "react";
import {reduxForm, Field, SubmissionError} from "redux-form";
import {Button} from "react-bootstrap";
import TextField from "../../Form/TextField";
import {accountActions} from '../../../actions';
import {required, email, length, format, confirmation, addValidator} from 'redux-form-validators'


//Client side validation
function validate(values) {
    const errors = {};

    if (values.currentPassword == undefined && (
        values['plain_password'] != undefined && (
            values.plain_password['first'] != undefined ||
            values.plain_password['second'] != undefined
        ))) {
        errors.currentPassword = 'To change your password please enter your current password.';
    }

    if (values.plain_password != undefined) {
        if (values.plain_password.first != undefined && values.plain_password.second != undefined) {
            if (values.plain_password.first != values.plain_password.second) {
                errors.plain_password = {
                    second: 'Passwords do not match'
                }
            }
        }
    }

    return errors;
}

const validateAndUpdateAccountData = (values, dispatch) => {

    return dispatch(accountActions.updateAccountPassword(values)).payload.then(result => {
        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            dispatch(accountActions.updateAccountPasswordSuccess()); //ps: this is same as dispatching RESET_USER_FIELDS
        } else {
            let errors = {};

            if(result.errors.children.currentPassword['errors'] != undefined) {
                errors['currentPassword'] = result.errors.children.currentPassword.errors.join(', ');
            }

            for (let field in result.errors.children.plainPassword) {
                if (result.errors.children.plainPassword && result.errors.children.plainPassword[field].errors) {
                    errors[field] = result.errors.children.plainPassword[field].errors.join(', ');
                }
            }

            dispatch(accountActions.saveAccountFailure(values, errors));
            throw new SubmissionError(errors);
        }
    });
}

class ChangePasswordForm extends Component {

    render() {
        const {handleSubmit, submitting, pristine, reset} = this.props;
        return (
            <form onSubmit={ handleSubmit(validateAndUpdateAccountData) }>
                <Field name="currentPassword"
                       component={ TextField }
                       type="password"
                       label="Current Password"
                       validate={length({
                           allowBlank: true,
                           min: 6,
                           message: {
                               id: 'form.errors.password.length',
                               defaultMessage: "Please enter your current password to change it"
                           }
                       })}
                />
                <Field name="plainPassword[first]"
                       component={ TextField }
                       type="password"
                       label="New Password"
                       validate={length({
                           allowBlank: true,
                           min: 6,
                           message: {
                               id: 'form.errors.password.length',
                               defaultMessage: "Password is too short"
                           }
                       })}
                />
                <Field name="plainPassword[second]"
                       component={ TextField }
                       type="password"
                       label="Confirm New Password"
                />
                <Button type="submit"
                        bsStyle="primary"
                        className="pull-right"
                        disabled={ submitting }>
                    {submitting ? 'Saving...' : 'Save'}
                </Button>
            </form>
        )
    }
}


export default reduxForm({
    form: 'ChangePasswordForm', // a unique identifier for this form
    validate, //<--- validation function given to redux-form
    //asyncValidate*/
})(ChangePasswordForm)