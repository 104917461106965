import {addValidator} from "redux-form-validators";
import {parse as parseLoc} from "../../../../util/parse/locRData"

/**
 * People appear to sometimes look only at STD 11, RFC 822 to determine
 * whether a particular hostname is correct or not. Hostnames should
 * strictly conform to the syntax given in STD 13, RFC 1034 (page 11),
 * with *addresses* in addition conforming to RFC 822. As an example
 * take "c&w.blues" which is perfectly legal according to RFC 822, but
 * which can have quite surprising effects on particular systems, e.g.,
 * "telnet c&w.blues" on a Unix system.
 */
export default addValidator({
    validator: (options, value, allValues) => {

        try {
            parseLoc(value);
        } catch(e) {
            return {
                id: "form.errors.dns.invalid_loc",
                defaultMessage: e.message
            }
        }

        return true;
    }
});
