import {addValidator} from "redux-form-validators";
import timestring from "../../../../util/timestring"

/**
 * https://www.ietf.org/rfc/rfc1035.txt
 * positive values of a signed 32 bit number (2,147,483,647)
 */
export default addValidator({
    defaultMessage: {
        id: "form.errors.dns.invalid_ttl",
        defaultMessage: "Invalid TTL"
    },
    validator: function (options, value, allValues) {
        try {
            let seconds;
            if(/^\d+$/.test(value)) {
                seconds = parseInt(value);
            } else {
                seconds = timestring(value);
            }

            // theoretical maximum of 2147483647 seconds, we us a day here
            if(seconds >= 300 && seconds <= 60 * 60 * 24) {
                return true;
            } else {
                return {
                    id: "form.errors.dns.invalid_ttl_format",
                    defaultMessage: "The TTL for a record needs to be between 300 seconds (5m) and 86400 seconds (1d)"
                }
            }
        } catch(e) {
            return {
                id: "form.errors.dns.invalid_ttl_format",
                defaultMessage: "please provide a correct TTL (e.g. 6h)",
            }
        }
    }
})
