import React from "react";
import {connect} from 'react-redux'
import {Field, formValueSelector, reduxForm, SubmissionError} from "redux-form";
import {Modal, Panel} from "react-bootstrap";
import creditcards from "creditcards";
import {addValidator, length, required} from 'redux-form-validators';
import TextField from './TextField';

const {getNames, getName, getCodeList} = require('country-list');

function validate(values) {
    const errors = {};

    if (creditcards.expiration.isPast(parseInt(values.expiration_month), 2000 + parseInt(values.expiration_year))) {
        errors.expiration_year = 'The expiration-date has to be in the future';
    }

    return errors;
}

const ccValidator = addValidator({
    defaultMessage: "Please enter a valid credit-card number",
    validator: function (options, value, allValues) {
        if (creditcards.card.isValid(value)) {
            let type = creditcards.card.type(value).toLowerCase();
            return type == 'visa' || type == 'mastercard' || type == 'american express';
        }

        return false
    }
})

const cvcValidator = addValidator({
    defaultMessage: "Please enter the valid CVC-Code from your CreditCard",
    validator: function (options, value, allValues) {
        if (creditcards.card.isValid(allValues.cc_number)) {
            if (creditcards.cvc.isValid(value, creditcards.card.type(allValues.cc_number))) {
                return true;
            } else {
                return false;
            }
        }
    }
})

const countryValidator = addValidator({
    defaultMessage: "Please select your Country",
    validator: function (options, value, allValues) {
        if (getName(value)) {
            return true;
        }

        return false;
    }
})

class PaymentDataForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: props.initialValues.type,
            editingAddress: false,
            showModal: false,
            checking: false,
            showIndicator: true,
        };

        this.savePaymentData = this.savePaymentData.bind(this);
        this.form = React.createRef();
        this.iframe = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.showModal == true) {
            if (prevProps.checking == true && this.props.checking == false) {
                this.setState((_prevState, props) => {
                    return {
                        ..._prevState,
                        showModal: false
                    }
                });
            }
        }
    }

    handleTabSelect(type) {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                type: type
            }
        });
    }

    editAddress(type) {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                editingAddress: true
            }
        });
    }

    savePaymentData(values, dispatch, props) {
        /**
         * Ablauf:
         * - Submit-Button is clicked
         * - Modal shown
         * - 1000ms => submit form to iframe
         * - promise resolves when
         *   - checking returns data
         *   or
         *   - iframe onLoad shows that our callback site is loaded
         * - hide iFrame
         * - form submit is completed
         * - show success or failure message to the user
         *
         * @todo: We may need to move the checking-calls from redux to this component
         *        so we can hide the modal when the callback was successful
         */

        let valuesToSubmit = {
            ...values,
            ...this.props.hiddenData
        }

        return new Promise((resolve, reject) => {
            this.iframe.current.addEventListener('load', (e) => {
                if (this.state.showIndicator == true) {
                    this.setState((prevState, props) => {
                        return {
                            ...prevState,
                            showIndicator: false
                        }
                    });
                }

                try {
                    let query = new URLSearchParams(this.iframe.current.contentWindow.location.search);
                    // check if iframe displays the success page or the failed page

                    let errorCode = query.get('ret_errorcode');
                    let coreErrorCode = query.get('core_errorcode')

                    if (errorCode > 0 || coreErrorCode > 0) {
                        throw new SubmissionError({
                            cc_number: query.get('ret_errormsg') + " " + query.get('ret_additionalmsg'),
                        });
                    } else {
                        this.props.startChecking(this.props.hiddenData['ud_transaction_id']);
                        resolve();
                    }
                } catch (e) {
                    if (e instanceof SubmissionError) {
                        reject(e);
                    }
                    this.props.startChecking(this.props.hiddenData['ud_transaction_id']);
                }
            });
        });
    }

    checkDialog() {
        return (
            <Modal backdrop="static" keyboard={false}
                   show={this.state.showModal}
                   id="ccUpdateModal"
            >
                <Modal.Header>
                    <Modal.Title>Validating Credit Card data</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <div id="cc_iframe_container">
                            <iframe name="cc_iframe" ref={this.iframe} id="cc_iframe"/>
                            {this.state.showIndicator &&
                            <div className="ui medium text active loader loading">
                                We are validating your credit-card data. This may take up to 1 minute.
                                Please wait...
                            </div>
                            }
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {(() => {
                        if (this.props.checking == true || this.props.success == null) {
                            return <button className="btn btn-primary success pull-right" disabled={true}>
                                Checking...</button>
                        } else {
                            if (this.props.pf == true) {
                                return <a onClick={(e) => {
                                    e.preventDefault();
                                    this.showModal(false)
                                }}
                                          className="btn btn-primary success pull-right">Close</a>
                            } else {
                                return <a href={Routing.generate('cart_index')}
                                          className="btn btn-primary success pull-right">Continue</a>
                            }
                        }
                    })()}
                </Modal.Footer>
            </Modal>
        )
    }

    getAddressForm() {
        return (
            <div>
                <Field type="text"
                       label="Name"
                       name="addr_name"
                       required="required"
                       component={TextField}
                       validate={
                           length({
                               min: 4,
                               message: {
                                   id: "form.errors.lessThan",
                                   defaultMessage: "Please enter the Name",
                               }
                           })
                       }
                />
                <Field type="text"
                       label="Street Address"
                       name="addr_street"
                       required="required"
                       component={TextField}
                       validate={
                           length({
                               min: 4, message: {
                                   id: "form.errors.lessThan",
                                   defaultMessage: "Please enter the address of the credit-card owner",
                               }
                           })
                       }

                />
                <Field type="text"
                       label="Apt.,  Suite, Bldg. (optional)"
                       name="addr_street2"
                       component={TextField}/>
                <Field type="text"
                       label="City"
                       name="addr_city"
                       required="required"
                       component={TextField}
                       validate={
                           length({
                               min: 3, message: {
                                   id: "form.errors.lessThan",
                                   defaultMessage: "The enter your city",
                               }
                           })
                       }/>
                <Field type="text"
                       label="Zip Code"
                       name="addr_zip"
                       required="required"
                       component={TextField}
                       validate={
                           length({
                               min: 2, message: {
                                   id: "form.errors.lessThan",
                                   defaultMessage: "Please enter a correct zip",
                               }
                           })
                       }/>
                <Field type="select"
                       name="addr_country"
                       required="required"
                       component={TextField}
                       label="Country"
                       validate={[
                           required(),
                           countryValidator()
                       ]}>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    {(() => {
                        let ccs = getCodeList()

                        return Object.keys(ccs).map((c) => {
                            if (c != 'us' && c != 'ca') {
                                return <option value={c.toUpperCase()} key={c}>{ccs[c]}</option>
                            }
                        });
                    })()}
                </Field>
            </div>);
    }

    getHiddenAddressForm() {
        return [
            <input type="hidden" key="addr_name" name="addr_name" defaultValue={this.props.addrProps.addr_name}/>,
            <input type="hidden" key="addr_street" name="addr_street" defaultValue={this.props.addrProps.addr_street}/>,
            <input type="hidden" key="addr_street2" name="addr_street2"
                   defaultValue={this.props.addrProps.addr_street2}/>,
            <input type="hidden" key="addr_city" name="addr_city" defaultValue={this.props.addrProps.addr_city}/>,
            <input type="hidden" key="addr_zip" name="addr_zip" defaultValue={this.props.addrProps.addr_zip}/>,
            <input type="hidden" key="addr_country" name="addr_country"
                   defaultValue={this.props.addrProps.addr_country}/>,
        ]
    }

    showModal(show, e) {
        if (this.state.showModal == show) {
            return;
        }
        if (this.props.invalid == true) {
            return;
        }
        this.setState((prevState, props) => {
            return {
                ...prevState,
                showModal: show,
                showIndicator: true
            }
        }, () => {
            if (this.state.showModal == true) {
                setTimeout(() => {
                    if (!this.props.invalid) {
                        this.form.current.submit()
                        this.form.current.dispatchEvent(new Event('submit'));
                    }
                }, 500)
            }
        });
    }

    render() {
        const {submitting, pristine, reset, handleSubmit} = this.props;

        const CreditCardHeader = (selectHandler) => {

            let classNames = "payment-icon ";

            if (this.state.type == 'paypal') {
                classNames += " inactive "
            }

            return (
                <div>
                    <strong>Credit Card{' '}</strong>
                    <span className="hidden-md">{(() => {
                        if (this.props.shortCcNumber != null &&
                            this.props.shortCcNumber != "") {
                            return " ending in " + this.props.shortCcNumber
                        }
                    })()}</span>
                </div>
            )
        };

        return (
            <div>
                <form id="paymentform"
                      method="post"
                      action={this.props.url}
                      onSubmit={handleSubmit(this.savePaymentData)}
                      target="cc_iframe"
                      ref={this.form}
                >
                    <div className="row">
                        <div className="col-sm-12 col-md-6 payment-panels">
                            <Panel header={CreditCardHeader(this.handleTabSelect.bind(this))}
                                   className="payment-type-panel">
                                <div>
                                    <Field type="text"
                                           name="cc_number"
                                           inputClassName={(value) => {
                                               if (creditcards.card.isValid(value)) {
                                                   return "form-control cc_number " + creditcards.card.type(value).replace(/\s/g, '').toLowerCase()
                                               }
                                               return "form-control cc_number";
                                           }}
                                           className={"sca-logos"}
                                           required={true}
                                           label="Card Number"
                                           labelClasses="control-label cc_label"
                                           component={TextField}
                                           placeholder="Please enter your card number"
                                           validate={ccValidator()}
                                    />
                                    <div className="row">
                                        <div className="col-xs-4">
                                            <Field type="text"
                                                   label="Security Code"
                                                   name="cc_checkcode"
                                                   required={true}
                                                   component={TextField}
                                                   validate={cvcValidator()}/>
                                        </div>
                                        <div className="col-xs-4">
                                            <Field
                                                name="cc_expdate_month"
                                                type="select"
                                                component={TextField}
                                                label="Expiration Month">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </Field>
                                        </div>
                                        <div className="col-xs-4">
                                            <Field
                                                name="cc_expdate_year"
                                                type="select"
                                                component={TextField}
                                                label="Expiration Year">
                                                {(() => {
                                                    let years = [];

                                                    let d = new Date();
                                                    let y = d.getFullYear();

                                                    for (let i = 0; i <= 10; i++) {
                                                        years.push(<option key={y + i}
                                                                           value={y + i - 2000}>{y + i}</option>)
                                                    }

                                                    return years;
                                                })()}
                                            </Field>
                                        </div>
                                    </div>
                                    <Field type="text"
                                           label="Name on the Card"
                                           name="addr_name"
                                           required="required"
                                           component={TextField}
                                           validate={
                                               length({
                                                   min: 4,
                                                   message: {
                                                       id: "form.errors.lessThan",
                                                       defaultMessage: "Please enter the Name of the credit-card owner",
                                                   }
                                               })
                                           }
                                    />
                                </div>
                            </Panel>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <Panel header="Billing Address">
                                {(() => {
                                    if (this.state.editingAddress == true) {
                                        return this.getAddressForm();
                                    } else {
                                        return (<div>
                                            {this.props.addrProps.addr_name}<br/>
                                            United Domains, Inc<br/>
                                            <br/>
                                            {this.props.addrProps.addr_street}<br/>
                                            {this.props.addrProps.addr_street2}<br/>
                                            <br/>
                                            {this.props.addrProps.addr_zip} {this.props.addrProps.addr_city}<br/>
                                            <br/>
                                            {this.props.addrProps.addr_country}<br/>
                                            <br/>
                                            {this.getHiddenAddressForm()}
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                this.editAddress()
                                            }}
                                               className="btn btn-xs btn-default"
                                            >
                                                edit
                                            </a>

                                        </div>)
                                    }
                                })()}
                            </Panel>
                        </div>
                    </div>
                    {(() => {
                        let hiddenFields = [];
                        for (let k in this.props.hiddenData) {
                            hiddenFields.push(<input type="hidden" key={k} name={k} value={this.props.hiddenData[k]}/>);
                        }

                        hiddenFields.push(<input type="hidden" key="app-name" name="threeDSec_browserAppName" value={navigator.appName} />);
                        hiddenFields.push(<input type="hidden" key="app-code-name" name="threeDSec_browserAppCodeName" value={navigator.appCodeName} />);
                        hiddenFields.push(<input type="hidden" key="app-version" name="threeDSec_browserAppVersion" value={navigator.appVersion} />);
                        hiddenFields.push(<input type="hidden" key="browser-user-agent" name="threeDSec_browserUserAgent" value={navigator.userAgent} />);
                        hiddenFields.push(<input type="hidden" key="browser-platform" name="threeDSec_browserPlatform" value={navigator.platform} />);
                        hiddenFields.push(<input type="hidden" key="browser-js-enabled" name="threeDSec_browserJavaScriptEnabled" value="true" />);
                        hiddenFields.push(<input type="hidden" key="browser-j-enabled" name="threeDSec_browserJavaEnabled" value={navigator.javaEnabled()} />);
                        hiddenFields.push(<input type="hidden" key="browser-color-depth" name="threeDSec_browserColorDepth" value={screen.colorDepth} />);
                        hiddenFields.push(<input type="hidden" key="browser-screen-height" name="threeDSec_browserScreenHeight" value={screen.height} />);
                        hiddenFields.push(<input type="hidden" key="browser-screen-width" name="threeDSec_browserScreenWidth" value={screen.width} />);
                        hiddenFields.push(<input type="hidden" key="screen-avail-heigth" name="threeDSec_screenAvailHeight" value={screen.availHeight} />);
                        hiddenFields.push(<input type="hidden" key="screen-avail-width" name="threeDSec_screenAvailWidth" value={screen.availWidth} />);
                        hiddenFields.push(<input type="hidden" key="browser-language" name="threeDSec_browserLanguage" value={window.navigator.language} />);
                        hiddenFields.push(<input type="hidden" key="browser-tz" name="threeDSec_browserTZ" value={new Date().getTimezoneOffset()} />);

                        return hiddenFields;
                    })()}
                    <div className="row mb20">
                        <div className="col-sm-12">
                            <button type="button"
                                    className="btn btn-lg btn-success pull-right"
                                    disabled={this.props.saving || submitting}
                                    onClick={() => {
                                        this.showModal(true);
                                    }}
                            >
                                {this.props.saving || submitting ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </div>
                </form>
                {this.checkDialog()}
            </div>
        )
    }
}

PaymentDataForm = reduxForm({
    form: 'PaymentDataForm', // a unique identifier for this form
    validate, // <--- validation function given to redux-form
})(PaymentDataForm)

// Decorate with connect to read form values
const selector = formValueSelector('PaymentDataForm') // <-- same as form name

export default connect(state => {
    const addrProps = selector(state, "addr_name", "addr_street", "addr_street2", "addr_city", "addr_zip", "addr_country");

    return {
        addrProps,
    }
})(PaymentDataForm);