import {addValidator} from "redux-form-validators";

/**
 * https://tools.ietf.org/html/rfc1912
 *
 * label: Allowable characters in a label for a host name are only ASCII
 *        letters, digits, and the `-' character.  Labels may not be all
 *        numbers, but may have a leading digit  (e.g., 3com.com).  Labels must
 *        end and begin only with a letter or digit.
 *        Note there are some Internet hostnames which violate this rule
 *        (411.org, 1776.com).  The presence of underscores in a label
 *        is allowed in [RFC 1033], except [RFC 1033] is informational
 *        only and was not defining a standard
 *
 * we do allow underscored for now
 */
export default addValidator({
    validator: function (options, value, allValues) {
        let parts = value.match(/^([0-9]{1,5})\s+([0-9]{1,5})\s+([0-9]{1,5})\s+(\S+)$/);


        if(parts == null || parts.length != 5) {
            return {
                id: "form.errors.dns.invalid_srv",
                defaultMessage: "Invalid SRV data"
            };
        }

        /**
         * Priority
         *
         * The priority of this target host.  A client MUST attempt to
         * contact the target host with the lowest-numbered priority it can
         * reach; target hosts with the same priority SHOULD be tried in an
         * order defined by the weight field.  The range is 0-65535.  This
         * is a 16 bit unsigned integer in network byte order.*
         */
        let priority = parseInt(parts[1]);
        if (priority < 0 || priority > 65535) {
            // invalid order
            return {
                id: "form.errors.dns.invalid_srv",
                defaultMessage: "Invalid Priority value (must be between 0 and 65535)"
            };
        }

        /**
         * Weight
         *
         * A server selection mechanism.  The weight field specifies a
         * relative weight for entries with the same priority. Larger
         * weights SHOULD be given a proportionately higher probability of
         * being selected. The range of this number is 0-65535.  This is a
         * 16 bit unsigned integer in network byte order.  Domain
         * administrators SHOULD use Weight 0 when there isn't any server
         * selection to do, to make the RR easier to read for humans (less
         * noisy).  In the presence of records containing weights greater
         * than 0, records with weight 0 should have a very small chance of
         * being selected.
         *
         * In the absence of a protocol whose specification calls for the
         * use of other weighting information, a client arranges the SRV
         * RRs of the same Priority in the order in which target hosts,
         * specified by the SRV RRs, will be contacted. The following
         * algorithm SHOULD be used to order the SRV RRs of the same
         * priority:
         *
         * To select a target to be contacted next, arrange all SRV RRs
         * (that have not been ordered yet) in any order, except that all
         * those with weight 0 are placed at the beginning of the list.
         *
         * Compute the sum of the weights of those RRs, and with each RR
         * associate the running sum in the selected order. Then choose a
         * uniform random number between 0 and the sum computed
         * (inclusive), and select the RR whose running sum value is the
         * first in the selected order which is greater than or equal to
         * the random number selected. The target host specified in the
         * selected SRV RR is the next one to be contacted by the client.
         * Remove this SRV RR from the set of the unordered SRV RRs and
         * apply the described algorithm to the unordered SRV RRs to select
         * the next target host.  Continue the ordering process until there
         * are no unordered SRV RRs.  This process is repeated for each
         * Priority.
         */

        let weight = parseInt(parts[2]);
        if (weight < 0 || weight > 65535) {
            // invalid order
            return {
                id: "form.errors.dns.invalid_srv",
                defaultMessage: "Invalid Weight value (must be between 0 and 65535)"
            };
        }

        /**
         * Port
         * The port on this target host of this service.  The range is 0-
         * 65535.  This is a 16 bit unsigned integer in network byte order.
         * This is often as specified in Assigned Numbers but need not be.
         */
        let port = parseInt(parts[3]);
        if (port < 0 || port > 65535) {
            // invalid order
            return {
                id: "form.errors.dns.invalid_srv",
                defaultMessage: "Invalid Port value (must be between 0 and 65535)"
            };
        }

        /**
         * Target
         *
         * The domain name of the target host.  There MUST be one or more
         * address records for this name, the name MUST NOT be an alias (in
         * the sense of RFC 1034 or RFC 2181).  Implementors are urged, but
         * not required, to return the address record(s) in the Additional
         * Data section.  Unless and until permitted by future standards
         * action, name compression is not to be used for this field.
         *
         * A Target of "." means that the service is decidedly not
         * available at this domain.
         */
        let target = parts[4];


        if(target != '.' && !target.match(/^(?!:\/\/)(?=\S{1,255}$)((\S{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.)$/i)) {
            return {
                id: "form.errors.dns.invalid_srv",
                defaultMessage: "Invalid Target value (must be a qualified domain name)"
            }
        }

        return true;
    }
})