import "core-js";
import "regenerator-runtime/runtime";
import React from "react";
import {Provider} from "react-redux";
import {render} from "react-dom";
import Root from "./containers/Root";
import CartBadge from "./containers/CartBadge";
import CartMenu from "./containers/CartMenu";
import ToplevelDomainTeaser from "./containers/ToplevelDomainTeaser";
import SearchForm from "./containers/SearchForm";
import store, {history} from "./store";
import {IntlProvider} from "react-intl";
import Validators from 'redux-form-validators';

// this is required, otherwise js will try to access defaultOptions but it is undefined
// webpack somehow screews up the order of definitions
Validators.defaultOptions = {
    allowBlank: false,
    urlProtocols: ['http', 'https'],
    dateFormat: 'yyyy-mm-dd', // ISO
    dateYmd: 'ymd',
    accept: ['1', 'true'],
    caseSensitive: true // confirmation, inclusion, exclusion
};

const app = document.getElementById('app');
const cartBadges = [].slice.call(document.getElementsByClassName('cartBadge'));
const cartMenuEl = document.getElementById('cart-menu');
const tldTeaser = document.getElementById('teaser_domainlist');
const searchForm = document.getElementById('search-form');

function getQueryParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function run() {
    window.store = store;

    if (app != undefined) {
        render(
            <Root store={store} history={history}/>,
            app
        )
    }

    if (cartBadges.length > 0) {
        cartBadges.forEach((cartBadgeEl) => {
            render(
                <Provider store={store}>
                    <CartBadge/>
                </Provider>,
                cartBadgeEl
            )
        })
    }

    if (cartMenuEl != undefined) {
        render(
            <IntlProvider locale="en">
                <Provider store={store}>
                    <CartMenu/>
                </Provider>
            </IntlProvider>,
            cartMenuEl
        )
    }

    if (tldTeaser != undefined) {
        render(
            <Provider store={store}>
                <ToplevelDomainTeaser tldType={tldTeaser.getAttribute('data-type')}/>
            </Provider>,
            tldTeaser
        )
    }

    if (searchForm != undefined) {
        let domain;
        if(window.location.href.indexOf('domain=') > 0) {
            domain = getQueryParameterByName('domain')
        } else {
            domain = null;
        }

        render(
            <Provider store={store}>
                <SearchForm navigate={
                                window.location.pathname != '/searchresult/' &&
                                window.location.pathname != '/domain/namecheck/' &&
                                window.location.pathname != '/domain/domainr/' &&
                                window.location.pathname != '/domain/domaintyper/' &&
                                window.location.pathname != '/domain/500px/' &&
                                window.location.pathname != '/domain/wildnames/'
                            }
                            initialValues={{
                                domain: domain
                            }}
                />
            </Provider>,
            searchForm)
    }
}

if (!global.Intl) {
    import(/* webpackChunkName: "intl" */ 'intl/dist/Intl').then((intl) => {

        global.Intl = intl;
        global.IntlPolyfill = intl;
    }).then(() => {
        return import(/* webpackChunkName: "intl" */ 'intl/locale-data/jsonp/en.js')
    }).then(() => {
        run();
    })
} else {
    run()
}
