import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {Field, FieldArray, reduxForm, SubmissionError} from "redux-form";
import {required} from 'redux-form-validators'
import TextField from "../../Form/TextField";
import MaterialIcon from "../../MaterialIcon";
import glueLabel from "./Validators/glueLabel"
import {dnsActions} from "../../../actions";
import {toastr} from "react-redux-toastr";
import getDuplicates from "../../../util/array-duplicates";
import ipv4 from "./Validators/ipv4";
import ipv6 from "./Validators/ipv6";


/**
 * validate:
 * - duplicate labels
 * - duplicate addresses
 */

const validateAndUpdateGlueRecord = (values, dispatch, props) => {

    const filteredValues = {
        name: values.name,
        ipv4: values.ipv4.filter((ip) => {return ip != '' && ip != undefined && ip != null}),
        ipv6: values.ipv6.filter((ip) => {return ip != '' && ip != undefined && ip != null}),
    };

    // we need to know if the submit was successful or not?
    dispatch(dnsActions.saveGlueRecord(
        props.zone,
        props.initialValues.name,
        filteredValues,
        props.initialValues.key == '_new',
        props.initialValues.key
    )).then(action => {
        let result = action.value.data;

        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            if (props.initialValues.key) {
                props.reset();
            }
            toastr.success("Glue Record saved successfully!");
        } else {
            // since we cannot say which field caused the error, we just display a global error message
            /*let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }*/

            toastr.error("Failed to save Glue Record!");
            throw new SubmissionError();
        }
    }).catch(error => {
        let msg = null;

        if (error.response && error.response.data && error.response.data.exception) {
            msg = error.response.data.exception;
        }
        toastr.error("Failed to save DNS Records!", msg);
        throw new SubmissionError();
    })
}

const validateGlueRecord = (values) => {

    let errors = {};
    if(
        values.ipv4 && values.ipv4.filter((ip) => {
            return ip != null && ip != '' && ip != undefined
        }).length == 0 &&
        values.ipv6 && values.ipv6.filter((ip) => {
            return ip != null && ip != '' && ip != undefined
        }).length == 0)
    {
        errors.ipv4 = ["Please provide at least one address for this glue record."];
    } else {
        errors.ipv4 = {};
        errors.ipv6 = {};
    }

    // see if any of the ipv4 or ipv6-addresses is specified more than once
    if(values.ipv4 && values.ipv4[0] != "") {
        let duplicateIPv4 = getDuplicates(values.ipv4);
        for (let i of duplicateIPv4) {
            errors.ipv4[i] = "Please provide each address only once";
        }
    }

    if(values.ipv6 && values.ipv6[0] != "") {
        let duplicateIPv6 = getDuplicates(values.ipv6);
        for (let i of duplicateIPv6) {
            errors.ipv6[i] = "Please provide each address only once";
        }
    }

    return errors;
}

const renderRecords = (props) => {
    const {fields, meta: {error}} = props;

    let inputClassnames = ['glue-address', 'form-inline'];

    if (fields.length > 1) {
        inputClassnames.push('mto');
    }

    return fields.map((record, index) => {
        return (
            <Field name={`${record}`}
                   component={TextField}
                   type="text"
                   size="sm"
                   className={inputClassnames.join(' ')}
                   key={props._key + '_' + index}
                   validate={props._validate}
            >
                {(() => {
                    if (fields.length > 1) {
                        return <a onClick={() => fields.remove(index)} className="clear-icon">
                            <MaterialIcon icon="clear"/>
                        </a>
                    }
                })()}
                {(() => {
                    if (fields.length - 1 == index) {
                        let p = {
                            onClick: () => {
                                fields.push('')
                            }
                        }
                        if (fields.length == 1) {
                            p.className = 'add-icon ml20';
                        } else {
                            p.className = 'add-icon';
                        }
                        return (
                            <a {...p}>
                                <MaterialIcon icon="add"/>
                            </a>
                        );
                    }
                })()}
            </Field>
        );
    }, this);
}

class GlueRecordForm extends React.Component {

    render() {

        return (<tr key={this.props._key} className="editing">
                <td key='glue_record_hostname'>
                    <Field name="name"
                           component={TextField}
                           placeholder="ns1"
                           type="text"
                           size="sm"
                           className="rname"
                           validate={[
                               required({
                                   message: {
                                       id: 'form.errors.name.required',
                                       defaultMessage: "Please provide the name for this glue-record"
                                   }
                               }), glueLabel({
                                   allGlueRecords: this.props.allGlueRecords,
                                   key: this.props._key
                               })]}

                    />
                </td>
                <td className="ip-field">
                    <FieldArray name="ipv4"
                                component={renderRecords}
                                _validate={[
                                    ipv4({
                                        required: false
                                    })
                                ]}
                    />
                </td>
                <td className="ip-field">
                    <FieldArray name="ipv6"
                                component={renderRecords}
                                _validate={[
                                    ipv6({
                                        required: false
                                    })
                                ]}

                    />
                </td>
                <td className="action">
                    {this.props.new == false &&
                    <Button bsStyle="default"
                            onClick={this.props.cancelEditGlueRecord}
                            disabled={this.props.saving == this.props._key}
                    >
                        cancel
                    </Button>}
                </td>
                <td className="action">
                    <Button bsStyle="primary"
                            onClick={this.props.handleSubmit(validateAndUpdateGlueRecord)}
                            disabled={this.props.saving == this.props._key}>
                        {(() => {
                            if (this.props.saving == this.props._key) {
                                return (this.props.new ? 'Adding...' : 'Saving...');
                            }
                            else {
                                return (this.props.new ? 'Add' : 'Save');
                            }
                        })()}
                    </Button>
                </td>
            </tr>
        );
    }

    static propTypes = {
        // state data
        _key: PropTypes.string.isRequired,
        allGlueRecords: PropTypes.array.isRequired,
        zone: PropTypes.string.isRequired
    }

    static defaultProps = {
        new: false
    }
}

export default reduxForm({
    onSubmit: validateAndUpdateGlueRecord,
    validate: validateGlueRecord
})(GlueRecordForm)
