const contactReducer = (state = {
    entities: {},
    dictionary: [],
    isFetching: false,
    isFetched: false,
    edit: null
}, action) => {

    switch (action.type) {
        case "CONTACT_EDIT_CANCEL": {
            return {
                ...state,
                edit: null
            }
        }
        case "CONTACT_EDIT": {
            return {
                ...state,
                edit: action.payload.handle,
                useFor: action.payload.useFor
            }
        }
        case "CONTACTLIST_FETCH_PENDING": {
            return {
                ...state,
                isFetching: true,
                isFetched: false
            }
        }
        case "CONTACTLIST_FETCH_REJECTED": {
            // show error

            return {
                isFetching: false,
                isFetched: true
            }
        }
        case "CONTACTLIST_FETCH_FULFILLED": {
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                entities: action.payload.entities.contact,
                dictionary: action.payload.result,
            }
        }
        case "CONTACT_UPDATE_SUCCESS": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.contact.handle]: action.payload.contact
                },
                edit: null
            }
        }
        default: {
            return state;
        }
    }
};

export default contactReducer;
