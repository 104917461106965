import {addValidator} from "redux-form-validators";

const Ipv6 = require('ip-address').Address6;

export default addValidator({
    validator: (options, value, allValues) => {
        if((
                value == "" || value == null) &&
            options.required == false
        ) {
            return true;
        }

        let ip = new Ipv6(value);

        let privateSubnets = [
            new Ipv6('fc00::/7')
        ];

        if (!ip.isValid()) {
            return {
                id: "form.errors.ip.invalid",
                defaultMessage: "This IP is not a valid IPv6 Address"
            }
        }

        if(ip.subnetMask != 128) {
            return {
                id: "form.errors.ip.invalid",
                defaultMessage: "You have to specify a single IPv6 Address here"
            }
        }

        for (let privateSubnet of privateSubnets) {
            if (ip.isInSubnet(privateSubnet)) {
                return {
                    id: "form.errors.ip.private",
                    defaultMessage: "The given IP is in a private subnet and should not be used in public DNS"
                }
            }
        }

        return true;
    }
});