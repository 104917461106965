import uuid from "uuid";

const Ipv6 = require('ip-address').Address6;

function sortResourceRecords(resourceRecords, sortBy, order = 'asc') {
    resourceRecords.sort((rr1, rr2) => {
        let sortBy2 = '';
        if (sortBy == 'name') {
            sortBy2 = 'type';
        } else {
            sortBy2 = 'name'
        }

        let o = 1;
        if (order == 'desc') {
            o = -1;
        }

        if (rr1[sortBy] == rr2[sortBy]) {
            if (rr1[sortBy2] == rr2[sortBy2]) {
                return rr1.rdata < rr2.rdata ? -1 : 1
            } else {
                if (sortBy2 == 'name') {
                    if (rr1['name'] == '@') {
                        return -1;
                    } else if (rr2['name'] == '@') {
                        return 1
                    }
                }
                return rr1[sortBy2] < rr2[sortBy2] ? -1 : 1;
            }
        } else {
            if (sortBy == 'name') {
                if (rr1['name'] == '@') {
                    return -o;
                } else if (rr2['name'] == '@') {
                    return o
                }
            }

            return rr1[sortBy] < rr2[sortBy] ? -o : o;
        }
    });

    return resourceRecords;
}

function sortGlueRecords(glueRecords) {
    for (let glueRecord of glueRecords) {
        glueRecord.ipv4.sort();

        if (glueRecord.ipv6.length == 0) {
            glueRecord.ipv6 = ['']
        } else {
            glueRecord.ipv6 = glueRecord.ipv6.map((ipv6) => {
                return new Ipv6(ipv6).correctForm();
            }).sort();
        }
    }

    glueRecords.sort((gr1, gr2) => {
        return gr1.name.localeCompare(gr2.name);
    });

    return glueRecords;
}

const dnsZoneReducer = (state = {
    entities: {},
    dictionary: [],
    isFetching: false,
    isFetched: false,
    currentZone: null
}, action) => {

    let zone, mailForwardRecordIndex;

    // implement sorting resource records
    switch (action.type) {
        case "DNSZONE_FETCH_FULFILLED": {
            let rr = sortResourceRecords(action.payload.resourceRecords, 'name', 'asc');

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        resourceRecords: rr,
                        glueRecords: sortGlueRecords(action.payload.glueRecords),
                        order: 'asc',
                        orderBy: 'name',
                        edit: null
                    }
                },
                dictionary: [
                    ...state.dictionary,
                    action.meta.zone
                ],
            }
        }
        case "DNS_SORT_ZONE_RESOURCE_RECORDS": {
            let order = 'asc';

            if (action.payload.sortBy == state.entities[action.payload.zone].orderBy) {
                if (state.entities[action.payload.zone].order == 'asc') {
                    order = 'desc';
                }
            }

            let rr = sortResourceRecords(state.entities[action.payload.zone].resourceRecords, action.payload.sortBy, order);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.zone]: {
                        ...state.entities[action.payload.zone],
                        resourceRecords: rr,
                        order: order,
                        orderBy: action.payload.sortBy,
                        edit: null
                    }
                }
            }
        }
        case "DNSZONE_EDIT_RR":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.zone]: {
                        ...state.entities[action.payload.zone],
                        edit: action.payload.key
                    }
                }
            };
        case "DNSZONE_CANCEL_EDIT_RR":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.zone]: {
                        ...state.entities[action.payload.zone],
                        edit: null
                    }
                }
            };
        case "DNSZONE_DELETE_RR_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        deleting: action.meta.key
                    }
                }
            }
        case "DNSZONE_DELETE_RR_REJECTED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        deleting: null
                    }
                }
            }
        case "DNSZONE_DELETE_RR_FULFILLED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        resourceRecords: state.entities[action.meta.zone].resourceRecords.filter((resourceRecord) => {
                            return resourceRecord.key != action.meta.key;
                        }),
                        deleting: null
                    }
                }
            }
        case "DNSZONE_SAVE_RR_SET_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        saving: action.meta.key
                    }
                }
            };
        case "DNSZONE_SAVE_RR_SET_REJECTED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        saving: null
                    }
                }
            };
        case "DNSZONE_SAVE_RR_SET_FULFILLED":
            // sort the record-sets

            // somehow we need to add/modify wildcard-records if addWildcard is set:
            let resourceRecords;

            if (action.meta.isNew == true) {
                resourceRecords = sortResourceRecords([
                    ...state.entities[action.meta.zone].resourceRecords,
                    {
                        ...action.payload.data.resourceRecordSet,
                        key: uuid()
                    },
                ], state.entities[action.meta.zone].sortBy, state.entities[action.meta.zone].order);
            } else {
                resourceRecords = state.entities[action.meta.zone].resourceRecords.map((rrSet) => {
                    if (rrSet.name == action.meta.name && rrSet.type == action.meta.type) {
                        return {
                            ...action.payload.data.resourceRecordSet,
                            key: action.meta.key
                        };
                    }

                    return rrSet;
                });
            }

            if (
                action.meta.addWildcard &&
                action.meta.addWildcard == true &&
                action.meta.name == '@'
            ) {
                let modifiedWildcard = false;
                for (let resourceRecord of resourceRecords) {
                    if (resourceRecord.name == "*" && resourceRecord.type == action.meta.type) {
                        resourceRecord.rdata = action.meta.rdata;
                        modifiedWildcard = true;
                    }
                }

                if (modifiedWildcard == false) {
                    resourceRecords.push({
                        'key': uuid(),
                        'name': '*',
                        'rdata': action.meta.rdata,
                        'ttl': action.meta.ttl,
                        'type': action.meta.type,
                    });
                }
            }

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        resourceRecords: resourceRecords,
                        edit: null,
                        saving: null
                    }
                }
            };

        case "DNSZONE_EDIT_GLUE":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.zone]: {
                        ...state.entities[action.payload.zone],
                        editGlue: action.payload.key
                    }
                }
            };
        case "DNSZONE_CANCEL_EDIT_GLUE":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.zone]: {
                        ...state.entities[action.payload.zone],
                        editGlue: null
                    }
                }
            };
        case "DNSZONE_DELETE_GLUERECORD_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        deletingGlue: action.meta.key
                    }
                }
            }
        case "DNSZONE_DELETE_GLUERECORD_REJECTED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        deletingGlue: null
                    }
                }
            }
        case "DNSZONE_DELETE_GLUERECORD_FULFILLED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        glueRecords: state.entities[action.meta.zone].glueRecords.filter((glueRecord) => {
                            return glueRecord.key != action.meta.key;
                        }),
                        deletingGlue: null
                    }
                }
            }
        case "DNSZONE_SAVE_GLUERECORD_PENDING":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        savingGlue: action.meta.key
                    }
                }
            };
        case "DNSZONE_SAVE_GLUERECORD_REJECTED":
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.zone]: {
                        ...state.entities[action.meta.zone],
                        savingGlue: null
                    }
                }
            };
        case "DNSZONE_SAVE_GLUERECORD_FULFILLED":
            if (action.meta.isNew == true) {
                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        [action.meta.zone]: {
                            ...state.entities[action.meta.zone],
                            glueRecords: sortGlueRecords([
                                ...state.entities[action.meta.zone].glueRecords,
                                {
                                    ...action.payload.data.glueRecord,
                                    key: uuid()
                                },
                            ]),
                            editGlue: null,
                            savingGlue: null
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        [action.meta.zone]: {
                            ...state.entities[action.meta.zone],
                            glueRecords: sortGlueRecords(state.entities[action.meta.zone].glueRecords.map((glueRecord) => {
                                if (glueRecord.name == action.meta.name) {
                                    return {
                                        ...action.payload.data.glueRecord,
                                        key: action.meta.key
                                    };
                                }

                                return glueRecord;
                            })),
                            editGlue: null,
                            savingGlue: null
                        }
                    }
                }
            }
        default: {
            return state;
        }
    }
};

export default dnsZoneReducer;
