import paginate from "./decorators/paginate";

const preRegistrationsReducer = (state = {
    entities: {},
    dictionary: [],
    isFetching: false,
    isFetched: false
}, action) => {

    switch (action.type) {
        case "PREREGISTRATIONLIST_FETCH_PENDING": {
            return {...state, isFetching: true}
        }
        case "PREREGISTRATIONLIST_FETCH_REJECTED": {
            return {...state, isFetching: false, error: action.payload}
        }
        case "PREREGISTRATIONLIST_FETCH_FULFILLED": {
            let itemsPerPage;

            if (action.payload.result.length <= 20) {
                itemsPerPage = 20;
            } else {
                itemsPerPage = 10;
            }

            return {
                ...state,
                isFetching: false,
                isFetched: true,
                entities: action.payload.entities.domain,
                dictionary: action.payload.result,
                itemsPerPage: itemsPerPage
            }
        }
        case "PREREGISTRATION_CONFIRM_TRADEMARK_PENDING": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        confirmingClaim: true
                    }
                }
            }
        }
        case "PREREGISTRATION_CONFIRM_TRADEMARK_REJECTED": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        confirmingClaim: false
                    }
                }
            }
        }
        case "PREREGISTRATION_CONFIRM_TRADEMARK_FULFILLED": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: action.payload
                }
            }
        }
        case "PREREGISTRATION_DELETE_PENDING": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        deleting: true
                    }
                }
            }
        }
        case "PREREGISTRATION_DELETE_REJECTED": {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.domain]: {
                        ...state.entities[action.meta.domain],
                        deleting: false
                    }
                }
            }
        }
        case "PREREGISTRATION_DELETE_FULFILLED": {
            let ret = {
                ...state,
                dictionary: state.dictionary.filter(domain => domain !== action.meta.domain),
            };

            delete ret.entities[action.meta.domain];

            return ret;
        }
        default: {
            return state;
        }
    }
};

const paginatePreRegistrationReducer = paginate(preRegistrationsReducer, {
    GOTO_PAGE: 'PREREGISTRATIONLIST_GOTO_PAGE',
    NEXT_PAGE: 'NEXT_DOMAIN_PAGE',
    PREV_PAGE: 'PREV_DOMAIN_PAGE',
    FILTER: 'PREREGISTRATIONLIST_FILTER',
    SORT: 'PREREGISTRATIONLIST_SORT',
    SET_ITEMS_PER_PAGE: 'PREREGISTRATIONLIST_SET_ITEMS_PER_PAGE'
}, {
    defaultSortBy: 'domain'
});

export default paginatePreRegistrationReducer;
