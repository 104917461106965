import {addValidator} from "redux-form-validators";

/**
 * Issuer Critical:  If set to '1', indicates that the corresponding
 * property tag MUST be understood if the semantics of the CAA record
 * are to be correctly interpreted by an issuer.
 *
 * Issuers MUST NOT issue certificates for a domain if the relevant
 * CAA Resource Record set contains unknown property tags that have
 * the Critical bit set.
 *
 * The following property tags are defined:
 *
 * issue <Issuer Domain Name> [; <name>=<value> ]* :  The issue property
 * entry authorizes the holder of the domain name <Issuer Domain
 * Name> or a party acting under the explicit authority of the holder
 * of that domain name to issue certificates for the domain in which
 * the property is published.
 *
 * issuewild <Issuer Domain Name> [; <name>=<value> ]* :  The issuewild
 * property entry authorizes the holder of the domain name <Issuer
 * Domain Name> or a party acting under the explicit authority of the
 * holder of that domain name to issue wildcard certificates for the
 * domain in which the property is published.
 *
 * iodef <URL> :  Specifies a URL to which an issuer MAY report
 * certificate issue requests that are inconsistent with the issuer's
 * Certification Practices or Certificate Policy, or that a
 * Certificate Evaluator may use to report observation of a possible
 * policy violation.  The Incident Object Description Exchange Format
 * (IODEF) format is used [RFC5070].
 *
 */
export default addValidator({
    validator: (options, value, allValues) => {

        let parts = value.split(' ');

        if(parts.length != 3) {
            return {
                id: "form.errors.dns.invalid_caa",
                defaultMessage: "Invalid data"
            }
        }

        if(!parts[0].match(/^[0-9]+$/)) {
            return {
                id: "form.errors.dns.invalid_caa",
                defaultMessage: "Invalid data"
            }
        } else {
            let flags = parseInt(parts[0])

            if(flags > 255) {
                return {
                    id: "form.errors.dns.invalid_caa",
                    defaultMessage: "Flag value must be between 0 and 255"
                }
            }
        }

        if(parts[1] != "issue" && parts[1] != "issuewild" && parts[2] != "iodef") {
            return {
                id: "form.errors.dns.invalid_caa",
                defaultMessage: "issue, issuewild and iodef are the only allowed tags"
            }
        }

        // how to validate the value?
        if(!parts[2].startsWith('"') || !parts[2].endsWith('"')) {
            return {
                id: "form.errors.dns.invalid_caa",
                defaultMessage: "the value needs to be enclosed by double quotes"
            }
        }

        if(parts[2].length <= 2) {
            return {
                id: "form.errors.dns.invalid_caa",
                defaultMessage: "value is required"
            }
        }

        // we do not validate the value part in detail for now

        return true;
    }
});
