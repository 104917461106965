import React from "react";
import PropTypes from 'prop-types';
import "url-search-params-polyfill";
import Link from "../containers/Link";

class PreviousSearches extends React.Component {

    constructor(props) {
        super(props);
    }

    getPreviousSearchTerms() {
        return this.props.searchHistory.map((previousSearch, index) => {
            return (<span key={'previousSearch' + index}>
                    {!!index && ", "}
                    <Link to={Routing.generate('search_result', {'domain': previousSearch.searchTerms.join('\n')})}>
                        {previousSearch.searchTerms.join('|')}
                    </Link>
                </span>);
        });
    }

    render() {
        return (
            <div id="previous-searches">
                {(() => {
                    if (this.props.searchHistory.length > 0) {
                        return (
                            <span>
                                Your previous searches:{' '}
                                {this.getPreviousSearchTerms()}
                            </span>
                        )
                    }
                })()}
            </div>
        );
    }


    static propTypes = {
        searchHistory: PropTypes.array.isRequired
    }


}

export default PreviousSearches;
