import React from "react";
import PropTypes from 'prop-types';

class ShortenedDomain extends React.Component {

    render() {
        let keyword, tld, domain;

        keyword = this.props.domain.substr(0, this.props.domain.indexOf("."));
        tld = this.props.domain.substring(this.props.domain.indexOf(".") + 1);

        if (keyword.length >= this.props.length) {
            domain = keyword.substr(0, this.props.length) + "..";
        } else {
            domain = keyword;
        };

        return (
            <abbr title={this.props.domain}>
                <span className="result-keyword">{domain}</span>
                <span className="result-tld">.{tld}</span>
            </abbr>
        )
    }

    static propTypes = {
        domain: PropTypes.string,
        length: PropTypes.number
    }

    static defaultProps = {
        length: 23
    }
}

export default ShortenedDomain;