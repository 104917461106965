import {toastr} from "react-redux-toastr";

function charge(invoiceNr, invoiceId)
{
    return {
        type: 'INVOICE_CHARGE',
        meta: {
            id: invoiceId
        },
        payload: fetch(Routing.generate('portfolio_billing_chargeinvoice', {'invoiceNr': invoiceNr}), {
            credentials: 'same-origin'
        }).then(response => {
            return response.json().then(json => {
                return response.ok ? json : Promise.reject(json);
            });
        }).then(json => {
            toastr.success(json.message);
            return json;
        }).catch((json) => {
            let msg = "Charging of invoice " + invoiceNr + " failed";

            if(json.hasOwnProperty('message')) {
                msg += ": " + json.message;
            }

            toastr.error(msg);
            throw {
                ...json
            };
        })
    };
}

function sortBy(sortKey) {
    return {
        type: 'INVOICELIST_SORT',
        orderBy: sortKey
    }
}

function gotoPage(page) {
    return {
        type: "INVOICELIST_GOTO_PAGE",
        page: page
    }
}

function setItemsPerPage(itemsPerPage) {
    return {
        type: "INVOICELIST_SET_ITEMS_PER_PAGE",
        itemsPerPage: itemsPerPage
    }
}

function filterBy(filter) {
    return {
        type: "INVOICELIST_FILTER",
        filter: filter
    }
}

export default {
    charge,
    sortBy,
    gotoPage,
    setItemsPerPage,
    filterBy
}
