import React from "react";
import {Route} from "react-router";
import ManageDomainsPage from "./ManageDomainsPage";
import ManagePreRegistrationsPage from "./ManagePreRegistrationsPage";
import ManageWhoisPage from "./ManageWhoisPage";
import AccountPage from "./AccountPage";
import ManageDNSPage from "./ManageDNSPage";
import BillingPage from "./BillingPage";
import ManageForwardsPage from "./ManageForwardsPage";
import ManageEmailPage from "./ManageEmailPage";

class App extends React.Component {

    render() {
        return (
            <main>
                <Route exact path='/portfolio/dns/:domain' component={ManageDNSPage}/>
                <Route exact path='/portfolio/forwards/:domain' component={ManageForwardsPage}/>
                <Route exact path='/portfolio/email/:domain' component={ManageEmailPage}/>
                <Route exact path='/portfolio/whois/:domain' component={ManageWhoisPage}/>
                <Route exact path='/portfolio/account' component={AccountPage}/>
                <Route exact path='/portfolio/billing/' component={BillingPage}/>
                <Route exact path='/portfolio/pre-registrations/' component={ManagePreRegistrationsPage}/>
                <Route exact path='/portfolio' component={ManageDomainsPage}/>
            </main>
        )
    }
}
export default App
