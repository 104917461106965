import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Pagination} from 'react-bootstrap';
import ManagePreRegistrationsTable from "../../components/Portfolio/ManagePreRegistrationsTable";
import {preRegistrationActions, cartActions} from "../../actions";
import ClaimConfirmationModal from '../ClaimConfirmationModal';

class ManagePreRegistrationsPage extends React.Component {

    constructor(props) {
        super(props)
    }

    saveClaimConfirmation(claimData) {
        var preRegistration = this.props.entities[claimData.domain];
        this.props.confirmClaim(preRegistration, claimData);
    }

    render() {
        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="row clearfix">
                        <div className="col-xs-12">
                            <h1>Manage Pre-Orders</h1>
                        </div>
                        <div className="col-xs-12">
                            <ManagePreRegistrationsTable {...this.props} />
                            {(() => {
                                if (this.props.dictionary.length > 20) {
                                    return <Pagination
                                        prev
                                        next
                                        first
                                        last
                                        boundaryLinks
                                        items={this.props.totalPages}
                                        maxButtons={5}
                                        activePage={this.props.page}
                                        onSelect={this.props.gotoPage}
                                    />
                                }
                            })()}
                            <ClaimConfirmationModal addToCart={false}
                                                    onConfirm={(claimData) => {
                                                        this.saveClaimConfirmation(claimData);
                                                    }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        ...state.preRegistrations,
        toplevelDomains: state.toplevelDomains,
        cart: state.cart,
        claims: state.claims,
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(preRegistrationActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagePreRegistrationsPage)
