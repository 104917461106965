import {addValidator} from "redux-form-validators";

export default addValidator({
    validator: (options, value, allValues) => {


        let prohibitionList = [
            33,   // ! exclamation mark
            34,   // " quotation mark
            35,   // # number sign
            36,   // $ dollar sign
            37,   // % percent sign
            38,   // & ampersand
            40,   // ( left parenthesis
            41,   // ) right parenthesis
            42,   // * asterisk
            43,   // + plus sign
            44,   // , comma
            47,   // / slash
            58,   // : colon
            59,   // ; semicolon
            60,   // < less than
            61,   // = equals
            62,   // > greater than
            63,   // ? question mark
            64,   // @ at sign
            91,   // [ left square bracket
            92,   // \ backslash
            93,   // ] right square bracket
            94,   // ^ caret
            95,   // _ underscore
            96,   // ` grave accent
            123,  // { left curly brace
            124,  // | vertical bar
            125,  // } right curly brace
            126,  // ~ tilde
            161,  // ¡ inverted exclamation mark
            162,  // ¢ sign
            163,  // £ pound sign
            164,  // ¤ currency sign
            165,  // ¥ yen sign
            167,  // ¦ broken bar
            168,  // ¨ diaeresis
            169,  // © copyright sign
            170,  // ª feminine ordinal indicator
            171,  // « left-pointing double angle quotation mark
            172,  // ¬ not sign
            174,  // ® registered sign
            175,  // ¯ macron
            176,  // ° degree sign
            177,  // ± plus-minus sign
            180,  // ´ acute accent
            182,  // ¶ pilcrow sign
            183,  // · middle dot
            8211, // –
            8216, // ‘
            8218, // ‚
            8220, // “
            8224, // †
            8230, // …
            8260, // ⁄
            8364, // €
            8804, // ≤
            8706, // ∂
            8710, // ∆
            8721, // ∑
            8730, // √
            8734, // ∞
            8747, // ∫
            8776  // ≈
        ];

        let prohibitionFirstLastLetterList = prohibitionList.concat();
        prohibitionFirstLastLetterList.push(45); // - hyphen

        let prohibitionDomainEndingList = prohibitionList.concat();
        prohibitionDomainEndingList.push(45);  // - hyphen
        prohibitionDomainEndingList.push(196); // Ä Latin capital letter A with diaeresis
        prohibitionDomainEndingList.push(214); // Ö Latin capital letter O with diaeresis
        prohibitionDomainEndingList.push(220); // Ü Latin capital letter U with diaeresis
        prohibitionDomainEndingList.push(223); // ß Latin small letter sharp s
        prohibitionDomainEndingList.push(228); // ä Latin small letter a with diaeresis
        prohibitionDomainEndingList.push(246); // ö Latin small letter o with diaeresis
        prohibitionDomainEndingList.push(252); // ü Latin small letter ü with diaeresis

        // we do not validate the value part in detail for now
        let i,
            character,
            code,
            rows = value.split(/\r*\n/);

        if (value.length == 0 || !value.length) {
            return null;
        }

        for (let key in rows) {
            let row = rows[key];
            let dotPosition = row.indexOf(".")
                , domainName = dotPosition === -1 ? row : row.substring(0, dotPosition)
                , domainEnding = dotPosition === -1 ? null : row.substring(dotPosition + 1, row.length);
            if (domainName.length > 63) {
                return {
                    id: "form.errors.search",
                    defaultMessage: "The Domainname is too long, you can only use up to 63 characters"
                };
            }
            for (i = 0; i < domainName.length; i++)
                if (character = domainName[i],
                        code = character.charCodeAt(0),
                    prohibitionList.includes(code) == true) {
                    return {
                        id: "form.errors.search",
                        defaultMessage: "The term «" + row + "» contains invalid special characters."
                    }
                }
            if (domainEnding && domainEnding.length) {
                for (i = 0; i < domainEnding.length; i++)
                    if (character = domainEnding[i],
                            code = character.charCodeAt(0),
                        prohibitionDomainEndingList.includes(code) == true) {
                        return {
                            id: "form.errors.search",
                            defaultMessage: "The term «" + row + "» contains invalid special characters."
                        }
                    }
            }
            if (domainName.length) {
                let firstLetter = domainName.charAt(0).charCodeAt(0)
                    , lastLetter = domainName.slice(-1).charCodeAt(0);
                if (prohibitionFirstLastLetterList.includes(firstLetter) == true || prohibitionFirstLastLetterList.includes(lastLetter) == true) {
                    return {
                        id: "form.errors.search",
                        defaultMessage: "Domain names must begin and end with either a letter or a number."
                    }
                }
            }
        }

        return true;
    }
});
