import React from "react";
import PropTypes from "prop-types";

class OrderProgress extends React.Component {

    getSteps() {
        return [1, 2, 3, 4, 5].map((i) => {
            let step = OrderProgress.steps[i];

            let classes = ['col-xs-2', 'bs-wizard-step'];

            if (i == 1) {
                classes.push('col-xs-offset-1');
            }
            if (this.props.step > i) {
                classes.push('complete');
            } else if (this.props.step == i) {
                classes.push('active');
            } else {
                classes.push('disabled');
            }

            return (<div className={classes.join(' ')} key={'order-progress-step-' + i}>
                <div className="text-center bs-wizard-stepnum">Step {i}</div>
                <div className="progress">
                    <div className="progress-bar"></div>
                </div>
                {(() => {
                    if (step.route == false) {
                        return <span className="bs-wizard-dot"></span>
                    } else {
                        return <a href={Routing.generate(step.route)} className="bs-wizard-dot"></a>
                    }
                })()}
                <div className="bs-wizard-info text-center hidden-xs">{step.name}</div>
            </div>)
        });
    }

    render() {
        if (this.props.active) {
            return (
                <div className="block block-white">
                    <div className="row bs-wizard">
                        {this.getSteps()}
                    </div>
                    <hr/>
                </div>
            );
        } else {
            return null;
        }
    }

    static steps = {
        1: {name: 'Log In or Sign Up', route: false},
        2: {name: 'Account Information', route: 'account_edit'},
        3: {name: 'Payment Information', route: 'payment_edit'},
        4: {name: 'Review Cart', route: 'cart_index'},
        5: {name: 'Order Finished', route: false}
    }

    static defaultProps = {
        active: true
    }

    static propTypes = {
        active: PropTypes.bool,
        step: PropTypes.number.isRequired,
    }
}

export default OrderProgress;
