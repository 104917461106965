import React from "react";
import PropTypes from 'prop-types';
import stringifySeconds from '../../util/stringify-seconds'

class TTL extends React.Component {

    render() {
        return (
            <span>{stringifySeconds(this.props.seconds)}</span>
        )
    }
}

TTL.propTypes = {
    seconds: PropTypes.number.isRequired
}

export default TTL