import React from "react";
import PropTypes from "prop-types";

class TldHints extends React.Component {

    render() {
        const {cart, toplevelDomains, hideCheckboxTlds} = this.props;

        const hints = new Map();

        for (const cartDomain of cart.dictionary) {
            let cartItem = cart.entities[cartDomain];
            let toplevelDomain = toplevelDomains.entities[cartItem.tld];

            if (toplevelDomain.detail_hint != undefined && toplevelDomain.detail_hint != "") {
                if(
                    hideCheckboxTlds == true &&
                    toplevelDomain.checkbox_confirmation_hint != undefined &&
                    toplevelDomain.checkbox_confirmation_hint != ""
                ) {
                    break;
                }
                hints.set(cartItem.tld, toplevelDomain.detail_hint);
            }
        }

        if (hints.size > 0) {
            let ret = [];
            hints.forEach((hint, tld) => {
                ret.push(<div className="row clearfix" key={'tldHint_' + tld}>
                    <div className="col-sm-12">
                        <hr/>
                        <div className="small" dangerouslySetInnerHTML={{__html: hint}}/>
                    </div>
                </div>)
            });

            return <div>{ret}</div>;
        } else {
            return null;
        }
    }

    static propTypes = {
        toplevelDomains: PropTypes.object,
        cart: PropTypes.object
    }
}

export default TldHints;
