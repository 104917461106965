import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, FieldArray, formValueSelector, reduxForm, SubmissionError} from "redux-form";
import {Button} from "react-bootstrap";
import {email, required} from 'redux-form-validators'
import localMailPart from "./Validators/localMailPart";
import {mailForwardActions} from '../../../actions';
import TextField from "../../../components/Form/TextField";
import {toastr} from "react-redux-toastr";
import MaterialIcon from "../../MaterialIcon";

const validateAndUpdateForwards = (values, dispatch, props) => {

    // we need to know if the submit was successful or not?

    return dispatch(mailForwardActions.saveForwards(
        props.domain.domain + ".",
        props.initialValues.local,
        values,
        props.originalResourceRecord
    )).then(action => {
        let result = action.value.data;

        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success == true) {

            //let other components know that everything is fine by updating the redux` state
            if (props.initialValues.key) {
                props.reset();
            }
            toastr.success("eMail-Forwards saved successfully!");
        } else {
            // since we cannot say which field caused the error, we just display a global error message
            /*let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }*/

            toastr.error("Failed to save Forward!");
            throw new SubmissionError();
        }
    }).catch(error => {
        let msg = null;

        if (error.response && error.response.data && error.response.data.exception) {
            msg = error.response.data.exception;
        }
        toastr.error("Failed to save eMail-Forwards!", msg);
        throw new SubmissionError();
    })
}

class MailForwardSetForm extends Component {

    renderTargets(props) {

        const {fields, meta: {error}} = props;

        let inputClassnames = ['targetAddress', 'form-inline'];

        if (fields.length > 1) {
            inputClassnames.push('mto');
        }

        return fields.map((target, index) => {
            return <tr key={"key_" + index} className="editing">

                {(() => {
                    if (index == 0) {
                        return props.groupFields(fields.length)
                    }

                    return null;
                })()}
                <td className="field targetAddress">
                    <Field name={target}
                           component={TextField}
                           type="text"
                           size="sm"
                           placeholder=""
                           className={inputClassnames.join(' ')}
                           validate={[
                               required({
                                   message: {
                                       id: 'form.errors.mailforward.target_required',
                                       defaultMessage: "Please provide a valid eMail-address"
                                   }
                               }),
                               email({
                                   message: {
                                       id: 'form.errors.mailforward.target_invalid',
                                       defaultMessage: "This is not a valid eMail-address"
                                   }
                               })
                           ]}
                    >
                        {(() => {
                            if (fields.length > 1) {
                                return <a onClick={() => fields.remove(index)} className="clear-icon">
                                    <MaterialIcon icon="clear"/>
                                </a>
                            }
                        })()}
                        {(() => {
                            if (fields.length - 1 == index) {
                                let p = {
                                    onClick: () => {
                                        fields.push('')
                                    }
                                }
                                if (fields.length == 1) {
                                    p.className = 'add-icon ml20';
                                } else {
                                    p.className = 'add-icon';
                                }
                                return (
                                    <a {...p}>
                                        <MaterialIcon icon="add"/>
                                    </a>
                                );
                            }
                        })()}
                    </Field>
                </td>
                {(() => {
                    if (index == 0) {
                        return <React.Fragment>
                            <td className="action" rowSpan={fields.length}>
                                {props.showCancel &&
                                <Button bsStyle="default"
                                        onClick={props.cancelEditRecord}
                                        disabled={props.saving == props.initialValues.local}
                                >
                                    cancel
                                </Button>}
                                {props.save}
                            </td>
                        </React.Fragment>
                    } else {
                        return null; //<td className="filler"></td>
                    }
                })()}
            </tr>
        });
    }

    render() {
        const {handleSubmit, submitting, pristine, reset, domain} = this.props;

        let groupFields = ((rowSpan) => {
            return <React.Fragment>
                <td className="field localPart" rowSpan={rowSpan}>
                    <Field name="local"
                           component={TextField}
                           type="text"
                           size="sm"
                           placeholder=""
                           className="localPart"
                           validate={[
                               required({
                                   message: {
                                       id: 'form.errors.mailforward.local_part_required',
                                       defaultMessage: "Please enter the email forwarding address' local part (the part that comes before the \"@\" symbol)."
                                   }
                               }),
                               localMailPart({
                                   allForwards: this.props.allForwards.dictionary,
                                   initialLocal: this.props.initialValues.local,
                                   domain: this.props.domain.domain,
                               })
                           ]}
                    />
                </td>
                <td rowSpan={rowSpan}>&nbsp;@{this.props.domain.domain} to</td>
            </React.Fragment>
        });

        let save = <Button bsStyle="primary"
                           onClick={this.props.handleSubmit(validateAndUpdateForwards)}
                           disabled={this.props.saving == this.props._key}>
            {(() => {
                if (this.props.saving == this.props.initialValues.local) {
                    return (this.props.new ? 'adding...' : 'saving...');
                } else {
                    return (this.props.new ? 'add' : 'save');
                }
            })()}
        </Button>;

        return (
            <FieldArray name="targets"
                        _key={this.props._key}
                        component={this.renderTargets}
                        props={{
                            initialValues: this.props.initialValues,
                            domain: this.props.domain,
                            groupFields: groupFields.bind(this),
                            save: save,
                        }}
                        showCancel={this.props.new == false}
                        cancelEditRecord={this.props.cancelEditRecord}
                        saving={this.props.saving}
                        allForwards={this.props.allForwards}
            />
        );
    }
}

MailForwardSetForm = reduxForm({
    form: 'EmailForwardConfigForm', // a unique identifier for this form
    onSubmit: validateAndUpdateForwards,
    enableReinitialize: false
})(MailForwardSetForm)

const selector = formValueSelector('EmailForwardConfigForm');

export default connect(state => {
    const defaultOrCustom = selector(state, 'defaultOrCustom');

    return {
        defaultOrCustom,
    }
})(MailForwardSetForm);
