import React, {Component} from "react";
import {DropdownButton, MenuItem, OverlayTrigger, Popover, Table} from "react-bootstrap";
import {spring, TransitionMotion} from "react-motion";
import MaterialIcon from "../MaterialIcon";
import {confirm} from '../../util/confirm';
import ResourceRecordSet from "./ResourceRecordSet";
import ResourceRecordSetForm from "./Form/ResourceRecordSetForm";
import stringifySeconds from "../../util/stringify-seconds";
import {toastr} from "react-redux-toastr";
import saveAs from "save-as";
import importZoneFile from "./ImportZoneFileModal";

class CustomResourceRecords extends Component {

    constructor(props) {
        super(props);

        this.deleteRecord = this.deleteRecord.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.cancelEditRecord = this.cancelEditRecord.bind(this);
        this.exportZoneFile = this.exportZoneFile.bind(this);
    }

    handleSort(sortBy) {
        this.props.sortResourceRecords(this.props.domain.domain + ".", sortBy);
    }

    deleteRecord(name, type, key) {
        confirm('All resource records in the set will be deleted. This action cannot be undone.', {
            'title': 'Delete resource records?'
        }).then(() => {
            return this.props.deleteResourceRecord(this.props.domain.domain + '.', name, type, key);
        }).then(({value, action}) => {
            if (value.data && value.data.success && value.data.success == true) {
                toastr.success("DNS RecordsSet deleted successfully!");
            }
        }).catch(error => {
            // confirmation denied
        })
    }

    editRecord(key) {
        this.props.editResourceRecord(this.props.domain.domain + ".", key);
    }

    cancelEditRecord() {
        this.props.cancelEditResourceRecord(this.props.domain.domain + ".");
    }

    // actual animation-related logic
    getDefaultStyles = () => {
        return this.props.zone.resourceRecords.filter((resourceRecord) => {
            return CustomResourceRecords.typesToShow[resourceRecord.type] == true;
        }).map((resourceRecord, index) => ({
            data: resourceRecord,
            key: resourceRecord.key,
            style: {
                height: 40,
                opacity: 1,
                fontSize: 14,
                paddingTop: 8,
                paddingBottom: 8
            }
        }));
    };

    getStyles = () => {
        return this.props.zone.resourceRecords.filter((resourceRecord) => {
            return CustomResourceRecords.typesToShow[resourceRecord.type] == true;
        }).map((resourceRecord, index) => {
            return {
                data: resourceRecord,
                key: resourceRecord.key,
                style: {
                    height: spring(40, {stiffness: 90, damping: 14}),
                    opacity: spring(1, {stiffness: 90, damping: 14}),
                    fontSize: spring(14, {stiffness: 90, damping: 14}),
                    paddingTop: spring(8, {stiffness: 90, damping: 14}),
                    paddingBottom: spring(8, {stiffness: 90, damping: 14}),
                }
            };
        });
    };

    willLeave() {
        return {
            height: spring(0, {stiffness: 90, damping: 14}),
            opacity: spring(0, {stiffness: 90, damping: 14}),
            fontSize: spring(0, {stiffness: 90, damping: 14}),
            paddingTop: spring(0, {stiffness: 90, damping: 14}),
            paddingBottom: spring(0, {stiffness: 90, damping: 14}),
        };
    };

    willEnter() {
        return {
            height: 0,
            opacity: 0,
            fontSize: 0,
            paddingTop: 0,
            paddingBottom: 0,
        };
    };

    exportZoneFile() {
        let zoneFile = [];

        zoneFile.push("$TTL    86400; 24 hours\n");
        zoneFile.push("$ORIGIN " + this.props.domain.domain + ".\n");

        // soa first
        // then root-ns
        // then
        // sort by name
        let records = this.props.zone.resourceRecords.sort((rr1, rr2) => {
            if (rr1.type == 'soa') {
                return -1;
            }
            if (rr2.type == 'soa') {
                return 1
            }

            if (rr1.name == '@' && rr1.type == 'ns') {
                return -1
            }
            if (rr2.name == '@' && rr2.type == 'ns') {
                return 1
            }

            if (rr1.name == rr2.name) {
                if (rr1.name == '@') {
                    if (rr1.type == 'ns') {
                        return -1;
                    }
                    if (rr2.type == 'ns') {
                        return -1;
                    }

                    return rr1.type.localeCompare(rr2.type);
                }
            } else {
                if (rr1.name == '@') {
                    return -1;
                }

                if (rr2.name == '@') {
                    return 1;
                }

                return rr1.name.localeCompare(rr2.name);
            }
        });

        // we need to get the longest name and type parts
        let lengths = records.reduce((accumulated, current) => {

            return {
                name: (accumulated.name < current.name.length) ? current.name.length : accumulated.name,
                type: (accumulated.type < current.type.length) ? current.type.length : accumulated.type,
            }
        }, {
            'name': 0,
            'type': 0
        });

        records.forEach((record) => {
            if (record.type == 'soa') {
                let parts = record.rdata[0].split(' ');

                zoneFile.push(record.name + ' IN SOA ' + parts[0] + " " + parts[1] + ' (\n');

                let addLength = record.name.length + parts[0].length + 12 + parts[1].length;
                let soaPad = Math.max(
                    parts[2].length,
                    parts[3].length,
                    parts[4].length,
                    parts[5].length,
                    parts[6].length,
                );

                zoneFile.push(" ".repeat(addLength) + parts[2].padEnd(soaPad, " ") + "; serial\n"); // serial
                zoneFile.push(" ".repeat(addLength) + parts[3].padEnd(soaPad, " ") + "; refresh\n"); //
                zoneFile.push(" ".repeat(addLength) + parts[4].padEnd(soaPad, " ") + "; retry\n");
                zoneFile.push(" ".repeat(addLength) + parts[5].padEnd(soaPad, " ") + "; expire\n");
                zoneFile.push(" ".repeat(addLength) + parts[6].padEnd(soaPad, " ") + "; nxdomain ttl\n");
                zoneFile.push(" ".repeat(addLength - 2) + ")\n\n");
            } else {
                let first = true;

                for (let rdata of record.rdata) {
                    if (first == true) {
                        zoneFile.push(
                            record.name.padEnd(lengths.name, " ") + " IN " +
                            record.type.toUpperCase().padEnd(lengths.type, " ") + " " +
                            rdata + "\n"
                        )

                        first = false;
                    } else {
                        zoneFile.push(
                            " ".repeat(lengths.name) + " IN " +
                            record.type.toUpperCase().padEnd(lengths.type, " ") + " " +
                            rdata + "\n"
                        )
                    }
                }

                zoneFile.push("\n");
            }
        });


        /*    @  1D  IN  SOA ns1.example.com. hostmaster.example.com. (
            2002022401 ; serial
        3H ; refresh
        15 ; retry
        1w ; expire
        3h ; nxdomain ttl
    )
        IN  NS     ns1.example.com. ; in the domain
        IN  NS     ns2.smokeyjoe.com. ; external to domain
        IN  MX  10 mail.another.com. ; external mail provider
        ; server host definitions
        ns1    IN  A      192.168.0.1  ;name server definition
        www    IN  A      192.168.0.2  ;web server definition
        ftp    IN  CNAME  www.example.com.  ;ftp server definition
        ; non server domain hosts
        bill   IN  A      192.168.0.3
        fred   IN  A      192.168.0.4*/

        let blob = new Blob(zoneFile, {type: "text/plain;charset=utf-8"});
        saveAs(blob, this.props.domain.domain + ".zone.txt");
    }

    importZoneFile() {
        importZoneFile(
            "Import Zone File", {}).then((fileContents) => {
        });
    }

    render() {
        const {orderBy, order, edit} = this.props.zone;

        const nameHelpPopover = (
            <Popover id="rr-name-help">
                A domain, subdomain, or host.
                See Resource Records for formatting examples. The default is @.
            </Popover>
        );

        const typeHelpPopover = (
            <Popover id="rr-type-help">
                The record's type. For example, the A record or MX record.
                See Resource Records for a list of supported record types.
            </Popover>
        );

        const ttlHelpPopover = (
            <Popover id="rr-ttl-help">
                (Time to Live) How often a copy of the record stored in
                cache must be updated or discarded. The default is 1 hour.
            </Popover>
        );

        const dataHelpPopover = (
            <Popover id="rr-data-help">
                The record's data, which varies depending on the record type.
                For example, a host's IPv4 address for the A record type.
                See Resource Records for data examples.
            </Popover>
        );

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-6">
                        <h3>Custom Resource Records</h3>
                    </div>
                    <div className="col-sm-6">
                        <div className="pull-right mt20">
                            <div className="dropdown  hidden-sm" style={{display: "inline-block"}}>
                                <DropdownButton
                                    bsStyle="primary"
                                    title="Actions"
                                    id="dns_zone_rr_actions_dropdown"
                                    pullRight
                                >
                                    {/*<MenuItem eventKey="1" disabled={true}>Edit as Zone-File</MenuItem>
                                    <MenuItem divider/>*/}
                                    <MenuItem eventKey="2" disabled={edit != null} onClick={this.exportZoneFile}>Export
                                        as Zone-File</MenuItem>
                                    {/*<MenuItem eventKey="3" disabled={true}>Import Zone-File</MenuItem>*/}
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                </div>
                Set up your own resource records. For example, A Records, CNAME Records, or MX Records.
                If you have a list of DNS records from a web host or email host, this is where you would enter
                those records.
                <Table id="custom-resource-records">
                    <thead>
                    <ResourceRecordSetForm new={true}
                                           _key="_new"
                                           initialValues={{
                                               name: '@',
                                               type: 'a',
                                               ttl: '1h',
                                               rdata: [''],
                                               key: '_new'
                                           }}
                                           allResourceRecords={this.props.zone.resourceRecords}
                                           zone={this.props.domain.domain + '.'}
                                           form="NewResourceRecordSet"
                                           saving={this.props.zone.saving}
                    />
                    <tr>
                        <th onClick={() => this.handleSort('name')}
                            className={orderBy == 'name' ? 'sorting-' + order : 'sorting'}>
                            Name{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={nameHelpPopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th onClick={() => this.handleSort('type')}
                            className={orderBy == 'type' ? 'sorting-' + order : 'sorting'}>
                            Type{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={typeHelpPopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th>
                            TTL{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={ttlHelpPopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th>
                            Data{' '}
                            <OverlayTrigger placement="bottom" trigger="click" rootClose={true}
                                            overlay={dataHelpPopover}>
                                <a onClick={(e) => {e.preventDefault()}}>
                                    <MaterialIcon icon="help"/>
                                </a>
                            </OverlayTrigger>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <TransitionMotion
                        defaultStyles={this.getDefaultStyles()}
                        styles={this.getStyles()}
                        willLeave={this.willLeave}
                        willEnter={this.willEnter}
                    >
                        {styles =>
                            <tbody>
                            {(() => {
                                return styles.map((d, index) => {
                                    let className;

                                    if (d.style.height < 40) {
                                        className = "removing";
                                    }

                                    if (
                                        edit != null &&
                                        edit == d.data.key
                                    ) {
                                        // editing = true;
                                        return <ResourceRecordSetForm key={d.key}
                                                                      _key={d.key}
                                                                      initialValues={{
                                                                          ...d.data,
                                                                          ttl: stringifySeconds(d.data.ttl)
                                                                      }}
                                                                      cancelEditRecord={this.cancelEditRecord}
                                                                      zone={this.props.domain.domain + '.'}
                                                                      allResourceRecords={this.props.zone.resourceRecords}
                                                                      form={"ResourceRecordSet_" + d.key}
                                                                      saving={this.props.zone.saving}
                                        />
                                    } else {
                                        let {paddingTop, paddingBottom, ...s} = d.style;

                                        return <ResourceRecordSet key={d.key}
                                                                  _key={d.key}
                                                                  style={s}
                                                                  tdStyle={{
                                                                      paddingBottom: paddingBottom,
                                                                      paddingTop: paddingTop
                                                                  }}
                                                                  resourceRecord={d.data}
                                                                  className={className}
                                                                  editRecord={this.editRecord}
                                                                  deleting={this.props.zone.deleting == d.key}
                                                                  deleteRecord={this.deleteRecord}
                                        />
                                    }

                                })
                            })()}
                            </tbody>
                        }
                    </TransitionMotion>
                </Table>
            </React.Fragment>
        )
    }

    static typesToShow = {
        'a': true, // a-record
        'aaaa': true, // a-record vor ipv6
        'afsdb': true, // andrew file system (do we need this?)
        'cname': true, // cname
        'hinfo': true, // Record intended to provide information about host CPU type and operating system. It was intended to allow protocols to optimize processing when communicating with similar peers
        'loc': true, // Specifies a geographical location associated with a domain name
        'mx': true, // Mail
        'naptr': true, // Allows regular-expression-based rewriting of domain names which can then be used as URIs, further domain names to lookups, etc.
        'ns': true, // Delegates a DNS zone to use the given authoritative name servers
        'ptr': true, // Pointer to a canonical name. Unlike a CNAME, DNS processing stops and just the name is returned. The most common use is for implementing reverse DNS lookups, but other uses include such things as DNS-SD.
        'spf': true, // OBSOLETE: SPF(99) (from RFC 4408) was specified as part of the Sender Policy Framework protocol as an alternative to storing SPF data in TXT records, using the same format. It was later found that the majority of SPF deployments lack proper support for this record type: true, and support for it was discontinued in RFC 7208.[13][14]
        'srv': true, // Generalized service location record, used for newer protocols instead of creating protocol-specific records such as MX.
        'txt': true, // Originally for arbitrary human-readable text in a DNS record. Since the early 1990s, however, this record more often carries machine-readable data, such as specified by RFC 1464: true, opportunistic encryption: true, Sender Policy Framework: true, DKIM: true, DMARC: true, DNS-SD
        'uri': true, // Can be used for publishing mappings from hostnames to URIs.

        // informational
        'rp': true, // Information about the responsible person(s) for the domain. Usually an email address with the @ replaced by a .

        // dane + encryption-keys
        'caa': true, // specify: true, which certificat authorities are allowed to issue certificates for a domain
        'cert': true, // certificate-data and revocation lists
        'openpgpkey': true, // A DNS-based Authentication of Named Entities (DANE) method for publishing and locating OpenPGP public keys in DNS for a specific email address using an OPENPGPKEY DNS resource record.
        'smimea': true, // S-MIME encryption certificates
        'sshfp': true, // Resource record for publishing SSH public host key fingerprints in the DNS System, in order to aid in verifying the authenticity of the host. RFC 6594 defines ECC SSH keys and SHA-256 hashes. See the IANA SSHFP RR parameters registry for details.
        'tlsa': true, // A record for DANE. RFC 6698 defines "The TLSA DNS resource record is used to associate a TLS server certificate or public key with the domain name where the record is found, thus forming a 'TLSA certificate association'".

        // dns-sec
        'ds': true, // The record used to identify the DNSSEC signing key of a delegated zone (this should be set automatically)
        'key': true, // Used only for SIG(0) (RFC 2931) and TKEY (RFC 2930).[5] RFC 3445 eliminated their use for application keys and limited their use to DNSSEC.[6] RFC 3755 designates DNSKEY as the replacement within DNSSEC.[7] RFC 4025 designates IPSECKEY as the replacement for use with IPsec.[8]

        // we should probably move this to another section
        //'x-http': true, // frame redirect
        //'x-smtp': true // mail forward
    };
}

export default CustomResourceRecords
