import React from "react";
import {Button} from 'react-bootstrap';
import PropTypes from "prop-types";

class GlueRecord extends React.Component {


    render() {
        const {className, glueRecord, style, tdStyle} = this.props;

        let nr = Math.max(
            glueRecord.ipv4.length,
            glueRecord.ipv6.length
        );

        let ret = [];

        for (let i = 0; i < nr; i++) {
            if (i > 0) {
                ret.push(<tr key={this.props._key + '_' + i} style={style} className={className}>
                    <td style={tdStyle}>{(() => {
                        if (glueRecord.ipv4.length >= i - 1) {
                            return glueRecord.ipv4[i];
                        }
                    })()}</td>
                    <td style={tdStyle}>{(() => {
                        if (glueRecord.ipv6.length >= i - 1) {
                            return glueRecord.ipv6[i];
                        }
                    })()}</td>
                </tr>);
            } else {
                ret.push(<tr key={this.props._key + '_' + i} style={style} className={className}>
                    <td style={tdStyle} rowSpan={nr}>
                        {glueRecord.name}
                        {' '}
                        <span className="domain">.{this.props.domain}</span>
                    </td>
                    <td style={tdStyle}>{(() => {
                        if (glueRecord.ipv4.length >= i - 1) {
                            return glueRecord.ipv4[i];
                        }
                    })()}</td>
                    <td style={tdStyle}>{(() => {
                        if (glueRecord.ipv6.length >= i - 1) {
                            return glueRecord.ipv6[i];
                        }
                    })()}</td>
                    <td className="action" rowSpan={nr} style={tdStyle}>
                        <Button bsStyle="default" disabled={this.props.deleting}
                                onClick={() => this.props.deleteGlueRecord(glueRecord.name, this.props._key)}>
                            {this.props.deleting?'deleting...':'delete'}
                        </Button>
                    </td>
                    <td className="action" rowSpan={nr} style={tdStyle}>
                        <Button bsStyle="primary"
                                onClick={() => this.props.editGlueRecord(glueRecord.key)}>
                            edit
                        </Button>
                    </td>
                </tr>);
            }
        }

        return ret;
    }

    static propTypes = {
        // actions
        editGlueRecord: PropTypes.func.isRequired,
        deleteGlueRecord: PropTypes.func.isRequired,

        // state data
        _key: PropTypes.string.isRequired,
        glueRecord: PropTypes.object.isRequired,
        style: PropTypes.object.isRequired,
        className: PropTypes.string
    }
}

export default GlueRecord
