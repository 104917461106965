import {addValidator} from "redux-form-validators";

const Ipv4 = require('ip-address').Address4;

export default addValidator({
    validator: (options, value, allValues) => {
        if((
            value == "" || value == null) &&
            options.required == false
        ) {
            return true;
        }

        let ip = new Ipv4(value);

        let privateSubnets = [
            new Ipv4('10.0.0.0/24'),
            new Ipv4('172.16.0.0/20'),
            new Ipv4('192.168.0.0/16')
        ];

        if (!ip.isValid()) {
            return {
                id: "form.errors.ip.invalid",
                defaultMessage: "Not a valid IPv4 address"
            }
        }

        if(ip.subnetMask != 32) {
            return {
                id: "form.errors.ip.invalid",
                defaultMessage: "Please specify a single IPv4 address"
            }
        }

        for (let privateSubnet of privateSubnets) {
            if (ip.isInSubnet(privateSubnet)) {
                return {
                    id: "form.errors.ip.private",
                    defaultMessage: "This IP is in a private subnet and cannot be used in public DNS"
                }
            }
        }

        return true;
    }
});
