import React, {Component} from "react";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Panel} from "react-bootstrap";
import TextField from "../../Form/TextField";
import {accountActions} from '../../../actions';
import {date, length, numericality, required} from 'redux-form-validators'

const validateAndUpdateExtraData = (values, dispatch) => {

    return dispatch(accountActions.updateExtraData(values)).payload.then(result => {
        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            dispatch(accountActions.saveAccountExtraSuccess(values)); //ps: this is same as dispatching RESET_USER_FIELDS
        } else {
            let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }

            dispatch(accountActions.saveAccountExtraFailure(values, errors));
            throw new SubmissionError(errors);
        }
    });
}

class ExtraDataForm extends Component {

    getExtraFields() {
        return (
            <div>
                {(() => {
                    if (
                        this.props.tlds['es'] != undefined ||
                        this.props.tlds['hk'] != undefined ||
                        (this.props.tlds['moscow'] != undefined && this.props.isOrganization == false)
                    ) {
                        return (<Field name="extraPassportId"
                                       type="text"
                                       component={ TextField }
                                       label="Passport/ID Number"
                                       help="required for .moscow, .hk and .es"
                                       validate={[required({
                                           message: {
                                               id: 'form.errors.passport_id.required',
                                               defaultMessage: "Please provide your Passport/ID Number"
                                           }
                                       }), length({
                                           minimum: 8,
                                           message: {
                                               id: 'form.errors.passport_id.length',
                                               defaultMessage: "Please provide your Passport/ID Number"
                                           }
                                       })
                                       ]}
                        />);
                    }
                })()}
                {(() => {
                    if (
                        this.props.tlds['hk'] != undefined ||
                        (this.props.tlds['moscow'] != undefined && this.props.isOrganization == false)
                    ) {
                        let eighteenYearsAgo = new Date()
                        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)

                        return (<Field name="extraBirthday"
                                       type="date"
                                       component={ TextField }
                                       label="Birthday"
                                       help="required for .hk and .moscow"
                                       validate={[
                                           date({
                                               format: 'yyyy-mm-dd', '<': eighteenYearsAgo,
                                               message: {
                                                   id: 'form.errors.birthday.range',
                                                   defaultMessage: "Please provide your correct birthday"
                                               }
                                           }),
                                           required({
                                               message: {
                                                   id: 'form.errors.birthday.required',
                                                   defaultMessage: "Please provide your birthday"
                                               }
                                           })]}
                        />);
                    }
                })()}
                {(() => {
                    if (
                        this.props.tlds['moscow'] != undefined &&
                        this.props.isRussianCustomer == true &&
                        this.props.isOrganization == false
                    ) {
                        return (<Field name="extraState"
                                       type="date"
                                       component={ TextField }
                                       label="State (cyrillic)"
                                       help="required for .moscow"
                                       validate={[
                                           length({
                                               minimum: 6,
                                               message: {
                                                   id: 'form.errors.state.length',
                                                   defaultMessage: "Please provide the state in cyrillic writing"
                                               }
                                           }),
                                           required({
                                               message: {
                                                   id: 'form.errors.state.required',
                                                   defaultMessage: "Please provide the russian state in cyrillic writing"
                                               }
                                           })]}
                        />);
                    }
                })()}
                {(() => {
                    if (
                        this.props.tlds['moscow'] != undefined &&
                        this.props.isOrganization == true
                    ) {
                        return (<Field name="extraVatId"
                                       type="text"
                                       component={ TextField }
                                       label="VAT ID number"
                                       help="required for .moscow"
                                       validate={[
                                           required({
                                               message: {
                                                   id: 'form.errors.vatid.required',
                                                   defaultMessage: "Please enter your valid VAT ID number"
                                               }
                                           }),
                                           length({
                                               minimum: 6,
                                               message: {
                                                   id: 'form.errors.vat_id.length',
                                                   defaultMessage: "Please provide your VAT ID number"
                                               }
                                           })
                                       ]}
                        />);
                    }
                })()}
                {(() => {
                    if (
                        this.props.tlds['es'] != undefined &&
                        this.props.isOrganization == true
                    ) {
                        return (<Field name="extraLegalForm"
                                       type="select"
                                       component={ TextField }
                                       label="Legalform of your company"
                                       help="required for .es"
                                       validate={[
                                           numericality({
                                               '>=': 1,
                                               '<=': 879,
                                               message: {
                                                   id: 'form.errors.legalform.format',
                                                   defaultMessage: "Please select the legalform of your Company"
                                               }
                                           }),
                                           required({
                                               message: {
                                                   id: 'form.errors.legalform.required',
                                                   defaultMessage: "Please select the legalform of your Company"
                                               }
                                           })
                                       ]}
                            >
                                <option>-- Please select your companies legal form --</option>
                                <option value="1">Individual</option>
                                <option value="39">EconomicInterestGroup</option>
                                <option value="47">Association</option>
                                <option value="59">SportsAssociation</option>
                                <option value="68">ProfessionalAssociation</option>
                                <option value="124">SavingsBank</option>
                                <option value="150">CommunityProperty</option>
                                <option value="152">CommunityofOwners</option>
                                <option value="164">OrderorReligiousInstitution</option>
                                <option value="181">Consulate</option>
                                <option value="197">PublicLawAssociation</option>
                                <option value="203">Embassy</option>
                                <option value="229">LocalAuthority</option>
                                <option value="269">SportsFederation</option>
                                <option value="286">Foundation</option>
                                <option value="365">MutualInsuranceCompany</option>
                                <option value="434">RegionalGovernmentBody</option>
                                <option value="436">CentralGovernmentBody</option>
                                <option value="439">PoliticalParty</option>
                                <option value="476">TradeUnion</option>
                                <option value="510">FarmPartnership</option>
                                <option value="524">PublicLimitedCompany</option>
                                <option value="525">SportsAssociation</option>
                                <option value="554">CivilSociety</option>
                                <option value="560">GeneralPartnership</option>
                                <option value="562">GeneralandLimitedPartnership</option>
                                <option value="566">Cooperative</option>
                                <option value="608">Worker-ownedCompany</option>
                                <option value="612">LimitedCompany</option>
                                <option value="713">SpanishOffice</option>
                                <option value="717">TemporaryAllianceofEnterprises</option>
                                <option value="744">Worker-ownedLimitedCompany</option>
                                <option value="745">RegionalPublicEntity</option>
                                <option value="746">NationalPublicEntity</option>
                                <option value="747">LocalPublicEntity</option>
                                <option value="877">Others</option>
                                <option value="878">DesignationofOriginSupervisoryCouncil</option>
                                <option value="879">EntityManagingNaturalAreas</option>
                            </Field>
                        );
                    }
                })()}
                {(() => {
                    if (
                        this.props.tlds['xxx'] != undefined
                    ) {
                        return (<Field name="extraIcmMembershipToken"
                                       type="text"
                                       component={ TextField }
                                       label="ICM Membership Token"
                                       help="In order to operate an active .XXX domain, you will need an ICM Membership Token, a unique 16-character code assigned by ICM Registry. If you have already been assigned a Membership Token by ICM Registry, please enter it below and click Save All Changes. If you have not received an ICM Membership Token, ICM Registry will contact you via email to complete the Membership Token process."
                                       validate={[
                                           length({
                                               is: 16,
                                               message: {
                                                   id: 'form.errors.icmtoken.length',
                                                   defaultMessage: "Please enter your ICM Membership Token"
                                               }
                                           }),
                                           required({
                                               message: {
                                                   id: 'form.errors.icmtoken.required',
                                                   defaultMessage: "Please enter your ICM Membership Token"
                                               }
                                           })]}
                        />);
                    }
                })()}
            </div>
        )
    }

    render() {
        const {handleSubmit, submitting, pristine, reset} = this.props;
        return (
            <form onSubmit={ handleSubmit(validateAndUpdateExtraData) }>
                <div className="row">
                    <div className="col-sm-12">
                        <Panel header="Additional information required">
                            Some registries require domain registrants to provide some extra information to register
                            domains. These information may be randomly checked by the respective registry to ensure
                            registrants' compliance with their guidelines and requirements.
                            <br />This information is for
                            internal use by the registry only, and will not be displayed in the domain's public Whois
                            record.
                            <br />
                            <br />
                            {this.getExtraFields()}
                            <Button type="submit"
                                    bsStyle="primary"
                                    className="pull-right"
                                    disabled={ submitting }>
                                {submitting ? 'Saving...' : 'Save'}
                            </Button>
                        </Panel>
                    </div>
                </div>
            </form>
        )
    }
}


export default reduxForm({
    form: 'ExtraDataForm', // a unique identifier for this form
    //validate, //<--- validation function given to redux-form
    //asyncValidate*/
})(ExtraDataForm)
