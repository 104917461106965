import uuid from 'uuid';

function shouldFetchData(state, domain) {
    return !state.dnsZones.entities.hasOwnProperty(domain + '.');
}

function fetchData(domain) {
    return (dispatch, getState) => {
        if (shouldFetchData(getState(), domain)) {
            let state = getState();

            return dispatch({
                type: 'DNSZONE_FETCH',
                meta: {
                    zone: domain + '.'
                },
                payload: fetch(Routing.generate('portfolio_dns_zone', {
                    'zone': domain + '.',
                    '_token': state.csrf.token
                }), {
                    credentials: 'same-origin'
                }).then(response => {
                    return response.json();
                }).then(response => {
                    return {
                        resourceRecords: response.records.map((record) => {
                            return {
                                ...record,
                                key: uuid() // we nee a hash that stays the same over the entire lifetime of this object for animations
                            }
                        }),
                        glueRecords: response.glueRecords.map((glueRecord) => {
                            return {
                                ...glueRecord,
                                key: uuid()
                            }
                        })
                    }
                })
            })
        }
    }
}

function sortResourceRecords(zone, sortBy) {
    return {
        type: 'DNS_SORT_ZONE_RESOURCE_RECORDS',
        payload: {
            sortBy: sortBy,
            zone: zone
        }
    }
}

function editResourceRecord(zone, key) {
    return {
        type: 'DNSZONE_EDIT_RR',
        payload: {
            zone: zone,
            key: key
        }
    }
}

function cancelEditResourceRecord(zone) {
    return {
        type: 'DNSZONE_CANCEL_EDIT_RR',
        payload: {
            zone: zone
        }
    }
}

function deleteResourceRecord(zone, name, type, key, meta = {}) {

    let map = new Map();
    map.set('dnsRecordSet[name]', name);
    map.set('dnsRecordSet[type]', type);
    map.set('dnsRecordSet[_token]', null);

    return {
        type: 'DNSZONE_DELETE_RR',
        payload: {
            request: {
                method: 'post',
                url: Routing.generate('portfolio_dns_recordset_delete', {zone: zone}),
                data: map,
            }
        },
        meta: {
            zone: zone,
            name: name,
            type: type,
            key: key,
            ...meta
        }
    }
}

function saveResourceRecordSet(zone, name, type, resourceRecordSet, isNew, key, meta = {}) {

    let map = new Map();

    // csrf-protection
    map.set('dnsRecordSet[_token]', null);

    // name and type may have been changed => overwrite the old values
    if (!isNew) {
        map.set('dnsRecordSet[_name]', name);
        map.set('dnsRecordSet[_type]', type);
    }

    map.set('dnsRecordSet[name]', resourceRecordSet.name);
    map.set('dnsRecordSet[type]', resourceRecordSet.type);
    map.set('dnsRecordSet[ttl]', resourceRecordSet.ttl);

    if(resourceRecordSet.addWildcard) {
        map.set('dnsRecordSet[addWildcard]', resourceRecordSet.addWildcard);
    }

    map.set('dnsRecordSet[resourceRecords][]', resourceRecordSet.rdata);

    return {
        type: 'DNSZONE_SAVE_RR_SET',
        payload: {
            request: {
                method: 'post',
                url: Routing.generate('portfolio_dns_recordset_save', {zone: zone}),
                data: map,
            }
        },
        meta: {
            zone: zone,
            isNew: isNew,
            addWildcard: resourceRecordSet.addWildcard,
            name: name,
            type: type,
            key: key,
            rdata: resourceRecordSet.rdata,
            ttl: resourceRecordSet.ttl,
            ...meta
        }
    }
}

function editGlueRecord(zone, key) {
    return {
        type: 'DNSZONE_EDIT_GLUE',
        payload: {
            zone: zone,
            key: key
        }
    }
}

function cancelEditGlueRecord(zone, key) {
    return {
        type: 'DNSZONE_CANCEL_EDIT_GLUE',
        payload: {
            zone: zone,
            key: key
        }
    }
}

function deleteGlueRecord(zone, name, key) {
    let map = new Map();
    map.set('glueRecord[name]', name);
    map.set('glueRecord[_token]', null);

    return {
        type: 'DNSZONE_DELETE_GLUERECORD',
        payload: {
            request: {
                method: 'post',
                url: Routing.generate('portfolio_dns_gluerecord_delete', {zone: zone}),
                data: map,
            }
        },
        meta: {
            zone: zone,
            name: name,
            key: key
        }
    }
}

function saveGlueRecord(zone, name, glueRecord, isNew, key) {
    let map = new Map();

    // name and type may have been changed => overwrite the old values
    map.set('glueRecord[_token]', null);
    map.set('glueRecord[_name]', name);

    map.set('glueRecord[name]', glueRecord.name);

    // it might be better to submit all records
    for (let i in glueRecord.ipv4) {
        map.set('glueRecord[ipv4][]', glueRecord.ipv4[i]);
    }

    for (let i in glueRecord.ipv6) {
        map.set('glueRecord[ipv6][]', glueRecord.ipv6[i]);
    }

    return {
        type: 'DNSZONE_SAVE_GLUERECORD',
        payload: {
            request: {
                method: 'post',
                url: Routing.generate('portfolio_dns_gluerecord_save', {zone: zone}),
                data: map,
            }
        },
        meta: {
            zone: zone,
            isNew: isNew,
            name: name,
            key: key,
        }
    }
}

export default {
    fetchData,
    sortResourceRecords,
    editResourceRecord,
    cancelEditResourceRecord,
    deleteResourceRecord,
    saveResourceRecordSet,
    editGlueRecord,
    cancelEditGlueRecord,
    deleteGlueRecord,
    saveGlueRecord
}
