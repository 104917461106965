function sortBy(sortKey) {
    return {
        type: 'UPSELL_SORT',
        orderBy: sortKey
    }
}

function getUpsellDomains() {

    return (dispatch, getState) => {
        let state = getState();

        if (!state.upsell.isFetching) {

            let es = new EventSource(Routing.generate('search_upsell'));

            es.addEventListener("open", (event) => dispatch({
                type: 'UPSELL_EVENT_OPEN',
                event: event,
            }));
            es.addEventListener("result", (event) => {
                dispatch({
                    type: 'UPSELL_EVENT_RESULT',
                    result: JSON.parse(event.data)
                });
            });
            es.addEventListener("finish", (event) => dispatch({type: 'UPSELL_EVENT_FINISH', event: event}));
            es.addEventListener("message", (event) => dispatch({
                type: 'UPSELL_EVENT_MESSAGE',
                event: event
            }));
            es.addEventListener("error", (event) => {
                return dispatch({type: 'UPSELL_EVENT_ERROR', event: event})
            });
            es.onerror = (event) => {
                return dispatch({type: 'UPSELL_EVENT_ERROR'});
            };
        }
    }
}

export default {
    sortBy,
    getUpsellDomains
}