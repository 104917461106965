import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Alert, Button, OverlayTrigger, Popover} from "react-bootstrap";
import MaterialIcon from "../../components/MaterialIcon";
import {contactActions, domainActions} from "../../actions";
import LoadingIndicator from "../../components/LoadingIndicator";
import ContactDetails from "../../components/Portfolio/ContactDetails";
import ContactForm from "../../components/Portfolio/Form/ContactForm";
import Link from "../Link";

class ManageWhoisPage extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.fetchContactData();
        this.props.domainActions.fetchData();
    }

    render() {
        const ownerPopover = (
            <Popover id="ownerPopover">
                This is the owner of the above mentioned domain. If you change the owner of this Domain you are going to
                lose the rights for this domain
            </Popover>
        );

        const adminPopover = (
            <Popover id="ownerPopover">
                This is the admin contact of the above mentioned domain. The person who is named is allowed to change or
                delete the domain
            </Popover>
        );

        const techPopover = (
            <Popover id="techPopover">
                The domain is managed by the company which is managed here. You cannot change this contact.
            </Popover>
        );

        return (
            <div className="panel panel-default" id="portfolio-whois">
                <div className="panel-body">
                    <Link to={"/portfolio"}
                          className="btn btn-default btn-xs">
                        back
                    </Link>
                    <div className="row clearfix">
                        <div className="col-xs-12">
                            <h1>Update Whois Data for {this.props.match.params.domain}</h1>
                        </div>
                    </div>
                    {(() => {
                        if (this.props.isFetched != true || this.props.domains.isFetched != true) {
                            return (<div><LoadingIndicator active={true}/></div>);
                        } else {
                            const domain = this.props.domains.entities[this.props.match.params.domain];

                            const adminContact = this.props.entities[domain.adminContact];
                            const ownerContact = this.props.entities[domain.ownerContact];

                            return (<div className="row">
                                <div className="col-md-4 contact-data">
                                    <h3>Owner-C{' '}
                                        <OverlayTrigger overlay={ownerPopover}>
                                            <a href="#">
                                                <MaterialIcon size="24" icon="info"/>
                                            </a>
                                        </OverlayTrigger>
                                    </h3>
                                    <ContactDetails contact={ownerContact}/>
                                    <br/>
                                    <Button bsStyle="primary"
                                            onClick={() => this.props.editContact(domain.ownerContact, domain.adminContact == domain.ownerContact ? 'bothC' : 'ownerC')}>
                                        Change
                                    </Button>
                                </div>
                                <div className="col-md-4 contact-data">
                                    <h3>Admin-C{' '}
                                        <OverlayTrigger overlay={adminPopover}>
                                            <a href="#">
                                                <MaterialIcon size="24" icon="info"/>
                                            </a>
                                        </OverlayTrigger>
                                    </h3>
                                    <ContactDetails contact={adminContact}/>
                                    <br/>
                                    <Button bsStyle="primary"
                                            onClick={() => this.props.editContact(domain.adminContact, domain.adminContact == domain.ownerContact ? 'bothC' : 'adminC')}>
                                        Change
                                    </Button>
                                </div>
                                <div className="col-md-4 contact-data">
                                    <h3>Tech-C{' '}
                                        <OverlayTrigger overlay={techPopover}>
                                            <a href="#">
                                                <MaterialIcon size="24" icon="info"/>
                                            </a>
                                        </OverlayTrigger>
                                    </h3>
                                    <dl className="dl-horizontal">
                                        <dt>Company</dt>
                                        <dd>ud.com</dd>
                                        <dt>Firstname:</dt>
                                        <dd>Hostmaster</dd>
                                        <dt>Lastname:</dt>
                                        <dd>Hostmaster</dd>
                                        <dt>Address:</dt>
                                        <dd>Gautinger Strasse 10</dd>
                                        <dt>ZIP:</dt>
                                        <dd>82319</dd>
                                        <dt>City:</dt>
                                        <dd>Starnberg</dd>
                                        <dt>Country:</dt>
                                        <dd>DE</dd>
                                        <dt>Phone:</dt>
                                        <dd>+49.8151368670</dd>
                                        <dt>E-Mail:</dt>
                                        <dd>support@uniteddomains.com</dd>
                                    </dl>
                                </div>
                            </div>)
                        }
                    })()}
                    <hr/>
                    <Alert bsStyle="warning">
                        If you register a domain name on behalf of a person or entity other than yourself, that third party will
                        become the owner of said domain name. The owner or the owner's contact person (if the owner is not a
                        natural person) will also become the Admin-C of the domain name. This means that certain changes to the
                        domain registration will require the owner's or Admin-C's declaration or signature. The owner and
                        Admin-C, where applicable, are bound by our Terms and Conditions[s], including any third party terms,
                        conditions or agreements referenced therein or elsewhere in your agreement with ud.com. You
                        hereby declare and certify that you have power of representation to bind the owner and Admin-C by this
                        means.
                    </Alert>

                </div>
                {(() => {
                    if (this.props.isFetched == true && this.props.domains.isFetched == true) {

                        const domain = this.props.domains.entities[this.props.match.params.domain];

                        if (this.props.edit != null) {

                            return (
                                <ContactForm initialValues={{
                                    ...this.props.entities[this.props.edit],
                                    useFor: this.props.useFor
                                }}
                                             domain={domain.domain}
                                             submitEditForm={this.props.submitEditForm}
                                             cancelEdit={this.props.cancelEdit}
                                />
                            );
                        }
                    }
                })()}
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        ...state.contacts,
        domains: state.domains,
        router: state.router
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(contactActions, dispatch),
    domainActions: bindActionCreators(domainActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageWhoisPage)
