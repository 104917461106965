/**
 * Return `dictionary` sorted by `prop` in either ascending or decending order based
 * on the value of `order` (either 'asc' or 'desc').
 *
 * @param prop
 * @param order
 * @param dictionary
 * @param entities
 */
const sortedList = (prop = 'pos', order = 'asc', dictionary = [], entities = {}) => {

    return dictionary.sort((idA, idB) => {
        if (prop == '_rand') {
            return .5 - Math.random();
        }

        let a = entities[idA];
        let b = entities[idB];

        if (a[prop] > b[prop]) return order === 'asc' ? 1 : -1;
        if (a[prop] < b[prop]) return order === 'asc' ? -1 : 1;

        return 0;
    });
};

const upsellReducer = (state = {
    orderBy: 'pos',
    order: 'desc',
    entities: {},
    dictionary: [],
    eventSource: null,
    isFetching: false,
    isFetched: false
}, action) => {

    let entities;
    let dictionary;

    switch (action.type) {
        case "UPSELL_EVENT_OPEN":

            if (state.isFetched) {
                return {
                    order: state.order,
                    orderBy: state.orderBy,
                    entities: {},
                    dictionary: [],
                    filteredList: [],
                    isFetching: true,
                    isFetched: false,
                    eventSource: action.event.target
                }
            } else {
                return {
                    ...state,
                    order: 'desc',
                    orderBy: 'searchresult_weight',
                    isFetching: true,
                    eventSource: action.event.target,
                }
            }
        case 'UPSELL_EVENT_RESULT':
            entities = state.entities;
            entities[action.result.domain] = action.result;

            dictionary = [
                ...state.dictionary,
                action.result.domain
            ];

            return {
                ...state,
                dictionary: dictionary,
                filteredList: sortedList(state.orderBy, state.order, sortedList('pos', 'asc', state.dictionary, state.entities).slice(0, 5), state.entities),
                entities: entities
            }
        case 'UPSELL_EVENT_FINISH':
            state.eventSource.close();

            return {
                ...state,
                isFetching: false,
                isFetched: true,
                eventSource: null
            }
        case 'UPSELL_EVENT_MESSAGE':
            return state;
        case 'UPSELL_EVENT_ERROR':
            try {
                state.eventSource.close();
            } catch (err) {
            }

            return {
                ...state,
                isFetching: false,
                isFetched: true,
                eventSource: null
            }
        case 'UPSELL_SORT': {
            const newOrder = action.orderBy === state.orderBy && state.order === 'asc' ? 'desc' : 'asc';

            return {
                ...state,
                filteredList: sortedList(action.orderBy, newOrder, sortedList('pos', 'asc', state.dictionary, state.entities).slice(0, 5), state.entities),
                orderBy: action.orderBy,
                order: newOrder
            };
        }
        default: {
            return state;
        }
    }
};

export default upsellReducer;