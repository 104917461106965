import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {push} from 'connected-react-router'

class Link extends React.Component {

    render() {
        const {to, dispatch, ...props} = this.props;

        return (
            <a href={to} {...props} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                if (this.props.disabled == false) {
                    dispatch(push(to))
                }
                return false;
            }}>{this.props.children}</a>
        )
    }

    static propTypes = {
        to: PropTypes.string,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        disabled: false
    }
}

export default connect()(Link)
