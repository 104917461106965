export default function (seconds) {
    if (isNaN(seconds)) {
        throw new TypeError('Invalid value sent to stringifySeconds');
    }
    let result = {};
    let remainingTime = seconds;

    result.years = Math.floor(remainingTime / 60 / 60 / 24 / 365);
    remainingTime -= result.years * 60 * 60 * 24 * 365;
    result.weeks = Math.floor(remainingTime / 60 / 60 / 24 / 7);
    remainingTime -= result.weeks * 60 * 60 * 24 * 7;
    result.days = Math.floor(remainingTime / 60 / 60 / 24);
    remainingTime -= result.days * 60 * 60 * 24;
    result.hours = Math.floor(remainingTime / 60 / 60);
    remainingTime -= result.hours * 60 * 60;
    result.minutes = Math.floor(remainingTime / 60);
    remainingTime -= result.minutes * 60;
    result.seconds = Math.floor(remainingTime);

    let s = [];

    if (result.years > 0) {
        s.push(result.years + "y");
    }

    if (result.weeks > 0) {
        s.push(result.weeks + "w");
    }

    if (result.days > 0) {
        s.push(result.days + "d");
    }

    if (result.hours > 0) {
        s.push(result.hours + "h");
    }

    if (result.minutes > 0) {
        s.push(result.minutes + "m");
    }

    if (result.seconds > 0) {
        s.push(result.seconds + "s");
    }

    return s.join(' ');
}