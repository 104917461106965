import React from 'react';
import PropTypes from 'prop-types';
import {Button, FormControl, Modal} from 'react-bootstrap';
import {confirmable, createConfirmation} from 'react-confirm';
import {decToDeg, parse as parseLoc} from "../../util/parse/locRData";

import Map from '../Map'

/**
 * WE also need
 * - altitude (elevationService)
 * - vertical and horizontal precision?
 * -
 */
class MapsLocationField extends React.Component {

    constructor(props) {
        super(props);

        let _location = null;
        try {
            _location = parseLoc(this.props.options.loc);
        } catch (e) {
            _location = {
                hp: 100,
                vp: 1000,
                latitude: {
                    degrees: 38,
                    minutes: 33,
                    seconds: 0,
                    ns: 'N'
                },
                longitude: {
                    degrees: 101,
                    minutes: 1,
                    seconds: 0,
                    ew: 'W'
                },
                altitude: 0,
                size: 1000000
            }
        }

        this.state = {
            location: _location
        };

        this.onRadiusChanged = this.onRadiusChanged.bind(this);
        this.onCenterChanged = this.onCenterChanged.bind(this);
    }

    onRadiusChanged(radius) {

        this.setState({
            location: {
                ...this.state.location,
                size: radius
            }
        });
    }

    onCenterChanged(center) {

        let lat = decToDeg(center.location.lat);
        let lng = decToDeg(center.location.lng);

        lat.ns = center.location.lat < 0 ? 'S' : 'N';
        lng.ew = center.location.lng < 0 ? 'W' : 'E';

        this.setState({
            location: {
                ...this.state.location,
                latitude: lat,
                longitude: lng,
                altitude: center.altitude
            }
        });
    }

    getRecord() {
        let lat, lng;
        if (this.state.location.latitude) {
            lat = this.state.location.latitude;
            lng = this.state.location.longitude;
        } else {
            return '';
        }

        let record = '';
        record += lat.degrees + ' ';
        record += lat.minutes + ' ';
        record += (Math.round(lat.seconds * 1000) / 1000) + ' ';
        record += lat.ns + ' ';

        record += lng.degrees + ' ';
        record += lng.minutes + ' ';
        record += (Math.round(lng.seconds * 1000) / 1000) + ' ';
        record += lng.ew + ' ';

        record += Math.round(this.state.location.altitude) + 'm ';

        record += Math.round(this.state.location.size) + 'm ';
        record += '100m 100m';

        return record;
    }

    render() {
        const {
            show,
            proceed,
            dismiss,
            cancel,
        } = this.props;

        return (
            <div className="static-modal">
                <Modal show={show}
                       onHide={dismiss}
                       backdrop={this.props.options.enableEscape ? true : 'static'}
                       keyboard={this.props.options.enableEscape}>
                    <Modal.Header>
                        <Modal.Title>Select Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Select your location on this map to get its record value.
                        <div style={{minHeight: '400px'}}>
                            <Map
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC9Ytph-eLQK2uKR-d4P-Ao7z0DPxmXI6I&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{height: `100%`}}/>}
                                containerElement={<div style={{height: `400px`}}/>}
                                mapElement={<div style={{height: `100%`}}/>}
                                location={this.state.location}
                                onRadiusChanged={this.onRadiusChanged}
                                onCenterChanged={this.onCenterChanged}
                            />
                        </div>
                        <br/>
                        <div className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-2 control-label">Record:</label>
                                <div className="col-sm-10">
                                    <FormControl type="text"
                                                 name="resulting_record"
                                                 value={this.getRecord()}
                                                 onChange={(e) => {
                                                 }}
                                                 disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={cancel}>Cancel</Button>
                        <Button className='button-l' bsStyle="primary" onClick={() => {
                            proceed(this.getRecord())
                        }}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

MapsLocationField.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,     // called when ok button is clicked.
    cancel: PropTypes.func,      // called when cancel button is clicked.
    dismiss: PropTypes.func,     // called when backdrop is clicked or escaped.
    enableEscape: PropTypes.bool,
}

const defaultConfirmation = createConfirmation(confirmable(MapsLocationField));

export function selectLocation(confirmation, options = {}) {
    return defaultConfirmation({confirmation, ...options});
}
