import React from "react";
import PropTypes from "prop-types";

class CheckboxHints extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    handleChange(tld, checked, e) {
        let tlds = [];

        let {cart, toplevelDomains} = this.props;

        for(const cartDomain of cart.dictionary) {
            let cartItem = cart.entities[cartDomain];
            let toplevelDomain = toplevelDomains.entities[cartItem.tld];

            if (toplevelDomain.checkbox_confirmation_hint != undefined && toplevelDomain.checkbox_confirmation_hint != "") {
                tlds.push(cartItem.tld);
            }
        }

        let previousChecked = true;
        let postChecked = true;

        for(let _tld of tlds) {
            if(this.state[_tld] == undefined || this.state[_tld] == false) {
                previousChecked = false;
                break;
            }
        }

        this.setState((state, props) => {
            return {...state, [tld]: checked};
        }, () => {
            for(let _tld of tlds) {
                if(this.state[_tld] == false) {
                    postChecked = false;
                    break;
                }
            }

            if(tlds == []) {
                return true;
            }

            if(previousChecked != postChecked) {
                this.props.onChange(postChecked);
            }
        });
    }

    render() {
        // transform this into a redux-form
        const {cart, toplevelDomains, onChange} = this.props;

        const hints = new Map();
        const tlds = [];

        cart.dictionary.forEach((cartDomain) => {
            let cartItem = cart.entities[cartDomain];
            let toplevelDomain = toplevelDomains.entities[cartItem.tld];

            if (toplevelDomain.checkbox_confirmation_hint != undefined && toplevelDomain.checkbox_confirmation_hint != "") {
                hints.set(cartItem.tld, toplevelDomain.checkbox_confirmation_hint);
                tlds.push(cartItem.tld);
            }
        });

        if (hints.size > 0) {
            let ret = [];
            hints.forEach((hint, tld) => {
                ret.push(
                    <div className="checkbox" key={'tldCheckbox_' + tld}>
                        <label>
                            <input name={"tld_" + tld} type="checkbox" checked={this.state[tld]} onClick={(e) => this.handleChange(tld, e.target.checked)}/>
                            <span dangerouslySetInnerHTML={{__html: hint}}/>
                        </label>
                    </div>
                )
            });

            return (<div className="row clearfix">
                <div className="col-sm-12">
                    <hr/>
                </div>
                <div className="col-sm-12 col-md-8 col-md-offset-2">
                    <div className="alert alert-warning">
                        {ret}
                    </div>
                </div>
            </div>);
        } else {
            return null;
        }
    }

    static propTypes = {
        toplevelDomains: PropTypes.object,
        cart: PropTypes.object
    }

}

export default CheckboxHints
