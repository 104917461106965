import React from "react";
import PropTypes from 'prop-types';
import {Provider} from "react-redux";
import {IntlProvider} from "react-intl";
import {ConnectedRouter} from "connected-react-router";
import ReduxToastr from "react-redux-toastr";
import App from "./App";

class Root extends React.Component {

    render() {
        const {store, history} = this.props;

        return (
            <Provider store={store}>
                <IntlProvider locale="en">
                    <div>
                        <ConnectedRouter history={history}>
                           <App />
                        </ConnectedRouter>
                        <ReduxToastr
                            timeOut={5000}
                            newestOnTop={true}
                            position="top-right"
                            confirmOptions={{
                                okText: "OK",
                                cancelText: "Cancel"
                            }}
                        />
                    </div>
                </IntlProvider>
            </Provider>
        )
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

export default Root
