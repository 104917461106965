const paymentDataReducer = (state = {
    'form': {
        'type': 'cc',
        'cc_number': null,
        'cc_expdate_month': null,
        'cc_expdate_year': null,
        'addr_name': null,
        'addr_street': null,
        'addr_street2': null,
        'addr_city': null,
        'addr_zip': null,
        'addr_country': null,
    },
    'hiddenData': {
        'core_error_url': null,
        'datastorage_reuse_method': 20,
        'ipayment_session_id': null,
        'ipayment_paypal_session_id': null,
        'noparams_on_redirect_url': null,
        'return_paymentdata_details': 0,
        'silent': true,
        'trx_securityhash': null,
        'ud_transaction_id': null,
        'use_datastorage': true,
        'account_id': null,
        'trxuser_id': null,
        'trxpassword': null,
        'trx_paymenttyp': 'paypal',
        'trx_typ': 'base_check',
        'trx_amount': null,
        'trx_currency': DEFAULT_CURRENCY,
        'invoice_text': 'Invoice',
    },
    'shortCcNumber': null,
    'url': null,
    'saving': false,
    'checking': false,
    'checkCount': 0,
    'error': null
}, action) => {
    switch (action.type) {
        case "PAYMENTDATA_IPAYMENT_PENDING":
            return {...state, saving: true}
        case "PAYMENTDATA_IPAYMENT_REJECTED":
            return {...state, saving: false}
        case "PAYMENTDATA_IPAYMENT_FULFILLED":
            return {...state, saving: false, checking: true, checkCount: 0}
        case "PAYMENTDATA_CHECK_PENDING":
            return {...state, checkCount: state.checkCount++, checking: true};
        case "PAYMENTDATA_CHECK_REJECTED":
            return {...state, saving: false, checking: false}
        case "PAYMENTDATA_CHECK_FULFILLED":
            if (action.payload.finished == false) {
                return state;
            }

            if (action.payload.error == 0) {
                // no error
                return {
                    ...state,
                    hiddenData: {
                        ...state.hiddenData,
                        ipayment_session_id: action.payload.ipayment_session_id,
                        trx_securityhash: action.payload.trx_securityhash,
                        ud_transaction_id: action.payload.ud_transaction_id
                    },
                    shortCcNumber: action.payload.shortCcNumber,
                    saving: false,
                    checking: false,
                }
            } else {
                // error
                return {
                    ...state,
                    hiddenData: {
                        ...state.hiddenData,
                        ipayment_session_id: action.payload.ipayment_session_id,
                        trx_securityhash: action.payload.trx_securityhash,
                        ud_transaction_id: action.payload.ud_transaction_id
                    },
                    saving: false,
                    checking: false,
                    error: action.payload.errorMessage
                }
            }
        case "PAYMENTDATA_SAVE_ACK":
            return {
                ...state,
                saving: null,
                checking: null,
                error: null
            }
    }
    return state;
};

export default paymentDataReducer;
