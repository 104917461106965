function initTeaser(tlds, type) {
    return {
        type: "TOPLEVELDOMAINTEASER_INIT",
        toplevelDomains: tlds,
        tldType: type
    }
}
function filterBy(filter) {
    return {
        type: "TOPLEVELDOMAINTEASER_FILTER",
        filter: (filter == "" ? null : filter)
    }
}

export default {
    initTeaser,
    filterBy
}
