import {applyMiddleware, createStore} from "redux";
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import reducer from "./reducers";
import {createBrowserHistory} from "history";
import axios from 'axios';
import axiosMiddleware from './middleware';

import {routerMiddleware} from 'connected-react-router';

export const history = createBrowserHistory()

const client = axios.create({
    responseType: 'json',
});

const middlewareConfig = {
    interceptors: {
        request: [
            ({getState, dispatch, getSourceAction}, req) => {
                let csrfToken = getState().csrf.token;

                if (req.url.indexOf('_token=') > 0) {
                    req.url.replace('_token=', '_token=' + csrfToken);
                }

                if (req.url.indexOf('[_token]=') > 0) {
                    req.url.replace('[_token]=', '[_token]=' + csrfToken);
                }

                if (req.data.constructor == Map) {
                    // safari only supports a subset of FormData, so we need to use map instead
                    let formData = new FormData();

                    for(let d of req.data) {
                        if(d[0].indexOf('_token') >= 0) {
                            formData.append(d[0], csrfToken);
                        } else {
                            if(Array.isArray(d[1])) {
                                for (let i in d[1]) {
                                    formData.append(d[0], d[1][i]);
                                }
                            } else {
                                formData.append(d[0], d[1]);
                            }
                        }
                    }

                    req.data = formData;
                } else if (req.data.constructor == FormData && req.data.entries) {
                    for (let value of req.data.entries()) {

                        if (value[0].indexOf('_token') >= 0) {
                            req.data.set(value[0], csrfToken);
                        }
                    }
                }

                return req;
            }
        ]
    }
}

const middlewares = [routerMiddleware(history), thunk, axiosMiddleware(client, middlewareConfig), promise];

if (__DEV__) {
    middlewares.push(createLogger());
}

middlewares.push(store => next => action => {
    next({...action, getState: store.getState});
});

if (window.__PRELOADED_STATE__ == undefined) {
    window.__PRELOADED_STATE__ = null;
}

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

export default createStore(reducer(history), preloadedState, applyMiddleware(...middlewares))
