import React, {Component} from "react";
import {connect} from 'react-redux'
import {Field, formValueSelector, reduxForm, SubmissionError} from "redux-form";
import RadioField from "../../Form/RadioField";
import {Button} from "react-bootstrap";
import {dnsActions} from '../../../actions';
import TextField from "../../../components/Form/TextField";
import SimpleSelectField from "../../Form/SimpleSelectField";
import {required} from "redux-form-validators";
import {toastr} from "react-redux-toastr";

const validateAndUpdateRedirect = (values, dispatch, props) => {

    let valuesToSave;

    if(values.defaultOrCustom == "default") {
        valuesToSave = {
            name: ['@'],
            addWildcard: true,
            ttl: 600,
            type: 'x-http',
            rdata: [
                'FRAME https://www.uniteddomains.com/defaultpage/'
            ]
        };
    } else {
        valuesToSave = {
            name: ['@'],
            addWildcard: true,
            ttl: 600,
            type: 'x-http',
            rdata: [
                values.redirectType + ' ' + values.redirectTarget
            ]
        };
    }


    // we need to know if the submit was successful or not?
    return dispatch(dnsActions.saveResourceRecordSet(
        props.zone,
        props.initialValues.name,
        'x-http',
        valuesToSave,
        props.initialValues.key == '_new',
        props.initialValues.key
    )).then(action => {
        let result = action.value.data;

        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state
            if (props.initialValues.key) {
                props.reset();
            }
            toastr.success("Forward saved successfully!");
        } else {
            // since we cannot say which field caused the error, we just display a global error message
            /*let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }*/

            toastr.error("Failed to save Forward!");
            throw new SubmissionError();
        }
    }).catch(error => {
        let msg = null;

        if (error.response && error.response.data && error.response.data.exception) {
            msg = error.response.data.exception;
        }
        toastr.error("Failed to save Redirect!", msg);
        throw new SubmissionError();
    })
}

class DomainForwardConfigForm extends Component {

    render() {
        const {handleSubmit, submitting, pristine, reset, domain} = this.props;

        return (
            <form onSubmit={handleSubmit(validateAndUpdateRedirect)}>
                <Field name="defaultOrCustom"
                       disabled={this.props.disabled == true}
                       component={RadioField}
                       options={{
                           'default': <span key="default">Forward to our default page (<a href="https://www.uniteddomains.com/defaultpage" target="_blank">preview</a>)</span>,
                           'custom': <span key="custom">Forward <strong>{domain.domain}</strong> to</span>,
                       }} />
                <div className="row">
                    <div className="col-xs-3">
                        <Field name="redirectTarget"
                               component={TextField}
                               type="text"
                               placeholder="redirect-target"
                               className='rdata form-inline'
                               disabled={this.props.defaultOrCustom == "default" || this.props.disabled == true}
                               validate={[
                                   required({
                                       message: {
                                           id: 'form.errors.redirect.url_required',
                                           defaultMessage: "Please provide a valid URL to redirect to"
                                       }
                                   }),
                               ]}
                        >
                        </Field>
                    </div>
                    <div className="col-xs-3">
                        <Field name="redirectType"
                               component={SimpleSelectField}
                               disabled={this.props.defaultOrCustom == "default" || this.props.disabled == true}
                               simpleValue={true}
                               >
                            <option value="FRAME">Frame (hidden redirect) </option>
                            <option value="REDIRECT">301 Header (open redirect) </option>
                        </Field>
                    </div>
                </div>

                <Button bsStyle="primary" type="submit" disabled={submitting || this.props.disabled == true}>
                    {submitting?'Saving...':'Save'}
                </Button>{' '}
                <Button bsStyle="danger" type="button" disabled={submitting || this.props.disabled == true} onClick={reset}>
                    Reset
                </Button>
            </form>
        )
    }
}

DomainForwardConfigForm = reduxForm({
    form: 'DomainForwardConfigForm', // a unique identifier for this form
    onSubmit: validateAndUpdateRedirect,
    enableReinitialize: false
})(DomainForwardConfigForm)

const selector = formValueSelector('DomainForwardConfigForm');

export default connect(state => {
    const defaultOrCustom = selector(state, 'defaultOrCustom');

    return {
        defaultOrCustom,
    }
})(DomainForwardConfigForm);
