const toplevelDomainReducer = (state = {
    entities: {},
    dictionary: [],
    isFetching: false,
    isFetched: false,
}, action) => {

    switch (action.type) {
        case "TOPLEVELDOMAINLIST_FETCH_PENDING": {
            return {...state, isFetching: true}
        }
        case "TOPLEVELDOMAINLIST_FETCH_REJECTED": {
            return {...state, isFetching: false, error: action.payload}
        }
        case "TOPLEVELDOMAINLIST_FETCH_FULFILLED": {
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                entities: action.payload.entities.toplevelDomain,
                dictionary: action.payload.result,
            }
        }
        default: {
            return state;
        }
    }
};

export default toplevelDomainReducer;
