import React from "react";
import PropTypes from "prop-types";

class ContactDetails extends React.Component {

    render() {
        const {contact} = this.props;

        return (
            <dl className="dl-horizontal">
                <dt>Company</dt>
                <dd>{contact.organization}</dd>
                <dt>Firstname:</dt>
                <dd>{contact.firstname}</dd>
                <dt>Lastname:</dt>
                <dd>{contact.lastname}</dd>
                <dt>Address:</dt>
                <dd>{contact.street}</dd>
                <dt>ZIP:</dt>
                <dd>{contact.zip}</dd>
                <dt>City:</dt>
                <dd>{contact.city}</dd>
                <dt>Country</dt>
                <dd>{contact.country}</dd>
                <dt>Phone:</dt>
                <dd>{contact.phone}</dd>
                <dt>E-Mail:</dt>
                <dd>{contact.email}</dd>
            </dl>
        )
    }

    static propTypes = {
        // state data
        contact: PropTypes.object.isRequired
    }
}

export default ContactDetails
