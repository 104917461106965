import React from 'react';
import PropTypes from 'prop-types';

class SimpleSelectField extends React.Component {

    render() {

        const {input, label, type, help, meta: {touched, error, invalid, warning}, children} = this.props;

        let labelClasses = "control-label";
        let containerClasses = '';
        let placeholder;

        if (this.props.horizontal > 0) {
            labelClasses += " col-sm-" + this.props.horizontal;
            containerClasses += " col-sm-" + (12 - this.props.horizontal);
        }

        let groupClasses = 'form-group';

        if (this.props.className) {
            groupClasses += ' ' + this.props.className;
        }

        if (touched && invalid) {
            groupClasses += ' has-error';
        }

        if (this.props.size != null) {
            groupClasses += ' form-group-' + this.props.size;
        }

        if(this.props.disabled) {
            input.disabled = this.props.disabled;
        }

        return (<div className={groupClasses}>
            {label && <label className={labelClasses}>{label}</label>}
            <select className="form-control" {...input}>
                {children}
            </select>
        </div>);
    }


    static defaultProps = {
        size: null
    };

    static propTypes = {
        size: PropTypes.string,
        horizontal: PropTypes.number,
    }
}

export default SimpleSelectField;
