import React from "react";
import PropTypes from "prop-types";
import {Button, ButtonToolbar} from 'react-bootstrap';

class MailForwardSet extends React.Component {

    render() {
        const {mailForward, domain, deleting, testing} = this.props;

        return mailForward.targets.map((record, index) => {
            if (index > 0) {
                return <tr key={this.props._key + '_' + index}>
                    <td style={this.props.tdStyle} className="targetAddress">
                        {record}
                    </td>
                    <td className="filler"></td>
                </tr>
            } else {
                return <tr key={this.props._key + '_' + index}>
                    <td rowSpan={mailForward.targets.length} className="localPart">{mailForward.local}</td>
                    <td rowSpan={mailForward.targets.length}>&nbsp;@{domain.domain}</td>
                    <td className="targetAddress">{record}</td>
                    <td className="action" rowSpan={mailForward.targets.length}>
                        {!this.props.disableEdit &&
                        <ButtonToolbar>
                            <Button bsStyle="default"
                                    disabled={deleting || testing}
                                    onClick={() => this.props.deleteRecord(mailForward.local)}>
                                {deleting ? 'deleting...' : 'delete'}
                            </Button>
                            <Button bsStyle="primary"
                                    disabled={deleting || testing}
                                    onClick={() => this.props.editRecord(mailForward.local)}>
                                edit
                            </Button>
                            <Button bsStyle="info"
                                    disabled={testing} // testing
                                    onClick={() => this.props.testMailForward(mailForward.local, this.props.domain.domain)}>
                                {testing ? 'Sending Test Email...':'Send Test Email'}
                            </Button>
                        </ButtonToolbar>
                        }
                    </td>
                </tr>
            }
        });
    }

    static propTypes = {
        // actions
        editRecord: PropTypes.func.isRequired,
        deleteRecord: PropTypes.func.isRequired,
        testMailForward: PropTypes.func.isRequired,

        // state data
        _key: PropTypes.string.isRequired,
        deleting: PropTypes.bool.isRequired,
        //mailForward: PropTypes.object.isRequired,
        //style: PropTypes.object.isRequired,
        //className: PropTypes.string
    }
}

export default MailForwardSet
