import React from "react";
import {Panel} from "react-bootstrap";
import ClipboardInput from '../../ClipboardInput';
import QRCode from 'qrcode.react';
import LoadingIndicator from "../../LoadingIndicator";
import TextField from "../../Form/TextField";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {accountActions} from '../../../actions';
import {toastr} from 'react-redux-toastr'

function validateAndEnable2FA(values, dispatch) {
    return dispatch(accountActions.finish2FASetup(
        'google_authenticator',
        values.authcode,
        values.password)
    ).payload.then(result => {
        // Note: Error's "data" is in result.payload.response.data (inside "response")
        // success's "data" is in result.payload.data
        if (result.success) {
            //let other components know that everything is fine by updating the redux` state

            toastr.success("Thank you for completing this process. Your account is now protected by Two Factor Authentication.");

            toastr.confirm((
                    <div>
                        <strong>IMPORTANT:</strong>
                        <br/>
                        <br/>
                        <span>
                            You should soon receive an email containing a link to your account recovery codes.
                            Please read this email in full and save your recovery codes in a safe place.
                            <br />
                            If you lose your recovery codes and cannot access your account you will
                            be <span className="text-danger">permanently locked out</span>.
                        </span>
                    </div>
                ),
                {
                    okText: 'Download My Recovery Codes Now.',
                    cancelText: 'I Will Download My Recovery Codes via Email.',
                    onOk: () => {
                        window.open(Routing.generate('portfolio_account_backupcodes'), '_blank');
                    }
            });

            dispatch(accountActions.enable2FASuccess()); //ps: this is same as dispatching RESET_USER_FIELDS
        } else {

            toastr.error("Failed to enable two-factor Authentication for your account!");

            let errors = {};

            for (let field in result.errors.children) {
                if (result.errors.children[field] && result.errors.children[field].errors) {
                    errors[field] = result.errors.children[field].errors.join(', ');
                }
            }

            dispatch(accountActions.enable2FAFailure(values, errors));
            throw new SubmissionError(errors);
        }
    });
}

class TwoFactorAuthenticationForm extends React.Component {
    render() {
        const {handleSubmit, submitting, pristine, reset} = this.props;

        return <form onSubmit={handleSubmit(validateAndEnable2FA)}>
            <div>
                <Panel>
                    <h3>1. Scan this barcode with your app</h3>
                    Scan the below image with the authenticator app.
                    If your app cannot read this image, or does not support this feature, enter the text code on the
                    right instead.
                    <br/>
                    <br/>
                    {(() => {
                        if (this.props.qrcode) {
                            return <div className="row">
                                <div className="col-md-6">
                                    <QRCode value={this.props.qrcode} size={200}/>
                                </div>
                                <div className="col-md-6">
                                    <ClipboardInput readOnly={true} value={this.props.googleAuthenticatorSecret}/>
                                </div>
                            </div>
                        } else {
                            return <LoadingIndicator active={true}/>
                        }
                    })()}
                    <h3>2. Enter the six-digit code from the app</h3>
                    After scanning the barcode, you should see a six digit code in your app. Please enter the code and
                    your account password here:
                    <br/>
                    <br/>
                    <Field name="authcode" component={TextField}
                           placeholder="Current 6-digit-code from your app"/>
                    <br/>
                    <br/>
                    <h3>3. Enter your current account password</h3>
                    <Field name="password"
                           component={TextField}
                           type="password"
                           placeholder="Password"/>
                </Panel>
            </div>
        </form>
    }
}

export default reduxForm({
    form: 'Enable2FA', // a unique identifier for this form
    onSubmit: validateAndEnable2FA
    //validate, //<--- validation function given to redux-form
    //asyncValidate*/
})(TwoFactorAuthenticationForm)


