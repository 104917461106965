import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {cartActions, upsellActions} from "../actions";
import FormattedCurrency from "../components/FormattedCurrency"
import {OverlayTrigger, Popover, Table, Tooltip} from "react-bootstrap";
import MaterialIcon from "../components/MaterialIcon";
import LoadingIndicator from "../../../../../BackofficeBundle/Resources/assets/react/components/LoadingIndicator";

class ThankYouPage extends React.Component {

    constructor(props) {
        super(props)

        this.preregPopover = (
            <Popover id="preregPopover" title="Free Pre-Registration for New gTLDs">
                This is a free, non-binding pre-registration. Once we know this domain's price and date of
                General Availability you will have the option to confirm this pre-registration as a binding
                pre-order. We will notify you via email when you can pre-order this domain extension.
                Please see our FAQ page for more information.
            </Popover>
        );

        this.preorderPopover = (
            <Popover id="preorderPopover" title="Binding Pre-Order">
                This is a binding pre-order. By pre-ordering this domain, you allow United Domains to attempt to
                register this domain for you when it reaches General Availability, and to charge your credit card
                on file for the total displayed here if we register the domain for you successfully. You will only
                be charged if the domain is successfully registered to you at General Availability. You will not
                be charged beforehand, nor will you be charged if we cannot register the domain for you.
                Please see our FAQ page for more information.
            </Popover>
        );

        this.premiumPopover = (renewalPrice) => {
            return (<Popover id="premiumPopover" title="Premium Name">
                    This domain’s registry has classified it as Premium Name, which is why its registration fee is
                    higher
                    than the standard price. A domain’s classification as “premium” is determined by its registry
                    exclusively. As a domain registrar, United Domains has no influence in this process. The annual
                    renewal fee for the domain is <FormattedCurrency value={renewalPrice} addtax={true}/>
                </Popover>
            )
        };

        this.preorderPremiumPopover = (
            <Popover id="preorderPremiumPopover" title="Binding Pre-Order (Premium Name)">
                This domain’s registry has classified it as Premium Name, which is why its registration fee is higher
                than the standard price. A domain’s classification as “premium” is determined by its registry
                exclusively. As a domain registrar, United Domains has no influence in this process. This is a binding
                pre-order. By pre-ordering this domain, you allow United Domains to attempt to register this domain
                for you when it reaches General Availability, and to charge your credit card on file for the total
                displayed here if we register the domain for you successfully. You will only be charged if the domain
                is successfully registered to you at General Availability. You will not be charged beforehand, nor
                will you be charged if we cannot register the domain for you. Please see our FAQ page for more
                information.
            </Popover>
        );

        this.makeOfferTooltip = (searchResult) => {
            return <Tooltip id="makeOfferTooltip">Make an offer for {searchResult.domain}</Tooltip>
        };

        this.priceTooHighTooltip = (
            <Tooltip id="priceTooHighTooltip">Very expensive Domain</Tooltip>
        );

        this.getPopover = this.getPopover.bind(this);
    }

    handleResultClick(searchResult, e) {
        if (searchResult.status == 'available' || searchResult.status == 'forsale') {
            if (searchResult.trademark) {
                var options = {};
                var claimData = this.props.claims.entities[searchResult.domain];

                if (claimData != undefined) {
                    options = {
                        claimConfirmation: {
                            'domain': searchResult.domain,
                            'lookupKey': claimData.lookupKey,
                            'confirm': true,
                            'validUntil': claimData.validUntil,
                            'noticeId': claimData.noticeId
                        }
                    }
                }

                this.props.cartActions.addDomainToCart(
                    searchResult.domain,
                    searchResult.tld,
                    searchResult.trademark,
                    options,
                );
            } else {
                if (searchResult.status == 'forsale') {
                    this.props.cartActions.addDomainToCart(
                        searchResult.domain,
                        searchResult.tld,
                        null,
                        {'type': 12}
                    );
                } else {
                    this.props.cartActions.addDomainToCart(
                        searchResult.domain,
                        searchResult.tld,
                    );
                }
            }
        }
    }

    componentDidMount() {
        this.props.getUpsellDomains();
    }

    handleSort(sortBy) {
        this.props.sortBy(sortBy);
    }

    getDisplayStatus(searchResult) {
        switch (searchResult.displayStatus) {
            case 1:
                return (<span>is <strong>available</strong></span>);
                break;
            case 2:
                return (<span><strong>available</strong> for pre-order</span>);
                break;
            case 3:
                return (<span><strong>available</strong> for pre-registration</span>);
                break;
            case 4:
                return (<span><strong>for sale</strong></span>);
                break;
            case 6:
                if (searchResult.forSale == 1) {
                    return (<span>please contact customer services</span>);
                } else {
                    return (<span>please contact customer services</span>);
                }
                break;
            default:
                break;
        }
    }

    getPopover(searchResult) {
        if (searchResult.premium == 1) {
            if (this.props.toplevelDomains.entities[searchResult.tld].status == 10) {
                // live premiums
                return (<OverlayTrigger trigger={["hover", "focus"]} placement="bottom"
                                        overlay={this.premiumPopover(searchResult.renewalPrice)}>
                    <a><MaterialIcon icon="info_outline"/></a>
                </OverlayTrigger>);
            } else {
                // pre-order premiums
                return (<OverlayTrigger trigger={["hover", "focus"]} placement="bottom"
                                        overlay={this.preorderPremiumPopover}>
                    <a><MaterialIcon icon="info_outline"/></a>
                </OverlayTrigger>);
            }
        } else {
            if (this.props.toplevelDomains.entities[searchResult.tld].status == 1) {
                // pre-orders
                return (<OverlayTrigger trigger={["hover", "focus"]} placement="bottom"
                                        overlay={this.preorderPopover}>
                    <a><MaterialIcon icon="info_outline"/></a>
                </OverlayTrigger>);
            }
            else if (this.props.toplevelDomains.entities[searchResult.tld].status == 0) {
                // pre-regs
                return (<OverlayTrigger trigger={["hover", "focus"]} placement="bottom"
                                        overlay={this.preregPopover}>
                    <a><MaterialIcon icon="info_outline"/></a>
                </OverlayTrigger>);
            }
        }
    }

    getTableContents() {
        return this.props.filteredList.map((domainName) => {
            let searchResult = this.props.entities[domainName];
            let cart = this.props.cart;

            let className = searchResult.className;

            let displayStatus = this.getDisplayStatus(searchResult);

            return (

                <tr className={className} key={searchResult.domain}
                    onClick={(e) => this.handleResultClick(searchResult, e)}
                >
                    <td className="domain">{searchResult.domainName}.<strong>{searchResult.tld}</strong>
                        <span className="visible-xs">
                            {displayStatus}
                            {(() => {
                                if (searchResult.displayStatus >= 1 && searchResult.displayStatus <= 4) {
                                    return (<span> for <FormattedCurrency value={searchResult.price} addtax={true}/></span>)
                                }
                            })()}
                        </span>
                    </td>
                    <td className="status hidden-xs">
                        {this.getPopover(searchResult)}
                        {displayStatus}
                    </td>
                    <td className="price hidden-xs">
                        {(() => {
                            if (searchResult.status != 'makeoffer') {
                                return (<FormattedCurrency value={searchResult.price} addtax={true}/>)
                            }
                        })()}
                    </td>
                    <td className="actions">
                        {(() => {
                            if (cart.entities && cart.entities[domainName]) {
                                if (cart.entities[domainName].adding == true || cart.entities[domainName].removing == true) {
                                    return (<LoadingIndicator active inline size="small"/>);
                                } else {
                                    return (
                                        <a onClick={(e) => {e.preventDefault(); this.props.cartActions.removeDomainFromCart(cart.entities[domainName])}}>
                                            <MaterialIcon icon="clear"/>
                                        </a>
                                    );
                                }
                            } else {
                                if (searchResult.status == 'makeoffer') {
                                    return (
                                        <OverlayTrigger placement="bottom"
                                                        overlay={this.makeOfferTooltip(searchResult)}>
                                            <a href={searchResult.link} title={'Make offer for ' + searchResult.domain}
                                               target="_blank" className="btn btn-success btn-xs">
                                                <span className="hidden-xs" >Make offer </span>
                                                <MaterialIcon icon="exit_to_app" />
                                            </a>
                                        </OverlayTrigger>
                                    );
                                } else if (searchResult.status == 'available' || searchResult.status == 'forsale') {
                                    return (
                                        <a onClick={(e) => {e.preventDefault()}}
                                           className="btn btn-success btn-xs">
                                        <span className="hidden-xs" >Add to cart <MaterialIcon
                                            icon="shopping_cart" /></span>
                                            <span className="visible-xs" >Buy&nbsp;<MaterialIcon
                                                icon="shopping_cart" /></span>
                                        </a>
                                    );
                                } else if (searchResult.status == 'price-too-high') {
                                    return (
                                        <OverlayTrigger placement="bottom" overlay={this.priceTooHighTooltip}>
                                            <a href={Routing.generate('contact_index')}><MaterialIcon icon="input"/></a>
                                        </OverlayTrigger>
                                    );
                                } else {
                                    return;
                                }
                            }
                        })()}
                    </td>
                </tr>
            )
        })
    }

    render() {
        let {order, orderBy} = this.props;

        return (
            <div id="upsell-domains">
                <Table>
                    <thead>
                    <tr>
                        <th onClick={() => this.handleSort('domain')}
                            className={orderBy == 'domain' ? 'sorting-' + order : 'sorting'}>
                            Domain&nbsp;
                        </th>
                        <th className="hidden-xs">
                            <span>Status&nbsp;</span>
                        </th>
                        <th onClick={() => this.handleSort('price')}
                            className={orderBy == 'price' ? ' hidden-xs price sorting-' + order : ' hidden-xs price sorting'}>
                            <span>Price&nbsp;</span>
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {(() => {
                        if (this.props.dictionary.length > 0) {
                            return this.getTableContents()
                        } else {
                            if (this.props.isFetched) {
                                return (<tr>
                                    <td colSpan="4" className="no-matching-results">
                                        No results are matching your filter
                                    </td>
                                </tr>);
                            }
                        }
                    })()}
                    </tbody>
                </Table>
            </div>
        );
    }

    static propTypes = {
        //navigate: PropTypes.bool
    }
}

const mapStateToProps = function (state) {
    return {
        ...state.upsell,
        cart: state.cart,
        toplevelDomains: state.toplevelDomains
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(upsellActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage)
