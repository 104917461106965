import {normalize} from "normalizr";
import * as schema from "../schema";
import { submit } from 'redux-form'
import {toastr} from 'react-redux-toastr'

function shouldFetchData(state) {
    return (Object.keys(state.contacts.dictionary).length == 0 && !state.contacts.isFetching)
}

function fetchContactData() {
    return (dispatch, getState) => {
        if (shouldFetchData(getState())) {
            return dispatch({
                type: 'CONTACTLIST_FETCH',
                payload: fetch(Routing.generate('portfolio_contacts_json'), {
                    method: 'POST',
                    credentials: 'same-origin'
                }).then(response => {
                    return response.json()
                }).then(contacts => {
                    if (contacts.length == 0) {
                        return {
                            entities: {
                                contacts: []
                            },
                            result: []
                        }
                    } else {
                        return normalize(contacts, schema.arrayOfContacts);
                    }
                })
            })
        }
    }
}

function editContact(handle, useFor) {
    return {
        type: 'CONTACT_EDIT',
        payload: {
            handle: handle,
            useFor: useFor
        }
    }
}

function cancelEdit() {
    return {
        type: 'CONTACT_EDIT_CANCEL',
        payload: null
    }
}

function submitEditForm() {
    return submit('ContactForm');
}

function updateContact(props, domain)
{
    return (dispatch, getState) => {

        let formData = new FormData();

        for (name in props) {
            formData.append('contact[' + name + ']', props[name]);
        }

        let state = getState();
        formData.append('contact[_token]', state.csrf.token)

        let headers = new Headers({
            "Accept": "application/json"
        });

        return {
            type: 'CONTACT_UPDATE',
            payload: fetch(Routing.generate('portfolio_whois_contact_save', {'domain': domain}), {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
                headers: headers
            }).then(response => {
                return response.json();
            })
        }
    }
}

function saveContactSuccess(values, domain)
{
    toastr.success("Contact updated successfully!");

    return {
        type: 'CONTACT_UPDATE_SUCCESS',
        payload: {
            contact: values,
            domain: domain
        }
    };
}

function saveContactFailure(values, errors, message = null)
{

    if(message != null) {
        toastr.error("Failed to update Contact! " + message);
    } else {
        toastr.error("Failed to update Contact!")
    }

    return {
        type: 'CONTACT_UPDATE_FAILED',
        payload: errors
    };
}

export default {
    saveContactSuccess,
    saveContactFailure,
    fetchContactData,
    editContact,
    cancelEdit,
    submitEditForm,
    updateContact,
}
