import {addValidator} from "redux-form-validators";

/**
 * Validates the local part of an eMail-Address
 */
export default addValidator({
    validator: (options, value, allValues) => {

        let domain = "my-domain.com";
        if(options.hasOwnProperty('domain')) {
            domain = options.domain;
        }

        let REG_LOCAL_PART_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))$/i;

        if (
            value != '*' &&
            !value.match(REG_LOCAL_PART_EMAIL)
        ) {
            return {
                id: "form.errors.mailforward.invalid_local_part",
                defaultMessage: "Enter the local part of the email address (e.g. enter \"info\" to create info@" + domain + ") or enter * to create a single \"catchall address\"."
            }
        }

        // duplicates labels
        let duplicates = options.allForwards.reduce((accumulator, currentValue) => {
            if (
                currentValue == value &&
                currentValue != options.initialLocal
            ) {
                return true;
            }

            return accumulator;
        }, false);

        if (duplicates == true) {
            return {
                id: "form.errors.mailforward.duplicate_local",
                defaultMessage: "Forwards for " + value + "@" + domain + " are already defined. Please edit the forward below"
            }
        }

        return true;
    }
});
