import React from "react";
import {Panel} from "react-bootstrap";
import InvoiceTable from "../../components/Portfolio/InvoiceTable";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {invoiceActions, paymentDataActions} from "../../actions";
import PaymentDataForm from "../../components/Form/PaymentDataForm";

class BillingPage extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Panel className="no-border" id="payment">
                <h1>Billing Information</h1>
                <br/>
                <div className="credit-card summary">
                    <PaymentDataForm initialValues={this.props.paymentData.form}
                                     hiddenData={this.props.paymentData.hiddenData}
                                     savePaymentData={this.props.paymentDataActions.savePaymentData}
                                     url={this.props.paymentData.url}
                                     saving={this.props.paymentData.saving}
                                     checking={this.props.paymentData.checking}
                                     startChecking={this.props.paymentDataActions.startChecking}
                                     stopChecking={this.props.paymentDataActions.stopChecking}
                                     _error={this.props.paymentData.error}
                                     shortCcNumber={this.props.paymentData.shortCcNumber}
                                     pf={true}
                                     ccNumber={this.props.ccNumber}
                    />
                </div>
                <hr/>
                <h2>Your Invoices</h2>
                <InvoiceTable {...this.props.invoiceActions}
                              {...this.props.invoices}
                />
                <br/>
                <div className="alert alert-warning" role="alert">
                    As part of the consolidation of our services to United Domains Germany, we’ve moved several
                    functions of the Renewal page:
                    <ul>
                        <li><strong>Manual Renewals</strong></li>
                        <li><strong>Auth Code Retrieval</strong></li>
                    </ul>

                    to the Manage Domains page, accessible by clicking Domains on the left side of the page.
                </div>
            </Panel>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        invoices: state.invoices,
        paymentData: state.paymentData
    }
}

const mapDispatchToProps = (dispatch) => ({
    invoiceActions: bindActionCreators(invoiceActions, dispatch),
    paymentDataActions: bindActionCreators(paymentDataActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage)
