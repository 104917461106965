import React from "react";
import PropTypes from 'prop-types';
import {Button, Modal} from "react-bootstrap";
import FormattedCurrency from "./FormattedCurrency";
import Dinero from 'dinero.js'

/**
 * Props:
 *  -> OK-Text
 *  -> Cancel-Text
 *  -> onConfirm
 *  -> onCancel
 */
class ConfirmPreRegistrations extends React.Component {

    getPreRegsRows() {
        return this.props.preRegsToConfirmDictionary.map((preRegDomain) => {
            let preReg = this.props.preRegsToConfirmEntities[preRegDomain];

            return (
                <tr key={'preReg_' + preRegDomain}>
                    <td>
                        <input type="checkbox"
                               onChange={(e) => this.props.setPreRegToConfirm(preReg, e.target.checked)}
                               checked={preReg.checked == true}/>
                    </td>
                    <td>{preReg.domain}</td>
                    <td><FormattedCurrency value={preReg.price} addtax={true}/></td>
                </tr>);
        });
    }

    render() {
        let {preRegsToConfirmDictionary, preRegsToConfirmEntities, onConfirm, onCancel, preRegsConfirming} = this.props

        if (preRegsToConfirmDictionary.length == null) {
            return null;
        }

        let allChecked = preRegsToConfirmDictionary.reduce((allChecked, preRegDomain) => {
            return allChecked && preRegsToConfirmEntities[preRegDomain].checked == true;
        }, true);

        let selectedDomains = preRegsToConfirmDictionary.filter((preRegDomain) => {
            return preRegsToConfirmEntities[preRegDomain].checked == true;
        });

        return (
            <Modal show={true} onHide={() => onCancel()} animation={true} id="preOrderModal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {preRegsToConfirmDictionary.length > 1 ? 'One Other Domain ' : 'Other Domains '}
                        to pre-order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    There {preRegsToConfirmDictionary.length == 1 ? 'is one' : 'are ' + preRegsToConfirmDictionary.length}
                    other pre-registered domain-name up for confirmation:
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>
                                <input type="checkbox"
                                       onChange={(e) => this.props.setAllPreRegsToConfirm(e.target.checked)}
                                       checked={allChecked == true}/>
                            </th>
                            <th>Pre-Registered Domain</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.getPreRegsRows()}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td></td>
                            <td>Sum:</td>
                            <td>{(() => {
                                let sum = this.props.preRegsToConfirmDictionary.reduce((sum, preRegDomain) => {
                                    let preReg = this.props.preRegsToConfirmEntities[preRegDomain];

                                    return sum.add(Dinero(preReg.price));
                                }, Dinero({amount: 0, currency: this.props.preRegsToConfirmEntities.entities[this.props.preRegsToConfirmDictionary[0]].price.currency}));

                                {/* TODO: Currency Calculation */}
                                return <FormattedCurrency value={sum} addtax={true} />
                            })()}</td>
                        </tr>
                        </tfoot>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onCancel()} bsStyle="primary">Cancel</Button>
                    <Button bsStyle="success" className="pull-right"
                            disabled={selectedDomains.length == 0 || preRegsConfirming == true}
                            onClick={(e) => onConfirm(preRegsToConfirmDictionary, preRegsToConfirmEntities)}>
                        {(() => {
                            if (preRegsConfirming) {
                                return <span>Adding selected domain{selectedDomains.length > 1 ? 's...' : '...'}</span>
                            } else {
                                return <span>Add {selectedDomains.length > 1 ? selectedDomains.length : ''} selected domain{selectedDomains.length == 0 || selectedDomains > 1 ? 's' : ''}
                                    to Cart</span>
                            }
                        })()}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ConfirmPreRegistrations.propTypes = {
    // actions
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    setPreRegToConfirm: PropTypes.func.isRequired,
    setAllPreRegsToConfirm: PropTypes.func.isRequired,

    preRegsToConfirmDictionary: PropTypes.array.isRequired,
    preRegsToConfirmEntities: PropTypes.object.isRequired,
    preRegsConfirming: PropTypes.bool.isRequired,
}

export default ConfirmPreRegistrations
