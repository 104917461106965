var shuffle = require('shuffle-array');

const filteredList = (filter = '', tlds) => {

    if (filter) {
        return tlds.filter((tld) => {
            return ('.' + tld).indexOf(filter.toLowerCase()) >= 0;
        });
    }

    return tlds;
};

const toplevelDomainTeaserReducer = (state = {
    filter: null,
    defaultList: [],
    list: [],
    tlds: [],
    type: 'top'
}, action) => {

    switch (action.type) {
        case "TOPLEVELDOMAINTEASER_INIT": {

            let defaultList, list, tlds;

            switch (action.tldType) {
                case 'live':
                    tlds = action.toplevelDomains.dictionary.filter((tld) => {
                        var toplevelDomain = action.toplevelDomains.entities[tld];

                        return toplevelDomain.status == 10;
                    });
                    shuffle(tlds);
                    defaultList = tlds.slice(0, 25).sort();
                    break;
                case 'preOrder':
                    tlds = action.toplevelDomains.dictionary.filter((tld) => {
                        var toplevelDomain = action.toplevelDomains.entities[tld];

                        return toplevelDomain.status == 1;
                    });
                    defaultList = tlds.sort();
                    break;
                case 'preReg':
                    tlds = action.toplevelDomains.dictionary.filter((tld) => {
                        var toplevelDomain = action.toplevelDomains.entities[tld];

                        return toplevelDomain.status == 0;
                    });
                    shuffle(tlds);
                    if (tlds.length >= 25) {
                        defaultList = tlds.slice(0, 25).sort();
                    } else {
                        defaultList = tlds.sort();
                    }
                    break;
                case 'top':
                    defaultList = action.toplevelDomains.dictionary.filter((tld) => {
                        var toplevelDomain = action.toplevelDomains.entities[tld];

                        return toplevelDomain.searchresult_weight != 0;
                    }).sort((tld1, tld2) => {
                        var toplevelDomain1 = action.toplevelDomains.entities[tld1];
                        var toplevelDomain2 = action.toplevelDomains.entities[tld2];

                        return toplevelDomain1.searchresult_weight > toplevelDomain2.searchresult_weight;
                    }).reverse()

                    break;
            }

            if (defaultList.length == 0) {
                tlds = action.toplevelDomains.dictionary;
                shuffle(tlds);

                defaultList = tlds.slice(0, 24);
            }

            if (state.filter == null || state.filter == '') {
                list = defaultList;
            } else {
                list = filteredList(state.filter, tlds);
            }
            return {
                ...state,
                tlds: action.toplevelDomains.dictionary,
                defaultList: defaultList,
                list: list,
                type: action.tldType
            };
        }
        case "TOPLEVELDOMAINTEASER_FILTER": {
            let list = filteredList(action.filter, state.tlds);

            return {
                ...state,
                filter: action.filter,
                list: list
            };
        }
        default: {
            return state;
        }
    }
};

export default toplevelDomainTeaserReducer;
