import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import MaterialIcon from "../MaterialIcon";
import TwoFactorAuthenticationForm from "./Form/TwoFactorAuthenticationForm";
import {toastr} from "react-redux-toastr";

class TwoFactorAuthenticationPanel extends React.Component {

    edit2FA() {
        return (<div>
            <div className="row btb15">
                <div className="col-md-4">
                    Status: <strong className="green">On <MaterialIcon icon="done"/></strong>
                </div>
                <div className="col-md-8">
                    {(() => {
                        if (this.props.disabling) {
                            return <Button bsStyle="danger" disabled={true}>
                                Disabling two-factor authentication
                            </Button>
                        } else {
                            return <Button bsStyle="danger" onClick={() => {
                                this.props.disable()
                            }}>
                                Disable two-factor authentication
                            </Button>
                        }
                    })()}
                </div>
            </div>
            <div className="row bb15">
                <div className="col-md-4">Recovery Codes</div>
                <div className="col-md-8">
                    Recovery codes can be used to access your account in the event you lose access to your device and
                    cannot receive two-factor authentication codes.

                    United Domains Support cannot restore access to accounts with two-factor authentication enabled for
                    security
                    reasons, saving your recovery codes in a safe place can help keep you from being locked out of your
                    account.
                    <br/>
                    <br/>
                    <a href={Routing.generate('portfolio_account_backupcodes')} className="btn btn-default">Download
                        recovery codes</a>
                </div>
            </div>
        </div>)
    }

    enable2FA() {
        return (
            <div className="row">
                <div className="col-md-6">
                    Status: <strong className="red">Off <MaterialIcon icon="clear"/></strong>
                </div>
                <div className="col-md-6">
                    <Button onClick={() => {
                        this.props.setup()
                    }}>
                        Set up two-factor authentication
                    </Button>
                </div>
                {this.setupDialog()}
            </div>
        )
    }

    setupDialog() {
        return (
            <Modal show={this.props.settingUp != false} onHide={this.props.cancelSetup}>
                <Modal.Header closeButton>
                    <Modal.Title>How to Set Up Two Factor Authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(() => {
                        if (this.props.settingUp == true) {
                            return <p>
                                Two Factor Authentication is a secure, easy way to ensure no one can gain access to your
                                United Domains account without your knowledge.<br/>
                                Once enabled, when you login normally with your username and password, you’ll also need
                                an app on your phone, which will generate a special, timed code you enter to ensure you
                                are the rightful account holder.
                                <br/>
                                <img style={{width: '100%'}}
                                     src="/img/2fa-steps.png"
                                     alt="Sample image to explain 2FA"/>
                                <br/>
                                We recommend installing the Google Authenticator App for <a
                                href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">Apple
                                iOS</a> or <a
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">Android</a> devices.
                                <br/>
                            </p>
                        } else if (this.props.settingUp == 'google_authenticator') {
                            return <TwoFactorAuthenticationForm initialValues={{
                                authcode: "",
                                password: ""
                            }} generatingSecret={this.props.generating_secret}
                                                                googleAuthenticatorSecret={this.props.google_authenticator_secret}
                                                                qrcode={this.props.qrcode}
                            />
                        }
                    })()}

                </Modal.Body>
                <Modal.Footer>
                    {(() => {
                        if (this.props.settingUp == true) {
                            return (<Button onClick={() => {
                                this.props.setup('google_authenticator')
                            }} bsStyle="primary">Set up with authenticator app</Button>);
                        } else if (this.props.settingUp == 'google_authenticator') {
                            if (this.props.enabling == true) {
                                return (<Button bsStyle="primary" disabled={true}>Saving...</Button>)
                            } else {
                                return (<Button onClick={() => {
                                    this.props.submitForm()
                                }}
                                                bsStyle="primary">Save</Button>)
                            }
                        }
                    })()}
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        if (this.props.configured == true) {
            return this.edit2FA();
        } else {
            return this.enable2FA();
        }
    }

    static propTypes = {
        // actions
        setup: PropTypes.func.isRequired,
        cancelSetup: PropTypes.func.isRequired,

        // state data
        method: PropTypes.string,
        settingUp: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ])
    }
}

export default TwoFactorAuthenticationPanel


