import React from "react";
import PropTypes from 'prop-types';

class LoadingIndicator extends React.Component {

    render() {
        if (this.props.active !== true) {
            return null;
        }

        let classes = ['ui', 'loader', 'active'];

        if (this.props.inline == true) {
            classes.push('inline');
        }

        classes.push(this.props.size);

        if (this.props.text) {
            classes.push('text');
        }

        return (
            <div className={classes.join(' ')}>{this.props.text}</div>
        )
    }
}

LoadingIndicator.defaultProps = {
    size: 'medium',
    active: false,
    inline: false
};
LoadingIndicator.propTypes = {
    size: PropTypes.string,
    active: PropTypes.bool.isRequired,
    inline: PropTypes.bool,
    text: PropTypes.string
}

export default LoadingIndicator