import React from "react";
import {connect} from "react-redux";
import Dinero from 'dinero.js'
import {bindActionCreators} from "redux";
import {cartActions} from "../actions";
import CartTable from "../components/CartTable";
import {Button} from "react-bootstrap";
import ConfirmActivateWPSCartModal from "../components/ConfirmActivateWPSCartModal";
import ConfirmPreRegistrations from "../components/ConfirmPreRegistrations";
import TldHints from "../components/TldHints";
import PromotionHint from "../components/PromotionHint";
import GdprHints from "../components/GdprHints";
import TaxHint from "../components/TaxHint";

class CartPage extends React.Component {

    constructor(props) {
        super(props)
    }

    isLoading() {
        return this.props.cart.dictionary.some((domainName) => {
            let cartItem = this.props.cart.entities[domainName];

            return cartItem.adding || cartItem.removing || cartItem.savingWPS;
        })
    }


    render() {
        const {currentWPSDomain} = this.props.cart;

        let loading = this.isLoading();

        return (
            <div id="cart">
                <CartTable {...this.props} loading={loading}/>
                <div className="text-right">
                {(() => {
                    if (loading == true) {
                        return (<Button disabled={true} bsStyle="success" bsSize="large">
                            saving...
                        </Button>)
                    }

                    if (this.props.cart.dictionary.length == 0) {
                        return (<Button disabled={true} bsStyle="success" bsSize="large">Continue Checkout</Button>)
                    } else {
                        return (
                            <a href={Routing.generate('order_review')} className='btn btn-success btn-lg'>
                                Continue Checkout
                            </a>)
                    }
                })()}
                </div>
                <ConfirmActivateWPSCartModal
                    cartItem={this.props.cart.entities[currentWPSDomain]}
                    onConfirm={() => this.props.setWps(this.props.cart.entities[currentWPSDomain], true)}
                    onCancel={() => this.props.changeWPSDomain(null)}
                    wpsPrice={Dinero({amount: WPS_PRICE, currency: DEFAULT_CURRENCY})}
                />

                {(() => {
                    if (this.props.cart.preRegsToConfirmDictionary.length > 0) {
                        return <ConfirmPreRegistrations
                            preRegsToConfirmEntities={this.props.cart.preRegsToConfirmEntities}
                            preRegsToConfirmDictionary={this.props.cart.preRegsToConfirmDictionary}
                            preRegsConfirming={this.props.cart.preRegsConfirming}
                            onConfirm={this.props.confirmPreRegs}
                            onCancel={this.props.cancelPreRegs}
                            setPreRegToConfirm={this.props.setPreRegToConfirm}
                            setAllPreRegsToConfirm={this.props.setAllPreRegsToConfirm}
                        />
                    }
                })()}
                <TaxHint />
                <br />
                <PromotionHint cart={this.props.cart} toplevelDomains={this.props.toplevelDomains}/>
                <TldHints cart={this.props.cart} toplevelDomains={this.props.toplevelDomains}/>
                <GdprHints cart={this.props.cart} toplevelDomains={this.props.toplevelDomains}/>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        cart: state.cart,
        toplevelDomains: state.toplevelDomains
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CartPage)
