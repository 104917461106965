import React from "react";
import PropTypes from 'prop-types';
import {Modal, Button} from "react-bootstrap";
import dateformat from "dateformat";

/**
 * Props:
 *  -> OK-Text
 *  -> Cancel-Text
 *  -> onConfirm
 *  -> onCancel
 */
class ConfirmDisableWPSModal extends React.Component {
    render() {
        let {domain, onConfirm, onCancel} = this.props

        if (domain == null || domain.wps != 'enabled') {
            return null;
        }

        return (
            <Modal show={true} onHide={() => onCancel()}>
                <Modal.Header closeButton>
                    <Modal.Title>Disable Domain Privacy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>WARNING:</strong>You are about to disable Domain Privacy for {domain.domain}.
                    Your personal data will then be visible to the public. You can enable Domain Privacy free of
                    charge at any time during your domain’s current registration term again.
                    <br />
                    <br />
                    Your Whois Protection settings are now updated. Please allow up to 48 hours for third-party
                    Whois lookup services to reflect your updates.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                    <Button bsStyle="primary"
                            onClick={() => onConfirm()}
                            disabled={domain.savingWPS}>
                        {domain.savingWPS ? 'Saving...' : 'Disable'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ConfirmDisableWPSModal.propTypes = {
    // actions
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ConfirmDisableWPSModal