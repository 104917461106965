import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {paymentDataActions} from "../actions";
import OrderProgress from "../components/OrderProgress";
import PaymentDataForm from "../components/Form/PaymentDataForm";

class PaymentPage extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="payment">
                <OrderProgress step={3} active={this.props.cart.dictionary.length > 0}/>
                <div className="row clearfix">
                    <div className="col-xs-12 mtb20">
                        <h3 className="text-left">Payment Details</h3>
                    </div>
                </div>
                {(() => {
                    let cart = this.props.cart;
                    let onlyPreRegs = cart.dictionary.reduce(function (onlyPreRegs, domain) {
                        let cartItem = cart.entities[domain];

                        return cartItem.type == 'pre-registration' && onlyPreRegs;
                    }, true);

                    if (onlyPreRegs == true) {
                        return (
                            <div className="row">
                                <div className="col-xs-12 mtb20">
                                    <h4>Why do we ask for this?</h4>
                                    To help us verify your account, we require you to enter a credit card. Your card
                                    will not be charged unless you decide to confirm your pre-order later. Your credit
                                    card statement may temporarily show an authorization hold from us for under $1.00.
                                    Your bank will clear this hold after a short time.
                                </div>
                            </div>
                        )
                    } else {
                        return null;
                    }
                })()}
                {(() => {
                    if (this.props.paymentData.success != null) {
                        if (this.props.paymentData.success == false) {
                            return <div className="alert alert-danger alert-dismissible" role="alert">
                                {this.props.paymentData.error}
                            </div>
                        }
                    }
                })()}
                <PaymentDataForm initialValues={this.props.paymentData.form}
                                 hiddenData={this.props.paymentData.hiddenData}
                                 savePaymentData={this.props.savePaymentData}
                                 url={this.props.paymentData.url}
                                 saving={this.props.paymentData.saving}
                                 checking={this.props.paymentData.checking}
                                 startChecking={this.props.startChecking}
                                 stopChecking={this.props.stopChecking}
                                 _error={this.props.paymentData.error}
                                 shortCcNumber={this.props.paymentData.shortCcNumber}
                                 ccNumber={this.props.ccNumber}
                                 url={this.props.paymentData.url}
                                 hiddenData={this.props.paymentData.hiddenData}

                />
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        paymentData: state.paymentData,
        cart: state.cart,
        toplevelDomains: state.toplevelDomains,
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(paymentDataActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage)
