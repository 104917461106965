import {schema} from 'normalizr';

// Domains
export const domain = new schema.Entity('domain', {}, {
    idAttribute: 'domain',
    defaults: {
        loadingAuthcode: false,
        addingRenewalToCart: false,
        authcode: null,
        savingStatus: false,
        savingWPS: false,
    }
});
export const arrayOfDomains = new schema.Array(domain);

// Cart Items
export const cartItem = new schema.Entity('cartItem', {}, {
    idAttribute: 'domain',
    default: {
        adding: false,
        removing: false
    }
});
export const arrayOfCartItem = new schema.Array(cartItem);

// Topleveldomain
export const toplevelDomain = new schema.Entity('toplevelDomain', {}, {
    idAttribute: 'tld',
    default: {
        renew_price: null,
        detail_hint: null
    }
});
export const arrayOfToplevelDomain = new schema.Array(toplevelDomain);

// Search Result
export const searchResult = new schema.Entity('searchResult', {}, {
    idAttribute: 'domain',
});

export const arrayOfSearchResults = new schema.Array(searchResult);

// Contact
export const contact = new schema.Entity('contact', {}, {
    idAttribute: 'handle',
});

export const arrayOfContacts = new schema.Array(contact);
