import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {cartActions} from "../actions";
import CartTable from "../components/CartTable";
import {Button} from "react-bootstrap";
import ConfirmActivateWPSCartModal from "../components/ConfirmActivateWPSCartModal";
import ConfirmPreRegistrations from "../components/ConfirmPreRegistrations";
import PromotionHint from "../components/PromotionHint";
import TldHints from "../components/TldHints";
import Dinero from "dinero.js";
import CheckboxHints from "../components/CheckboxHints";

class OrderReviewPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            checkboxHintsChecked: false,
            pristine: true
        }
    }

    render() {
        const {cart, toplevelDomains} = this.props;
        const {currentWPSDomain} = cart;

        let showCheckboxHints = false;
        for (let cartDomain of cart.dictionary) {
            let cartItem = cart.entities[cartDomain];
            let toplevelDomain = toplevelDomains.entities[cartItem.tld];

            if (toplevelDomain.checkbox_confirmation_hint != undefined && toplevelDomain.checkbox_confirmation_hint != "") {

                showCheckboxHints = true;
                break;
            }
        }

        return (
            <div id="cart">
                <CartTable {...this.props} />

                {showCheckboxHints && this.state.pristine == false &&
                <div className="alert alert-danger" role="alert">You must indicate your acceptance to the terms outlined
                    by the registry or registries below to proceed.</div>
                }
                {showCheckboxHints && <CheckboxHints cart={this.props.cart}
                                                     toplevelDomains={this.props.toplevelDomains}
                                                     onChange={(checked) => {this.setState((props, state) => ({checkboxHintsChecked: checked}))}}
                />
                }
                {(() => {
                    if (this.props.cart.dictionary.length > 0) {
                        if (showCheckboxHints == true && this.state.checkboxHintsChecked == false) {
                            return (<button className="btn btn-success btn-lg pull-right" onClick={() => {
                                this.setState((state, props) => ({...state, pristine: false}))
                            }}>
                                Finish Order
                            </button>);
                        } else {
                            return (<a href={Routing.generate('order_register')}
                                       className="btn btn-success btn-lg pull-right">
                                Finish Order
                            </a>);
                        }
                    } else {
                        return (<Button disabled={true} bsStyle="success" bsSize="large" className="pull-right">Finish
                            order</Button>)
                    }
                })()}

                <ConfirmActivateWPSCartModal
                    cartItem={this.props.cart.entities[currentWPSDomain]}
                    onConfirm={() => this.props.setWps(this.props.cart.entities[currentWPSDomain], true)}
                    onCancel={() => this.props.changeWPSDomain(null)}
                    wpsPrice={Dinero({amount: WPS_PRICE, currency: DEFAULT_CURRENCY})}
                />

                {(() => {
                    if (this.props.cart.preRegsToConfirmDictionary.length > 0) {
                        return <ConfirmPreRegistrations
                            preRegsToConfirmEntities={this.props.cart.preRegsToConfirmEntities}
                            preRegsToConfirmDictionary={this.props.cart.preRegsToConfirmDictionary}
                            preRegsConfirming={this.props.cart.preRegsConfirming}
                            onConfirm={this.props.confirmPreRegs}
                            onCancel={this.props.cancelPreRegs}
                            setPreRegToConfirm={this.props.setPreRegToConfirm}
                            setAllPreRegsToConfirm={this.props.setAllPreRegsToConfirm}
                        />
                    }
                })()}

                <PromotionHint cart={this.props.cart} toplevelDomains={this.props.toplevelDomains}/>
                <TldHints cart={this.props.cart} toplevelDomains={this.props.toplevelDomains} hideCheckboxTlds={true}/>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        cart: state.cart,
        toplevelDomains: state.toplevelDomains
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(cartActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderReviewPage)
