import {addValidator} from "redux-form-validators";

/**
 * People appear to sometimes look only at STD 11, RFC 822 to determine
 * whether a particular hostname is correct or not. Hostnames should
 * strictly conform to the syntax given in STD 13, RFC 1034 (page 11),
 * with *addresses* in addition conforming to RFC 822. As an example
 * take "c&w.blues" which is perfectly legal according to RFC 822, but
 * which can have quite surprising effects on particular systems, e.g.,
 * "telnet c&w.blues" on a Unix system.
 */
export default addValidator({
    validator: (options, value, allValues) => {

        // nullmx is always correct
        if(value == '.' || value == '0 .') {
            return true;
        }

        let parts = value.split(/\s/);
        let re = /^([0-9]{1,5}\s+)?(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])\.?$/i;


        if (!value.match(re) || value.length > 254) {
            return {
                id: "form.errors.dns.invalid_mx",
                defaultMessage: "Invalid entry"
            }
        }

        if(parts.length == 2 && (parseInt(parts[0]) == NaN || parseInt(parts[0]) > 32768)) {
            // wrong priority, also we need to add priority automatically if not specified by the user
            return {
                id: "form.errors.dns.invalid_mx",
                defaultMessage: "Invalid preference (0-32768)"
            }
        }
        if(parts.length == 1) {
            return {
                id: "form.errors.dns.invalid_mx",
                defaultMessage: "Missing preference (e.g. 10 " + parts[0] + ")"
            }
        }

        let mx;
        if(parts.length == 2) {
            mx = parts[1];
        } else {
            mx = parts[0];
        }

        // required only for local mx
        if(mx.endsWith(options.zone)) {
            mx = mx.substr(0, mx.indexOf(mx) - 1);
        }

        if(!mx.endsWith('.')) {
            // check if parts[2] is a cname
            let cnameConflict = options.allResourceRecords.reduce((accumulator, currentValue) => {
                if (
                    currentValue.type == 'cname' &&
                    currentValue.name == mx
                ) {
                    return true;
                }

                return accumulator;
            }, false);

            if (cnameConflict == true) {
                return {
                    id: "form.errors.dns.invalid_mx",
                    defaultMessage: "You cannot use an alias (CNAME) here"
                }
            }
        }

        return true;
    }
});
