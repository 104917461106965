import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from "react-bootstrap";
import {push} from 'connected-react-router';
import {Field, formValueSelector, reduxForm} from "redux-form";
import {searchActions} from "../actions";
import PreviousSearches from "../components/PreviousSearches";
import MaterialIcon from "../components/MaterialIcon";
import SearchField from "../components/Form/SearchField";
import search from "../components/Form/Validators/search";

const validateAndSubmitSearchRequest = (values, dispatch, props) => {
    let searchTerm = getSearchTerm(values.domain);

    if (props.navigate == true) {
        window.location.href = Routing.generate('search_result', {'domain': searchTerm});
    } else {
        dispatch(push(
            Routing.generate('search_result', {'domain': searchTerm})
        ));

        return false;
    }
}

const getSearchTerm = (value) => {
    if (value == undefined) {
        return value;
    }

    if (window.location.pathname.indexOf('/flashsale') == 0) {
        let tld = 'club';
        if (!value.indexOf('.' + tld) >= 0) {
            return value + '.' + tld;
        }
    } else if (window.location.pathname.indexOf('/domain/buy-') == 0) {
        let tld = window.location.pathname.replace('/domain/buy-', '').replace('-domain-names/', '').replace('-', '.');
        if (!value.indexOf('.' + tld) >= 0) {
            // currentSearchTerm
            return value + '.' + tld
        }
    }

    return value.replace(/\s\s*$/gm, "");
}

class SearchForm extends React.Component {

    constructor(props) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);

        if (props.initialValues != undefined && props.initialValues.domain != undefined) {
            this.state = {
                bulkSearch: props.initialValues.domain.split("\n").length > 1
            }
        } else {
            this.state = {
                bulkSearch: false
            }
        }

        this.toggleBulkSearch = this.toggleBulkSearch.bind(this);
    }

    toggleBulkSearch() {
        this.setState({
            bulkSearch: !this.state.bulkSearch
        });
    }

    onKeyDown(event) {
        let code = (event.keyCode ? event.keyCode : event.which);

        if (this.state.bulkSearch == false) {
            if (code == 13) {
                if (this.props.navigate == true) {
                    this.props.submitSearchForm();
                } else {
                    this.props.navigateTo(Routing.generate('search_result', {'domain': getSearchTerm(this.props.currentSearchTerm)}));
                }
                event.stopPropagation();
                event.preventDefault();
                return false;
            }
        }

        if (code == 32) {
            event.stopPropagation();
            event.preventDefault();
            return false;
        }
    }

    render() {
        const { handleSubmit, pristine, reset, submitting } = this.props
        const bulkSearch = this.state.bulkSearch;

        let placeholder;

        if (bulkSearch) {
            placeholder = 'Enter One Domain Per Line';
        }
        else {
            if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= 992) {
                placeholder = 'Start here'
            }
            else {
                placeholder = 'Discover your domain name';
            }
        }

        return (
            <form className={bulkSearch == true ? 'form bulk-search' : 'form single-search'}
                  action={Routing.generate('search_result')}
                  method="GET"
                  onSubmit={ handleSubmit }
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-sm-offset-1 col-xs-9">
                            <Field name="domain"
                                   id="main-search-field"
                                   placeholder={placeholder}
                                   rows={bulkSearch == true ? 4 : 1}
                                   component={SearchField}
                                   label={null}
                                   toggleBulkSearch={this.toggleBulkSearch}
                                   validate={[search()]}
                                   onKeyDown={(e) => {
                                       this.onKeyDown(e);
                                   }}
                            />
                        </div>
                        <div className="col-md-1 col-sm-1 col-xs-3">
                            <Button type="submit" className="btn-black mainsearch">
                                <MaterialIcon icon="search" className="md-49"/>
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <PreviousSearches searchHistory={this.props.history}
                                              navigateTo={this.props.navigateTo}
                                              isFetching={this.props.isFetching}/>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    static propTypes = {
        navigate: PropTypes.bool
    }
}

const mapStateToProps = (state, props) => {

    return {
        ...state.search,
        history: state.searchResult.history,
        isFetching: state.searchResult.current.isFetching || state.searchResult.current.isExpanding,
        currentSearchTerm: formValueSelector(props.form)(state, 'domain')
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(searchActions, dispatch),
})

SearchForm = connect(mapStateToProps, mapDispatchToProps)(SearchForm);

export default reduxForm({
    form: 'SearchForm', // a unique identifier for this form
    onSubmit: validateAndSubmitSearchRequest
})(SearchForm)
