/**
 * Return `dictionary` sorted by `prop` in either ascending or decending order based
 * on the value of `order` (either 'asc' or 'desc').
 *
 * @param prop
 * @param order
 * @param dictionary
 * @param entities
 */
const sortedList = (prop = 'domain', order = 'asc', dictionary = [], entities = {}, toplevelDomains = {}) => {

    return dictionary.sort((idA, idB) => {


        if (prop == '_rand') {
            return .5 - Math.random();
        }

        let a = entities[idA];
        let b = entities[idB];

        if (prop == 'searchresult_weight') {

            if (a.directHit != b.directHit) {
                if (a.directHit > b.directHit) return order === 'asc' ? 1 : -1;
                if (a.directHit < b.directHit) return order === 'asc' ? -1 : 1;
            }

            let tldA = toplevelDomains[a.tld];
            let tldB = toplevelDomains[b.tld];

            if (tldA[prop] > tldB[prop]) return order === 'asc' ? 1 : -1;
            if (tldA[prop] < tldB[prop]) return order === 'asc' ? -1 : 1;

            if (tldA[prop] == tldB[prop]) {
                if (a.domain > b.domain) return order === 'asc' ? 1 : -1;
                if (a.domain < b.domain) return order === 'asc' ? -1 : 1;
            }
        } else {
            if (a[prop] > b[prop]) return order === 'asc' ? 1 : -1;
            if (a[prop] < b[prop]) return order === 'asc' ? -1 : 1;
        }

        return 0;
    });
};

/**
 * Return a new array, a subset of `dictionary`, where the corresponding entities
 * are matching the given `filter`. Assumes an array of ids (dictionary) and a
 * corresponding entities-object (entities).
 * It cycles through the dictionary, get the corresponding entity, looks at
 * each property, and compares all string properties to the value of the
 * `filter` string, returning only those which contain an exact match.
 *
 * @param filter
 * @param dictionary
 * @param entities
 * @returns {dictionary}
 */
const filteredList = (filters, dictionary = [], entities = {}, toplevelDomains = {}) => {

    return dictionary.filter((domain) => {
        var searchResult = entities[domain];
        var toplevelDomain = toplevelDomains[searchResult.tld];

        var ret = true;

        if (filters.hideTakenDomains == false &&
            filters.promos == false &&
            filters.stati.live == false &&
            filters.stati.preOrder == false &&
            filters.stati.preReg == false &&
            Object.keys(filters.categories).length == 0) {
            return true;
        }

        if (filters.hideTakenDomains == true) {
            ret = searchResult.status == "available" && ret;
        }

        if (filters.promos == true) {
            ret = (
                    (
                        toplevelDomain.promotion_price != null &&
                        toplevelDomain.promotion_price != undefined
                    ) &&
                    searchResult.premium == 0
                )
                && ret;
        }

        if (filters.stati.live == true || filters.stati.preOrder == true || filters.stati.preReg == true) {
            let statRet = false;
            if (filters.stati.live == true) {
                statRet = toplevelDomain.status == 10 || statRet;
            }
            if (filters.stati.preOrder) {
                statRet = toplevelDomain.status == 1 || statRet;
            }
            if (filters.stati.preReg) {
                statRet = toplevelDomain.status == 0 || statRet;
            }

            ret = statRet && ret;
        }

        if (Object.keys(filters.categories).length > 0 || filters.top25 == true) {
            let catRet = false;
            Object.keys(filters.categories).forEach((categoryId) => {
                catRet = toplevelDomain.categories.includes(parseInt(categoryId)) || catRet;
            });

            ret = catRet && ret;
        }

        return ret;
    });
};

const searchResultReducer = (state = {
    orderBy: 'searchresult_weight',
    order: 'desc',
    filters: {
        hideTakenDomains: false,
        stati: {
            live: false,
            preOrder: false,
            preReg: false,
            promo: false
        },
        categories: {},
    },
    current: {
        searchTerms: null,
        expectedResultCount: 0,
        entities: {},
        dictionary: [],
        filteredList: [],
        eventSource: null,
        isFetching: false,
        isFetched: false,
        isExpandable: false,
        isExpanding: false,
        isExpanded: false,
        extendingEventSource: null
    },
    history: []
}, action) => {

    let entities;
    let dictionary;

    switch (action.type) {
        case 'SEARCH_RESULT_SHOW_PREVIOUS_RESULT':

            const previousSearch = state.history[action.payload.index];

            return {
                history: [
                    ...state.history.filter((element, index) => {
                        return index != action.payload.index
                    }),
                    state.current
                ],
                current: {
                    ...previousSearch,
                    filteredList: filteredList(
                        state.filters,
                        sortedList(state.orderBy, state.order, previousSearch.dictionary, previousSearch.entities, action.getState().toplevelDomains.entities),
                        previousSearch.entities,
                        action.getState().toplevelDomains.entities
                    )
                },
                filters: state.filters,
                order: state.order,
                orderBy: state.orderBy
            }
            break;
        case "SEARCH_RESULT_EVENT_OPEN":

            if (state.current.isFetched) {
                return {
                    filters: state.filters,
                    order: state.order,
                    orderBy: state.orderBy,
                    history: [
                        ...state.history,
                        state.current
                    ],
                    current: {
                        entities: {},
                        dictionary: [],
                        filteredList: [],
                        isFetching: true,
                        isFetched: false,
                        isExpanding: false,
                        isExpanded: false,
                        eventSource: action.event.target,
                        expandingEventSource: null,
                        searchTerms: action.searchTerms,
                        isExpandable: (action.searchTerms.length == 1),
                        expectedResultCount: 0
                    }
                }
            } else {
                return {
                    ...state,
                    order: 'desc',
                    orderBy: 'searchresult_weight',
                    current: {
                        ...state.current,
                        isFetching: true,
                        eventSource: action.event.target,
                        searchTerms: action.searchTerms,
                        isExpandable: (action.searchTerms.length == 1),
                        expectedResultCount: 0
                    }
                }
            }
            break;
        case 'SEARCH_RESULT_EVENT_STATS':
            return {
                ...state,
                current: {
                    ...state.current,
                    expectedResultCount: state.current.expectedResultCount + parseInt(action.payload)
                }
            }
        case 'SEARCH_RESULT_EVENT_RESULT':
            entities = state.current.entities;

            entities[action.result.domain] = action.result;
            dictionary = [
                ...state.current.dictionary,
                action.result.domain
            ];

            return {
                ...state,
                current: {
                    ...state.current,
                    dictionary: dictionary,
                    filteredList: filteredList(
                        state.filters,
                        sortedList(state.orderBy, state.order, dictionary, entities, action.getState().toplevelDomains.entities),
                        entities,
                        action.getState().toplevelDomains.entities
                    ),
                    entities: entities
                }
            }
            break;
        case 'SEARCH_RESULT_EVENT_FINISH':

            state.current.eventSource.close();

            return {
                ...state,
                current: {
                    ...state.current,
                    isFetching: false,
                    isFetched: true,
                    eventSource: null
                }
            }
            break;
        case 'SEARCH_RESULT_EVENT_MESSAGE':
            return state;
            break;
        case 'SEARCH_RESULT_EVENT_ERROR':
            try {
                state.current.eventSource.close();
            } catch (err) {
            }

            return {
                ...state,
                current: {
                    ...state.current,
                    isFetching: false,
                    isFetched: true,
                    eventSource: null
                }
            }
            break;
        case "SEARCH_RESULT_EXPANDING_EVENT_OPEN":
            return {
                ...state,
                current: {
                    ...state.current,
                    isExpanding: true,
                    expandingEventSource: action.event.target,
                }
            }
            break;
        case 'SEARCH_RESULT_EXPANDING_EVENT_STATS':
            return {
                ...state,
                current: {
                    ...state.current,
                    expectedResultCount: state.current.expectedResultCount + parseInt(action.payload)
                }
            }
        case 'SEARCH_RESULT_EXPANDING_EVENT_RESULTS':

            entities = state.current.entities;
            var domains = [];

            action.results.forEach((result) => {
                entities[result.domain] = result;
                domains.push(result.domain);
            });

            dictionary = [
                ...state.current.dictionary,
                ...domains
            ]

            return {
                ...state,
                current: {
                    ...state.current,
                    dictionary: dictionary,
                    filteredList: filteredList(
                        state.filters,
                        sortedList(state.orderBy, state.order, dictionary, entities, action.getState().toplevelDomains.entities),
                        entities,
                        action.getState().toplevelDomains.entities
                    ),
                    entities: entities
                }
            }
            break;
        case 'SEARCH_RESULT_EXPANDING_EVENT_FINISH':

            state.current.expandingEventSource.close();

            return {
                ...state,
                current: {
                    ...state.current,
                    isExpanding: false,
                    isExpanded: true,
                    expandingEventSource: null
                }
            }
            break;
        case 'SEARCH_RESULT_EXPANDING_EVENT_MESSAGE':
            return state;
            break;
        case 'SEARCH_RESULT_EXPANDING_EVENT_ERROR':
            try {
                state.current.eventSource.close();
            } catch (err) {
            }

            return {
                ...state,
                current: {
                    ...state.current,
                    isExpanding: false,
                    isExpanded: false,
                    expandingEventSource: null
                }
            }
            break;
        case 'SEARCH_RESULT_SORT': {
            const newOrder = action.orderBy === state.orderBy && state.order === 'asc' ? 'desc' : 'asc';

            return {
                ...state,
                current: {
                    ...state.current,
                    filteredList: filteredList(
                        state.filters,
                        sortedList(action.orderBy, newOrder, state.current.dictionary, state.current.entities, action.getState().toplevelDomains.entities),
                        state.current.entities,
                        action.getState().toplevelDomains.entities
                    )
                },
                orderBy: action.orderBy,
                order: newOrder,
            };
        }
        case 'SEARCH_RESULT_SET_FILTERS': {
            return {
                ...state,
                filters: action.payload,
                current: {
                    ...state.current,
                    filteredList: filteredList(
                        action.payload,
                        sortedList(state.order, state.orderBy, state.current.dictionary, state.current.entities, action.getState().toplevelDomains.entities),
                        state.current.entities,
                        action.getState().toplevelDomains.entities
                    )
                },
            }
        }
        default: {
            return state;
        }
    }
};

export default searchResultReducer;