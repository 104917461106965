import {addValidator} from "redux-form-validators";

/**
 * People appear to sometimes look only at STD 11, RFC 822 to determine
 * whether a particular hostname is correct or not. Hostnames should
 * strictly conform to the syntax given in STD 13, RFC 1034 (page 11),
 * with *addresses* in addition conforming to RFC 822. As an example
 * take "c&w.blues" which is perfectly legal according to RFC 822, but
 * which can have quite surprising effects on particular systems, e.g.,
 * "telnet c&w.blues" on a Unix system.
 */
export default addValidator({
    validator: (options, value, allValues) => {

        /**
         * Both RDATA fields are required in all RP RRs.
         */

        let parts = value.split(/\s/);

        if(parts.length != 2) {
            return {
                id: "form.errors.dns.invalid_rp",
                defaultMessage: "Invalid value"
            }
        }

        /**
         * The first field, <mbox-dname>, is a domain name that specifies the
         * mailbox for the responsible person.  Its format in master files uses
         * the DNS convention for mailbox encoding, identical to that used for
         * the RNAME mailbox field in the SOA RR.  The root domain name (just
         * ".") may be specified for <mbox-dname> to indicate that no mailbox is
         * available.
         */
        if(parts[0] != ".") {
            if(parts[0].endsWith('.')) {
                let mailbox = parts[0].replace(/([^\\])\./, '$1@');
                mailbox = mailbox.replace(/\\\./, '.');

                if(!mailbox.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\.?$/)) {
                    return {
                        id: "form.errors.dns.invalid_rp",
                        defaultMessage: "Invalid email"
                    }
                }
            } else {
                if(!parts[0].match(/([a-z0-9!#$%&'*+/=?^_`{|}~-])+(\\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*/)) {
                    return {
                        id: "form.errors.dns.invalid_rp",
                        defaultMessage: "Invalid email"
                    }
                }
            }
        }


        /**
         * The second field, <txt-dname>, is a domain name for which TXT RR's
         * exist.  A subsequent query can be performed to retrieve the
         * associated TXT resource records at <txt-dname>.  This provides a
         * level of indirection so that the entity can be referred to from
         * multiple places in the DNS.  The root domain name (just ".") may be
         * specified for <txt-dname> to indicate that the TXT_DNAME is absent,
         * and no associated TXT RR exists.
         */
        if(parts[1] != '.' && options.allResourceRecords) {
            let hasMatchingTxt = false;

            for(let record of options.allResourceRecords) {
                if(record.type == 'txt' && record.name == parts[1]) {
                    hasMatchingTxt = true;
                    break;
                }
            }

            if(hasMatchingTxt != true) {
                return {
                    id: "form.errors.dns.invalid_rp",
                    defaultMessage: 'No matching TXT record with name "' + parts[1] + '" found'
                }
            }
        }

        return true;
    }
});
