import React from "react";
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";
import ShortenedDomain from "../components/ShortenedDomain";
import FormattedCurrency from "../components/FormattedCurrency";
import {FormattedDate} from "react-intl";
import MaterialIcon from "./MaterialIcon";
import LoadingIndicator from "./LoadingIndicator";
import Dinero from "dinero.js";

class CartTable extends React.Component {

    constructor(props) {
        super(props);

        if (window.matchMedia) {
            const mq = window.matchMedia("(min-width: 768px)");
            mq.addListener(this.windowResize.bind(this));

            this.state = {
                mobile: !mq.matches
            };
        } else {
            this.state = {
                mobile: false
            }
        }
    }

    windowResize(query) {
        if (query.matches) {
            this.setState({
                mobile: false
            });
        } else {
            this.setState({
                mobile: true
            });
        }
    }


    getRegistrationType(cartItem) {
        switch (cartItem.type) {
            case 'pre-registration':
                return (<span className="small">(Free Pre-Registration)</span>);
                break;
            case 'pre-order':
                return (<span className="small">(Binding Pre-Order)</span>);
                break;
            case 'live-registration':
                return (<span className="small">(Registration)</span>);
                break;
            case 'renew':
                return (<span className="small">(Renew)</span>);
                break;
            case 'transfer':
                return (<span className="small">(Transfer)</span>);
                break;
            case 'aftermarket':
                return (<span className="small">(Purchase)</span>);
            default:
        }
    }

    getTableContents(containsRenewals) {

        return this.props.cart.dictionary.map((domainName) => {
            let cartItem = this.props.cart.entities[domainName];
            let toplevelDomain = this.props.toplevelDomains.entities[cartItem.tld];

            const rows = []
            rows.push(
                <tr key={cartItem.domain}>
                    <td className="domain"
                        colSpan={containsRenewals == true && cartItem.type != 'renew' && this.state.mobile != true ? 2 : 1}>
                        <strong>
                            <ShortenedDomain domain={cartItem.domain} length={this.state.mobile == true ? 20 : 40}/>
                        </strong>
                        &nbsp;&nbsp;
                        {this.getRegistrationType(cartItem)}
                        {(() => {
                            if (toplevelDomain.hint) {
                                return <span>
                                    <br/>
                                    <span className="color-blue">{toplevelDomain.hint}</span>
                                </span>
                            }
                        })()}
                    </td>
                    {(() => {
                        if (cartItem.type == 'renew') {
                            let originalAccountingDate = new Date(cartItem.original_accounting_date);
                            let newAccountingDate = new Date(
                                originalAccountingDate.getFullYear() + 1,
                                originalAccountingDate.getMonth(),
                                originalAccountingDate.getDate(),
                                originalAccountingDate.getHours(),
                                originalAccountingDate.getMinutes(),
                            );

                            return <td className="hidden-xs">
                                <small>
                                    Current Expiration Date:&nbsp;
                                    <span className="originalExpirationDate">
                                        <FormattedDate
                                            value={originalAccountingDate}
                                            year='numeric'
                                            month='long'
                                            day='2-digit'/>
                                    </span>
                                </small>
                                <br/>
                                <small>
                                    New Expiration Date:&nbsp;
                                    <span className="newExpirationDate">
                                        <FormattedDate
                                            value={newAccountingDate}
                                            year='numeric'
                                            month='long'
                                            day='2-digit'/>
                                    </span>
                                </small>
                            </td>
                        }
                    })()}
                    <td className="price">
                        {/* TODO: Currency Calculation */}
                        <FormattedCurrency value={cartItem.price} addtax={true}/>
                        {toplevelDomain.promotion_price != null ? ' *' : ''}
                        {(() => {
                            if (cartItem.renewal_price != undefined && cartItem.price.amount != cartItem.renewal_price.amount) {
                                return (<span className="renewalInfo hidden-xs">
                                            <br/>
                                            <small>(renews for <FormattedCurrency value={cartItem.renewal_price} addtax={true}/>)
                                            </small>
                                        </span>)
                            }
                        })()}
                    </td>
                    <td className="actions">
                        {(() => {
                            if (cartItem.removing == true) {
                                return (<LoadingIndicator active inline size="small"/>);
                            } else {
                                return (
                                    <a onClick={(e) => {e.preventDefault(); this.props.removeDomainFromCart(cartItem)}}>
                                        <MaterialIcon icon="clear"/>
                                    </a>
                                );
                            }
                        })()}
                    </td>
                </tr>
            );

            return rows;
        })
    }

    render() {
        let containsRenewals = this.props.cart.dictionary.reduce((cr, domainName) => {
            let cartItem = this.props.cart.entities[domainName];

            return cr || (cartItem.type == 'renew');
        }, false);

        let total = this.props.cart.dictionary.reduce((total, domainName) => {
            let cartItem = this.props.cart.entities[domainName];

            {/* TODO: Currency Calculation */}
            return total.add(Dinero(cartItem.price));
        }, Dinero({amount: 0, currency: DEFAULT_CURRENCY}));


        return (
            <Table responsive hover striped className="mtb25" title="My Shopping Cart" id="cart">
                <thead>
                <tr>
                    <th colSpan={containsRenewals && this.state.mobile == false ? 2 : 1}>Domain</th>
                    <th className="price">Price</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {(() => {
                    if (this.props.cart.dictionary.length > 0) {
                        return this.getTableContents(containsRenewals)
                    } else {
                        return (<tr id="no-items">
                            <td colSpan={containsRenewals == true ? 6 : 5}>
                                <form action={Routing.generate('search_result')} method="get">
                                    <span className="red">Your cart is empty. Please perform a domain search.</span>
                                    <div className="search-form-items clearfix ">
                                        <div className="form-group search-form-input-field">
                                            <input placeholder="Find Domain Names"
                                                   type="text"
                                                   className="form-control"
                                                   name="domain"/>
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-md btn-warning submit" type="submit">
                                                <span className="glyphicon glyphicon-search"></span> Check Availability
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </td>
                        </tr>);
                    }
                    //onClick="$('#main-search-field').val($('#cartsearch').val()); $('#search-form').submit(); return false"
                })()}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={this.state.mobile == true ? 1 : (containsRenewals ? 2 : 1)}>
                        <strong>Total:</strong>
                    </td>
                    <td className="price">
                        <strong id="totalprice">
                            <FormattedCurrency value={total} addtax={true}/>
                        </strong>
                    </td>
                    <td>
                        <LoadingIndicator active={this.props.loading} inline={true} size="small"/>
                    </td>
                </tr>
                </tfoot>
            </Table>
        )
    }
}

CartTable.propTypes = {
    // actions
    removeDomainFromCart: PropTypes.func.isRequired,

    // state data
    cart: PropTypes.object.isRequired,
    toplevelDomains: PropTypes.object.isRequired
}

export default CartTable
